import { AmortizedCostDto } from "../../api/types";

function validateAmortizationCosts(amortizationCosts: Array<AmortizedCostDto>) {
  let invalid = false;

  if (!amortizationCosts?.length) {
    return invalid;
  }

  for (let item of amortizationCosts) {
    if (
      !item.from ||
      !item.amount ||
      !item.description ||
      !item.counterType ||
      !item.costDistributionType
    ) {
      invalid = true;
    }
  }

  return invalid;
}

export default validateAmortizationCosts;
