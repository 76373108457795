import produce, { Draft } from "immer";
import { State, StateCreator } from "zustand";

const immerMiddleware = <T extends State>(
  config: StateCreator<T>
): StateCreator<T> => (set, get, api) =>
  config(
    (partial, replace) => {
      const nextState =
        typeof partial === "function"
          ? produce(partial as (state: Draft<T>) => T)
          : (partial as T);
      return set(nextState, replace);
    },
    get,
    api
  );

export default immerMiddleware;
