import React, { useEffect, useState } from "react";
import PageLayout from "../../../layouts/PageLayout/PageLayout";
import { Box } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import SearchHeader from "../../../components/SearchHeader";
import TenantSelector from "../../../fragments/TenantSelector";
import NewTenantDialog from "../../../components/NewTenantDialog";
import { FormikProvider, useFormik } from "formik";
import ErrorAlert from "../../../components/ErrorAlert";
import { CardRow } from "../../../components/Card";
import TenantForm from "../../../components/Forms/TenantForm";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import useListApartments from "../../../api/hooks/useListApartments";
import { useQueryClient } from "react-query";
import useDeleteTenant from "../../../api/hooks/useDeleteTenant";
import useListTenants from "../../../api/hooks/useListTenats";
import useSetTenant from "../../../api/hooks/useSetTenant";
import {
  TenantInfoFormFields,
  TenantInfoFormValues,
} from "../../../components/Forms/TenantInfoForm/TenantInfoForm";
import { TenantApartmentsFormValues } from "../../../components/Forms/TenantApartmentsForm/TenantApartmentsForm";
import { ApartmentWithDatesDto } from "../../../api/types";
import QueryKeys from "../../../api/QueryKeys";
import routes from "../../../constants/routes";
import { validateTenantForm } from "../../../components/NewTenantDialog/NewTenantDialog";
import { formatTenantApartments } from "../../../utils/format/formatTenantApartments";
import Button from "../../../components/Button";

const useStyles = makeStyles(() => ({
  errorAlert: {
    marginTop: 16,
  },
}));

function TenantDetailPage() {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { tenantId } = useParams<{ tenantId: string }>();

  const { data: apartmentsData } = useListApartments();
  const setTenant = useSetTenant();
  const deleteTenant = useDeleteTenant();
  const { data } = useListTenants({ id: tenantId }, !!tenantId);

  const tenant = data?.tenants[0];

  const isEditable = tenant ? tenant?.isEditable : true;

  const [newTenantDialogOpen, setNewTenantDialogOpen] = useState<boolean>(
    false
  );

  const formik = useFormik<TenantInfoFormValues & TenantApartmentsFormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      city: "",
      addressLine1: "",
      addressLine2: "",
      zip: "",
      email: "",
      apartments: [
        {
          apartment: null,
          dateIn: null,
          dateOut: null,
        },
      ],
    },
    onSubmit: () => {},
    validate: validateTenantForm,
    validateOnChange: true,
  });

  useEffect(() => {
    if (tenant) {
      formik.setValues({
        firstName: tenant?.firstName,
        lastName: tenant?.lastName,
        city: tenant?.address?.city,
        addressLine1: tenant?.address?.addressLineOne,
        addressLine2: tenant?.address?.addressLineTwo,
        zip: tenant?.address?.zip,
        email: tenant?.email,
        apartments: tenant?.apartments.map(
          (apartment: ApartmentWithDatesDto) => {
            return {
              apartment: apartment,
              dateIn: apartment?.dateIn,
              dateOut: apartment?.dateOut,
            };
          }
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  const handleSubmit = async () => {
    const {
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      city,
      email,
      zip,
      apartments,
    } = formik.values;
    await setTenant.mutate(
      {
        id: tenantId,
        firstName,
        lastName,
        email,
        address: {
          addressLineOne: addressLine1,
          addressLineTwo: addressLine2,
          city,
          zip,
        },
        apartments: formatTenantApartments(apartments),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.LIST_TENANTS]);
          formik.resetForm();
          history.push(routes.owner.tenant);
        },
      }
    );
  };

  const handleDelete = () => {
    deleteTenant.mutate(tenantId || "", {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.LIST_TENANTS]);
        formik.resetForm();
        history.push(routes.owner.tenant);
      },
    });
  };

  let invalidApartmentsData = false;

  if (!formik.values.apartments?.length) {
    invalidApartmentsData = true;
  }

  for (let item of formik.values.apartments) {
    if (!item?.apartment?.id || !item?.dateIn) {
      invalidApartmentsData = true;
    }
  }

  const isLoading = setTenant.isLoading || deleteTenant.isLoading;

  const header = (
    <SearchHeader
      onButtonNewClick={() => setNewTenantDialogOpen(true)}
      selector={
        <TenantSelector
          onSelect={(tenant) =>
            history.push(routes.owner.getTenantDetail(tenant?.id || ""))
          }
        />
      }
    />
  );

  const footer = (
    <CardRow footer justifyContent="space-between">
      <Button
        loading={deleteTenant.isLoading}
        variant="outlined"
        color="default"
        onClick={handleDelete}
        disabled={isLoading || !isEditable}
      >
        {t("delete")}
      </Button>
      <Button
        loading={setTenant.isLoading}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={
          isLoading || !formik.isValid || invalidApartmentsData || !isEditable
        }
      >
        {t("save")}
      </Button>
    </CardRow>
  );

  return (
    <PageLayout
      displayBackButton
      onNavigateBack={() => history.push(routes.owner.tenant)}
      loading={false}
      error={false}
      layoutType="owner"
      sticky={header}
      footer={footer}
      contentMarginTop
    >
      <FormikProvider value={formik}>
        <Box padding={3}>
          <TenantForm
            values={formik.values}
            onChange={formik.handleChange}
            apartmentsList={apartmentsData?.apartments || []}
            isEditable={isEditable}
            // Disable email field until we figure out how to update emails also on keycloak
            disabledFields={new Set([TenantInfoFormFields.Email])}
          />
          <ErrorAlert
            error={setTenant?.error || deleteTenant?.error}
            className={classes.errorAlert}
          />
        </Box>
      </FormikProvider>
      <NewTenantDialog
        open={newTenantDialogOpen}
        onClose={() => setNewTenantDialogOpen(false)}
      />
    </PageLayout>
  );
}

export default TenantDetailPage;
