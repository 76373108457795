import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikValues } from "formik";
import { Grid, TextFieldProps, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormCard from "../FormCard";
import Input from "../../Input";
import ListIcon from "@material-ui/icons/FormatListBulleted";
import { BORDER_COLOR_SECONDARY, SUBHEADER_COLOR } from "../../../theme";

export enum TenantInfoFormFields {
  FirstName = "firstName",
  LastName = "lastName",
  AddressLine1 = "addressLine1",
  AddressLine2 = "addressLine2",
  Zip = "zip",
  City = "city",
  Email = "email",
}

export interface TenantInfoFormValues extends FormikValues {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  zip: string;
  city: string;
  email: string;
}

interface Props {
  values: TenantInfoFormValues;
  onChange: (e: React.ChangeEvent<any>) => void;
  disabledFields?: Set<TenantInfoFormFields>;
  isEditable?: boolean;
}

const useStyles = makeStyles(() => ({
  grid: { maxWidth: 780 },
  subheader: {
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 5,
    color: SUBHEADER_COLOR,
    width: "100%",
    borderBottom: `1px solid ${BORDER_COLOR_SECONDARY}`,
  },
}));

function TenantInfoForm({
  onChange,
  values,
  isEditable = true,
  disabledFields,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const getTextFieldProps = (
    fieldName: TenantInfoFormFields
  ): TextFieldProps => ({
    id: fieldName,
    name: fieldName,
    onChange,
    color: "secondary",
    disabled: !isEditable || disabledFields?.has(fieldName),
    InputLabelProps: { shrink: true },
    fullWidth: true,
  });

  const {
    firstName,
    lastName,
    addressLine2,
    addressLine1,
    zip,
    city,
    email,
  } = values;

  return (
    <FormCard
      header={`${t("name")}, ${t("address")}`}
      headerIcon={<ListIcon fontSize="small" />}
    >
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12} sm={6}>
          <Input
            required
            value={firstName}
            label={t("tenantName")}
            data-cy="tenant-form-name-input"
            InputProps={{
              ...getTextFieldProps(TenantInfoFormFields.FirstName),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            required
            value={lastName}
            label={t("tenantSurname")}
            data-cy="tenant-form-surname-input"
            InputProps={{
              ...getTextFieldProps(TenantInfoFormFields.LastName),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subheader}>{t("address")}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            required
            value={addressLine1}
            label={`${t("addressLine")} 1`}
            data-cy="tenant-form-address1-input"
            gutterBottom={0}
            InputProps={{
              ...getTextFieldProps(TenantInfoFormFields.AddressLine1),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            value={addressLine2}
            label={`${t("addressLine")} 2`}
            data-cy="tenant-form-address2-input"
            gutterBottom={0}
            InputProps={{
              ...getTextFieldProps(TenantInfoFormFields.AddressLine2),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input
            required
            value={zip}
            label={t("zip")}
            data-cy="tenant-form-zip-input"
            gutterBottom={0}
            InputProps={{
              ...getTextFieldProps(TenantInfoFormFields.Zip),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={9} />
        <Grid item xs={12} sm={6}>
          <Input
            required
            value={city}
            label={t("city")}
            data-cy="tenant-form-city-input"
            gutterBottom={0}
            InputProps={{
              ...getTextFieldProps(TenantInfoFormFields.City),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subheader}>{t("contact")}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            required
            value={email}
            label={t("email")}
            data-cy="tenant-form-email-input"
            gutterBottom={0}
            InputProps={{
              ...getTextFieldProps(TenantInfoFormFields.Email),
            }}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
}

export default TenantInfoForm;
