import React from "react";
import TenantInfoForm from "../TenantInfoForm";
import TenantApartmentsForm from "../TenantApartmentsForm";
import {
  TenantInfoFormFields,
  TenantInfoFormValues,
} from "../TenantInfoForm/TenantInfoForm";
import { TenantApartmentsFormValues } from "../TenantApartmentsForm/TenantApartmentsForm";
import { TenantFormApartmentsFragment } from "../../../api/types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    "& > div:first-child": {
      marginBottom: 16,
    },
  },
}));

interface Props {
  values: TenantInfoFormValues & TenantApartmentsFormValues;
  onChange: (e: React.ChangeEvent<any>) => void;
  apartmentsList: Array<TenantFormApartmentsFragment | null>;
  disabledFields?: Set<TenantInfoFormFields>;
  isEditable?: boolean;
}

function TenantForm({
  values,
  onChange,
  apartmentsList,
  isEditable,
  disabledFields,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TenantInfoForm
        values={values}
        onChange={onChange}
        isEditable={isEditable}
        disabledFields={disabledFields}
      />
      <TenantApartmentsForm
        values={values}
        apartmentsList={apartmentsList}
        isEditable={isEditable}
      />
    </div>
  );
}

export default TenantForm;
