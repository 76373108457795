import { useKeycloak } from "@react-keycloak/web";
import { BASE_URL } from "../cfg";

function useDownloadInvoice() {
  const { keycloak } = useKeycloak();

  const handleDownload = async (invoiceId: string) => {
    const token = keycloak.token;
    const fileName = `${invoiceId}.pdf`;
    const anchor = document.createElement("a");
    const headers = {
      authorization: `Bearer ${token}`,
    };

    document.body.append(anchor);

    try {
      const response = await fetch(
        `${BASE_URL}/invoice/${invoiceId}/download`,
        { headers }
      );

      if (response.status !== 200) {
        throw new Error(response.status.toString());
      }

      const blob = await response.blob();
      const fileUrl = window.URL.createObjectURL(blob);
      anchor.href = fileUrl;
      anchor.download = fileName;
      anchor.click();
      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.log("Failed to download pdf", error);
    }
  };

  return {
    handleDownload,
  };
}

export default useDownloadInvoice;
