import { gql } from "graphql-request";
import { useQuery } from "react-query";
import QueryKeys from "../QueryKeys";
import { GetFiltersInput } from "../types";
import { gqlClient } from "../../App";

function useListTenants(filters?: GetFiltersInput, enabled = true) {
  return useQuery(
    [QueryKeys.LIST_TENANTS, filters],
    async () => {
      return await gqlClient.request(
        gql`
          query ListTenants($filters: GetFiltersInput) {
            tenants(filters: $filters) {
              id
              isEditable
              firstName
              lastName
              email
              isEditable
              address {
                addressLineOne
                addressLineTwo
                city
                zip
              }
              apartments {
                id
                name
                dateIn
                dateOut
                apartmentNumber
              }
            }
          }
        `,
        {
          filters,
          operationName: QueryKeys.LIST_TENANTS,
        }
      );
    },
    {
      enabled,
    }
  );
}

export default useListTenants;
