import _ from "lodash";
import { ChartItemFieldsFragment } from "../../../api/types";
import { BarChartData, BarChartItem } from "../../../types";
import getLabel from "../getLabel";
import formatCurrency from "../formatCurrency";

function getBarChartData(
  items: Array<ChartItemFieldsFragment | null> = [],
  valueKey: "value" | "totalPrice" = "totalPrice"
): BarChartData {
  if (!items || !items.length) {
    return {
      keys: [],
      data: [],
    };
  }
  const renderPrice = valueKey === "totalPrice";

  const dataMap: any = {};

  const createMap: any = () => {
    for (const item of items) {
      if (!item) {
        continue;
      }

      const key = item.item;
      const currentItem = dataMap[key] || {};
      const itemType = getLabel(item.itemType);

      dataMap[key] = {
        ...currentItem,
        item: item.item,
        itemType,
        type: itemType,
        [`${itemType}`]:
          // @ts-ignore
          Math.round((item[valueKey] + Number.EPSILON) * 100) / 100,
        [`${itemType}Unit`]: item.unit,
      };
    }
  };

  createMap();
  const keys = _.chain(items)
    .groupBy("itemType")
    .keys()
    .value()
    .map((k) => getLabel(k));
  const data: Array<BarChartItem> = _.flatMap(dataMap);

  return {
    keys,
    data,
    axisLeft: {
      format: renderPrice ? formatCurrency : (v: any) => `${v}`,
    },
  };
}

export default getBarChartData;
