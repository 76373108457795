import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

interface Props {
  value: number;
  index: number;
  children: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles(() => ({
  box: {
    padding: "24px 20px",
  },
}));

function TabPanel(props: Props) {
  const { children, value, index, className, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={className}
      {...other}
    >
      {value === index && <Box className={classes.box}>{children}</Box>}
    </div>
  );
}

export default TabPanel;
