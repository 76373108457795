import { ApartmentFormValues } from "../components/_deprecated/Forms/ApartmentForm/ApartmentForm";
import { ConsumptionType } from "../api/types";
import { PropertyFormValues } from "../components/_deprecated/Forms/PropertyForm/PropertyForm";

function formatPrice(price: string | number | null) {
  if (!price) {
    return 0;
  }

  const formattedPrice = price.toString().replace(/,/g, ".");

  return Number(formattedPrice);
}

function getPricingInput(values: ApartmentFormValues | PropertyFormValues) {
  let additionalCosts = [];

  if (values.additionalCosts) {
    additionalCosts = values.additionalCosts
      .filter((c: any) => Boolean(c?.label && c?.basePrice))
      .map((c: any) => ({
        ...c,
        basePrice: formatPrice(c?.basePrice),
      }));
  }

  return [
    {
      basePrice: formatPrice(values?.pricingElectricityH),
      pricingType: ConsumptionType.ElectricityH,
    },
    {
      basePrice: formatPrice(values?.pricingElectricityL),
      pricingType: ConsumptionType.ElectricityL,
    },
    {
      basePrice: formatPrice(values?.pricingElectricityPV),
      pricingType: ConsumptionType.ElectricityPv,
    },
    {
      basePrice: formatPrice(values?.pricingElectricity),
      pricingType: ConsumptionType.Electricity,
    },
    {
      basePrice: formatPrice(values?.pricingMaximumPower),
      pricingType: ConsumptionType.MaximumPower,
    },
    {
      basePrice: formatPrice(values?.pricingWaterCold),
      pricingType: ConsumptionType.WaterColdVolume,
    },
    {
      basePrice: formatPrice(values?.pricingWaterWarm),
      pricingType: ConsumptionType.WaterWarmVolume,
    },
    {
      basePrice: formatPrice(values?.pricingHeating),
      pricingType: ConsumptionType.HeatingEnergy,
    },
    {
      basePrice: formatPrice(values?.pricingCharging),
      pricingType: ConsumptionType.Charging,
    },
    ...additionalCosts,
  ];
}

export default getPricingInput;
