import React, { ChangeEvent } from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikValues } from "formik";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormCard from "../FormCard";
import { PropertyOwnerDto } from "../../../api/types";
import PropertyOwnerAutocomplete from "./PropertyOwnerAutocomplete";
import PersonIcon from "@material-ui/icons/Person";

export enum PropertyOwnerFormFields {
  PropertyOwner = "propertyOwner",
}

export interface PropertyOwnerFormValues extends FormikValues {
  propertyOwner: Partial<(PropertyOwnerDto & { name?: string }) | null>;
}

interface Props {
  values: PropertyOwnerFormValues;
  onChange: (e: React.ChangeEvent<any>) => void;
  disabledFields?: Set<PropertyOwnerFormFields>;
  onFieldChange?: (fieldName: string, value: any) => void;
  placeholders?: {
    [key: string]: number | string;
  };
  isEditable?: boolean;
}

const useStyles = makeStyles(() => ({
  grid: { maxWidth: 368 },
}));

function PropertyOwnerForm({
  values,
  onFieldChange,
  isEditable = true,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { propertyOwner } = values;

  return (
    <FormCard
      header={t("owner")}
      headerIcon={<PersonIcon fontSize="default" />}
    >
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12}>
          <PropertyOwnerAutocomplete
            disabled={!isEditable}
            value={propertyOwner}
            onChange={(
              e: ChangeEvent<any>,
              value: Partial<PropertyOwnerDto>
            ) => {
              if (onFieldChange) {
                onFieldChange(PropertyOwnerFormFields.PropertyOwner, value);
              }
            }}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
}

export default PropertyOwnerForm;
