import React from "react";
import PageLayout from "../../../layouts/PageLayout/PageLayout";
import SearchHeader from "../../../components/SearchHeader";
import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import ApartmentSelector from "../../../fragments/ApartmentSelector";
import routes from "../../../constants/routes";
import { useHistory } from "react-router";
import useDeleteInvoice from "../../../api/hooks/useDeleteInvoice";
import QueryKeys from "../../../api/QueryKeys";
import { queryClient } from "../../../App";
import InvoicesTable from "../../../components/InvoicesTable";
import { useTenantOverview } from "../OverviewPage/OverviewPage";

function InvoicesPage() {
  const history = useHistory();
  const { apartmentId } = useParams<{
    apartmentId: string;
  }>();
  const { data } = useTenantOverview();
  const deleteInvoice = useDeleteInvoice();

  const apartment = data?.apartments?.find(
    (apartment) => apartment?.id === apartmentId
  );
  const invoices = apartment?.invoices;

  const handleDeleteClick = async (invoiceId: string) => {
    deleteInvoice.mutate(invoiceId, {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.LIST_APARTMENTS]);
      },
    });
  };

  return (
    <PageLayout
      loading={false}
      error={false}
      layoutType="tenant"
      displayBackButton
      contentMarginTop
      onNavigateBack={() =>
        history.push(routes.tenant.getApartmentDetail(apartment?.id || ""))
      }
      sticky={
        <SearchHeader
          hideButtonNew={true}
          onButtonNewClick={() => {}}
          selector={
            <ApartmentSelector
              onSelect={(apartment) =>
                history.push(routes.tenant.getInvoices(apartment?.id || ""))
              }
            />
          }
        />
      }
    >
      <Box>
        <InvoicesTable
          invoices={invoices || []}
          onDeleteInvoiceClick={(id) => handleDeleteClick(id)}
        />
      </Box>
    </PageLayout>
  );
}

export default InvoicesPage;
