import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import PageLayout from "../../../layouts/PageLayout/PageLayout";
import CardRow from "../../../components/Card/CardRow/CardRow";
import Filters from "../../../fragments/Filters";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Header/Header";
import { useParams } from "react-router-dom";
import { Tab, Tabs, Theme } from "@material-ui/core";
import TabPanel from "../../../components/TabPanel";
import useListApartments from "../../../api/hooks/useListApartments";
import TenantCard from "../../../components/TenantCard";
import AddressCard from "../../../components/AddressCard";
import InvoicesCard from "../../../fragments/InvoicesCard";
import SetInvoiceDialog from "../../../components/SetInvoiceDialog";
import { useTheme } from "@material-ui/core/styles";
import ApartmentDetail from "./components/ApartmentDetail";
import { useHistory } from "react-router";
import routes from "../../../constants/routes";
import ApartmentConsumptionHistory from "../../../components/ApartmentConsumptionHistory";
import Row from "../../../components/Row";
import List from "../../../components/List";

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: {},
  apartmentInfoRoot: {
    width: "100%",
    [theme.breakpoints.down(1100)]: {
      flexDirection: "column",
    },
  },
  tabs: {
    marginTop: 16,
  },
  apartmentInfo: {
    width: "100%",
    display: "flex",
    flex: "none",
    flexDirection: "column",
    justifyContent: "flex-start",
    [theme.breakpoints.down(1100)]: {
      width: "100%",
      marginBottom: 24,
    },
  },
  consumptionHistory: {
    marginBottom: 50,
  },
  details: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& > *": {
      [theme.breakpoints.down(1100)]: {
        width: "100%",
      },
    },
    "& > div:not(:last-child)": {
      marginBottom: 16,
    },
  },
  header: {
    width: "100%",
  },
  label: {
    color: "#272833",
  },
  row: {
    [theme.breakpoints.down(740)]: {
      flexDirection: "column",
      "& > div:not(:last-child)": {
        marginBottom: 16,
      },
    },
  },
}));

function ApartmentDetailPage() {
  const { t } = useTranslation();
  const { apartmentId, propertyId } = useParams<{
    apartmentId: string;
    propertyId: string;
  }>();
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const footerRef = useRef<HTMLDivElement>(null);

  const [tab, setTab] = React.useState(0);
  const [newInvoiceDialogOpen, setNewInvoiceDialogOpen] = useState<boolean>(
    false
  );

  const { data: apartmentData } = useListApartments(
    { id: apartmentId },
    Boolean(apartmentId)
  );

  const apartment = apartmentData?.apartments?.[0];

  const apartmentInfo = (
    <div className={classes.apartmentInfo}>
      <div className={classes.consumptionHistory}>
        <ApartmentConsumptionHistory apartment={apartment} />
      </div>
      <Row alignItems="flex-start" className={classes.row}>
        <TenantCard tenants={apartment?.tenants} />
        <AddressCard
          address={apartment?.property?.address}
          cardHeader={t("billingAddress")}
        />
        <InvoicesCard
          invoices={apartment?.invoices}
          limit={2}
          onAddNewInvoice={() => setNewInvoiceDialogOpen(true)}
          onSeeAllInvoices={() =>
            history.push(routes.owner.getInvoices(propertyId, apartmentId))
          }
        />
      </Row>
    </div>
  );

  return (
    <PageLayout
      layoutType="owner"
      sticky={
        <List spacing={0} width="100%">
          <Header />{" "}
          <CardRow>
            <Tabs
              value={tab}
              onChange={(e, value) => {
                setTab(value);
              }}
              className={classes.tabs}
              indicatorColor="primary"
            >
              <Tab label={t("consumption")} className={classes.label} />
              <Tab label={t("configure")} className={classes.label} />
            </Tabs>
            {tab === 0 && <Filters />}
          </CardRow>
        </List>
      }
      footer={<div ref={footerRef} />}
      displayBackButton
      onNavigateBack={() =>
        history.push(routes.owner.getPropertyDetail(propertyId || ""))
      }
    >
      <TabPanel value={tab} index={0} className={classes.tabPanel}>
        {apartmentInfo}
      </TabPanel>
      <TabPanel value={tab} index={1} className={classes.tabPanel}>
        <ApartmentDetail
          apartment={apartment}
          propertyId={propertyId}
          footerRef={footerRef}
        />
      </TabPanel>
      <SetInvoiceDialog
        open={newInvoiceDialogOpen}
        apartmentId={apartmentId}
        apartmentTenants={apartment?.tenants || []}
        onClose={() => setNewInvoiceDialogOpen(false)}
      />
    </PageLayout>
  );
}

export default ApartmentDetailPage;
