import { ConsumptionType } from "../../api/types";
import { t } from "../../i18n";

const labelMap: Record<string, string> = {
  [ConsumptionType.ElectricityL]: t("consumptionType.label.electricityL"),
  [ConsumptionType.ElectricityH]: t("consumptionType.label.electricityH"),
  [ConsumptionType.Electricity]: t("consumptionType.label.electricity"),
  [ConsumptionType.ElectricityPv]: t("consumptionType.label.electricityPV"),
  [ConsumptionType.HeatingEnergy]: t("consumptionType.label.heatingEnergy"),
  [ConsumptionType.Charging]: t("consumptionType.label.charging"),
  [ConsumptionType.HeatingFlow]: t("consumptionType.label.heatingFlow"),
  [ConsumptionType.WaterColdVolume]: t("consumptionType.label.waterColdVolume"),
  [ConsumptionType.WaterWarmVolume]: t("consumptionType.label.waterWarmVolume"),
  [ConsumptionType.WaterColdFlow]: t("consumptionType.label.waterColdFlow"),
  [ConsumptionType.WaterWarmFlow]: t("consumptionType.label.waterWarmFlow"),
  [ConsumptionType.MaximumPower]: t("consumptionType.label.maximumPower"),
};

function getLabel(consumptionType: string) {
  return labelMap[consumptionType] || "";
}

export default getLabel;
