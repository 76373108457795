import React from "react";
import { Button, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import useConsumption from "../../../../api/hooks/useConsumption";
import { getNextDate } from "../../../../utils/date/date.utils";
import ApartmentConsumption from "../../../owner/ApartmentDetailPage/components/ApartmentConsumption";
import useFilterStore, {
  fullFilterState,
} from "../../../../store/useFilterStore";
import shallow from "zustand/shallow";
import Card, {
  CardContent,
  CardHeader,
  CardRow,
} from "../../../../components/Card";
import Icon from "../../../../components/Icon";
import { IconHome } from "../../../../assets";
import { TEXT_SECONDARY } from "../../../../theme";
import IconRight from "@material-ui/icons/ChevronRight";
import routes from "../../../../constants/routes";
import { useHistory } from "react-router-dom";
import getDisplayAddress from "../../../../utils/getDisplayAddress";
import { AddressDto } from "../../../../api/types";

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: {
    flex: 1,
    width: "100%",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.06)",
  },
}));

interface Props {
  propertyId: string;
  apartmentId: string;
  apartmentName: string;
  apartmentAddress: AddressDto;
}

function ApartmentCard({
  propertyId,
  apartmentId,
  apartmentName,
  apartmentAddress,
}: Props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { date, consumptionPeriod } = useFilterStore(fullFilterState, shallow);
  const history = useHistory();

  const {
    data: consumptionData,
    isLoading: isLoadingConsumptionData,
    error: consumptionDataError,
  } = useConsumption(
    {
      from: date.toISOString(),
      to: getNextDate(date, consumptionPeriod, "next").toISOString(),
      apartmentId: apartmentId,
      propertyId,
    },
    Boolean(apartmentId) || Boolean(propertyId)
  );

  return (
    <Card className={classes.root}>
      <CardRow borderBottom>
        <CardHeader
          className={classes.header}
          title={apartmentName || ""}
          subtitle={getDisplayAddress(apartmentAddress)}
          icon={
            <Icon
              width={32}
              height={32}
              icon={<IconHome htmlColor={TEXT_SECONDARY} />}
            />
          }
          onClick={() => {
            history.push(routes.tenant.getApartmentDetail(apartmentId));
          }}
          hideDropDownIcon
        />
        <Button
          size="small"
          variant="outlined"
          endIcon={<IconRight />}
          onClick={() => {
            history.push(routes.tenant.getApartmentDetail(apartmentId));
          }}
        >
          Detail
        </Button>
      </CardRow>
      <CardContent className={classes.cardContent} withSpacing height="100%">
        <ApartmentConsumption
          hideConsumptionCards
          consumptionData={consumptionData?.getConsumption}
          propertyId={propertyId}
          apartmentId={apartmentId}
          apartmentName={apartmentName}
          loading={isLoadingConsumptionData}
          error={consumptionDataError}
        />
      </CardContent>
    </Card>
  );
}

export default ApartmentCard;
