import React from "react";
import CardRow from "../Card/CardRow/CardRow";
import PropertySelector from "../../fragments/PropertySelector";
import HorizontalDivider from "../HorizontalDivider";
import ApartmentSelector from "../../fragments/ApartmentSelector";
import { ApartmentSelectorProps } from "../../fragments/ApartmentSelector/ApartmentSelector";
import { PropertySelectorProps } from "../../fragments/PropertySelector/PropertySelector";
import { useHistory } from "react-router";
import routes from "../../constants/routes";
import ButtonNewProperty from "../ButtonNewProperty/ButtonNewProperty";
import { useParams } from "react-router-dom";

interface Props {
  onPropertySelected?: (property: string) => void;
  onApartmentSelected?: (apartment: string) => void;
  ApartmentSelectorProps?: Partial<ApartmentSelectorProps>;
  PropertySelectorProps?: Partial<PropertySelectorProps>;
}

function Header({
  onApartmentSelected,
  onPropertySelected,
  ApartmentSelectorProps,
  PropertySelectorProps,
}: Props) {
  const history = useHistory();
  const { propertyId } = useParams<any>();

  const handleSelectApartment = (apartment: any) => {
    onApartmentSelected?.(apartment?.id || "");

    if (!apartment || !apartment?.id) {
      history.push(routes.owner.getPropertyDetail(propertyId || ""));
    } else {
      history.push(
        routes.owner.getApartmentDetail(propertyId || "", apartment?.id || "")
      );
    }
  };

  return (
    <CardRow header borderBottom>
      <PropertySelector
        onSelect={(property) => {
          onPropertySelected?.(property?.id || "");
          history.push(routes.owner.getPropertyDetail(property?.id || ""));
        }}
        {...PropertySelectorProps}
      />
      <HorizontalDivider />
      <ApartmentSelector
        disabled={!propertyId}
        onSelect={handleSelectApartment}
        {...ApartmentSelectorProps}
      />
      <ButtonNewProperty />
    </CardRow>
  );
}

export default Header;
