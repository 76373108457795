import React from "react";
import { makeStyles } from "@material-ui/styles";
import PropertyInfoForm from "../PropertyInfoForm";
import PropertyPricesForm from "../PropertyPricesForm";
import { PropertyInfoFormFields } from "../PropertyInfoForm/PropertyInfoForm";
import { PropertyPricesFormFields } from "../PropertyPricesForm/PropertyPricesForm";
import PropertyOwnerForm, {
    PropertyOwnerFormFields,
} from "../PropertyOwnerForm/PropertyOwnerForm";
import { useKeycloak } from "@react-keycloak/web";
import hasRealmRole from "../../../utils/hasRealmRole";
import Role from "../../../auth/Role";
import PhotovoltaicsConfigForm from "../PhotovoltaicsConfigForm";
import { PropertyFormValues } from "../../../types";
import MainCounterForm from "../MainCounterForm";

const useStyles = makeStyles(() => ({
    root: {
        "& > div:not(last-child)": {
            marginBottom: 16,
        },
    },
}));

interface Props {
    values: PropertyFormValues;
    onChange: (e: React.ChangeEvent<any>) => void;
    onFieldChange: (fieldName: string, value: string) => void;
    disabledFields?: Set<
        PropertyInfoFormFields & PropertyOwnerFormFields & PropertyPricesFormFields
    >;
    isEditable?: boolean;
    counterIds: string[];
}

function PropertyForm({
                          values,
                          onChange,
                          isEditable,
                          onFieldChange,
                          counterIds,
                      }: Props) {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const isAdmin = hasRealmRole(keycloak, Role.Admin);

    return (
        <div className={classes.root}>
            <PropertyInfoForm
                values={values}
                onChange={onChange}
                isEditable={isEditable}
            />
            {isAdmin && (
                <PropertyOwnerForm
                    values={values}
                    onChange={onChange}
                    isEditable={isEditable}
                    onFieldChange={onFieldChange}
                />
            )}
            <PropertyPricesForm
                values={values}
                onChange={onChange}
                isEditable={isEditable}
            />
            <PhotovoltaicsConfigForm
                values={values}
                onFieldChange={onFieldChange}
            />
            <MainCounterForm
                values={values}
                onFieldChange={onFieldChange}
                counterIds={counterIds}
            />
        </div>
    );
}

export default PropertyForm;
