import React, { useState } from "react";
import PageLayout from "../../../layouts/PageLayout/PageLayout";
import { gql } from "graphql-request";
import INVOICE_NAME_FIELDS from "../../../api/fragments/invoiceNameFields";
import { useQuery } from "react-query";
import { AddressDto, TenantOverviewDataQuery } from "../../../api/types";
import QueryKeys from "../../../api/QueryKeys";
import { gqlClient } from "../../../App";
import CardRow from "../../../components/Card/CardRow/CardRow";
import ApartmentSelector from "../../../fragments/ApartmentSelector";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Filters from "../../../fragments/Filters";
import { useHistory } from "react-router-dom";
import routes from "../../../constants/routes";
import ApartmentCard from "./components/ApartmentCard";
import LoadingSkeleton from "../../../components/LoadingSkeleton";

const TENANT_OVERVIEW_DATA = gql`
  ${INVOICE_NAME_FIELDS}
  query TenantOverviewData {
    apartments {
      id
      name
      apartmentNumber
      tenants {
        firstName
        lastName
      }
      property {
        id
        address {
          addressLineOne
          addressLineTwo
          zip
          city
        }
      }
      invoices {
        ...InvoiceNameFields
      }
    }
  }
`;

export function useTenantOverview() {
  return useQuery<TenantOverviewDataQuery>(
    [QueryKeys.TENANT_OVERVIEW_DATA],
    async () => await gqlClient.request(TENANT_OVERVIEW_DATA)
  );
}

const useStyles = makeStyles(() => ({
  box: { "& > div:not(:last-child)": { marginBottom: 16 } },
}));

function OverviewPage() {
  const classes = useStyles();
  const history = useHistory();
  const { data, isLoading, error } = useTenantOverview();

  const [selectedApartmentId, setSelectedApartment] = useState<string>("");

  const filteredApartments = data?.apartments?.filter(
    (apartment) => apartment?.id === selectedApartmentId
  );
  const apartments = selectedApartmentId
    ? filteredApartments
    : data?.apartments;

  const handleSelectApartment = (apartment: any) => {
    setSelectedApartment(apartment?.id);

    if (!apartment || !apartment?.id) {
      history.push(routes.tenant.overview);
    } else {
      history.push(routes.tenant.getApartmentDetail(apartment?.id || ""));
    }
  };

  return (
    <PageLayout
      loading={isLoading}
      error={error}
      layoutType="tenant"
      sticky={
        <CardRow header borderBottom>
          <ApartmentSelector
            apartments={data?.apartments}
            onSelect={handleSelectApartment}
          />
        </CardRow>
      }
    >
      <CardRow>
        <Filters />
      </CardRow>
      <div>{isLoading && <LoadingSkeleton items={1} />}</div>
      <Box padding={2} className={classes.box}>
        {apartments?.map((apartment, index) => (
          <ApartmentCard
            key={index}
            propertyId={apartment?.property?.id || ""}
            apartmentId={apartment?.id || ""}
            apartmentName={apartment?.name || ""}
            apartmentAddress={apartment?.property?.address as AddressDto}
          />
        ))}
      </Box>
    </PageLayout>
  );
}

export default OverviewPage;
