import { gql } from "graphql-request/dist";
import PropertyConsumption from "../../components/_deprecated/PropertyConsumption";
import OwnerDetails from "../../components/_deprecated/OwnerDetails";
import PropertyAddress from "../../components/_deprecated/PropertyAddress";
import { useQuery } from "react-query";
import { PropertyDetailQuery } from "../types";
import QueryKeys from "../QueryKeys";
import { gqlClient } from "../../App";
import CONSUMPTION_PRICING_FIELDS from "../fragments/consumptionPricingFields";

const PROPERTY_DETAIL_FIELDS = gql`
  ${PropertyConsumption.fragments.property}
  ${OwnerDetails.fragments.ownerDetails}
  ${PropertyAddress.fragments.propertyAddress}
  ${CONSUMPTION_PRICING_FIELDS}
  fragment PropertyDetail on PropertyDto {
    ...PropertyConsumption
    id
    name
    dataGatewayId
    isEditable
    areaSize
    mainCounterName
    owners {
      ...OwnerDetails
    }
    address {
      ...PropertyAddress
    }
    defaultConsumptionPricings {
      ...ConsumptionPricingFields
    }
    counterPricings {
      price
      counterType
    }
    apartments {
      id
      name
      isEditable
      apartmentNumber
    }
    amortizedCosts {
      description
      counterType
      amount
      from
      to
      costDistributionType
    }
    photovoltaicsConfig {
      costDistributionType
    }
  }
`;

const PROPERTY_DETAIL = gql`
  ${PROPERTY_DETAIL_FIELDS}
  query PropertyDetail($id: ID!) {
    properties(filters: { id: $id }) {
      ...PropertyDetail
    }
  }
`;

function usePropertyDetail(propertyId: string, enabled: boolean = true) {
  return useQuery<PropertyDetailQuery>(
    [QueryKeys.PROPERTY_DETAIL, propertyId],
    async () =>
      await gqlClient.request(PROPERTY_DETAIL, {
        id: propertyId,
        operationName: QueryKeys.PROPERTY_DETAIL,
      }),
    {
      enabled,
    }
  );
}

export default usePropertyDetail;
