import React, { ChangeEvent } from "react";
import { FieldArray } from "formik";
import {
  ApartmentFields,
  TenantApartmentsFormFields,
} from "./TenantApartmentsForm";
import { Button, Grid, IconButton } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { TenantFormApartmentsFragment } from "../../../api/types";
import Input from "../../Input";
import { BORDER_COLOR_SECONDARY, SUBHEADER_COLOR } from "../../../theme";
import ApartmentsAutocomplete from "../../ApartmentsAutocomplete";

interface Props {
  apartmentFields: Array<ApartmentFields>;
  allApartments: Array<TenantFormApartmentsFragment | null>;
  isEditable?: boolean;
}

const useStyles = makeStyles(() => ({
  removeApartmentButton: {
    border: `1px solid ${BORDER_COLOR_SECONDARY}`,
    borderRadius: 4,
    height: 32,
    width: 32,
    color: SUBHEADER_COLOR,
    marginTop: 18,
  },
  autocomplete: {
    marginTop: 0,
  },
  gridItem: {
    display: "flex",
    flexDirection: "row",
  },
  apartmentsList: {
    marginBottom: 4,
  },
}));

function ApartmentFieldArray({
  apartmentFields = [],
  allApartments,
  isEditable = true,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const datePickerCommonProps = {
    animateYearScrolling: true,
    fullWidth: true,
    autoOk: true,
    required: true,
    disabled: !isEditable,
  };

  return (
    <FieldArray
      name={TenantApartmentsFormFields.Apartments}
      validateOnChange={false}
    >
      {({ remove, push, replace }) => {
        return (
          <div>
            {apartmentFields.map((field, index) => (
              <Grid
                key={index}
                container
                spacing={3}
                alignItems="flex-start"
                className={classes.apartmentsList}
              >
                <Grid item xs={12} sm={5}>
                  <ApartmentsAutocomplete
                    options={allApartments || []}
                    onChange={(e: ChangeEvent<any>, value: any) => {
                      replace(index, { ...field, apartment: value });
                    }}
                    value={field.apartment}
                    id={TenantApartmentsFormFields.Apartment}
                    fieldName={TenantApartmentsFormFields.Apartment}
                    label={t("assignedApartments")}
                    required
                    className={classes.autocomplete}
                    cypressId="tenant-form-apartments-autocomplete"
                    InputProps={{ gutterBottom: 12 }}
                    disabled={!isEditable}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <DatePicker
                    label={t("dateIn")}
                    value={field.dateIn}
                    onChange={(date) =>
                      replace(index, { ...field, dateIn: date })
                    }
                    name={TenantApartmentsFormFields.DateIn}
                    variant="dialog"
                    color="secondary"
                    data-cy="tenant-form-dateIn-input"
                    {...datePickerCommonProps}
                    TextFieldComponent={(params: any) => <Input {...params} />}
                  />
                </Grid>
                <Grid item xs={10} sm={3}>
                  <DatePicker
                    label={t("dateOut")}
                    value={field.dateOut}
                    minDate={field.dateIn ?? undefined}
                    onChange={(date) =>
                      replace(index, { ...field, dateOut: date })
                    }
                    name={TenantApartmentsFormFields.DateOut}
                    minDateMessage={t("invalidDates")}
                    variant="dialog"
                    color="secondary"
                    data-cy="tenant-form-dateOut-input"
                    {...datePickerCommonProps}
                    TextFieldComponent={(params: any) => <Input {...params} />}
                    required={false}
                  />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <IconButton
                    onClick={() => remove(index)}
                    className={classes.removeApartmentButton}
                    disableRipple
                    disabled={!isEditable}
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                onClick={() =>
                  push({ apartment: null, dateIn: null, dateOut: null })
                }
                size="small"
                variant="outlined"
                color="secondary"
                startIcon={<AddIcon />}
                data-cy="add-another-apartment-button"
                disabled={!isEditable}
              >
                {t("assignedApartments")}
              </Button>
            </Grid>
          </div>
        );
      }}
    </FieldArray>
  );
}

export default ApartmentFieldArray;
