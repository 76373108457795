import React from "react";
import {
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/styles";
import { TEXT_PRIMARY, TEXTFIELD_BACKGROUND_COLOR } from "../../theme";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import Row from "../Row";

interface Props {
  open: boolean;
  onClose: () => void;
  dialogHeader?: string;
  maxWidth: "sm" | "md" | "lg" | "xl";
  children: React.ReactNode;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  disabledSubmitButton?: boolean;
  onSubmit: () => void;
  onSecondaryButtonClick?: () => void;
  background?: string;
  primaryButtonLoading?: boolean;
  secondaryButtonLoading?: boolean;
  errorAlert?: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  root: {},
  headerContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  header: {
    fontSize: 20,
    fontWeight: 700,
    color: TEXT_PRIMARY,
    textTransform: "capitalize",
  },
  content: {
    backgroundColor: (props: Props) =>
      props?.background || TEXTFIELD_BACKGROUND_COLOR,
    padding: 16,
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: "16px 24px",
    "& > div:first-child": {
      width: "100%",
    },
    "& button": {
      textTransform: "capitalize",
    },
  },
}));

function Dialog(props: Props) {
  const classes = useStyles(props);
  const {
    open,
    dialogHeader,
    maxWidth,
    onClose,
    children,
    onSubmit,
    disabledSubmitButton,
    primaryButtonText,
    secondaryButtonText,
    onSecondaryButtonClick,
    primaryButtonLoading,
    secondaryButtonLoading,
    errorAlert,
    ...rest
  } = props;

  const { t } = useTranslation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MUIDialog
      open={open}
      fullScreen={fullScreen}
      maxWidth={maxWidth || "lg"}
      className={classes.root}
      scroll="paper"
      {...rest}
    >
      {dialogHeader && (
        <DialogTitle>
          <div className={classes.headerContent}>
            <Typography className={classes.header}>{dialogHeader}</Typography>
            <IconButton aria-label="close" onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
      )}
      <DialogContent dividers className={classes.content}>
        {children}
      </DialogContent>
      <DialogActions className={classes.footer}>
        {errorAlert}
        <Row justifyContent="flex-end">
          <Button
            loading={secondaryButtonLoading}
            variant="outlined"
            color="default"
            onClick={() =>
              onSecondaryButtonClick ? onSecondaryButtonClick() : onClose()
            }
            size="small"
            data-cy="dialog-close-button"
          >
            {secondaryButtonText || t("cancel")}
          </Button>
          <Button
            loading={primaryButtonLoading}
            disabled={disabledSubmitButton}
            variant="contained"
            color="primary"
            onClick={onSubmit}
            size="small"
            data-cy="dialog-submit-button"
          >
            {primaryButtonText || t("save")}
          </Button>
        </Row>
      </DialogActions>
    </MUIDialog>
  );
}

export default Dialog;
