function getDisplayAddress(input: any) {
  if (!input) {
    return "";
  }

  return [
    input?.addressLineOne ?? "",
    `${input?.addressLineTwo ?? ""},`,
    `${input?.zip ?? ""}`,
  ]
    .filter(Boolean)
    .join(" ");
}

export default getDisplayAddress;
