import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import Row from "../Row";
import { CARD_SPACING, ROW_HEIGHT } from "../../theme";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";

export interface SectionProps {
  children: React.ReactNode;
  borderRight?: boolean;
  borderBottom?: boolean;
  height?: string;
  className?: string;
}

interface HeaderProps {
  title?: string;
  children?: React.ReactNode;
}

interface ContentProps {
  children?: React.ReactNode;
  className?: string;
}

interface ActionProps {
  children?: React.ReactNode;
}

const useStyles = makeStyles<Theme>((theme) => ({
  sectionRoot: (props: any) => ({
    height: props.height || "100%",
    paddingLeft: CARD_SPACING,
    paddingRight: CARD_SPACING,
    paddingBottom: CARD_SPACING,
  }),
  headerRoot: {
    height: ROW_HEIGHT,
  },
  headerTitle: {
    flex: 1,
  },
  contentRoot: {},
  borderRight: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  action: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  divider: {
    marginLeft: -CARD_SPACING,
    marginRight: -CARD_SPACING,
  },
}));

function Section(props: SectionProps) {
  const { children, borderRight, borderBottom, className } = props;
  const classes = useStyles(props);

  return (
    <div
      className={clsx(className, classes.sectionRoot, {
        [classes.borderRight]: borderRight,
        [classes.borderBottom]: borderBottom,
      })}
    >
      {children}
    </div>
  );
}

function Header({ title, children }: HeaderProps) {
  const classes = useStyles();

  return (
    <Row className={classes.headerRoot}>
      {title && <Typography variant="h6">{title}</Typography>}
      {children}
    </Row>
  );
}

function Content({ children, className }: ContentProps) {
  const classes = useStyles();

  return <div className={clsx(className, classes.contentRoot)}>{children}</div>;
}

function Action({ children }: ActionProps) {
  const classes = useStyles();

  return <div className={classes.action}>{children}</div>;
}

function SectionDivider() {
  const classes = useStyles();

  return <Divider className={classes.divider} />;
}

Section.Header = Header;
Section.Content = Content;
Section.Action = Action;
Section.Divider = SectionDivider;

export default Section;
