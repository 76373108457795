import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { DIVIDER_COLOR, SUBHEADER_COLOR } from "../../../theme";

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    boxShadow: "0px 2px 4px rgba(39, 40, 51, 0.12)",
    borderRadius: 8,
    backgroundColor: "white",
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: `2px solid ${DIVIDER_COLOR}`,
    padding: "16px 0 16px 24px",
    "& > svg": {
      marginRight: 16,
      color: SUBHEADER_COLOR,
    },
  },
  header: {
    color: "#272833",
    fontSize: 18,
    fontWeight: 400,
  },
  children: {
    padding: 24,
  },
}));

interface Props {
  header: string;
  headerIcon: React.ReactNode;
  children: React.ReactNode;
}

const FormCard = ({ header, children, headerIcon }: Props) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.cardHeader}>
        {headerIcon}
        <Typography variant="h5" className={classes.header}>
          {header}
        </Typography>
      </div>
      <CardContent className={classes.children}>{children}</CardContent>
    </Card>
  );
};

export default FormCard;
