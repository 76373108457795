import React from "react";
import ApartmentConsumption from "../_deprecated/ApartmentConsumption";
import { gql } from "graphql-request";
import useFilterStore, { fullFilterState } from "../../store/useFilterStore";
import shallow from "zustand/shallow";
import useConsumption from "../../api/hooks/useConsumption";
import { getCurrentDate, getViewEndDate } from "../../utils/date/date.utils";
import {
  Bar,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { CHARTS_CONTAINER_HEIGHT } from "../../theme";
import getYAxisLabel from "../../utils/chart/context/getYAxisLabel";
import contextComponents from "../../utils/chart/context/contextComponents";
import getLabel from "../../utils/chart/getLabel";
import getFillColor from "../../utils/chart/getFillColor";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import ErrorCodes from "../../constants/ErrorCodes";
import ErrorMessage from "../ErrorMessage";
import EmptyState from "../EmptyState";
import getErrorCode from "../../utils/getErrorCode";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import getBarChartDataByDate from "../../utils/chart/getBarChartDataByDate";
import propertyLegendFormatter from "../../utils/chart/formatter/propertyLegendFormatter";
import Row from "../Row";
import ConsumptionItemCard from "../ConsumptionItemCard";
import ExportConsumptionAsCsv from "../../fragments/ExportConsumptionsAsCsv";
import getConsumptionCardsData from "../../utils/chart/getConsumptionCardsData";
import hasRealmRole from "../../utils/hasRealmRole";
import keycloak from "../../auth/keycloak";
import Role from "../../auth/Role";

interface Props {
  apartment?: any;
  hideConsumptionCards?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    width: "100%",
    marginBottom: (props: Props) => `${props?.hideConsumptionCards ? 0 : 50}px`,
  },
  consumptions: {
    flex: 1,
  },
  row: {
    marginBottom: 32,
    marginLeft: 8,
  },
  consumptionCards: {
    marginLeft: 8,
    padding: 0,
    flex: 1,
    flexWrap: "wrap",
  },
  exportCsvButton: {
    alignSelf: "flex-start",
  },
  errorMessage: {
    height: "100%",
  },
  emptyStateRoot: {
    height: CHARTS_CONTAINER_HEIGHT,
  },
}));

function ApartmentConsumptionHistory(props: Props) {
  const { apartment, hideConsumptionCards } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);
  const {
    date,
    consumptionPeriod,
    showCosts,
    consumptionTypes,
  } = useFilterStore(fullFilterState, shallow);

  const {
    data: consumptionData,
    isLoading: loading,
    error,
    isFetched,
  } = useConsumption(
    {
      from: getCurrentDate(date, consumptionPeriod).toISOString(true),
      to: getViewEndDate(date, consumptionPeriod).toISOString(true),
      apartmentId: apartment?.id,
      propertyId: apartment?.property?.id,
      counters: Array.from(consumptionTypes),
      level: consumptionPeriod as any,
    },
    Boolean(apartment)
  );

  const chartData = getBarChartDataByDate(
    consumptionData?.getConsumption,
    showCosts ? "totalPrice" : "value",
    "itemType",
    consumptionPeriod
  );
  const errorCode = getErrorCode(error);
  const consumptionCardsData = getConsumptionCardsData(
    consumptionData?.getConsumption || []
  );
  const disabledExportButton = !consumptionData?.getConsumption?.length;

  const isTenant = hasRealmRole(keycloak, Role.Tenant);

  if (!apartment && isTenant) {
    return null;
  }

  if (loading || !isFetched) {
    return (
      <div className={classes.emptyStateRoot}>
        <LoadingIndicator />
      </div>
    );
  }

  if (errorCode === ErrorCodes.IncompleteData) {
    return (
      <div className={classes.emptyStateRoot}>
        <ErrorMessage
          className={classes.errorMessage}
          title={t("errors.incompleteDataHeader")}
          message={t("errors.incompleteDataText")}
        />
      </div>
    );
  }

  if (!chartData?.keys?.length && isFetched) {
    return (
      <div className={classes.emptyStateRoot}>
        <EmptyState />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Row
        className={classes.row}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Row className={classes.consumptionCards}>
          {!hideConsumptionCards &&
            Object.keys(
              consumptionCardsData
            )?.map((consumptionType: any, index: number) => (
              <ConsumptionItemCard
                consumptionType={consumptionType}
                value={consumptionCardsData[consumptionType].value}
                totalPrice={consumptionCardsData[consumptionType].totalPrice}
                key={index}
              />
            ))}
        </Row>
        <ExportConsumptionAsCsv
          propertyId={apartment?.property?.id || ""}
          apartmentId={apartment?.id || ""}
          fileName={apartment?.apartmentNumber || ""}
          className={classes.exportCsvButton}
          disabled={!consumptionData || disabledExportButton}
          level={consumptionPeriod}
        />
      </Row>
      <ResponsiveContainer width="100%" height={440} debounce={1}>
        <ComposedChart
          width={650}
          height={CHARTS_CONTAINER_HEIGHT}
          data={chartData.items}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <XAxis dataKey="date" />
          <YAxis label={getYAxisLabel()} />
          {contextComponents}
          <Legend
            verticalAlign="top"
            align="right"
            layout="vertical"
            iconType="square"
            formatter={propertyLegendFormatter}
            wrapperStyle={{
              right: 0,
            }}
          />
          {chartData.keys.map((key: any) => (
            <Bar
              key={key}
              dataKey={getLabel(key)}
              stackId="a"
              fill={getFillColor(key)}
              maxBarSize={100}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

ApartmentConsumption.fragments = {
  apartment: gql`
    fragment ApartmentConsumption_Apartment on ApartmentDto {
      id
      property {
        id
      }
    }
  `,
};

export default ApartmentConsumptionHistory;
