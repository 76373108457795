import _ from "lodash";
import { ChartItemFieldsFragment, ConsumptionType } from "../../api/types";
import getLabel from "./getLabel";
import { ConsumptionPeriod } from "../../types";
import moment from "moment";
import { roundValue } from "../roundValue";

type Context = {
  name: string;
  unit: string;
};

type Values = {
  [key in ConsumptionType]?: number;
};

type ChartItem = Context & Values;

interface Data {
  items: Array<ChartItem>;
  keys: Array<string>;
}

function formatKey(timestamp: moment.MomentInput, period?: ConsumptionPeriod) {
  const date = moment(timestamp);

  switch (period) {
    case ConsumptionPeriod.Day:
      return date.format("HH:mm");
    case ConsumptionPeriod.Week:
      return date.format("DD.MM.");
    case ConsumptionPeriod.Month:
      return date.format("DD.MM.");
    case ConsumptionPeriod.HalfYear:
      return date.format("MMMM");
    case ConsumptionPeriod.Year:
      return date.format("MMMM");
  }

  return date.format("DD.MM HH:mm");
}

// TODO: Add proper typing
function getBarChartDataByDate(
  items: Array<ChartItemFieldsFragment | null> = [],
  valueKey: "value" | "totalPrice" = "totalPrice",
  itemKey: "item" | "itemType" = "item",
  period: ConsumptionPeriod
): Data {
  if (!items || !items.length) {
    return {
      items: [],
      keys: [],
    };
  }
  const renderPrice = valueKey === "totalPrice";

  const dataMap: any = {};
  const keys: Set<string> = new Set();

  const createMap: any = () => {
    for (const item of items) {
      if (!item) {
        continue;
      }

      const key = formatKey(item.timestamp, period);
      const currentItem = dataMap[key] || {};
      const itemType = getLabel(item.itemType);
      keys.add(item.itemType);
      const name = itemKey === "item" ? item.item : getLabel(item.itemType);

      dataMap[key] = {
        ...currentItem,
        name,
        date: key,
        unitLabel: {
          ...currentItem.unitLabel,
          [`${itemType}`]: renderPrice ? "CHF" : item.unit,
        },
        [`${itemType}`]: roundValue(item[valueKey]),
      };
    }
  };

  createMap();

  const data: Array<ChartItem> = _.flatMap(dataMap);

  return {
    items: data,
    keys: Array.from(keys),
  };
}

export default getBarChartDataByDate;
