import React, { useMemo, useState } from "react";
import { ConsumptionPeriod } from "../../../types";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, Menu, Theme } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import Row from "../../Row";
import useConsumptionPeriod from "../../../hooks/_deprecated/useConsumptionPeriod";

interface Props {
  value?: ConsumptionPeriod;
  onChange?: (period: ConsumptionPeriod) => void;
  menuBreakpoint?: "xs" | "sm" | "md";
  condensed?: boolean;
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},
  menu: {
    marginTop: 48,
  },
  button: {
    "&$selected": {
      border: `2px solid ${theme.palette.secondary.main}`,
    },
  },
  donutRow: {
    width: "100%",
    height: 250,
  },
  selected: {},
}));

function ConsumptionPeriodTabs({
  value,
  onChange,
  menuBreakpoint,
  condensed,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down(menuBreakpoint || "md")
  );
  const renderMenu = isMobile || condensed;
  const [anchorEl, setAnchorEl] = useState(null);
  const [period, setPeriod] = useConsumptionPeriod();

  const menuOpen = Boolean(anchorEl);

  const tabValues = useMemo(
    () => [
      { period: ConsumptionPeriod.Day, text: t("day") },
      { period: ConsumptionPeriod.Week, text: t("week") },
      { period: ConsumptionPeriod.Month, text: `${t("month")}` },
      { period: ConsumptionPeriod.Year, text: `${t("year")}` },
    ],
    [t]
  );

  const handleMenuIconClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTabClick = (period: ConsumptionPeriod) => {
    setAnchorEl(null);
    setPeriod(period);
    if (onChange) {
      onChange(period);
    }
  };

  const menu = (
    <div>
      <IconButton
        aria-label="menu"
        aria-controls="mobile-menu"
        aria-haspopup="true"
        onClick={handleMenuIconClick}
        color="secondary"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="consumption-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={() => {
          setAnchorEl(null);
        }}
        className={classes.menu}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {tabValues.map((tab) => (
          <MenuItem
            selected={tab.period === (value || period)}
            key={tab.text}
            onClick={() => {
              handleTabClick(tab.period);
            }}
          >
            {tab.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );

  const tabs = (
    <Row spacing={8}>
      {tabValues.map((tab) => (
        <Button
          key={tab.text}
          variant="outlined"
          size="small"
          className={clsx(classes.button, {
            [classes.selected]: tab.period === (value || period),
          })}
          onClick={() => {
            handleTabClick(tab.period);
          }}
        >
          {tab.text}
        </Button>
      ))}
    </Row>
  );

  return <div>{renderMenu ? menu : tabs}</div>;
}

export default ConsumptionPeriodTabs;
