import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import { useQueryClient } from "react-query";
import moment from "moment";
import { isNumber } from "lodash";

import { makeStyles } from "@material-ui/styles";

import ErrorAlert from "../ErrorAlert";
import Dialog from "../Dialog";
import QueryKeys from "../../api/QueryKeys";
import {
  ConsumptionType,
  SetInvoiceInput,
  Maybe,
  TenantFieldsFragment,
} from "../../api/types";
import InvoiceForm, {
  consumptionTypesList,
  InvoiceFormFields,
  InvoiceFormValues,
} from "../Forms/InvoiceForm/InvoiceForm";
import useSetInvoice from "../../api/hooks/useSetInvoice";
import useListTenants from "../../api/hooks/useListTenats";
import useListInvoices from "../../api/hooks/useListInvoices";

const useStyles = makeStyles(() => ({
  errorAlert: {
    marginBottom: 16,
  },
}));

interface Props {
  open: boolean;
  apartmentId: string;
  onClose: () => void;
  apartmentTenants: Array<
    Maybe<{ __typename?: "TenantWithDatesDto" } & TenantFieldsFragment>
  >;
  invoiceId?: string;
}

type ValidationErrors = {
  invalidFromDate?: boolean;
  invalidToDate?: boolean;
  invalidTenantId?: boolean;
  invalidDates?: boolean;
  invalidMessagingProps?: boolean;
};

const intervalMonthsIsValid = (intervalMonths?: number | null) =>
  isNumber(intervalMonths) &&
  intervalMonths !== null &&
  intervalMonths !== undefined &&
  intervalMonths > 0;

function validate(values: InvoiceFormValues) {
  const errors: ValidationErrors = {};

  if (!values.startMonth) {
    errors.invalidFromDate = true;
  }

  if (!values.endMonth) {
    errors.invalidToDate = true;
  }

  if (moment(values.startMonth).isAfter(moment(values.endMonth))) {
    errors.invalidDates = true;
  }

  if (!values.tenant?.id) {
    errors.invalidTenantId = true;
  }

  const templatePropsAreInvalid =
    !values.messagingStartTime || !intervalMonthsIsValid(values.intervalMonths);

  if (values.useMessaging && templatePropsAreInvalid) {
    errors.invalidMessagingProps = true;
  }

  return errors;
}

const initialFormValues: InvoiceFormValues = {
  startMonth: moment().startOf("month"),
  endMonth: moment().startOf("month"),
  tenant: null,
  includeVat: true,
  includedConsumptionTypes: consumptionTypesList,
  useMessaging: false,
  intervalMonths: 1,
  messagingStartTime: moment().startOf("month"),
};

const createSetInvoiceVariables = (
  invoiceFormValues: InvoiceFormValues,
  apartmentId: string
): SetInvoiceInput => {
  const {
    startMonth,
    endMonth,
    tenant,
    includeVat,
    includedConsumptionTypes,
    useMessaging,
    messagingStartTime,
    intervalMonths,
    id,
    dateSent,
  } = invoiceFormValues;

  const formattedIncludedConsumptionTypes = includedConsumptionTypes?.map(
    (type) => type?.id
  );

  const input: SetInvoiceInput = {
    startMonth: moment(startMonth).startOf("month").toISOString(),
    endMonth: moment(endMonth).endOf("month").toISOString(),
    includeVat: includeVat,
    tenantId: tenant?.id || "",
    apartmentId: apartmentId,
    includedConsumptionTypes: formattedIncludedConsumptionTypes,
    id,
    dateSent,
  };

  /**
   * Add intervalMonths only if messagingStartTime is defined. intervalMonths is default and we want these values
   * always together
   */
  if (useMessaging) {
    if (messagingStartTime) {
      input.messagingStartTime = messagingStartTime.toISOString();
    }
    if (isNumber(intervalMonths)) {
      input.intervalMonths = intervalMonths;
    }
  }

  return input;
};

function SetInvoiceDialog({
  onClose,
  open,
  apartmentId,
  apartmentTenants,
  invoiceId,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const createInvoice = useSetInvoice();
  const { data } = useListTenants();

  const formik = useFormik<InvoiceFormValues>({
    initialValues: initialFormValues,
    validate,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: () => {},
  });

  const { setFieldValue } = formik;

  // TODO: implement loading?
  const { data: invoiceData } = useListInvoices({ id: invoiceId }, !!invoiceId);

  const [invoiceNr, setInvoiceNr] = useState<string>("");

  const setValues = formik.setValues;
  useEffect(() => {
    // Reset here. Prevent leftover data
    setValues(initialFormValues);
    setInvoiceNr("");

    const tenant = apartmentTenants?.[0] || data?.tenants?.[0] || null;
    setFieldValue(InvoiceFormFields.Tenant, tenant);

    if (invoiceData && invoiceData?.invoices.length > 0) {
      const [invoice] = invoiceData.invoices;

      setValues({
        id: invoice.id,
        intervalMonths: invoice.intervalMonths,
        useMessaging: invoice.intervalMonths && invoice.messagingStartTime,
        includedConsumptionTypes:
          invoice?.items?.map((item) => ({
            // TODO: type cast won't be necessary after consumption type and invoice item type will be united
            id: (item.itemType as unknown) as ConsumptionType,
            name: item.label,
          })) || consumptionTypesList,
        endMonth: invoice.toDate,
        startMonth: invoice.fromDate,
        includeVat: invoice.vat > 0,
        messagingStartTime: invoice.messagingStartTime
          ? moment(invoice.messagingStartTime)
          : invoice.messagingStartTime,
        tenant,
        dateSent: invoice.dateSent,
      });
      setInvoiceNr(invoice.nr.toString(10));
    }
  }, [apartmentTenants, data, invoiceData, open, setFieldValue, setValues]);

  const handleSubmit = async () => {
    const variables = createSetInvoiceVariables(formik.values, apartmentId);

    createInvoice.mutate(variables, {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.LIST_APARTMENTS]);
        formik.resetForm();

        if (onClose) {
          onClose();
        }
      },
    });
  };

  const handleCloseClick = () => {
    formik.resetForm();
    createInvoice.reset();

    if (onClose) {
      onClose();
    }
  };

  const onFieldChange = (
    fieldName: keyof InvoiceFormValues,
    value: InvoiceFormValues[keyof InvoiceFormValues]
  ) => {
    setFieldValue(fieldName as string, value);
    if (createInvoice.error) {
      createInvoice.reset();
    }
  };

  const { startMonth, endMonth } = formik.values;
  const invalidDates = moment(startMonth).isAfter(moment(endMonth));

  const disabledSubmitButton = createInvoice.isLoading || !formik.isValid;
  const dialogHeader = invoiceNr
    ? `${t("invoiceNr")} ${invoiceNr}`
    : t("newInvoice");

  return (
    <Dialog
      open={open}
      dialogHeader={dialogHeader}
      onClose={handleCloseClick}
      maxWidth="sm"
      onSubmit={handleSubmit}
      disabledSubmitButton={disabledSubmitButton}
      background="white"
      primaryButtonLoading={createInvoice.isLoading}
      errorAlert={
        <ErrorAlert
          error={createInvoice.error}
          errorMessage={invalidDates ? t("errors.invalidDates") : ""}
          className={classes.errorAlert}
        />
      }
    >
      <FormikProvider value={formik}>
        <InvoiceForm
          tenants={apartmentTenants || data?.tenants || []}
          values={formik.values}
          onChange={formik.handleChange}
          onFieldChange={onFieldChange}
        />
      </FormikProvider>
    </Dialog>
  );
}

export default SetInvoiceDialog;
