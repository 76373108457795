import React from "react";
import Section from "../../Section";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { AddressDto } from "../../../api/types";
import { gql } from "graphql-request/dist";

interface PropertyAddressProps {
  address?: AddressDto;
  borderRight?: boolean;
  borderBottom?: boolean;
}

function PropertyAddress({ address }: PropertyAddressProps) {
  const { t } = useTranslation();

  return (
    <>
      <Section.Header title={t("address")} />
      <Section.Content>
        <Typography>{address?.addressLineOne}</Typography>
        <Typography>{address?.addressLineTwo}</Typography>
        <Typography>{`${address?.zip} ${address?.city}`}</Typography>
      </Section.Content>
    </>
  );
}

PropertyAddress.fragments = {
  propertyAddress: gql`
    fragment PropertyAddress on AddressDto {
      addressLineOne
      addressLineTwo
      city
      zip
    }
  `,
};

export default PropertyAddress;
