import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikValues } from "formik";
import {
  Grid,
  TextFieldProps,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ConsumptionType, InvoiceItemType } from "../../../api/types";
import FormCard from "../FormCard";
import getPricingLabel from "../../../utils/chart/getPricingLabel";
import getUnit from "../../../utils/chart/getUnit";
import Input from "../../Input";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { BORDER_COLOR_SECONDARY, SUBHEADER_COLOR } from "../../../theme";

export enum ApartmentPricesFormFields {
  ApartmentNumber = "apartmentNumber",
  PricingElectricityL = "pricingElectricityL",
  PricingElectricityH = "pricingElectricityH",
  PricingElectricityPV = "pricingElectricityPV",
  PricingElectricity = "pricingElectricity",
  PricingMaximumPower = "pricingMaximumPower",
  PricingWaterWarm = "pricingWaterWarm",
  PricingWaterCold = "pricingWaterCold",
  PricingHeating = "pricingHeating",
  SelectedApartment = "selectedApartment",
  PricingCharging = "pricingCharging",
}

export interface ApartmentPricesFormValues extends FormikValues {
  pricingElectricityL: number | string | null;
  pricingElectricityH: number | string | null;
  pricingElectricityPV: number | string | null;
  pricingElectricity: number | string | null;
  pricingMaximumPower: number | string | null;
  pricingWaterWarm: number | string | null;
  pricingWaterCold: number | string | null;
  pricingHeating: number | string | null;
  pricingCharging: number | string | null;
}

interface Props {
  values: ApartmentPricesFormValues;
  onChange: (e: React.ChangeEvent<any>) => void;
  onFieldChange: (fieldName: string, value: any) => void;
  disabledFields?: Set<ApartmentPricesFormFields>;
  placeholders?: {
    [key: string]: number | string;
  };
  isEditable?: boolean;
}

const useStyles = makeStyles<Theme, any>((theme) => ({
  grid: {
    [theme.breakpoints.down("sm")]: {
      "& > div": {
        marginBottom: 12,
      },
    },
  },
  subheader: {
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 5,
    color: SUBHEADER_COLOR,
    width: "100%",
    borderBottom: `1px solid ${BORDER_COLOR_SECONDARY}`,
  },
  input: {
    width: 190,
  },
}));

function ApartmentPricesForm({
  onChange,
  values,
  placeholders,
  isEditable = true,
}: Props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const getTextFieldProps = (
    fieldName: ApartmentPricesFormFields
  ): TextFieldProps => ({
    id: fieldName,
    name: fieldName,
    onChange,
    color: "secondary",
    disabled: !isEditable,
    placeholder: placeholders?.[fieldName]?.toString(),
    InputLabelProps: { shrink: true },
    className: classes.input,
  });

  const {
    pricingElectricityL,
    pricingElectricity,
    pricingElectricityH,
    pricingElectricityPV,
    pricingMaximumPower,
    pricingWaterCold,
    pricingWaterWarm,
    pricingHeating,
    pricingCharging,
  } = values;

  const electricity = (
    <Grid container xs={12} sm={6} md={4} spacing={3}>
      <Grid item xs={12}>
        <Typography className={classes.subheader}>
          {t("electricity")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingElectricityL}
          label={getPricingLabel(InvoiceItemType.ElectricityL)}
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.Electricity)}`}
          InputProps={{
            ...getTextFieldProps(ApartmentPricesFormFields.PricingElectricityL),
            disabled: !!pricingElectricity,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingElectricityH}
          label={getPricingLabel(InvoiceItemType.ElectricityH)}
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.Electricity)}`}
          InputProps={{
            ...getTextFieldProps(ApartmentPricesFormFields.PricingElectricityH),
            disabled: !!pricingElectricity,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingElectricity}
          label={getPricingLabel(InvoiceItemType.Electricity)}
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.Electricity)}`}
          InputProps={{
            ...getTextFieldProps(ApartmentPricesFormFields.PricingElectricity),
            disabled: !!(pricingElectricityL || pricingElectricityH),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingCharging}
          label={getPricingLabel(InvoiceItemType.Charging)}
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.Charging)}`}
          InputProps={{
            ...getTextFieldProps(ApartmentPricesFormFields.PricingCharging),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingElectricityPV}
          label={getPricingLabel(InvoiceItemType.ElectricityPv)}
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.ElectricityPv)}`}
          InputProps={{
            ...getTextFieldProps(
              ApartmentPricesFormFields.PricingElectricityPV
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingMaximumPower}
          s
          label={getPricingLabel(InvoiceItemType.MaximumPower)}
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.MaximumPower)}`}
          InputProps={{
            ...getTextFieldProps(ApartmentPricesFormFields.PricingMaximumPower),
          }}
        />
      </Grid>
    </Grid>
  );

  const water = (
    <Grid container xs={12} sm={6} md={4} spacing={3}>
      <Grid item xs={12}>
        <Typography className={classes.subheader}>{t("water")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingWaterWarm}
          label={getPricingLabel(InvoiceItemType.WaterWarmVolume)}
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.WaterWarmVolume)}`}
          InputProps={{
            ...getTextFieldProps(ApartmentPricesFormFields.PricingWaterWarm),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingWaterCold}
          label={getPricingLabel(InvoiceItemType.WaterColdVolume)}
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.WaterColdVolume)}`}
          InputProps={{
            ...getTextFieldProps(ApartmentPricesFormFields.PricingWaterCold),
          }}
        />
      </Grid>
    </Grid>
  );

  const heating = (
    <Grid container xs={12} sm={6} md={4} spacing={3}>
      <Grid item xs={12}>
        <Typography className={classes.subheader}>{t("heating")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingHeating}
          label={getPricingLabel(InvoiceItemType.HeatingEnergy)}
          gutterBottom={0}
          InputProps={{
            ...getTextFieldProps(ApartmentPricesFormFields.PricingHeating),
          }}
          unit={`CHF/${getUnit(ConsumptionType.HeatingEnergy)}`}
        />
      </Grid>
    </Grid>
  );

  return (
    <FormCard
      header={t("priceAndTariff")}
      headerIcon={<ListAltIcon fontSize="default" />}
    >
      <Grid
        container
        className={classes.grid}
        alignItems="flex-start"
        justify="space-between"
      >
        {electricity}
        {water}
        {heating}
      </Grid>
    </FormCard>
  );
}

export default ApartmentPricesForm;
