import { useQuery } from "react-query";
import QueryKeys from "../QueryKeys";
import { gqlClient } from "../../App";
import { gql } from "graphql-request/dist";
import CHART_ITEM_FIELDS from "../fragments/chartItemFields";
import { FetchConsumptionDataInput, GetConsumptionQuery } from "../types";

export default function useConsumption(
  input: Partial<FetchConsumptionDataInput>,
  enabled?: boolean
) {
  return useQuery<GetConsumptionQuery>(
    [QueryKeys.GET_CONSUMPTION, input],
    async () => {
      return await gqlClient.request(
        gql`
          ${CHART_ITEM_FIELDS}
          query GetConsumption($input: FetchConsumptionDataInput!) {
            getConsumption(consumptionInput: $input) {
              ...ChartItemFields
            }
          }
        `,
        {
          input,
          operationName: QueryKeys.GET_CONSUMPTION,
        }
      );
    },
    {
      enabled,
      retry: false,
    }
  );
}
