import { gql } from "graphql-request";
import { useMutation } from "react-query";
import QueryKeys from "../QueryKeys";
import { gqlClient } from "../../App";
import { SetApartmentInput } from "../types";

function useSetApartment() {
  return useMutation(
    QueryKeys.SET_APARTMENT,
    async (input: SetApartmentInput) => {
      return await gqlClient.request(
        gql`
          mutation SetApartment($input: SetApartmentInput!) {
            setApartment(input: $input) {
              id
            }
          }
        `,
        {
          input,
          operationName: QueryKeys.SET_APARTMENT,
        }
      );
    }
  );
}

export default useSetApartment;
