import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import routes from "./constants/routes";
import AppRouter from "./components/AppRouter";
import { QueryClient, QueryClientProvider } from "react-query";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useKeycloak } from "@react-keycloak/web";
import { GraphQLClient } from "graphql-request/dist";
import { GQL_BASE_URL } from "./cfg";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createLocalStoragePersistor } from "react-query/createLocalStoragePersistor-experimental";
import LoadingIndicator from "./components/LoadingIndicator";
import hasRealmRole from "./utils/hasRealmRole";
import Role from "./auth/Role";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  },
});

const localStoragePersistor = createLocalStoragePersistor();

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
})
  .then((r) => console.log("Query client persisted", r))
  .catch((e) => console.log("Failed to persist", e));

export const gqlClient = new GraphQLClient(GQL_BASE_URL);

function App() {
  const location = useLocation();
  const { keycloak, initialized } = useKeycloak();
  const token = keycloak.token;
  const loginRequired = keycloak.loginRequired;
  const [authLoaded, setAuthLoaded] = useState(false);
  const loginPathname = location.pathname === routes.login;

  useEffect(() => {
    if (token) {
      gqlClient.setHeader("authorization", `Bearer ${token}`);
      setAuthLoaded(true);
    }

    if (initialized && !token) {
      setAuthLoaded(true);
    }
  }, [token, authLoaded, initialized]);

  // We don't have an index page, so redirect to Tenants page
  if (location.pathname === "/") {
    if (!keycloak.authenticated) {
      return <Redirect to={routes.login} />;
    }
    if (
      hasRealmRole(keycloak, Role.Dev) ||
      hasRealmRole(keycloak, Role.Admin)
    ) {
      return <Redirect to={routes.admin.properties} />;
    }
    if (hasRealmRole(keycloak, Role.Owner)) {
      return <Redirect to={routes.owner.properties} />;
    }
    if (hasRealmRole(keycloak, Role.Tenant)) {
      return <Redirect to={routes.tenant.apartmentDetail} />;
    }
  }

  if (loginRequired && !loginPathname) {
    return <Redirect to={routes.login} />;
  }

  if (initialized && !token && !loginPathname) {
    return <Redirect to={routes.login} />;
  }

  if (!loginPathname && (!initialized || !authLoaded)) {
    return <LoadingIndicator />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AppRouter />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

const AppWithQueryClient = () => (
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);

export default AppWithQueryClient;
