import React from "react";
import { makeStyles } from "@material-ui/styles";
import Card, {
  CardContent,
  CardHeader,
  CardRow,
} from "../../../../components/Card";
import { IconHome } from "../../../../assets";
import { TEXT_SECONDARY } from "../../../../theme";
import Icon from "../../../../components/Icon/Icon";
import { Button } from "@material-ui/core";
import IconRight from "@material-ui/icons/ChevronRight";
import {
  Bar,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import useConsumption from "../../../../api/hooks/useConsumption";
import getBarChartData from "../../../../utils/chart/getBarChartData";
import getLabel from "../../../../utils/chart/getLabel";
import getFillColor from "../../../../utils/chart/getFillColor";
import { Property } from "../../../../api/hooks/useListProperties";
import getDisplayAddress from "../../../../utils/getDisplayAddress";
import EmptyState from "../../../../components/EmptyState";
import useFilterStore, {
  fullFilterState,
} from "../../../../store/useFilterStore";
import shallow from "zustand/shallow";
import { getCurrentDate, getNextDate } from "../../../../utils/date/date.utils";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import ExportConsumptionAsCsv from "../../../../fragments/ExportConsumptionsAsCsv";
import getErrorCode from "../../../../utils/getErrorCode";
import ErrorCodes from "../../../../constants/ErrorCodes";
import ErrorMessage from "../../../../components/ErrorMessage";
import { useTranslation } from "react-i18next";
import getMaxBarSize from "../../../../utils/chart/getMaxBarSize";
import propertyLegendFormatter from "../../../../utils/chart/formatter/propertyLegendFormatter";
import contextComponents from "../../../../utils/chart/context/contextComponents";
import getYAxisLabel from "../../../../utils/chart/context/getYAxisLabel";
import getDynamicAxisProps from "../../../../utils/chart/context/getDynamicAxisProps";

interface Props {
  property: Property;
  onDetailClick?: (property: Property) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    width: "100%",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.06)",
    overflow: "hidden",
  },
  header: {
    flex: 1,
  },
  cardContent: {
    overflow: "hidden",
  },
  errorMessage: {
    height: "100%",
  },
}));

const headerIcon = (
  <Icon width={32} height={32} icon={<IconHome htmlColor={TEXT_SECONDARY} />} />
);

function PropertyCard({ property, onDetailClick }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    consumptionPeriod,
    date,
    showCosts,
    consumptionTypes,
  } = useFilterStore(fullFilterState, shallow);

  const { data, isLoading, error } = useConsumption(
    {
      from: getCurrentDate(date, consumptionPeriod).toISOString(),
      to: getNextDate(date, consumptionPeriod, "next").toISOString(),
      propertyId: property?.id,
      counters: Array.from(consumptionTypes),
    },
    Boolean(property?.id)
  );

  const chartData = getBarChartData(
    data?.getConsumption,
    showCosts ? "totalPrice" : "value"
  );

  const chart = (
    <ResponsiveContainer width="100%" height="100%" debounce={1}>
      <ComposedChart
        width={500}
        height={400}
        data={chartData.items}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <XAxis dataKey="name" {...getDynamicAxisProps(chartData)} />
        <YAxis label={getYAxisLabel()} />
        {contextComponents}
        <Legend
          verticalAlign="top"
          align="right"
          layout="vertical"
          iconType="square"
          formatter={propertyLegendFormatter}
          wrapperStyle={{
            right: 0,
          }}
        />
        {chartData.keys.map((key) => (
          <Bar
            key={key}
            dataKey={getLabel(key)}
            stackId="a"
            fill={getFillColor(key)}
            maxBarSize={getMaxBarSize(chartData.items?.length)}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );

  const errorCode = getErrorCode(error);

  let content;
  let noConsumptionData = data?.getConsumption?.length === 0;

  if (isLoading) {
    content = <LoadingIndicator />;
  } else if (errorCode === ErrorCodes.IncompleteData) {
    content = (
      <ErrorMessage
        className={classes.errorMessage}
        title={t("errors.incompleteDataHeader")}
        message={t("errors.incompleteDataText")}
      />
    );
  } else if (noConsumptionData) {
    content = <EmptyState />;
  } else {
    content = chart;
  }

  return (
    <Card className={classes.root}>
      <CardRow borderBottom>
        <CardHeader
          className={classes.header}
          title={property?.name || ""}
          subtitle={getDisplayAddress(property?.address)}
          icon={headerIcon}
          hideDropDownIcon
          onClick={() => {
            onDetailClick?.(property);
          }}
          isEditable={property?.isEditable}
        />
        <ExportConsumptionAsCsv
          propertyId={property?.id || ""}
          fileName={property?.name}
          disabled={noConsumptionData || isLoading}
        />
        <Button
          size="small"
          variant="outlined"
          endIcon={<IconRight />}
          onClick={() => {
            onDetailClick?.(property);
          }}
        >
          Detail
        </Button>
      </CardRow>
      <CardContent className={classes.cardContent} withSpacing height="390px">
        {content}
      </CardContent>
    </Card>
  );
}

export default PropertyCard;
