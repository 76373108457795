import React, { useEffect, useMemo, useState } from "react";
import getLabel from "../../utils/chart/getLabel";
import {
  Button,
  ButtonProps,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  makeStyles,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useFilterStore, { fullFilterState } from "../../store/useFilterStore";
import shallow from "zustand/shallow";
import { ConsumptionType } from "../../api/types";
import { TFunction } from "i18next";

const useStyles = makeStyles(() => ({
  button: {
    flex: "none !important",
    textTransform: "none",
    margin: 0,
    "& span": {
      whiteSpace: "nowrap",
    },
  },
  menuItem: {
    padding: "0px 24px 0px 4px",
  },
  menu: {
    "& ul": {
      display: "flex",
      flexDirection: "column",
      "& button": {
        margin: "4px 16px",
        textTransform: "capitalize",
      },
      "& hr": {
        marginBottom: 16,
      },
    },
  },
  formControl: {
    padding: "0 16px",
    marginBottom: 20,
  },
  formControlLabel: {
    "& > span": {
      fontSize: 14,
    },
    "& > span:first-child": {
      paddingRight: 20,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  buttonProps: Partial<ButtonProps>;
}

enum CostConsumptionType {
  Electricity = "Electricity",
  Water = "Water",
  Heating = "Heating",
  Charging = "Charging",
}

const consumptionTypesList = [
  ConsumptionType.WaterWarmVolume,
  ConsumptionType.WaterColdVolume,
  ConsumptionType.ElectricityL,
  ConsumptionType.ElectricityH,
  ConsumptionType.ElectricityPv,
  ConsumptionType.Electricity,
  ConsumptionType.MaximumPower,
  ConsumptionType.HeatingEnergy,
  ConsumptionType.Charging,
];

function getSelectorLabel(selectedTypes: Array<string>, t: TFunction) {
  let label;

  if (selectedTypes?.length === consumptionTypesList?.length) {
    label = t("all");
  } else if (selectedTypes?.length > 1) {
    label = `${getLabel(selectedTypes?.[0])} ...`;
  } else {
    label = getLabel(selectedTypes?.[0]);
  }

  return label;
}

function ConsumptionTypeSelector({ buttonProps }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { consumptionTypes, setConsumptionTypes, showCosts } = useFilterStore(
    fullFilterState,
    shallow
  );
  const costsConsumptionTypesList = useMemo(
    () => [
      {
        label: t("electricity"),
        value: CostConsumptionType.Electricity,
        consumptionTypes: [
          ConsumptionType.ElectricityL,
          ConsumptionType.ElectricityH,
          ConsumptionType.Electricity,
          ConsumptionType.ElectricityPv,
          ConsumptionType.MaximumPower,
        ],
      },
      {
        label: t("water"),
        value: CostConsumptionType.Water,
        consumptionTypes: [
          ConsumptionType.WaterWarmVolume,
          ConsumptionType.WaterColdVolume,
        ],
      },
      {
        label: t("consumptionType.label.heatingEnergy"),
        value: CostConsumptionType.Heating,
        consumptionTypes: [ConsumptionType.HeatingEnergy],
      },
      {
        label: t("consumptionType.label.charging"),
        value: CostConsumptionType.Charging,
        consumptionTypes: [ConsumptionType.Charging],
      },
    ],
    [t]
  );

  const [selectedConsumptionTypes, setSelectedConsumptionTypes] = useState<
    Set<ConsumptionType>
  >(consumptionTypes);
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const [
    costConsumptionType,
    setCostConsumptionType,
  ] = useState<CostConsumptionType>();

  const handleChange = (event: any) => {
    setCostConsumptionType(event.target.value);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!showCosts) {
      const updated = new Set(costsConsumptionTypesList[0].consumptionTypes);
      setCostConsumptionType(costsConsumptionTypesList[0].value);
      setSelectedConsumptionTypes(updated);
      setConsumptionTypes(updated);
      return;
    } else {
      const updated = new Set(consumptionTypesList);
      setSelectedConsumptionTypes(updated);
      setConsumptionTypes(updated);
    }
  }, [costsConsumptionTypesList, setConsumptionTypes, showCosts]);

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleSelect = (type: ConsumptionType) => {
    const selected = Array.from(selectedConsumptionTypes);

    if (selected.includes(type)) {
      const updated = selected.filter((selectedType) => selectedType !== type);
      setSelectedConsumptionTypes(new Set(updated));
      return;
    }

    const updated = [...selected, type];
    setSelectedConsumptionTypes(new Set(updated));
  };

  const handleSelectAll = () => {
    setSelectedConsumptionTypes(new Set(consumptionTypesList));
  };

  const handleConfirm = () => {
    if (!showCosts) {
      const chosenType = costsConsumptionTypesList?.find(
        (type) => type.value === costConsumptionType
      );
      const types = new Set(chosenType?.consumptionTypes);
      setSelectedConsumptionTypes(types);
      setConsumptionTypes(types);
      handleClose();
      return;
    }

    setConsumptionTypes(selectedConsumptionTypes);
    handleClose();
  };

  let content = null;

  if (showCosts) {
    content = (
      <div className={classes.content}>
        {consumptionTypesList.map((type) => (
          <MenuItem
            selected={selectedConsumptionTypes.has(type)}
            onClick={() => handleSelect(type)}
            key={type}
            className={classes.menuItem}
          >
            <Checkbox
              checked={selectedConsumptionTypes.has(type)}
              size="small"
              color={
                selectedConsumptionTypes.has(type) ? "secondary" : "default"
              }
            />
            <Typography>{getLabel(type)}</Typography>
          </MenuItem>
        ))}
        <Divider />
        <Button
          variant="text"
          size="small"
          color="secondary"
          onClick={handleSelectAll}
        >
          {t("selectAll")}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={handleConfirm}
        >
          {t("confirm")}
        </Button>
      </div>
    );
  }

  if (!showCosts) {
    content = (
      <div className={classes.content}>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="consumption-types"
            name="radio-buttons-consumption-types"
            value={costConsumptionType}
            onChange={(e) => handleChange(e)}
          >
            {costsConsumptionTypesList.map((type) => (
              <FormControlLabel
                value={type.value}
                control={<Radio />}
                label={type.label}
                key={type.label}
                className={classes.formControlLabel}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={handleConfirm}
        >
          {t("confirm")}
        </Button>
      </div>
    );
  }

  const consumptionType = costsConsumptionTypesList.find(
    (type) => type.value === costConsumptionType
  );
  let label = consumptionType?.label;

  if (showCosts) {
    label = getSelectorLabel(Array.from(selectedConsumptionTypes), t);
  }
  return (
    <div>
      <Button
        {...buttonProps}
        className={classes.button}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {label}
      </Button>
      <Menu
        id="consumption-periods-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        className={classes.menu}
      >
        {content}
      </Menu>
    </div>
  );
}

export default ConsumptionTypeSelector;
