import { CounterPricingDto, CounterType } from "../../api/types";

const formatCounterPricings = (
  counterPricings: Array<{ price: string | number; counterType: CounterType }>
): Array<CounterPricingDto> => {
  const counterPricingsList = [];

  for (let price of counterPricings) {
    if (Number(price?.price) > 0 && price?.counterType) {
      counterPricingsList.push({
        price: Number(price.price),
        counterType: price.counterType,
      });
    }
  }

  return counterPricingsList;
};

export default formatCounterPricings;
