import React, { Fragment, useEffect, useState } from "react";
import {
  CostPortion,
  CounterType,
  ConsumptionType,
  AmortizedCostDto,
} from "../../../api/types";
import { FieldArray } from "formik";
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputProps,
  MenuItem,
  Select,
  Divider,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import { PropertyPricesFormFields } from "./PropertyPricesForm";
import RemoveIcon from "@material-ui/icons/Remove";
import Input from "../../Input";
import Label from "../../Label/Label";
import { BORDER_COLOR_SECONDARY, SUBHEADER_COLOR } from "../../../theme";
import { DatePicker } from "@material-ui/pickers";
import getCostPortionLabel from "../../../utils/getCostPortionLabel";
import getLabel from "../../../utils/chart/getLabel";
import ConfirmationDialog from "../../ConfirmationDialog/ConfirmationDialog";
import moment from "moment";

const useStyles = makeStyles(() => ({
  deleteButton: {
    border: `1px solid ${BORDER_COLOR_SECONDARY}`,
    borderRadius: 4,
    height: 32,
    width: 32,
    marginTop: 18,
    color: SUBHEADER_COLOR,
  },
  addButton: { color: SUBHEADER_COLOR, marginTop: 4 },
  select: {
    width: "100%",
  },
  label: {
    color: "#272833",
    fontWeight: 500,
    fontSize: 14,
    pointerEvents: "none",
  },
  gridRow: {
    marginBottom: 20,
  },
  priceInput: {
    "& > div": {
      display: "flex",
      alignItems: "baseline",
    },
  },
  checkbox: { height: 32, padding: 0 },
}));

const costPortionTypes: Array<{ id: CostPortion; name: string }> = [
  {
    id: CostPortion.Area,
    name: getCostPortionLabel(CostPortion.Area),
  },
  {
    id: CostPortion.Consumption,
    name: getCostPortionLabel(CostPortion.Consumption),
  },
  {
    id: CostPortion.Uniform,
    name: getCostPortionLabel(CostPortion.Uniform),
  },
];

const counterTypesFields: Array<{ id: CounterType; name: string }> = [
  {
    id: CounterType.Electricity,
    name: getLabel(CounterType.Electricity),
  },
  {
    id: CounterType.Heating,
    name: getLabel(ConsumptionType.HeatingEnergy),
  },
  {
    id: CounterType.WaterWarm,
    name: getLabel(ConsumptionType.WaterWarmVolume),
  },
  {
    id: CounterType.WaterCold,
    name: getLabel(ConsumptionType.WaterColdVolume),
  },
  {
    id: CounterType.Charging,
    name: getLabel(ConsumptionType.Charging),
  },
];

interface Props {
  amortizedCosts?: Array<AmortizedCostDto>;
  isEditable?: boolean;
}

type OneTypeCost = {
  index: number;
  oneTimeCost: boolean;
};

function AmortizedCosts({ amortizedCosts = [], isEditable = true }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(
    false
  );
  const [costIndexToDelete, setCostIndexToDelete] = useState<number>(0);
  const [oneTimeCosts, setOneTimeCosts] = useState<Array<OneTypeCost>>([]);

  useEffect(() => {
    const updated: Array<OneTypeCost> = [];

    amortizedCosts?.forEach((cost, index) => {
      updated.push({
        index,
        oneTimeCost: moment(cost.from).isSame(cost.to, "day"),
      });
    });

    setOneTimeCosts(updated);
  }, [amortizedCosts]);

  const handleOneTimeCostsChange = (index: number) => {
    const currentlyEdited = oneTimeCosts?.find((cost) => cost.index === index);
    const filtered = oneTimeCosts?.filter((cost) => cost.index !== index);
    let newItem;

    if (currentlyEdited) {
      newItem = { index, oneTimeCost: !currentlyEdited?.oneTimeCost };
    } else {
      newItem = { index, oneTimeCost: true };
    }

    const result = [...filtered, newItem];
    setOneTimeCosts(result);
  };

  const isChecked = (index: number) => {
    const currentlyEdited = oneTimeCosts?.find((cost) => cost.index === index);
    return currentlyEdited?.oneTimeCost || false;
  };

  const commonInputProps: InputProps = {
    fullWidth: true,
    disabled: !isEditable,
    color: "secondary",
  };

  const datePickerCommonProps = {
    animateYearScrolling: true,
    fullWidth: true,
    autoOk: true,
  };

  return (
    <FieldArray
      name={PropertyPricesFormFields.AmortizedCosts}
      validateOnChange={false}
    >
      {({ remove, push, replace }) => (
        <Fragment>
          {amortizedCosts?.map((pricing, index) => (
            <Grid
              key={index}
              container
              spacing={3}
              alignItems="flex-start"
              className={classes.gridRow}
            >
              <Grid item xs={12} md={3}>
                <Label value={t("costType")} />
                <Select
                  labelId="costType"
                  id="costType"
                  value={pricing.counterType}
                  className={classes.select}
                  disabled={!isEditable}
                  onChange={(e) =>
                    replace(index, {
                      ...pricing,
                      counterType: e.target.value,
                    })
                  }
                  color="secondary"
                  disableUnderline
                >
                  {counterTypesFields?.map((type, index) => (
                    <MenuItem value={type.id} key={index}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={3}>
                <Input
                  label={t("description")}
                  value={pricing.description}
                  type="text"
                  gutterBottom={0}
                  onChange={(e) =>
                    replace(index, {
                      ...pricing,
                      description: e.target.value,
                    })
                  }
                  inputProps={{ min: 0 }}
                  {...commonInputProps}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Input
                  label={t("amount")}
                  value={pricing.amount}
                  type="number"
                  unit="CHF"
                  gutterBottom={0}
                  className={classes.priceInput}
                  onChange={(e) =>
                    replace(index, {
                      ...pricing,
                      amount: e.target.value,
                    })
                  }
                  inputProps={{ min: 0 }}
                  {...commonInputProps}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Label value={t("costPortionType")} />
                <Select
                  labelId="costPortionType"
                  id="costPortionType"
                  value={pricing.costDistributionType}
                  className={classes.select}
                  disabled={!isEditable}
                  onChange={(e) =>
                    replace(index, {
                      ...pricing,
                      costDistributionType: e.target.value,
                    })
                  }
                  color="secondary"
                  disableUnderline
                >
                  {costPortionTypes?.map((type, index) => (
                    <MenuItem value={type.id} key={index}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  label={t("costTime")}
                  value={pricing.from}
                  maxDate={pricing?.to || undefined}
                  onChange={(date) =>
                    replace(index, { ...pricing, from: date })
                  }
                  variant="dialog"
                  color="secondary"
                  data-cy="costTime-input"
                  {...datePickerCommonProps}
                  disabled={!isEditable}
                  TextFieldComponent={(params: any) => <Input {...params} />}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <DatePicker
                  label={t("amortizedUntil")}
                  value={pricing.to}
                  minDate={pricing?.from || undefined}
                  onChange={(date) => replace(index, { ...pricing, to: date })}
                  variant="dialog"
                  color="secondary"
                  data-cy="costTime-input"
                  {...datePickerCommonProps}
                  disabled={!isEditable || isChecked(index)}
                  TextFieldComponent={(params: any) => <Input {...params} />}
                />
              </Grid>
              <Grid item xs={4} md={3}>
                <Label value={t("oneTimeCost")} />
                <Checkbox
                  checked={isChecked(index)}
                  className={classes.checkbox}
                  onChange={() => {
                    handleOneTimeCostsChange(index);
                    if (!isChecked(index)) {
                      replace(index, { ...pricing, to: pricing.from });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <IconButton
                  onClick={() => {
                    if (moment(pricing.to).isAfter(moment())) {
                      setConfirmationDialogOpen(true);
                      setCostIndexToDelete(index);
                    } else {
                      remove(index);
                    }
                  }}
                  className={classes.deleteButton}
                  disableRipple
                  disabled={!isEditable}
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </Grid>
              {index !== amortizedCosts?.length - 1 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
              <ConfirmationDialog
                open={confirmationDialogOpen}
                onClose={() => setConfirmationDialogOpen(false)}
                onSubmit={() => {
                  remove(costIndexToDelete);
                  setConfirmationDialogOpen(false);
                }}
                error={null}
                confirmationText={t("propertyNotFullyAmortized")}
                primaryButtonText={t("continue")}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              onClick={() =>
                push({
                  counterType: "",
                  amount: "",
                  costDistributionType: "",
                  description: "",
                  from: null,
                  to: null,
                })
              }
              size="small"
              variant="outlined"
              color="secondary"
              startIcon={<AddIcon />}
              className={classes.addButton}
              disabled={!isEditable}
            >
              {t("priceConfiguration")}
            </Button>
          </Grid>
        </Fragment>
      )}
    </FieldArray>
  );
}

export default AmortizedCosts;
