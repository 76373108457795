import { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useHistory, useLocation } from "react-router";

function LoginPage() {
  const history = useHistory();
  const location = useLocation<any>();
  const { keycloak } = useKeycloak();
  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    if (keycloak.authenticated) {
      history.replace({
        ...from,
      });
    }
  }, [keycloak, from, history]);

  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak]);

  return null;
}

export default LoginPage;
