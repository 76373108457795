import React from "react";
import PageLayout from "../../../layouts/PageLayout/PageLayout";
import CardRow from "../../../components/Card/CardRow/CardRow";
import PropertySelector from "../../../fragments/PropertySelector";
import ButtonNew from "../../../components/ButtonNew";
import Filters from "../../../fragments/Filters";
import { useHistory, useParams } from "react-router";
import usePropertyDetail from "../../../api/hooks/usePropertyDetail";
import NewPropertyDialog from "../../../components/NewPropertyDialog";
import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useConsumption from "../../../api/hooks/useConsumption";
import { getCurrentDate, getNextDate } from "../../../utils/date/date.utils";
import useFilterStore, { fullFilterState } from "../../../store/useFilterStore";
import shallow from "zustand/shallow";
import PropertyConsumption from "../../owner/PropertyDetailPage/components/PropetyConsumption";
import Row from "../../../components/Row";
import AddressCard from "../../../components/AddressCard";
import OwnerCard from "../../../components/OwnerCard";
import routes from "../../../constants/routes";
import TabPanel from "../../../components/TabPanel";
import PropertyForm from "../../../components/Forms/PropertyForm";
import useSetProperty from "../../../api/hooks/useSetProperty";
import useDeleteProperty from "../../../api/hooks/useDeleteProperty";
import { useKeycloak } from "@react-keycloak/web";
import { useFormik } from "formik";
import { validatePropertyForm } from "../../../components/NewPropertyDialog/NewPropertyDialog";
import getPricingInput from "../../../utils/getPricingInput";
import QueryKeys from "../../../api/QueryKeys";
import { useQueryClient } from "react-query";
import ExportConsumptionAsCsv from "../../../fragments/ExportConsumptionsAsCsv";
import hasRealmRole from "../../../utils/hasRealmRole";
import Role from "../../../auth/Role";
import Button from "../../../components/Button";
import formatCounterPricings from "../../../utils/format/formatCounterPricings";
import formatAmortizationCostsInput from "../../../utils/format/formatAmortizationCostsInput";
import validateAmortizationCosts from "../../../utils/validate/validateAmortizationCosts";
import ErrorAlert from "../../../components/ErrorAlert";
import { propertyFormikInitialValues } from "../../../constants/propertyFormikInitialValues";
import useSetPropertyValues from "../../../hooks/useSetPropertyValues";
import { PropertyFormValues } from "../../../types";
import useApartmentIdsForProperty from "../../../api/hooks/useApartmentIdsForProperty";

const useStyles = makeStyles(() => ({
  header: {
    height: "100%",
  },
  tabs: {
    marginTop: 16,
  },
  exportCsvButton: {
    position: "absolute",
    right: 40,
  },
  errorAlert: {
    marginTop: 16,
  },
}));

function PropertyDetailPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { date, consumptionPeriod, consumptionTypes } = useFilterStore(
    fullFilterState,
    shallow
  );
  const { propertyId } = useParams<{ propertyId: string }>();
  const history = useHistory();
  const setProperty = useSetProperty();
  const deleteProperty = useDeleteProperty();
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  const [newPropertyDialogOpen, setNewPropertyDialogOpen] = React.useState(
    false
  );
  const [tab, setTab] = React.useState(0);

  const { data: propertyData, isLoading, error } = usePropertyDetail(
    propertyId,
    Boolean(propertyId)
  );

  const {
    data: consumptionData,
    isLoading: isLoadingConsumptionData,
    error: consumptionDataError,
  } = useConsumption(
    {
      from: getCurrentDate(date, consumptionPeriod).toISOString(),
      to: getNextDate(date, consumptionPeriod, "next").toISOString(),
      propertyId,
      counters: Array.from(consumptionTypes),
    },
    Boolean(propertyId)
  );

  const property = propertyData?.properties?.[0];
  const isEditable = property ? property.isEditable : true;

  const formik = useFormik<PropertyFormValues>({
    initialValues: propertyFormikInitialValues,
    onSubmit: () => {},
    validate: validatePropertyForm,
    validateOnChange: true,
  });

  useSetPropertyValues(property, formik.setValues, tab);

  // ApartmentsIds = counterIds
  const {
    data: { getApartmentIdsForProperty: counterIds = [] } = {
      getApartmentIdsForProperty: [],
    },
  } = useApartmentIdsForProperty(property?.id || "", !!property?.id);

  const handleSubmit = async () => {
    const {
      name,
      dataGatewayId,
      addressLine1,
      addressLine2,
      city,
      zip,
      propertyOwner,
      counterPricings,
      areaSize,
      amortizedCosts,
      mainCounterName,
      costDistributionType,
    } = formik.values;

    const counterPricingsList = formatCounterPricings(counterPricings);
    const amortizedCostsInput = formatAmortizationCostsInput(amortizedCosts);

    const photovoltaicsConfig = costDistributionType
      ? {
          costDistributionType,
        }
      : null;

    await setProperty.mutate(
      {
        id: property?.id,
        name,
        dataGatewayId,
        areaSize: areaSize ? Number(areaSize) : null,
        address: {
          addressLineOne: addressLine1,
          addressLineTwo: addressLine2,
          city,
          zip,
        },
        defaultConsumptionPricings: getPricingInput(formik.values),
        propertyOwnerId: propertyOwner?.id,
        counterPricings: [...counterPricingsList],
        amortizedCosts: [...amortizedCostsInput],
        mainCounterName,
        photovoltaicsConfig,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([QueryKeys.LIST_PROPERTIES]);
          await queryClient.refetchQueries([
            QueryKeys.PROPERTY_DETAIL,
            propertyId,
          ]);
          formik.resetForm();
          setTab(0);
        },
      }
    );
  };

  const handleDelete = () => {
    deleteProperty.mutate(property?.id || "", {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.LIST_PROPERTIES]);
        formik.resetForm();
        history.push(routes.admin.properties);
      },
    });
  };

  const loading = deleteProperty.isLoading || setProperty.isLoading;
  const isAdmin = hasRealmRole(keycloak, Role.Admin);
  const { propertyOwner } = formik.values;
  const invalidAmortizationCosts = validateAmortizationCosts(
    formik.values.amortizationCosts
  );
  const disabledButton =
    loading ||
    !formik.isValid ||
    (isAdmin && !propertyOwner) ||
    !isEditable ||
    invalidAmortizationCosts;

  const footer = (
    <CardRow footer justifyContent="space-between">
      <Button
        loading={deleteProperty.isLoading}
        variant="outlined"
        color="default"
        onClick={handleDelete}
        disabled={disabledButton}
      >
        {t("delete")}
      </Button>
      <Button
        loading={setProperty.isLoading}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={disabledButton || !formik.isValid}
      >
        {t("save")}
      </Button>
    </CardRow>
  );

  return (
    <PageLayout
      loading={isLoading}
      error={error}
      layoutType="admin"
      displayBackButton
      contentMarginTop
      sticky={
        <CardRow header borderBottom>
          <PropertySelector
            onSelect={(property) =>
              history.push(routes.admin.getPropertyDetail(property?.id || ""))
            }
          />
          <ButtonNew onButtonNewClick={() => setNewPropertyDialogOpen(true)} />
        </CardRow>
      }
      footer={tab === 1 ? footer : undefined}
    >
      <CardRow>
        <Tabs
          value={tab}
          onChange={(e, value) => {
            setTab(value);
          }}
          className={classes.tabs}
          indicatorColor="primary"
        >
          <Tab label={t("consumption")} />
          <Tab label={t("configure")} />
        </Tabs>
        <Filters />
      </CardRow>
      <TabPanel value={tab} index={0}>
        <ExportConsumptionAsCsv
          propertyId={propertyId}
          fileName={property?.name}
          className={classes.exportCsvButton}
          disabled={
            !consumptionData?.getConsumption || isLoadingConsumptionData
          }
        />
        <PropertyConsumption
          consumptionData={consumptionData?.getConsumption}
          error={consumptionDataError}
          loading={isLoadingConsumptionData}
        />
        <Row alignItems="flex-start">
          <OwnerCard owner={property?.owners?.[0]} />
          <AddressCard address={property?.address} />
        </Row>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <PropertyForm
          values={formik.values}
          counterIds={counterIds}
          onChange={formik.handleChange}
          isEditable={isEditable}
          onFieldChange={(fieldName, value) => {
            formik.setFieldValue(fieldName, value);
          }}
        />
        <ErrorAlert error={setProperty?.error} className={classes.errorAlert} />
      </TabPanel>
      <NewPropertyDialog
        open={newPropertyDialogOpen}
        onClose={() => setNewPropertyDialogOpen(false)}
      />
    </PageLayout>
  );
}

export default PropertyDetailPage;
