import { BarChartData } from "../getBarChartData";
import { XAxisProps } from "recharts";

export default function getDynamicAxisProps(
  chartData: BarChartData
): Partial<XAxisProps> {
  const displayLabelsAtAngle = chartData?.items?.length > 6;

  return {
    dy: displayLabelsAtAngle ? 30 : 10,
    tick: { fontSize: 12 },
    interval: 0,
    allowDataOverflow: true,
    angle: displayLabelsAtAngle ? 40 : 0,
    height: displayLabelsAtAngle ? 70 : 20,
    tickMargin: 0,
    tickSize: 12,
  };
}
