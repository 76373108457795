import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import useSetTenant from "../../api/hooks/useSetTenant";
import useListApartments from "../../api/hooks/useListApartments";
import { FormikProvider, useFormik } from "formik";
import QueryKeys from "../../api/QueryKeys";
import ErrorAlert from "../ErrorAlert";
import { TenantApartmentsFormValues } from "../Forms/TenantApartmentsForm/TenantApartmentsForm";
import { TenantInfoFormValues } from "../Forms/TenantInfoForm/TenantInfoForm";
import TenantForm from "../Forms/TenantForm";
import Dialog from "../Dialog";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import routes from "../../constants/routes";
import { formatTenantApartments } from "../../utils/format/formatTenantApartments";

interface Props {
  open: boolean;
  onClose: () => void;
}

type ValidationErrors = {
  invalidEmail?: boolean;
  invalidName?: boolean;
  invalidSurname?: boolean;
  invalidAddress?: boolean;
  invalidZip?: boolean;
  invalidCity?: boolean;
  invalidApartments?: boolean;
};

export function validateTenantForm(
  values: TenantApartmentsFormValues & TenantInfoFormValues
) {
  const errors: ValidationErrors = {};

  if (!values.email) {
    errors.invalidEmail = true;
  }

  if (!values.firstName) {
    errors.invalidName = true;
  }

  if (!values.lastName) {
    errors.invalidSurname = true;
  }

  if (!values.addressLine1) {
    errors.invalidAddress = true;
  }

  if (!values.zip) {
    errors.invalidZip = true;
  }

  if (!values.city) {
    errors.invalidCity = true;
  }

  return errors;
}

const useStyles = makeStyles(() => ({
  errorAlert: {
    marginBottom: 16,
  },
}));

function NewTenantDialog({ open, onClose }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const setTenant = useSetTenant();
  const { data: apartmentsData } = useListApartments();

  const formik = useFormik<TenantApartmentsFormValues & TenantInfoFormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      city: "",
      addressLine1: "",
      addressLine2: "",
      zip: "",
      email: "",
      apartments: [
        {
          apartment: null,
          dateIn: null,
          dateOut: null,
        },
      ],
    },
    onSubmit: () => {},
    validate: validateTenantForm,
    validateOnChange: true,
    validateOnMount: true,
  });

  const handleSubmit = async () => {
    const {
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      city,
      zip,
      email,
      apartments,
    } = formik.values;

    await setTenant.mutate(
      {
        firstName,
        lastName,
        address: {
          addressLineOne: addressLine1,
          addressLineTwo: addressLine2,
          city,
          zip,
        },
        email,
        apartments: formatTenantApartments(apartments),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.LIST_TENANTS]);
          formik.resetForm();

          if (onClose) {
            onClose();
          }

          history.push(routes.owner.tenant);
        },
      }
    );
  };

  const handleCloseClick = () => {
    formik.resetForm();
    setTenant.reset();

    if (onClose) {
      onClose();
    }
  };

  let invalidApartmentsData = false;

  if (!formik.values.apartments?.length) {
    invalidApartmentsData = true;
  }

  for (let item of formik.values.apartments) {
    if (!item?.apartment?.id || !item?.dateIn) {
      invalidApartmentsData = true;
    }
  }

  const disabledSubmitButton =
    setTenant.isLoading || !formik.isValid || invalidApartmentsData;

  return (
    <Dialog
      open={open}
      dialogHeader={t("newTenant")}
      onClose={handleCloseClick}
      maxWidth="lg"
      data-cy="new-tenant-dialog"
      onSubmit={handleSubmit}
      disabledSubmitButton={disabledSubmitButton}
      primaryButtonLoading={setTenant.isLoading}
      errorAlert={
        <ErrorAlert error={setTenant.error} className={classes.errorAlert} />
      }
    >
      <FormikProvider value={formik}>
        <TenantForm
          values={formik.values}
          onChange={formik.handleChange}
          apartmentsList={apartmentsData?.apartments || []}
        />
      </FormikProvider>
    </Dialog>
  );
}

export default NewTenantDialog;
