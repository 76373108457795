import React, { useState } from "react";
import PageLayout from "../../../layouts/PageLayout/PageLayout";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router";
import SearchHeader from "../../../components/SearchHeader";
import TenantSelector from "../../../fragments/TenantSelector";
import NewTenantDialog from "../../../components/NewTenantDialog";
import Table from "../../../components/Table";
import useListTenants from "../../../api/hooks/useListTenats";
import routes from "../../../constants/routes";
import { TenantDto, TenantSelector_TenantFragment } from "../../../api/types";
import { Skeleton } from "@material-ui/lab";

function TenantsPage() {
  const history = useHistory();
  const { data, isLoading } = useListTenants();

  const [newTenantDialogOpen, setNewTenantDialogOpen] = useState<boolean>(
    false
  );
  const [
    selectedTenant,
    setSelectedTenant,
  ] = useState<TenantSelector_TenantFragment | null>(null);

  const filteredData = data?.tenants?.filter(
    (tenant: TenantDto) => tenant?.id === selectedTenant?.id
  );

  const rows = selectedTenant ? filteredData : data?.tenants;

  const skeleton = isLoading && (
    <div>
      {new Array(5).fill(0).map((_, index) => (
        <Box width="100%" paddingX={0} key={index}>
          <Skeleton
            variant="rect"
            width="100%"
            height={40}
            style={{ marginTop: 6 }}
          />
        </Box>
      ))}
    </div>
  );

  return (
    <PageLayout
      loading={false}
      error={false}
      layoutType="owner"
      contentMarginTop
      sticky={
        <SearchHeader
          onButtonNewClick={() => setNewTenantDialogOpen(true)}
          selector={
            <TenantSelector
              onSelect={(tenant) => setSelectedTenant(tenant || null)}
            />
          }
        />
      }
    >
      <Box>
        <Table
          loading={isLoading}
          rows={rows}
          onOpenDetailClick={(tenant) => {
            history.push(routes.owner.getTenantDetail(tenant.id));
          }}
        />
        {skeleton}
      </Box>
      <NewTenantDialog
        open={newTenantDialogOpen}
        onClose={() => setNewTenantDialogOpen(false)}
      />
    </PageLayout>
  );
}

export default TenantsPage;
