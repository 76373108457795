import React from "react";
import OwnerForm from "../Forms/OwnerForm";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import { useQueryClient } from "react-query";
import { OwnerInfoFormValues } from "../Forms/OwnerInfoForm/OwnerInfoForm";
import { OwnerPropertiesFormValues } from "../Forms/OwnerPropertiesForm/OwnerPropertiesForm";
import useSetPropertyOwner from "../../api/hooks/useSetPropertyOwner";
import useListProperties from "../../api/hooks/useListProperties";
import ErrorAlert from "../ErrorAlert";
import Dialog from "../Dialog";
import QueryKeys from "../../api/QueryKeys";
import { makeStyles } from "@material-ui/styles";
import { OwnerPaymentDetailsFormValues } from "../Forms/OwnerPaymentDetailsForm/OwnerPaymentDetailsForm";

interface Props {
  open: boolean;
  onClose?: () => void;
}

const useStyles = makeStyles(() => ({
  errorAlert: {
    marginBottom: 16,
  },
}));

type ValidationErrors = {
  invalidFirstName?: boolean;
  invalidLastName?: boolean;
  invalidEmail?: boolean;
  invalidAddress?: boolean;
  invalidZip?: boolean;
  invalidCity?: boolean;
};

export function validateOwnerForm(
  values: OwnerInfoFormValues & OwnerPropertiesFormValues
) {
  const errors: ValidationErrors = {};

  if (!values.firstName) {
    errors.invalidFirstName = true;
  }

  if (!values.lastName) {
    errors.invalidLastName = true;
  }

  if (!values.email) {
    errors.invalidEmail = true;
  }

  if (!values.addressLine1) {
    errors.invalidAddress = true;
  }

  if (!values.zip) {
    errors.invalidZip = true;
  }

  if (!values.city) {
    errors.invalidCity = true;
  }

  return errors;
}

function NewOwnerDialog({ onClose, open }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const setPropertyOwner = useSetPropertyOwner();
  const { data } = useListProperties();
  const queryClient = useQueryClient();

  const formik = useFormik<
    OwnerInfoFormValues &
      OwnerPropertiesFormValues &
      OwnerPaymentDetailsFormValues
  >({
    initialValues: {
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      zip: "",
      city: "",
      email: "",
      bankAccountNumber: "",
      beneficiaryName: "",
      beneficiaryAddressLine1: "",
      beneficiaryAddressLine2: "",
      beneficiaryZip: "",
      beneficiaryCity: "",
      beneficiaryVAT: "",
      managedProperty: [],
    },
    onSubmit: () => {},
    validate: validateOwnerForm,
    validateOnMount: true,
    validateOnChange: true,
  });

  const handleSubmit = async () => {
    const {
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      zip,
      city,
      email,
      bankAccountNumber,
      beneficiaryName,
      beneficiaryAddressLine1,
      beneficiaryAddressLine2,
      beneficiaryCity,
      beneficiaryZip,
      beneficiaryVAT,
      managedProperty,
    } = formik.values;

    await setPropertyOwner.mutate(
      {
        firstName,
        lastName,
        address: {
          addressLineOne: addressLine1,
          addressLineTwo: addressLine2,
          city,
          zip,
        },
        email,
        bankAccountNumber,
        beneficiaryName,
        beneficiaryAddress: {
          addressLineOne: beneficiaryAddressLine1,
          addressLineTwo: beneficiaryAddressLine2,
          city: beneficiaryCity,
          zip: beneficiaryZip,
        },
        beneficiaryVAT: beneficiaryVAT,
        propertyIds: managedProperty?.map((property) => property?.id),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.LIST_PROPERTY_OWNERS]);
          formik.resetForm();

          if (onClose) {
            onClose();
          }
        },
      }
    );
  };

  const handleCloseClick = () => {
    formik.resetForm();
    setPropertyOwner.reset();

    if (onClose) {
      onClose();
    }
  };

  const disabledSubmitButton = setPropertyOwner.isLoading || !formik.isValid;

  return (
    <Dialog
      open={open}
      dialogHeader={t("newOwner")}
      onClose={handleCloseClick}
      maxWidth="lg"
      data-cy="new-tenant-dialog"
      onSubmit={handleSubmit}
      disabledSubmitButton={disabledSubmitButton}
      primaryButtonLoading={setPropertyOwner.isLoading}
      errorAlert={
        <ErrorAlert
          error={setPropertyOwner.error}
          className={classes.errorAlert}
        />
      }
    >
      <FormikProvider value={formik}>
        <OwnerForm
          values={formik.values}
          onChange={formik.handleChange}
          propertiesList={data?.properties || []}
          onFieldChange={(fieldName, value) => {
            formik.setFieldValue(fieldName, value);
          }}
        />
      </FormikProvider>
    </Dialog>
  );
}

export default NewOwnerDialog;
