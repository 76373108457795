const routes = {
  overview: "/overview",
  login: "/login",
  invoice: "/invoice/:invoiceId",
  adminRoot: "/a/",
  ownerRoot: "/o/",
  tenantRoot: "/t/",
  admin: {
    dashboard: "/a/dashboard",
    loxoneMiniserver: "/a/loxone-miniserver",
    properties: "/a/properties",
    propertyDetail: "/a/properties/:propertyId",
    getPropertyDetail: (propertyId: string) => `/a/properties/${propertyId}`,
    owner: "/a/owners",
    ownerDetail: "/a/owners/:ownerId",
    getOwnerDetail: (ownerId: string) => `/a/owners/${ownerId}`,
  },
  owner: {
    dashboard: "/o/dashboard",
    properties: "/o/properties",
    propertyDetail: "/o/properties/:propertyId",
    getPropertyDetail: (propertyId: string) => `/o/properties/${propertyId}`,
    apartments: "/o/apartments",
    apartmentDetail: "/o/properties/:propertyId/apartments/:apartmentId/",
    getApartmentDetail: (propertyId: string, apartmentId: string) =>
      `/o/properties/${propertyId}/apartments/${apartmentId}`,
    tenant: "/o/tenants",
    tenantDetail: "/o/tenants/:tenantId",
    getTenantDetail: (tenantId: string) => `/o/tenants/${tenantId}`,
    invoices: "/o/properties/:propertyId/apartments/:apartmentId/invoices",
    getInvoices: (propertyId: string, apartmentId: string) =>
      `/o/properties/${propertyId}/apartments/${apartmentId}/invoices`,
  },
  tenant: {
    overview: "/t/overview",
    apartmentDetail: "/t/overview/apartments/:apartmentId/detail",
    getApartmentDetail: (apartmentId: string) =>
      `/t/overview/apartments/${apartmentId}/detail`,
    consumption: "/t/consumption",
    invoices: "/t/invoices/apartments/:apartmentId",
    getInvoices: (apartmentId: string) =>
      `/t/invoices/apartments/${apartmentId}`,
  },
};

export default routes;
