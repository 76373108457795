import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import Section from "../../Section";
import List from "../../List";
import { Typography } from "@material-ui/core";
import { gql } from "graphql-request/dist";
import ADDRESS_FIELDS from "../../../api/fragments/addressFields";
import { OwnerDetailsFragment } from "../../../api/types";

interface OwnerDetailsProps {
  owners?: Array<OwnerDetailsFragment> | null;
  borderRight?: boolean;
  borderBottom?: boolean;
}

const useStyles = makeStyles(() => ({
  email: {
    fontWeight: 500,
    marginTop: 8,
  },
}));

function OwnerDetails({ owners }: OwnerDetailsProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Section.Header title={t("owner")} />
      <Section.Content>
        <List>
          {owners?.map((owner) => {
            return (
              <div key={owner.id}>
                <Typography>{`${owner?.firstName || ""} ${
                  owner?.lastName || ""
                }`}</Typography>
                <Typography>{owner?.address?.addressLineOne || ""}</Typography>
                <Typography>{owner?.address?.addressLineTwo || ""}</Typography>
                <Typography>{`${owner?.address?.zip || ""} ${
                  owner?.address?.city || ""
                }`}</Typography>
                <Typography className={classes.email}>
                  {owner?.email || ""}
                </Typography>
              </div>
            );
          })}
        </List>
      </Section.Content>
    </>
  );
}

OwnerDetails.fragments = {
  ownerDetails: gql`
    ${ADDRESS_FIELDS}
    fragment OwnerDetails on PropertyOwnerDto {
      id
      firstName
      lastName
      email
      address {
        ...AddressFields
      }
    }
  `,
};

export default OwnerDetails;
