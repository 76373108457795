import React from "react";
import {
  InputLabel as MaterialInputLabel,
  InputLabelProps,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

interface Props extends InputLabelProps {
  value?: string;
  required?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#272833",
    marginBottom: 4,
    fontWeight: 500,
    fontSize: 14,
    pointerEvents: "none",
  },
}));

function Label({ value, className, required, ...rest }: Props) {
  const classes = useStyles();
  if (!value) {
    return null;
  }

  return (
    <MaterialInputLabel
      classes={{
        root: clsx(className, classes.root),
      }}
      required={required}
      {...rest}
    >
      {value}
    </MaterialInputLabel>
  );
}

export default Label;
