import { FormikValues } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { Grid, MenuItem, Select } from "@material-ui/core";

import FormCard from "../FormCard";
import PowerIcon from "@material-ui/icons/Power";
import { makeStyles } from "@material-ui/styles";
import Label from "../../Label";
import { PhotovoltaicsCostDistributionType } from "../../../api/types";

export enum MainCounterFormFields {
  MainCounterName = "mainCounterName",
}

export interface MainCounterFormValues extends FormikValues {
  mainCounterName: string | undefined;
}

interface Props {
  values: MainCounterFormValues;
  onFieldChange: (fieldName: string, value: string) => void;
  counterIds: string[];
  isEditable?: boolean;
}

const useStyles = makeStyles(() => ({
  grid: {
    maxWidth: 368,
  },
  select: {
    width: "100%",
  },
}));

function MainCounterForm({
  values,
  counterIds,
  onFieldChange,
  isEditable = true,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { mainCounterName, costDistributionType } = values;

  const apartmentsMenuItems = counterIds.map((counterId) => (
    <MenuItem value={counterId} key={counterId}>
      {counterId}
    </MenuItem>
  ));

  const onChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    field: MainCounterFormFields
  ) => {
    const eventValue = event.target.value;
    if (typeof eventValue === "string") {
      onFieldChange(field, eventValue);
    }
  };

  const mainCounterRequired =
    costDistributionType === PhotovoltaicsCostDistributionType.Consumption;

  return (
    <FormCard
      header={t("counterConfiguration")}
      headerIcon={<PowerIcon fontSize="default" />}
    >
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12}>
          <Label value={t("mainCounter")} required={mainCounterRequired} />
          <Select
            labelId="counter-name-label"
            id="counter-name-select"
            value={mainCounterName || ""}
            className={classes.select}
            disabled={!isEditable}
            onChange={(event) =>
              onChange(event, MainCounterFormFields.MainCounterName)
            }
            color="secondary"
            disableUnderline
            required={mainCounterRequired}
          >
            {apartmentsMenuItems}
          </Select>
        </Grid>
      </Grid>
    </FormCard>
  );
}

export default MainCounterForm;
