import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SELECTOR_WIDTH } from "../../theme";
import { makeStyles } from "@material-ui/core";
import { CardHeader } from "../../components/Card";
import SearchableMenu from "../../components/SearchableMenu";
import { gql } from "graphql-request";
import { useQuery } from "react-query";
import QueryKeys from "../../api/QueryKeys";
import { gqlClient } from "../../App";
import { HomeIconPrimary } from "../../assets";
import {
  TenantSelector_TenantFragment,
  TenantSelectorDataQuery,
} from "../../api/types";
import getDisplayName from "../../utils/getDisplayName";
import { useParams } from "react-router";

const useStyles = makeStyles(() => ({
  root: {
    width: SELECTOR_WIDTH,
  },
}));

export interface TenantSelectorProps {
  onSelect?: (tenant?: TenantSelector_TenantFragment | null) => void;
}

function useTenantSelectorData() {
  return useQuery<TenantSelectorDataQuery>(
    [QueryKeys.TENANT_SELECTOR],
    async () =>
      await gqlClient.request(gql`
        ${TenantSelector.fragments.tenants}
        query TenantSelectorData {
          tenants {
            ...TenantSelector_Tenant
          }
        }
      `)
  );
}

function TenantSelector({ onSelect }: TenantSelectorProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data } = useTenantSelectorData();
  const { tenantId } = useParams<{ tenantId: string }>();
  const [
    selectedTenant,
    setSelectedTenant,
  ] = useState<TenantSelector_TenantFragment | null>(null);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<any>(undefined);

  const query = searchQuery.toLowerCase();
  const filteredTenants = data?.tenants?.filter(
    (tenant) =>
      tenant?.id?.toLowerCase().includes(query) ||
      tenant?.firstName?.toLowerCase()?.includes(query) ||
      tenant?.lastName?.toLowerCase()?.includes(query)
  );

  useEffect(() => {
    if (tenantId) {
      const selectedTenant = data?.tenants?.find(
        (tenant) => tenant?.id === tenantId
      );
      setSelectedTenant(selectedTenant || null);
    }
  }, [tenantId, setSelectedTenant, data]);

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (
    tenantId: string | null,
    tenant?: TenantSelector_TenantFragment | null
  ) => {
    setSelectedTenant(tenant || null);
    handleClose();

    if (onSelect) {
      onSelect(tenant);
    }
  };

  return (
    <div className={classes.root}>
      <CardHeader
        title={getDisplayName(selectedTenant) || t("all")}
        label={t("tenant")}
        icon={HomeIconPrimary}
        onClick={handleClick}
      />
      <SearchableMenu<TenantSelector_TenantFragment>
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        items={filteredTenants || new Array<any>()}
        getId={(item) => item?.id}
        getName={(item) => getDisplayName(item)}
        onSelect={handleSelect}
        value={selectedTenant?.id}
        searchQuery={searchQuery}
        onChangeSearchQuery={(value) => setSearchQuery(value)}
      />
    </div>
  );
}

TenantSelector.fragments = {
  tenants: gql`
    fragment TenantSelector_Tenant on TenantDto {
      id
      firstName
      lastName
    }
  `,
};

export default TenantSelector;
