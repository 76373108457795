import React, { ChangeEvent } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FormikValues } from "formik";
import { DatePicker, DatePickerProps } from "@material-ui/pickers";
import { Moment } from "moment";
import {
  ConsumptionType,
  InvoiceItemType,
  TenantDto,
} from "../../../api/types";
import Autocomplete from "../../Autocomplete";
import { MONTH_DATE_FORMAT } from "../../../cfg";
import getPricingLabel from "../../../utils/chart/getPricingLabel";
import Input from "../../Input";
import { BORDER_COLOR_SECONDARY, SUBHEADER_COLOR } from "../../../theme";

export const consumptionTypesList: Array<{
  id: ConsumptionType;
  name: string;
}> = [
  {
    id: ConsumptionType.Electricity,
    name: getPricingLabel(InvoiceItemType.Electricity),
  },
  {
    id: ConsumptionType.ElectricityH,
    name: getPricingLabel(InvoiceItemType.ElectricityH),
  },
  {
    id: ConsumptionType.ElectricityL,
    name: getPricingLabel(InvoiceItemType.ElectricityL),
  },
  {
    id: ConsumptionType.ElectricityPv,
    name: getPricingLabel(InvoiceItemType.ElectricityPv),
  },
  {
    id: ConsumptionType.MaximumPower,
    name: getPricingLabel(InvoiceItemType.MaximumPower),
  },
  {
    id: ConsumptionType.WaterWarmVolume,
    name: getPricingLabel(InvoiceItemType.WaterWarmVolume),
  },
  {
    id: ConsumptionType.WaterColdVolume,
    name: getPricingLabel(InvoiceItemType.WaterColdVolume),
  },
  {
    id: ConsumptionType.HeatingEnergy,
    name: getPricingLabel(InvoiceItemType.HeatingEnergy),
  },
  {
    id: ConsumptionType.Charging,
    name: getPricingLabel(InvoiceItemType.Charging),
  },
  {
    id: ConsumptionType.AdditionalCost,
    name: getPricingLabel(InvoiceItemType.AdditionalCost),
  },
];

const datePickerProps: Partial<DatePickerProps> = {
  color: "secondary",
  variant: "dialog",
  fullWidth: true,
  format: MONTH_DATE_FORMAT,
  InputLabelProps: { shrink: true },
  PopoverProps: {
    anchorOrigin: {
      horizontal: "left",
      vertical: "top",
    },
    transformOrigin: {
      horizontal: "left",
      vertical: "top",
    },
  },
  autoOk: true,
  required: true,
  disableToolbar: false,
  views: ["year", "month"],
  openTo: "month",
};

export enum InvoiceFormFields {
  StartMonth = "startMonth",
  EndMonth = "endMonth",
  Tenant = "tenant",
  IncludeVat = "includeVat",
  IncludedConsumptionTypes = "includedConsumptionTypes",
  MessagingStartTime = "messagingStartTime",
  IntervalMonths = "intervalMonths",
  UseMessaging = "useMessaging",
}

export interface InvoiceFormValues extends FormikValues {
  startMonth: Moment;
  endMonth: Moment;
  tenant?: TenantDto | null;
  includeVat: boolean;
  includedConsumptionTypes: Array<{
    id: ConsumptionType;
    name: string;
  }>;
  useMessaging: boolean;
  messagingStartTime?: Moment;
  intervalMonths?: number | null;
  id?: string;
  dateSent?: string;
}

interface Props {
  values: InvoiceFormValues;
  onChange: (e: React.ChangeEvent<any>) => void;
  onFieldChange: (
    fieldName: keyof InvoiceFormValues,
    value: InvoiceFormValues[keyof InvoiceFormValues]
  ) => void;
  disabledFields?: Set<InvoiceFormValues>;
  tenants: any[];
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  checkbox: {
    marginRight: 8,
  },
  subheader: {
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 5,
    color: SUBHEADER_COLOR,
    width: "100%",
    borderBottom: `1px solid ${BORDER_COLOR_SECONDARY}`,
  },
  autocompleteInput: {
    "& div": {
      height: "100%",
    },
  },
}));

function InvoiceForm({ values, onChange, onFieldChange, tenants }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    startMonth,
    endMonth,
    tenant,
    includeVat,
    includedConsumptionTypes,
    useMessaging,
    messagingStartTime,
    intervalMonths,
  } = values;

  const extendedDatePickerProps = {
    ...datePickerProps,
    required: useMessaging,
  };

  return (
    <form className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography className={classes.subheader}>{t("period")}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label={t("from")}
            value={startMonth}
            onChange={(date) =>
              onFieldChange(InvoiceFormFields.StartMonth, date)
            }
            name={InvoiceFormFields.StartMonth}
            {...datePickerProps}
            TextFieldComponent={(params: any) => <Input {...params} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label={t("to")}
            value={endMonth}
            onChange={(date) => onFieldChange(InvoiceFormFields.EndMonth, date)}
            name={InvoiceFormFields.EndMonth}
            {...datePickerProps}
            TextFieldComponent={(params: any) => <Input {...params} />}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subheader}>{t("tenant")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            options={tenants}
            optionLabel={(option: TenantDto) =>
              `${option?.firstName} ${option?.lastName}`
            }
            onChange={(e: ChangeEvent<any>, value: any) => {
              onFieldChange(InvoiceFormFields.Tenant, value);
            }}
            value={tenant}
            id={InvoiceFormFields.Tenant}
            fieldName={InvoiceFormFields.Tenant}
            label={t("tenantFirstName")}
            required
            filterSelectedOptions={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subheader}>{t("pricings")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            options={consumptionTypesList}
            onChange={(e: ChangeEvent<any>, value: any) => {
              onFieldChange(InvoiceFormFields.IncludedConsumptionTypes, value);
            }}
            value={includedConsumptionTypes}
            id={InvoiceFormFields.IncludedConsumptionTypes}
            fieldName={InvoiceFormFields.IncludedConsumptionTypes}
            label={t("includedCosts")}
            multiple
            className={classes.autocompleteInput}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            key="vat"
            control={
              <Checkbox
                checked={includeVat}
                onChange={() => {
                  onFieldChange(InvoiceFormFields.IncludeVat, !includeVat);
                }}
                name={InvoiceFormFields.IncludeVat}
                className={classes.checkbox}
              />
            }
            label={t("includeVat")}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subheader}>
            <FormControlLabel
              key={InvoiceFormFields.UseMessaging}
              control={
                <Checkbox
                  checked={useMessaging}
                  onChange={() => {
                    onFieldChange(
                      InvoiceFormFields.UseMessaging,
                      !useMessaging
                    );
                  }}
                  name={InvoiceFormFields.UseMessaging}
                  className={classes.checkbox}
                />
              }
              label={t("useMessaging")}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label={t("messagingStartTime")}
            value={messagingStartTime}
            onChange={(date) =>
              onFieldChange(InvoiceFormFields.MessagingStartTime, date)
            }
            name={InvoiceFormFields.MessagingStartTime}
            {...extendedDatePickerProps}
            TextFieldComponent={(params: any) => <Input {...params} />}
            disabled={!useMessaging}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            value={intervalMonths}
            label={t("intervalMonths")}
            gutterBottom={0}
            InputProps={{
              id: InvoiceFormFields.IntervalMonths,
              name: InvoiceFormFields.IntervalMonths,
              color: "secondary",
              disabled: !useMessaging,
              InputLabelProps: { shrink: true },
              type: "number",
              inputProps: { min: 0 },
            }}
            onChange={onChange}
            required={useMessaging}
          />
        </Grid>
      </Grid>
    </form>
  );
}

export default InvoiceForm;
