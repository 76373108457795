import { useMutation } from "react-query";
import QueryKeys from "../QueryKeys";
import { gql } from "graphql-request/dist";
import { SetDataGatewayInput } from "../types";
import { gqlClient } from "../../App";

function useSetDataGateway() {
  return useMutation(
    QueryKeys.SET_DATA_GATEWAY,
    async (input: SetDataGatewayInput) => {
      return await gqlClient.request(
        gql`
          mutation SetDataGateway($input: SetDataGatewayInput!) {
            setDataGateway(input: $input) {
              id
            }
          }
        `,
        {
          input,
          operationName: QueryKeys.SET_DATA_GATEWAY,
        }
      );
    }
  );
}

export default useSetDataGateway;
