import { useMutation } from "react-query";
import QueryKeys from "../QueryKeys";
import { gqlClient } from "../../App";
import { gql } from "graphql-request/dist";

function useDeleteDataGateway() {
  return useMutation(
    QueryKeys.DELETE_DATA_GATEWAY,
    async (id: string) =>
      await gqlClient.request(
        gql`
          mutation DeleteDataGateway($id: String!) {
            deleteDataGateway(id: $id)
          }
        `,
        {
          id,
          operationName: QueryKeys.DELETE_DATA_GATEWAY,
        }
      )
  );
}

export default useDeleteDataGateway;
