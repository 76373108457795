import { gql } from "graphql-request";
import { useQuery } from "react-query";
import QueryKeys from "../QueryKeys";
import { GetFiltersInput, ListPropertiesQuery } from "../types";
import { gqlClient } from "../../App";
import ADDRESS_FIELDS from "../fragments/addressFields";
import CONSUMPTION_PRICING_FIELDS from "../fragments/consumptionPricingFields";

function useListProperties(filters?: GetFiltersInput, enabled = true) {
  return useQuery<ListPropertiesQuery>(
    [QueryKeys.LIST_PROPERTIES, filters],
    async () => {
      return await gqlClient.request(
        gql`
          ${ADDRESS_FIELDS}
          ${CONSUMPTION_PRICING_FIELDS}
          query ListProperties($filters: GetFiltersInput) {
            properties(filters: $filters) {
              id
              name
              isEditable
              dataGatewayId
              isEditable
              areaSize
              address {
                ...AddressFields
              }
              owners {
                id
                firstName
                lastName
                email
                address {
                  addressLineOne
                  addressLineTwo
                  city
                  zip
                }
                properties {
                  id
                  name
                }
              }
              apartments {
                id
                name
              }
              defaultConsumptionPricings {
                ...ConsumptionPricingFields
              }
              counterPricings {
                counterType
                price
              }
            }
          }
        `,
        {
          filters,
          operationName: QueryKeys.LIST_PROPERTIES,
        }
      );
    },
    {
      enabled,
    }
  );
}

export type Property = ListPropertiesQuery["properties"][0];

export default useListProperties;
