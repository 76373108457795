import { roundValue } from "../roundValue";
import { ConsumptionType, GetConsumptionQuery } from "../../api/types";

type ConsumptionCardData = Record<
    ConsumptionType | string,
    { totalPrice: number; value: number } | never
>;

function getConsumptionCardsData(
    consumptionData: GetConsumptionQuery["getConsumption"],
): ConsumptionCardData {
    const result: ConsumptionCardData = {};

    if (!consumptionData?.length) {
        return {};
    }

    for (let consumption of consumptionData) {
        if (!result[consumption.itemType]) {
            result[consumption.itemType] = {
                totalPrice: roundValue(consumption.totalPrice),
                value: roundValue(consumption.value),
            };
        } else {
            result[consumption.itemType].totalPrice += roundValue(
                consumption.totalPrice,
            );
            result[consumption.itemType].value += roundValue(consumption.value);
        }
    }

    return result;
}

export default getConsumptionCardsData;
