import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import IconAdd from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  button: {
    minWidth: "unset",
    marginLeft: "auto",
  },
}));

interface Props {
  onButtonNewClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

function ButtonNew({ onButtonNewClick }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      startIcon={<IconAdd />}
      size="small"
      className={classes.button}
      variant="contained"
      color="primary"
      onClick={(e) => onButtonNewClick(e)}
    >
      {t("new")}
    </Button>
  );
}
export default ButtonNew;
