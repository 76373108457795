import { ns } from "./object/object.utils";
import ErrorCodes from "../constants/ErrorCodes";

export default function getErrorCode(error: any) {
  if (!error) {
    return ErrorCodes.Undefined;
  }

  const gqlErrorCode = ns(() => error.graphQLErrors[0].extensions.code, null);

  const responseErrorCode = ns(
    () => error.response.errors[0].extensions.code,
    null
  );

  const responseExceptionErrorCode = ns(
    () => error.response.errors[0].extensions.exception.response.message[0],
    null
  );

  return (
    responseExceptionErrorCode ||
    responseErrorCode ||
    gqlErrorCode ||
    ErrorCodes.Unknown
  );
}
