import React from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { CARD_SPACING } from "../../../theme";

interface Props {
  children: React.ReactNode;
  withSpacing?: boolean;
  height?: string;
  maxHeight?: string | number | boolean;
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    height: (props: any) => props?.height,
    overflowY: "auto",
  },
  spacing: {
    padding: CARD_SPACING,
  },
  maxHeight: {
    maxHeight: (props: any) => props?.maxHeight || "calc(100vh - 250px)",
  },
}));

function CardContent(props: Props) {
  const classes = useStyles(props);
  const { children, withSpacing, maxHeight } = props;

  return (
    <div
      className={clsx(classes.root, props.className, {
        [classes.spacing]: withSpacing,
        [classes.maxHeight]: Boolean(maxHeight),
      })}
    >
      {children}
    </div>
  );
}

export default CardContent;
