import { CostPortion } from "../api/types";
import { t } from "../i18n";

const labelMap: Record<string, string> = {
  [CostPortion.Area]: t("area"),
  [CostPortion.Consumption]: t("consumption"),
  [CostPortion.Uniform]: t("linear"),
};

function getCostPortionLabel(costPortion: CostPortion) {
  return labelMap[costPortion] || "";
}

export default getCostPortionLabel;
