import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./translations/resources";

i18n.use(initReactI18next).init({
  resources,
  lng: "de",
  interpolation: {
    escapeValue: false,
  },
  defaultNS: "common",
});

export const t = i18n.t.bind(i18n);

export default i18n;
