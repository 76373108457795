import React from "react";
import { TEXT_SECONDARY } from "../../../theme";
import { TooltipPayload } from "recharts";

const propertyTooltipFormatter = (
  v: string | number | Array<string | number>,
  name: string,
  entry: TooltipPayload
) => {
  const dataKey = `${entry.dataKey}`;
  return (
    <div>
      <span style={{ fontWeight: "bold" }}>{v}</span>{" "}
      <span style={{ color: TEXT_SECONDARY }}>
        {entry.payload.unitLabel[dataKey]}
      </span>
    </div>
  );
};

export default propertyTooltipFormatter;
