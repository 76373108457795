import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import Dialog from "../Dialog";
import { Typography } from "@material-ui/core";
import Input from "../Input";
import ErrorAlert from "../ErrorAlert";
import { useMutation, useQueryClient } from "react-query";
import QueryKeys from "../../api/QueryKeys";
import { gqlClient } from "../../App";
import { gql } from "graphql-request";

interface Props {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  errorAlert: {
    marginTop: 16,
  },
  textField: {
    marginBottom: 16,
  },
  infoText: {
    marginBottom: 20,
  },
  content: {},
}));

function useAddReadonlyProperty() {
  return useMutation(
    QueryKeys.ADD_READONLY_PROPERTY,
    async (gatewayId: String) => {
      return await gqlClient.request(
        gql`
          mutation AddReadonlyProperty($gatewayId: String!) {
            addReadonlyProperty(gatewayId: $gatewayId)
          }
        `,
        {
          gatewayId,
          operationName: QueryKeys.ADD_READONLY_PROPERTY,
        }
      );
    }
  );
}

function NewReadonlyPropertyDialog({ open, onClose }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const addReadonlyProperty = useAddReadonlyProperty();
  const queryClient = useQueryClient();

  const [propertyUuid, setPropertyUuid] = useState<string>("");
  const [error, setError] = useState<any>(null);

  const resetState = () => {
    setPropertyUuid("");
    setError(null);
  };

  const handleSubmit = async () => {
    try {
      await addReadonlyProperty.mutate(propertyUuid || "", {
        onSuccess: async () => {
          await queryClient.invalidateQueries([QueryKeys.LIST_PROPERTIES]);
          if (onClose) {
            onClose();
          }
          resetState();
        },
      });
    } catch {
      setError(addReadonlyProperty.error);
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    resetState();
  };

  return (
    <Dialog
      open={open}
      dialogHeader={t("newReadonlyProperty")}
      onClose={handleClose}
      maxWidth="sm"
      data-cy="new-readonly-property-dialog"
      onSubmit={handleSubmit}
      disabledSubmitButton={!propertyUuid}
      background="white"
      primaryButtonLoading={addReadonlyProperty.isLoading}
    >
      <div className={classes.content}>
        <Typography variant="body2" className={classes.infoText}>
          {t("propertyIdInfo")}
        </Typography>
        <Input
          value={propertyUuid}
          label={t("propertyId")}
          required
          fullWidth
          color="secondary"
          data-cy="property-id-input"
          onChange={(e) => setPropertyUuid(e.target.value)}
          className={classes.textField}
        />
        {error && <ErrorAlert error={t("unexpectedError")} />}
      </div>
    </Dialog>
  );
}

export default NewReadonlyPropertyDialog;
