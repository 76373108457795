import ErrorMessage from "../../components/ErrorMessage";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  fullScreen?: boolean;
}

function EmptyState({ fullScreen }: Props) {
  const { t } = useTranslation("common");
  const [render, setRender] = useState<boolean>(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setRender(true);
    }, 30);

    return () => {
      clearTimeout(handler);
    };
  }, []);

  if (!render) {
    return null;
  }

  return (
    <ErrorMessage
      fullScreen={fullScreen}
      title={t("noResultsTitle")}
      message={t("noResultsMessage")}
    />
  );
}

export default EmptyState;
