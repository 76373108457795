import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, CircularProgressProps } from "@material-ui/core";
import clsx from "clsx";

interface LoadingIndicatorProps {
  className?: string;
  progressProps?: CircularProgressProps;
  size?: number | string;
  height?: string;
  [key: string]: any;
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: (props: LoadingIndicatorProps) => props.height || "100%",
  },
}));

function LoadingIndicator(props: LoadingIndicatorProps) {
  const { className, progressProps, size, ...rest } = props;

  const classes = useStyles(props);
  return (
    <div className={clsx(classes.root, className)}>
      <CircularProgress
        size={size}
        color="secondary"
        {...progressProps}
        {...rest}
      />
    </div>
  );
}

export default React.memo(LoadingIndicator);
