import Keycloak from "keycloak-js";
import { AUTHORITY, CLIENT_ID, KEYCLOAK_REALM } from "../cfg";

const keycloak = Keycloak({
  url: AUTHORITY,
  realm: KEYCLOAK_REALM,
  clientId: CLIENT_ID,
});

export default keycloak;
