import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikValues } from "formik";
import {
  Grid,
  TextFieldProps,
  Tooltip,
  Typography,
  Zoom,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  AmortizedCostDto,
  ConsumptionPricingFieldsFragment,
  ConsumptionType,
  CounterPricingDto,
  InvoiceItemType,
} from "../../../api/types";
import FormCard from "../FormCard";
import getPricingLabel from "../../../utils/chart/getPricingLabel";
import getUnit from "../../../utils/chart/getUnit";
import Input from "../../Input";
import AdditionalCostFields from "./AdditionalCostsField";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import CounterPricings from "./CounterPricings";
import { BORDER_COLOR_SECONDARY, SUBHEADER_COLOR } from "../../../theme";
import Row from "../../Row";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AmortizedCosts from "./AmortizedCosts";

export enum PropertyPricesFormFields {
  ApartmentNumber = "apartmentNumber",
  PricingElectricityL = "pricingElectricityL",
  PricingElectricityH = "pricingElectricityH",
  PricingElectricityPV = "pricingElectricityPV",
  PricingElectricity = "pricingElectricity",
  PricingMaximumPower = "pricingMaximumPower",
  PricingWaterWarm = "pricingWaterWarm",
  PricingWaterCold = "pricingWaterCold",
  PricingHeating = "pricingHeating",
  SelectedApartment = "selectedApartment",
  PricingCharging = "pricingCharging",
  AdditionalCosts = "additionalCosts",
  CounterPricings = "counterPricings",
  AmortizedCosts = "amortizedCosts",
}

export interface PropertyPricesFormValues extends FormikValues {
  pricingElectricityL: number | string | null;
  pricingElectricityH: number | string | null;
  pricingElectricityPV: number | string | null;
  pricingElectricity: number | string | null;
  pricingMaximumPower: number | string | null;
  pricingWaterWarm: number | string | null;
  pricingWaterCold: number | string | null;
  pricingHeating: number | string | null;
  pricingCharging: number | string | null;
  additionalCosts: Array<ConsumptionPricingFieldsFragment>;
  counterPricings: Array<CounterPricingDto>;
  amortizedCosts: Array<AmortizedCostDto>;
}

interface Props {
  values: PropertyPricesFormValues;
  onChange: (e: React.ChangeEvent<any>) => void;
  disabledFields?: Set<PropertyPricesFormFields>;
  placeholders?: {
    [key: string]: number | string;
  };
  isEditable?: boolean;
}

const useStyles = makeStyles(() => ({
  grid: {
    marginBottom: 44,
  },
  subheader: {
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 5,
    color: SUBHEADER_COLOR,
    width: "100%",
    borderBottom: `1px solid ${BORDER_COLOR_SECONDARY}`,
  },
  subheaderRow: {
    paddingBottom: 5,
    width: "100%",
    borderBottom: `1px solid ${BORDER_COLOR_SECONDARY}`,
    "& > p": {
      fontSize: 14,
      fontWeight: 600,
      color: SUBHEADER_COLOR,
    },
    "& > svg": {
      fontSize: 22,
      color: SUBHEADER_COLOR,
      cursor: "pointer",
    },
  },
  input: {
    width: 190,
  },
  additionalCostsFields: {
    marginBottom: 12,
  },
  costsFields: {
    marginBottom: 32,
  },
  pricingsGrid: {
    "& > div:not(:first-child)": {
      padding: 12,
    },
  },
}));

function PropertyPricesForm({
  onChange,
  values,
  placeholders,
  isEditable = true,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const getTextFieldProps = (
    fieldName: PropertyPricesFormFields
  ): TextFieldProps => ({
    id: fieldName,
    name: fieldName,
    onChange,
    color: "secondary",
    disabled: !isEditable,
    placeholder: placeholders?.[fieldName]?.toString(),
    InputLabelProps: { shrink: true },
    className: classes.input,
    type: "number",
    inputProps: { min: 0 },
  });

  const {
    pricingElectricityL,
    pricingElectricity,
    pricingElectricityH,
    pricingElectricityPV,
    pricingMaximumPower,
    pricingWaterCold,
    pricingWaterWarm,
    pricingHeating,
    pricingCharging,
    additionalCosts,
    counterPricings,
    amortizedCosts,
  } = values;

  const electricity = (
    <Grid item xs={12} sm={6} md={4} className={classes.pricingsGrid}>
      <Grid item xs={10}>
        <Typography className={classes.subheader}>
          {t("electricity")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingElectricityL}
          label={getPricingLabel(InvoiceItemType.ElectricityL)}
          data-cy="af-electricity-l-input"
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.Electricity)}`}
          InputProps={{
            ...getTextFieldProps(PropertyPricesFormFields.PricingElectricityL),
            disabled: !!pricingElectricity || !isEditable,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingElectricityH}
          label={getPricingLabel(InvoiceItemType.ElectricityH)}
          data-cy="af-electricity-l-input"
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.Electricity)}`}
          InputProps={{
            ...getTextFieldProps(PropertyPricesFormFields.PricingElectricityH),
            disabled: !!pricingElectricity || !isEditable,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingElectricity}
          label={getPricingLabel(InvoiceItemType.Electricity)}
          data-cy="af-electricity-l-input"
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.Electricity)}`}
          InputProps={{
            ...getTextFieldProps(PropertyPricesFormFields.PricingElectricity),
            disabled:
              !!(pricingElectricityL || pricingElectricityH) || !isEditable,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingCharging}
          label={getPricingLabel(InvoiceItemType.Charging)}
          data-cy="af-electricity-l-input"
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.Charging)}`}
          InputProps={{
            ...getTextFieldProps(PropertyPricesFormFields.PricingCharging),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingElectricityPV}
          label={getPricingLabel(InvoiceItemType.ElectricityPv)}
          data-cy="af-electricity-l-input"
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.ElectricityPv)}`}
          InputProps={{
            ...getTextFieldProps(PropertyPricesFormFields.PricingElectricityPV),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingMaximumPower}
          label={getPricingLabel(InvoiceItemType.MaximumPower)}
          data-cy="af-electricity-l-input"
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.MaximumPower)}`}
          InputProps={{
            ...getTextFieldProps(PropertyPricesFormFields.PricingMaximumPower),
          }}
        />
      </Grid>
    </Grid>
  );

  const water = (
    <Grid item xs={12} sm={6} md={4} className={classes.pricingsGrid}>
      <Grid item xs={10}>
        <Typography className={classes.subheader}>{t("water")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingWaterWarm}
          label={getPricingLabel(InvoiceItemType.WaterWarmVolume)}
          data-cy="af-electricity-l-input"
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.WaterWarmVolume)}`}
          InputProps={{
            ...getTextFieldProps(PropertyPricesFormFields.PricingWaterWarm),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingWaterCold}
          label={getPricingLabel(InvoiceItemType.WaterColdVolume)}
          data-cy="af-electricity-l-input"
          gutterBottom={0}
          unit={`CHF/${getUnit(ConsumptionType.WaterColdVolume)}`}
          InputProps={{
            ...getTextFieldProps(PropertyPricesFormFields.PricingWaterCold),
          }}
        />
      </Grid>
    </Grid>
  );

  const heating = (
    <Grid item xs={12} sm={6} md={4} className={classes.pricingsGrid}>
      <Grid item xs={10}>
        <Typography className={classes.subheader}>{t("heating")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Input
          value={pricingHeating}
          label={getPricingLabel(InvoiceItemType.HeatingEnergy)}
          data-cy="af-electricity-l-input"
          gutterBottom={0}
          InputProps={{
            ...getTextFieldProps(PropertyPricesFormFields.PricingHeating),
          }}
          unit={`CHF/${getUnit(ConsumptionType.HeatingEnergy)}`}
        />
      </Grid>
    </Grid>
  );

  const counterPricingsFields = (
    <Grid container spacing={3} className={classes.costsFields}>
      <Grid item xs={12}>
        <Typography className={classes.subheader}>
          {t("counterPricings")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CounterPricings
          counterPricings={counterPricings}
          isEditable={isEditable}
        />
      </Grid>
    </Grid>
  );

  const amortizationCostsFields = (
    <Grid container spacing={3} className={classes.costsFields}>
      <Grid item xs={12}>
        <Typography className={classes.subheader}>
          {t("amortizationCosts")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AmortizedCosts
          amortizedCosts={amortizedCosts}
          isEditable={isEditable}
        />
      </Grid>
    </Grid>
  );

  const additionalCostsFields = (
    <Grid container spacing={3} className={classes.additionalCostsFields}>
      <Grid item xs={12}>
        <Row className={classes.subheaderRow}>
          <Typography>{t("additionalCosts")}</Typography>
          <Tooltip
            placement="bottom-start"
            TransitionComponent={Zoom}
            title={t("additionalCostsInfo") as string}
          >
            <InfoOutlinedIcon />
          </Tooltip>
        </Row>
      </Grid>
      <Grid item xs={12}>
        <AdditionalCostFields
          additionalCosts={additionalCosts}
          isEditable={isEditable}
        />
      </Grid>
    </Grid>
  );

  return (
    <FormCard
      header={t("priceAndTariff")}
      headerIcon={<HomeOutlinedIcon fontSize="default" />}
    >
      <Grid
        container
        className={classes.grid}
        alignItems="flex-start"
        justify="space-between"
      >
        {electricity}
        {water}
        {heating}
      </Grid>
      {counterPricingsFields}
      {amortizationCostsFields}
      {additionalCostsFields}
    </FormCard>
  );
}

export default PropertyPricesForm;
