import React from "react";
import { makeStyles } from "@material-ui/styles";
import ApartmentInfoForm, {
  ApartmentInfoFormFields,
  ApartmentInfoFormValues,
} from "../ApartmentInfoForm/ApartmentInfoForm";
import ApartmentPricesForm, {
  ApartmentPricesFormFields,
  ApartmentPricesFormValues,
} from "../ApartmentPricesForm/ApartmentPricesForm";
import { ListPropertiesQuery } from "../../../api/types";

const useStyles = makeStyles(() => ({
  root: {
    "& > div:first-child": {
      marginBottom: 16,
    },
  },
}));

interface Props {
  values: ApartmentInfoFormValues & ApartmentPricesFormValues;
  onChange: (e: React.ChangeEvent<any>) => void;
  onFieldChange: (fieldName: string, value: any) => void;
  disabledFields?: Set<ApartmentInfoFormFields & ApartmentPricesFormFields>;
  propertiesList: ListPropertiesQuery["properties"];
  apartmentsList: any;
  placeholders?: {
    [key: string]: number | string;
  };
  isEditable?: boolean;
}

function ApartmentForm({
  values,
  onChange,
  onFieldChange,
  propertiesList,
  apartmentsList,
  placeholders,
  isEditable,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ApartmentInfoForm
        values={values}
        onChange={onChange}
        onFieldChange={onFieldChange}
        propertiesList={propertiesList}
        apartmentsList={apartmentsList}
        isEditable={isEditable}
      />
      <ApartmentPricesForm
        values={values}
        onChange={onChange}
        onFieldChange={onFieldChange}
        placeholders={placeholders}
        isEditable={isEditable}
      />
    </div>
  );
}

export default ApartmentForm;
