import React, { CSSProperties } from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { BORDER_RADIUS } from "../../../theme";

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  cypressId?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    borderRadius: BORDER_RADIUS,
    overflow: "hidden",
  },
}));

function Card({ children, className, style, cypressId }: Props) {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, className)}
      style={style}
      data-cy={cypressId}
    >
      {children}
    </div>
  );
}

export default Card;
