import React, { memo } from "react";
import { BarSvgProps, ResponsiveBar } from "@nivo/bar";
import ErrorMessage from "../../ErrorMessage";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress } from "@material-ui/core";
import getErrorCode from "../../../utils/getErrorCode";
import ErrorCodes from "../../../constants/ErrorCodes";

interface Props {
  loading?: boolean;
  config?: BarSvgProps;
  hideLegends?: boolean;
  units?: Array<string>;
  error?: any;
}

const useStyles = makeStyles(() => ({
  root: {},
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  errorMessage: {
    height: "100%",
  },
}));

const DEFAULT_BAR_CONFIG: BarSvgProps = {
  data: [],
  indexBy: "item",
  padding: 0.3,
  layout: "vertical",
  margin: {
    top: 10,
    right: 180,
    bottom: 50,
    left: 75,
  },
  valueScale: {
    type: "linear",
  },
  indexScale: {
    type: "band",
    round: true,
  },
  colors: { scheme: "nivo" },
  axisTop: null,
  axisBottom: {
    tickSize: 6,
    tickPadding: 8,
    tickRotation: 18,
    legendPosition: "middle",
    legendOffset: 32,
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
  },
  labelSkipWidth: 12,
  labelSkipHeight: 12,
  animate: true,
  motionStiffness: 90,
  motionDamping: 15,
  legends: [
    {
      dataFrom: "keys",
      anchor: "bottom-right",
      direction: "column",
      justify: false,
      translateX: 120,
      translateY: 5,
      itemWidth: 100,
      itemHeight: 30,
      itemDirection: "left-to-right",
      symbolSize: 20,
    },
  ],
  labelFormat: (v) => Number(v).toFixed(2),
  tooltipFormat: (v) => Number(v).toFixed(2),
};

// export const HIDE_LEGENDS_CONFIG = {
//   legends: [],
//   margin: { right: 30, left: 60, top: 60, bottom: 60 },
// };

function getTickRotation(data: any) {
  const items = data.length;

  if (items < 5) {
    return 0;
  } else if (items > 5 && items < 12) {
    return 18;
  } else {
    return 45;
  }
}

function BarChart(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const errorCode = getErrorCode(props?.error);

  if (props?.loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={32} color="secondary" />
      </div>
    );
  }

  if (errorCode === ErrorCodes.IncompleteData) {
    return (
      <ErrorMessage
        className={classes.errorMessage}
        title={t("errors.incompleteDataHeader")}
        message={t("errors.incompleteDataText")}
      />
    );
  }

  if (props?.units?.length === 0) {
    return (
      <ErrorMessage
        className={classes.errorMessage}
        title={t("noCheckboxesSelectedTitle")}
        message={t("noCheckboxesSelectedMessage")}
      />
    );
  }

  if (!props?.config?.data?.length) {
    return (
      <ErrorMessage
        className={classes.errorMessage}
        title={t("noResultsTitle")}
        message={t("noResultsMessage")}
      />
    );
  }

  return (
    <ResponsiveBar
      {...DEFAULT_BAR_CONFIG}
      {...props.config}
      axisBottom={{
        ...props.config.axisBottom,
        tickRotation: getTickRotation(props?.config?.data),
      }}
    />
  );
}

export default memo(BarChart);
