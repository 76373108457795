import React from "react";
import {
  ButtonProps as MUIButtonProps,
  CircularProgressProps,
} from "@material-ui/core";
import { Button as MaterialButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoadingIndicator from "../LoadingIndicator";

export interface ButtonProps extends MUIButtonProps {
  //Button will receive secondary styles if true
  secondary?: boolean;

  loading?: boolean;

  progressProps?: CircularProgressProps;

  hidden?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

function Button({
  secondary,
  disabled,
  loading,
  progressProps,
  children,
  hidden,
  ...rest
}: ButtonProps) {
  const classes = useStyles();

  if (hidden) {
    return null;
  }

  const content = !loading ? (
    children
  ) : (
    <LoadingIndicator
      progressProps={{
        size: 24,
        color: "inherit",
        ...progressProps,
      }}
    />
  );

  const additionalProps: Record<string, any> = {
    variant: "contained",
    color: "primary",
  };

  if (secondary) {
    additionalProps.variant = "outlined";
  }

  return (
    <MaterialButton
      classes={{
        root: classes.root,
      }}
      size="small"
      disabled={disabled || loading}
      children={content}
      {...additionalProps}
      {...rest}
    />
  );
}

export default Button;
