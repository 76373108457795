import React from "react";
import { makeStyles } from "@material-ui/styles";
import { BORDER_COLOR, SUBHEADER_COLOR } from "../../theme";
import { Typography } from "@material-ui/core";
import Row from "../Row";
import getUnit from "../../utils/chart/getUnit";
import getLabel from "../../utils/chart/getLabel";
import getFillColor from "../../utils/chart/getFillColor";
import useFilterStore from "../../store/useFilterStore";
import { roundValue } from "../../utils/roundValue";

interface Props {
  consumptionType: string;
  value: number;
  totalPrice: number;
}

const useStyles = makeStyles(() => ({
  root: {
    width: "auto",
    minWidth: 138,
    border: `1px solid ${BORDER_COLOR}`,
    borderLeft: (props: Props) =>
      `4px solid ${getFillColor(props.consumptionType)}`,
    padding: "8px 16px",
    alignSelf: "stretch",
    backgroundColor: "white",
    marginBottom: 8,
  },
  value: { fontSize: 20, fontWeight: 500, marginRight: "4px !important" },
  unit: { fontSize: 16, fontWeight: 400, color: SUBHEADER_COLOR },
  label: {
    color: SUBHEADER_COLOR,
    fontSize: 12,
  },
}));

function ConsumptionItemCard(props: Props) {
  const { consumptionType, value, totalPrice } = props;
  const classes = useStyles(props);

  const { showCosts } = useFilterStore();

  return (
    <div className={classes.root}>
      <Row alignItems="baseline">
        <Typography className={classes.value}>
          {showCosts ? roundValue(totalPrice) : roundValue(value)}
        </Typography>
        <Typography className={classes.unit}>
          {showCosts ? "CHF" : getUnit(consumptionType)}
        </Typography>
      </Row>
      <Typography className={classes.label}>
        {getLabel(consumptionType)}
      </Typography>
    </div>
  );
}

export default ConsumptionItemCard;
