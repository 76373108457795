import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikValues } from "formik";
import { Grid, TextFieldProps, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormCard from "../FormCard";
import Input from "../../Input";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { BORDER_COLOR_SECONDARY, SUBHEADER_COLOR } from "../../../theme";

export enum PropertyInfoFormFields {
  Name = "name",
  DataGatewayId = "dataGatewayId",
  AddressLine1 = "addressLine1",
  AddressLine2 = "addressLine2",
  Zip = "zip",
  City = "city",
  PropertyId = "string",
  AreaSize = "areaSize",
}

export interface PropertyInfoFormValues extends FormikValues {
  name: string;
  dataGatewayId: string;
  addressLine1: string;
  addressLine2: string;
  zip: string;
  city: string;
  propertyId: string;
  areaSize: string;
}

interface Props {
  values: PropertyInfoFormValues;
  onChange: (e: React.ChangeEvent<any>) => void;
  disabledFields?: Set<PropertyInfoFormFields>;
  placeholders?: {
    [key: string]: number | string;
  };
  isEditable?: boolean;
}

const useStyles = makeStyles(() => ({
  subheader: {
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 5,
    color: SUBHEADER_COLOR,
    width: "100%",
    borderBottom: `1px solid ${BORDER_COLOR_SECONDARY}`,
  },
  zipInput: {
    width: 190,
  },
}));

function PropertyInfoForm({
  onChange,
  values,
  placeholders,
  isEditable = true,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const getTextFieldProps = (
    fieldName: PropertyInfoFormFields
  ): TextFieldProps => ({
    id: fieldName,
    name: fieldName,
    onChange,
    color: "secondary",
    disabled: !isEditable,
    placeholder: placeholders?.[fieldName]?.toString(),
    InputLabelProps: { shrink: true },
    fullWidth: true,
  });

  const {
    name,
    addressLine1,
    addressLine2,
    dataGatewayId,
    city,
    zip,
    propertyId,
    areaSize,
  } = values;


  return (
    <FormCard
      header={t("nameAddress")}
      headerIcon={<HomeOutlinedIcon fontSize="default" />}
    >
      <Grid container spacing={4} alignItems="flex-start">
        <Grid container item xs={12} sm={6} md={4} spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.subheader}>{t("name")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
                required
                value={name}
                label={t("propertyName")}
                data-cy="property-form-name-input"
                gutterBottom={0}
                InputProps={{
                  ...getTextFieldProps(PropertyInfoFormFields.Name),
                }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
                required
                value={dataGatewayId}
                label={t("gatewayId")}
                data-cy="pf-gateway-input"
                gutterBottom={0}
                InputProps={{
                  ...getTextFieldProps(PropertyInfoFormFields.DataGatewayId),
                }}
            />
          </Grid>
          {propertyId && (
              <Grid item xs={12}>
                <Input
                    value={propertyId}
                    label={t("propertyId")}
                    data-cy="pf-property-id"
                    gutterBottom={0}
                    InputProps={{
                      ...getTextFieldProps(PropertyInfoFormFields.PropertyId),
                    }}
                    disabled
                />
              </Grid>
          )}
        </Grid>
        <Grid container item xs={12} sm={6} md={4} spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.subheader}>{t("address")}</Typography>
          </Grid>
          <Grid item xs={12} >
            <Input
                value={addressLine1}
                label={`${t("addressLine")} 1`}
                required
                data-cy="property-form-addressLine1-input"
                gutterBottom={0}
                InputProps={{
                  ...getTextFieldProps(PropertyInfoFormFields.AddressLine1),
                }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
                value={addressLine2}
                label={`${t("addressLine")} 2`}
                data-cy="property-form-addressLine2-input"
                gutterBottom={0}
                InputProps={{
                  ...getTextFieldProps(PropertyInfoFormFields.AddressLine2),
                }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
                value={zip}
                label={t("zip")}
                required
                data-cy="property-form-zip-input"
                gutterBottom={0}
                InputProps={{
                  ...getTextFieldProps(PropertyInfoFormFields.Zip),
                }}
                className={classes.zipInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
                value={city}
                label={t("city")}
                required
                data-cy="property-form-city-input"
                gutterBottom={0}
                InputProps={{
                  ...getTextFieldProps(PropertyInfoFormFields.City),
                }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.subheader}>
              {t("propertyAreaSize")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
                value={areaSize}
                label={t("propertyAreaSize")}
                data-cy="property-area-size"
                gutterBottom={0}
                InputProps={{
                  ...getTextFieldProps(PropertyInfoFormFields.AreaSize),
                }}
                type="number"
                inputProps={{ min: 0 }}
            />
          </Grid>
        </Grid>

      </Grid>
    </FormCard>
  );
}

export default PropertyInfoForm;
