import React, { useState } from "react";
import SearchHeader from "../../../components/SearchHeader";
import { Box } from "@material-ui/core";
import Table from "../../../components/Table";
import routes from "../../../constants/routes";
import PageLayout from "../../../layouts/PageLayout/PageLayout";
import NewOwnerDialog from "../../../components/NewOwnerDialog";
import { useHistory } from "react-router";
import {
  OwnerSelector_OwnerFragment,
  PropertyOwnerDto,
} from "../../../api/types";
import useListPropertyOwners from "../../../api/hooks/useListPropertyOwners";
import OwnerSelector from "../../../fragments/OwnerSelector";
import { Skeleton } from "@material-ui/lab";

function OwnersPage() {
  const history = useHistory();
  const { data, isLoading } = useListPropertyOwners();

  const [newOwnerDialogOpen, setNewOwnerDialogOpen] = useState<boolean>(false);
  const [
    selectedOwner,
    setSelectedOwner,
  ] = useState<OwnerSelector_OwnerFragment | null>(null);

  const filteredData = data?.propertyOwners?.filter(
    (owner: PropertyOwnerDto) => owner?.id === selectedOwner?.id
  );
  const rows = selectedOwner ? filteredData : data?.propertyOwners;

  const skeleton = isLoading && (
    <div>
      {new Array(5).fill(0).map((_, index) => (
        <Box width="100%" paddingX={0} key={index}>
          <Skeleton
            variant="rect"
            width="100%"
            height={40}
            style={{ marginTop: 6 }}
          />
        </Box>
      ))}
    </div>
  );

  return (
    <PageLayout
      loading={false}
      error={false}
      layoutType="admin"
      contentMarginTop
      sticky={
        <SearchHeader
          onButtonNewClick={() => setNewOwnerDialogOpen(true)}
          selector={
            <OwnerSelector
              onSelect={(owner) => setSelectedOwner(owner || null)}
            />
          }
        />
      }
    >
      <Box>
        <Table
          loading={isLoading}
          rows={rows}
          onOpenDetailClick={(owner) => {
            history.push(routes.admin.getOwnerDetail(owner.id));
          }}
        />
        {skeleton}
      </Box>
      <NewOwnerDialog
        open={newOwnerDialogOpen}
        onClose={() => setNewOwnerDialogOpen(false)}
      />
    </PageLayout>
  );
}

export default OwnersPage;
