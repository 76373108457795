import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PersonIcon from "@material-ui/icons/Person";
import { DIVIDER_COLOR, SUBHEADER_COLOR } from "../../theme";
import { PropertyOwnerDto } from "../../api/types";
import Row from "../Row";
import getDisplayName from "../../utils/getDisplayName";

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    maxWidth: 375,
    alignSelf: "stretch",
  },
  header: {
    padding: "8px 16px",
    borderBottom: `2px solid ${DIVIDER_COLOR}`,
    "& svg": {
      color: SUBHEADER_COLOR,
    },
  },
  name: {
    fontWeight: 500,
  },
  owner: {
    "&:not(:last-child)": {
      paddingBottom: 12,
    },
    "&  > p": {
      fontSize: 16,
    },
  },
}));

interface Props {
  owner?: Partial<PropertyOwnerDto>;
}

function OwnerCard({ owner }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  if (!owner) {
    return null;
  }

  return (
    <Card className={classes.card}>
      <Row className={classes.header}>
        <PersonIcon fontSize="small" />
        <Typography variant="h5">{t("owner")}</Typography>
      </Row>
      <CardContent>
        <div className={classes.owner}>
          <Typography className={classes.name}>
            {getDisplayName(owner)}
          </Typography>
          <Typography>{owner?.email}</Typography>
        </div>
      </CardContent>
    </Card>
  );
}

export default OwnerCard;
