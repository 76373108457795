/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Navigation from "../../../components/Navigation";
import { TENANT_PAGE_BG_COLOR } from "../../../theme";
import AvatarDropdownMenu from "../../../components/AvatarDropdownMenu";
import EmptyState from "../../../components/EmptyState";
import { GraphQLError } from "graphql";
import ErrorMessage from "../../../components/ErrorMessage";
import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";
import routes from "../../../constants/routes";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Row from "../../../components/Row";

type Props = {
  children: JSX.Element | JSX.Element[];
  loading?: boolean;
  emptyState?: boolean;
  error?: GraphQLError | null | unknown;
};

type TenantPageMenuItem = {
  text: string;
  to: string;
  selected: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.default,
  },
  contentRoot: {
    width: "100%",
  },
  container: {},
}));

function TenantPageLayout({ children, loading, emptyState, error }: Props) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const tenantPageMenuItems: TenantPageMenuItem[] = [
    {
      text: t("overview"),
      to: routes.tenant.overview,
      selected: pathname === routes.tenant.overview,
    },
    {
      text: t("consumption"),
      to: routes.tenant.consumption,
      selected: pathname === routes.tenant.consumption,
    },
    {
      text: t("invoices"),
      to: routes.tenant.invoices,
      selected: pathname === routes.tenant.invoices,
    },
  ];

  const mobileMenu = (
    <Navigation.MobileNavigationMenu>
      {/*{tenantPageMenuItems.map((menuItem) => {*/}
      {/*  return (*/}
      {/*    <MenuItem*/}
      {/*      component={Link}*/}
      {/*      to={menuItem.to}*/}
      {/*      selected={menuItem.selected}*/}
      {/*      key={menuItem.text}*/}
      {/*    >*/}
      {/*      {menuItem.text}*/}
      {/*    </MenuItem>*/}
      {/*  );*/}
      {/*})}*/}
    </Navigation.MobileNavigationMenu>
  );

  const menu = (
    <Row maxWidth>
      {/*{tenantPageMenuItems.map((menuItem) => {*/}
      {/*  return (*/}
      {/*    <Navigation.Button to={menuItem.to} selected={menuItem.selected} key={menuItem.text}>*/}
      {/*      {menuItem.text}*/}
      {/*    </Navigation.Button>*/}
      {/*  );*/}
      {/*})}*/}
    </Row>
  );

  const navigation = (
    <Navigation color={TENANT_PAGE_BG_COLOR}>
      <Navigation.Logo />
      {isMobile ? mobileMenu : menu}
      <AvatarDropdownMenu avatar="M" />
    </Navigation>
  );

  return (
    <div className={classes.root}>
      {navigation}
      <div className={classes.contentRoot}>
        {children}
        {error && <ErrorMessage fullScreen />}
        {emptyState && !loading && !error && <EmptyState fullScreen />}
      </div>
    </div>
  );
}

export default TenantPageLayout;
