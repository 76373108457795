import getAvatarLabel from "./getAvatarLabel";
import getUserDisplayName from "./getUserDisplayName";
import { IdTokenParsed } from "../types";

type UserData = {
  displayName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  avatarLabel?: string;
};

// Get user name, email and other useful info from id token
export default function getUserData(
  idTokenParsed: IdTokenParsed
): UserData | undefined {
  if (!idTokenParsed) {
    return;
  }

  const firstName = idTokenParsed?.given_name || "";
  const lastName = idTokenParsed?.family_name || "";
  const email = idTokenParsed?.email || "";

  return {
    firstName,
    lastName,
    email,
    avatarLabel: getAvatarLabel(idTokenParsed),
    displayName: getUserDisplayName(idTokenParsed),
  };
}
