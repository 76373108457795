import useFilterStore from "../../../store/useFilterStore";
import { LabelProps } from "recharts";

export default function getYAxisLabel(): LabelProps {
  const showCosts = useFilterStore.getState().showCosts;
  // TODO: Use a single type
  const label = showCosts ? "CHF" : "kWh, m3";

  return {
    value: `Verbrauch [${label}]`,
    angle: -90,
    position: "center",
    dx: -44,
    style: { fontSize: 14 },
  };
}
