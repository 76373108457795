import { gql } from "graphql-request";
import { useQuery } from "react-query";
import QueryKeys from "../QueryKeys";
import { GetFiltersInput } from "../types";
import { gqlClient } from "../../App";

function useListPropertyOwners(
  filters?: GetFiltersInput,
  enabled: boolean = true
) {
  return useQuery(
    [QueryKeys.LIST_PROPERTY_OWNERS, filters],
    async () => {
      return await gqlClient.request(
        gql`
          query ListPropertyOwners($filters: GetFiltersInput) {
            propertyOwners(filters: $filters) {
              id
              firstName
              lastName
              email
              bankAccountNumber
              beneficiaryName
              beneficiaryVAT
              beneficiaryAddress {
                addressLineOne
                addressLineTwo
                city
                zip
              }
              address {
                addressLineOne
                addressLineTwo
                city
                zip
              }
              properties {
                id
                name
              }
            }
          }
        `,
        {
          filters,
          operationName: QueryKeys.LIST_PROPERTY_OWNERS,
        }
      );
    },
    {
      enabled,
    }
  );
}

export default useListPropertyOwners;
