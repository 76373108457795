import React from "react";
import { Bar, ComposedChart, ResponsiveContainer, YAxis } from "recharts";
import getLabel from "../../../../utils/chart/getLabel";
import getFillColor from "../../../../utils/chart/getFillColor";
import getBarChartData from "../../../../utils/chart/getBarChartData";
import useFilterStore, {
  fullFilterState,
} from "../../../../store/useFilterStore";
import shallow from "zustand/shallow";
import { makeStyles } from "@material-ui/styles";
import Row from "../../../../components/Row";
import ConsumptionItemCard from "../../../../components/ConsumptionItemCard";
import getConsumptionCardsData from "../../../../utils/chart/getConsumptionCardsData";
import ExportConsumptionAsCsv from "../../../../fragments/ExportConsumptionsAsCsv";
import { CHARTS_CONTAINER_HEIGHT, DIVIDER_COLOR } from "../../../../theme";
import LoadingIndicator from "../../../../components/LoadingIndicator/LoadingIndicator";
import ErrorCodes from "../../../../constants/ErrorCodes";
import ErrorMessage from "../../../../components/ErrorMessage";
import EmptyState from "../../../../components/EmptyState";
import getErrorCode from "../../../../utils/getErrorCode";
import { useTranslation } from "react-i18next";
import getYAxisLabel from "../../../../utils/chart/context/getYAxisLabel";
import contextComponents from "../../../../utils/chart/context/contextComponents";

interface Props {
  consumptionData: any;
  apartmentId: string;
  apartmentName: string;
  propertyId: string;
  disabledExportButton?: boolean;
  error: any;
  loading?: boolean;
  hideConsumptionCards?: boolean;
  borderBottom?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    width: "100%",
    marginBottom: (props: Props) => `${props?.hideConsumptionCards ? 0 : 50}px`,
  },
  consumptions: {
    flex: 1,
  },
  row: {
    marginBottom: 32,
    marginLeft: 8,
  },
  consumptionCards: {
    marginLeft: 8,
    padding: 0,
    flex: 1,
    flexWrap: "wrap",
  },
  exportCsvButton: {
    alignSelf: "flex-start",
  },
  errorMessage: {
    height: "100%",
  },
  emptyStateRoot: {
    height: CHARTS_CONTAINER_HEIGHT,
    borderBottom: (props: Props) =>
      `${props?.borderBottom ? `2px solid ${DIVIDER_COLOR}` : "none"}`,
  },
}));

function ApartmentConsumption(props: Props) {
  const {
    consumptionData,
    propertyId,
    apartmentId,
    apartmentName,
    disabledExportButton = false,
    error,
    loading,
    hideConsumptionCards = false,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);
  const { showCosts } = useFilterStore(fullFilterState, shallow);
  const errorCode = getErrorCode(error);

  const chartData = getBarChartData(
    consumptionData,
    showCosts ? "totalPrice" : "value",
    "itemType"
  );

  const consumptionCardsData = getConsumptionCardsData(consumptionData);

  if (loading) {
    return (
      <div className={classes.emptyStateRoot}>
        <LoadingIndicator />
      </div>
    );
  }

  if (errorCode === ErrorCodes.IncompleteData) {
    return (
      <div className={classes.emptyStateRoot}>
        <ErrorMessage
          className={classes.errorMessage}
          title={t("errors.incompleteDataHeader")}
          message={t("errors.incompleteDataText")}
        />
      </div>
    );
  }

  if (!chartData?.keys?.length) {
    return (
      <div className={classes.emptyStateRoot}>
        <EmptyState />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Row
        className={classes.row}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Row className={classes.consumptionCards}>
          {!hideConsumptionCards &&
            Object.keys(
              consumptionCardsData
            )?.map((consumptionType: any, index: number) => (
              <ConsumptionItemCard
                consumptionType={consumptionType}
                value={consumptionCardsData[consumptionType].value}
                totalPrice={consumptionCardsData[consumptionType].totalPrice}
                key={index}
              />
            ))}
        </Row>
        <ExportConsumptionAsCsv
          propertyId={propertyId}
          apartmentId={apartmentId}
          fileName={apartmentName}
          className={classes.exportCsvButton}
          disabled={!consumptionData || disabledExportButton}
        />
      </Row>
      <ResponsiveContainer width="100%" height={340} debounce={1}>
        <ComposedChart
          width={650}
          height={CHARTS_CONTAINER_HEIGHT}
          data={chartData.items}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <YAxis label={getYAxisLabel()} />
          {contextComponents}
          {chartData.keys.map((key: any) => (
            <Bar
              key={key}
              dataKey={getLabel(key)}
              stackId="a"
              fill={getFillColor(key)}
              maxBarSize={100}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ApartmentConsumption;
