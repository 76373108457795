import React, { useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import QueryKeys from "../../../../api/QueryKeys";
import { queryClient } from "../../../../App";
import getPricingInput from "../../../../utils/getPricingInput";
import { CardRow } from "../../../../components/Card";
import { Portal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ErrorAlert from "../../../../components/ErrorAlert";
import { makeStyles } from "@material-ui/styles";
import ApartmentForm from "../../../../components/Forms/ApartmentForm";
import {
  ApartmentInfoFormFields,
  ApartmentInfoFormValues,
} from "../../../../components/Forms/ApartmentInfoForm/ApartmentInfoForm";
import { ApartmentPricesFormValues } from "../../../../components/Forms/ApartmentPricesForm/ApartmentPricesForm";
import getApartmentPricingValues from "../../../../utils/getApartmentPricingValues";
import useListProperties from "../../../../api/hooks/useListProperties";
import useSetApartment from "../../../../api/hooks/useSetApartment";
import useDeleteApartment from "../../../../api/hooks/useDeleteApartment";
import useApartmentIdsForProperty from "../../../../api/hooks/useApartmentIdsForProperty";
import { forOwn } from "lodash";
import { validateApartmentForm } from "../../../../components/NewApartmentDialog/NewApartmentDialog";
import routes from "../../../../constants/routes";
import Button from "../../../../components/Button";

const useStyles = makeStyles(() => ({
  errorAlert: {
    marginTop: 16,
  },
  cardRow: {
    marginTop: 16,
  },
}));

interface Props {
  apartment: any;
  propertyId: string;
  footerRef?: React.RefObject<HTMLDivElement>;
}

function ApartmentDetail({ apartment, propertyId, footerRef }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { data: propertiesList } = useListProperties();
  const setApartment = useSetApartment();
  const deleteApartment = useDeleteApartment();

  const [placeholders, setPlaceholders] = useState<{
    [key: string]: number | string;
  }>({});

  const isEditable = apartment?.isEditable;
  const apartmentId = apartment?.id;

  const formik = useFormik<ApartmentInfoFormValues & ApartmentPricesFormValues>(
    {
      initialValues: {
        name: null,
        selectedApartment: null,
        floor: "",
        apartmentNumber: "",
        property: null,
        pricingHeating: "",
        pricingWaterWarm: "",
        pricingWaterCold: "",
        pricingElectricityH: "",
        pricingElectricityL: "",
        pricingElectricityPV: "",
        pricingElectricity: "",
        pricingMaximumPower: "",
        pricingCharging: "",
        areaSize: "",
      },
      onSubmit: () => {},
      validate: validateApartmentForm,
      validateOnChange: true,
    }
  );

  const { data: apartmentsList } = useApartmentIdsForProperty(
    formik.values.property?.id || "",
    !!formik.values.property?.id
  );

  const apartmentIds: Array<string> = apartmentsList?.getApartmentIdsForProperty?.map(
    (id: string) => ({
      id,
      name: id,
    })
  );

  useEffect(() => {
    if (apartment) {
      const pricing = apartment?.consumptionPricings;
      const pricingValues = getApartmentPricingValues(pricing);

      const defaultPricingValues = getApartmentPricingValues(
        apartment?.property?.defaultConsumptionPricings
      );
      setPlaceholders(defaultPricingValues);

      formik.setValues({
        selectedApartment: { name: apartment?.name, id: "" },
        floor: apartment?.floor,
        apartmentNumber: apartment?.apartmentNumber ?? "",
        property: apartment?.property || null,
        areaSize: apartment?.areaSize?.toString() || "",
        ...pricingValues,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apartment]);

  const handleSubmit = async () => {
    const values = formik?.values;
    const { areaSize } = values;

    await setApartment.mutate(
      {
        id: apartmentId || undefined,
        name: values?.selectedApartment?.name || "",
        floor: values?.floor ?? "",
        propertyId: values?.property?.id ?? "",
        apartmentNumber: values?.apartmentNumber ?? "",
        consumptionPricings: getPricingInput(values),
        areaSize: areaSize ? Number(areaSize) : null,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.LIST_APARTMENTS]);
          queryClient.invalidateQueries([QueryKeys.GET_CONSUMPTION]);
          formik.resetForm();
          setPlaceholders({});
          history.push(routes.owner.getPropertyDetail(propertyId));
        },
      }
    );
  };

  const handleDelete = () => {
    deleteApartment.mutate(apartmentId, {
      onSuccess: async () => {
        history.goBack();
        await queryClient.invalidateQueries([QueryKeys.LIST_APARTMENTS]);
        setPlaceholders({});
        history.push(routes.owner.getPropertyDetail(propertyId));
      },
    });
  };

  const handleFieldChange = (fieldName: string, value: any) => {
    if (fieldName === ApartmentInfoFormFields.Property) {
      const defaultPricings = getApartmentPricingValues(
        value?.defaultConsumptionPricings
      );
      forOwn(defaultPricings, (value, key) => {
        formik.setFieldValue(key, "");
      });
      setPlaceholders(defaultPricings);
    }
    formik.setFieldValue(fieldName, value);
  };

  const loading = setApartment?.isLoading || deleteApartment?.isLoading;

  return (
    <FormikProvider value={formik}>
      <ApartmentForm
        values={formik.values}
        onChange={formik.handleChange}
        onFieldChange={handleFieldChange}
        propertiesList={propertiesList?.properties || []}
        apartmentsList={apartmentIds || []}
        placeholders={placeholders}
        isEditable={isEditable}
      />
      <ErrorAlert
        error={setApartment?.error || deleteApartment?.error}
        className={classes.errorAlert}
      />
      <Portal container={footerRef?.current}>
        <CardRow justifyContent="space-between" footer>
          <Button
            loading={deleteApartment.isLoading}
            variant="outlined"
            color="default"
            onClick={handleDelete}
            disabled={loading || !isEditable}
          >
            {t("delete")}
          </Button>
          <Button
            loading={setApartment.isLoading}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading || !formik.isValid || !isEditable}
          >
            {t("save")}
          </Button>
        </CardRow>
      </Portal>
    </FormikProvider>
  );
}

export default ApartmentDetail;
