import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Theme, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { gql } from "graphql-request/dist";
import Input from "../../../../components/Input";
import Autocomplete from "../../../../components/Autocomplete";
import QueryKeys from "../../../../api/QueryKeys";
import Dialog from "../../../../components/Dialog";
import { gqlClient, queryClient } from "../../../../App";
import useSetDataGateway from "../../../../api/hooks/useSetDataGateway";
import useListProperties from "../../../../api/hooks/useListProperties";
import useDeleteDataGateway from "../../../../api/hooks/useDeleteDataGateway";
import { DataGatewayDetailQuery } from "../../../../api/types";
import ErrorAlert from "../../../../components/ErrorAlert";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";

interface Props {
  onClose: () => void;
  id?: string;
  open: boolean;
}

const useStyles = makeStyles<Theme, any>((theme) => ({
  content: {
    width: 440,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  errorAlert: {
    marginTop: 16,
  },
}));

const DATA_GATEWAY_DETAIL_FIELDS = gql`
  fragment DataGatewayDetail on DataGatewayDto {
    id
    property {
      id
      name
    }
  }
`;

const DATA_GATEWAY_DETAIL = gql`
  ${DATA_GATEWAY_DETAIL_FIELDS}
  query DataGatewayDetail($id: ID) {
    dataGateways(filters: { id: $id }) {
      ...DataGatewayDetail
    }
  }
`;

function useDataGatewayDetail(id?: string) {
  return useQuery<DataGatewayDetailQuery>(
    [QueryKeys.DATA_GATEWAY_DETAIL, id],
    async () =>
      await gqlClient.request(DATA_GATEWAY_DETAIL, {
        id,
        operationName: QueryKeys.DATA_GATEWAY_DETAIL,
      }),
    {
      enabled: Boolean(id),
    }
  );
}

function DataGatewayDialog({ onClose, open, id }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { data } = useListProperties();
  const { data: detail } = useDataGatewayDetail(id);
  const setDataGateway = useSetDataGateway();
  const deleteDataGateway = useDeleteDataGateway();
  const client = useQueryClient();
  const gateway = detail?.dataGateways?.[0];

  const [propertyOption, setPropertyOption] = useState<any>(null);
  const [gatewayId, setGatewayId] = useState<string>("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(
    false
  );

  const propertyOptions = useMemo(() => {
    return data?.properties.filter(Boolean).map((p) => ({
      id: p!.id,
      name: p!.name,
      object: { ...p },
    }));
  }, [data]);

  useEffect(() => {
    const gateway = detail?.dataGateways?.[0];
    if (gateway && open) {
      setGatewayId(gateway?.id);
      setPropertyOption(gateway?.property);
    }
  }, [detail, open]);

  const clearForm = () => {
    setPropertyOption(null);
    setGatewayId("");
  };

  const handleClose = () => {
    clearForm();
    setDataGateway.reset();
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = async () => {
    await setDataGateway.mutate(
      {
        id: gatewayId || "",
        propertyId: propertyOption?.id,
      },
      {
        onSuccess: () => {
          client.invalidateQueries([QueryKeys.PAGE_DATA_GATEWAY]);
          handleClose();
        },
      }
    );
  };

  const handleDelete = () => {
    deleteDataGateway.mutate(id!, {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.PAGE_DATA_GATEWAY]);
        setConfirmationDialogOpen(false);
        handleClose();
      },
    });
  };

  if (confirmationDialogOpen) {
    return (
      <ConfirmationDialog
        open={true}
        onClose={() => setConfirmationDialogOpen(false)}
        onSubmit={handleDelete}
        error={deleteDataGateway.error}
        primaryButtonLoading={deleteDataGateway.isLoading}
      />
    );
  }

  return (
    <Dialog
      open={open}
      dialogHeader={t("dataGateway")}
      onClose={handleClose}
      maxWidth="md"
      data-cy="new-miniserver-dialog"
      onSubmit={handleSubmit}
      secondaryButtonText={gateway ? t("delete") : t("close")}
      onSecondaryButtonClick={() =>
        gateway ? setConfirmationDialogOpen(true) : handleClose()
      }
      background="white"
      primaryButtonLoading={setDataGateway.isLoading}
      disabledSubmitButton={!gatewayId || !propertyOption}
    >
      <div className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              fullWidth
              color="secondary"
              value={gatewayId}
              onChange={(e) => {
                setGatewayId(e.target.value);
              }}
              label={`${t("gatewayId")}`}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="property-autocomplete"
              fieldName="property-autocomplete"
              label={t("property")}
              options={propertyOptions || []}
              value={propertyOption}
              onChange={(e, value) => {
                setPropertyOption(value);
              }}
              required
            />
          </Grid>
        </Grid>
        <ErrorAlert
          error={setDataGateway.error}
          className={classes.errorAlert}
        />
      </div>
    </Dialog>
  );
}

export default DataGatewayDialog;
