import { gql } from "graphql-request";

export const ADDRESS_FIELDS = gql`
  fragment AddressFields on AddressDto {
    addressLineOne
    addressLineTwo
    city
    zip
  }
`;

export default ADDRESS_FIELDS;
