/**
 * Get null safe value from object. If any of the values in the path
 * is undefined it will return a fallback value instead of throwing an error.
 *
 * Example: ns(() => user.params.value, 'fallback value')
 * this will return value if user params and value are defined or fallback if
 * any of the objects are null
 *
 * @param fn The function to get a value from object
 * @param fallback The fallback value if null safe fails
 */
export function ns<T>(fn: () => T, fallback: any = null) {
  try {
    return fn() || fallback;
  } catch (e) {
    return fallback;
  }
}
