import { gql } from "graphql-request";

const CHART_ITEM_FIELDS = gql`
  fragment ChartItemFields on ConsumptionItemDto {
    timestamp
    item
    itemType
    unit
    value
    totalPrice
  }
`;

export default CHART_ITEM_FIELDS;
