import React, { useState } from "react";
import Section from "../../Section";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  ApartmentConsumption_ApartmentFragment,
  ConsumptionType,
} from "../../../api/types";
import getDonutChartData from "../../../utils/chart/getDonutChartData";
import DonutChart from "../DonutChart/DonutChart";
import { gql } from "graphql-request/dist";
import { ConsumptionPeriod, ValueKey } from "../../../types";
import ChartContainer from "../ChartContainer";
import ConsumptionLoader, {
  ConsumptionRenderProps,
} from "../ConsumptionLoader/ConsumptionLoader";
import BarChart from "../BarChart";
import getHistoricalBarChartData from "../../../utils/chart/getHistoricalBarChartData";
import formatCurrency from "../../../utils/chart/formatCurrency";
import { RenderProps } from "../ChartContainer/ChartContainer";

interface Props {
  apartmentId?: string;
  apartment?: ApartmentConsumption_ApartmentFragment | null;
  borderRight?: boolean;
  selectedPeriod?: ConsumptionPeriod;
  onPeriodChange?: (period: ConsumptionPeriod) => void;
  condensed?: boolean;
  height?: string;
  useHistoricalData?: boolean;
  onRenderPropsChange?: (renderProps: RenderProps) => void;
  onConsumptionRenderPropsChange?: (props: ConsumptionRenderProps) => void;
}

function ApartmentConsumption({
  apartment,
  borderRight,
  condensed,
  height = "300px",
  useHistoricalData,
  onRenderPropsChange,
  onConsumptionRenderPropsChange,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [options, setOptions] = useState<Array<ConsumptionType> | undefined>();

  return (
    <Section borderBottom={isMobile} borderRight={borderRight}>
      <ChartContainer
        height={height}
        SectionProps={{ borderBottom: false }}
        storageId={apartment?.id}
        condensed={condensed}
        options={options}
        onRenderPropsChange={onRenderPropsChange}
      >
        {({ from, to, period, types, valueKey, units }) => (
          <ConsumptionLoader
            input={{
              from: from.toISOString(),
              to: to.toISOString(),
              propertyId: apartment?.property?.id,
              apartmentId: apartment?.id,
              level: useHistoricalData ? (period as any) : undefined,
            }}
            setOptions={setOptions}
            types={types}
            enabled={Boolean(apartment?.id)}
            onRenderPropsChange={onConsumptionRenderPropsChange}
          >
            {({ consumption, loading, error }) =>
              useHistoricalData ? (
                <BarChart
                  loading={loading}
                  config={{
                    labelSkipWidth: 16,
                    ...getHistoricalBarChartData(
                      consumption,
                      period,
                      valueKey,
                      units[0]
                    ),
                  }}
                  units={units}
                  error={error}
                />
              ) : (
                <DonutChart
                  loading={loading}
                  data={getDonutChartData(consumption, valueKey)}
                  // @ts-ignore
                  config={{
                    valueFormat: (v) =>
                      valueKey === ValueKey.TotalPrice
                        ? formatCurrency(v.toFixed(2))
                        : `${v.toFixed(2)}`,
                    // @ts-ignore
                    tooltipFormat: (v) => Number(v).toFixed(2),
                  }}
                  units={units}
                  error={error}
                />
              )
            }
          </ConsumptionLoader>
        )}
      </ChartContainer>
    </Section>
  );
}

ApartmentConsumption.fragments = {
  apartment: gql`
    fragment ApartmentConsumption_Apartment on ApartmentDto {
      id
      property {
        id
      }
    }
  `,
};

export default ApartmentConsumption;
