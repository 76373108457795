import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SELECTOR_WIDTH } from "../../theme";
import { makeStyles } from "@material-ui/core";
import { CardHeader } from "../../components/Card";
import SearchableMenu from "../../components/SearchableMenu";
import { gql } from "graphql-request";
import { useQuery } from "react-query";
import QueryKeys from "../../api/QueryKeys";
import { gqlClient } from "../../App";
import {
  PropertySelector_PropertyFragment,
  PropertySelectorDataQuery,
} from "../../api/types";
import { HomeIconPrimary } from "../../assets";
import { useParams } from "react-router";
import useDebounce from "../../hooks/useDebounce";

const useStyles = makeStyles(() => ({
  root: {
    width: SELECTOR_WIDTH,
  },
}));

export interface PropertySelectorProps {
  onSelect?: (property: PropertySelector_PropertyFragment | null) => void;
}

function usePropertySelectorData() {
  return useQuery<PropertySelectorDataQuery>(
    [QueryKeys.PROPERTY_SELECTOR],
    async () =>
      await gqlClient.request(gql`
        ${PropertySelector.fragments.property}
        query PropertySelectorData {
          properties {
            ...PropertySelector_Property
          }
        }
      `)
  );
}

function PropertySelector({ onSelect }: PropertySelectorProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data } = usePropertySelectorData();
  const { propertyId } = useParams<{ propertyId: string }>();

  const [anchorEl, setAnchorEl] = useState<any>(undefined);
  const [
    selectedProperty,
    setSelectedProperty,
  ] = useState<PropertySelector_PropertyFragment | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 300).toLowerCase();

  const filteredProperties = data?.properties?.filter(
    (property) =>
      property?.id?.toLowerCase()?.includes(debouncedSearchQuery) ||
      property?.name?.toLowerCase()?.includes(debouncedSearchQuery)
  );

  useEffect(() => {
    if (propertyId) {
      const selectedProperty = data?.properties?.find(
        (property) => property?.id === propertyId
      );
      setSelectedProperty(selectedProperty || null);
    }
  }, [propertyId, data, setSelectedProperty]);

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (
    propertyId?: string | null,
    property?: PropertySelector_PropertyFragment | null
  ) => {
    setSelectedProperty(property || null);
    handleClose();

    if (onSelect) {
      onSelect(property || null);
    }
  };

  return (
    <div className={classes.root}>
      <CardHeader
        title={selectedProperty?.name || t("all")}
        label={t("property")}
        icon={HomeIconPrimary}
        onClick={handleClick}
      />
      <SearchableMenu<PropertySelector_PropertyFragment>
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        items={filteredProperties || new Array<any>()}
        getId={(item) => item?.id}
        getName={(item) => item?.name}
        onSelect={handleSelect}
        value={selectedProperty?.id}
        onChangeSearchQuery={(value) => setSearchQuery(value)}
      />
    </div>
  );
}

PropertySelector.fragments = {
  property: gql`
    fragment PropertySelector_Property on PropertyDto {
      id
      name
    }
  `,
};

export default PropertySelector;
