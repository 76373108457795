import React, { useState } from "react";
import {
  Button,
  ButtonProps,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useFilterStore, { fullFilterState } from "../../store/useFilterStore";
import shallow from "zustand/shallow";
import { ConsumptionPeriod } from "../../types";

const useStyles = makeStyles(() => ({
  button: {
    flex: "none !important",
    textTransform: "none",
    margin: 0,
  },
}));

const consumptionPeriods = [
  ConsumptionPeriod.Day,
  ConsumptionPeriod.Week,
  ConsumptionPeriod.Month,
  ConsumptionPeriod.Year,
];

interface Props {
  buttonProps: Partial<ButtonProps>;
}

function ConsumptionPeriodSelector({ buttonProps }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { consumptionPeriod, setConsumptionPeriod } = useFilterStore(
    fullFilterState,
    shallow
  );

  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <div>
      <Button
        className={classes.button}
        {...buttonProps}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {t(consumptionPeriod)}
      </Button>
      <Menu
        id="consumption-periods-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
      >
        {consumptionPeriods?.map((period) => (
          <MenuItem
            key={period}
            selected={period === consumptionPeriod}
            onClick={() => {
              setConsumptionPeriod(period);
              handleClose();
            }}
          >
            <Typography>{t(period)}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default ConsumptionPeriodSelector;
