import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "../../../layouts/PageLayout/PageLayout";
import CardHeader from "../../../components/Card/CardHeader";
import { Box, makeStyles } from "@material-ui/core";
import CardRow from "../../../components/Card/CardRow/CardRow";
import clsx from "clsx";
import Filters from "../../../fragments/Filters";
import useListProperties from "../../../api/hooks/useListProperties";
import List from "../../../components/List";
import { useHistory } from "react-router";
import NewPropertyDialog from "../../../components/NewPropertyDialog";
import PropertyCard from "../../owner/PropertiesPage/components/PropertyCard";
import PropertySelector from "../../../fragments/PropertySelector";
import ButtonNew from "../../../components/ButtonNew";
import routes from "../../../constants/routes";
import LoadingSkeleton from "../../../components/LoadingSkeleton";

const useStyles = makeStyles(() => ({
  header: {
    height: "100%",
  },
  match: {
    flex: 1,
  },
}));

function PropertiesPage() {
  const { t } = useTranslation();
  const { data, isLoading, error } = useListProperties();
  const classes = useStyles();
  const history = useHistory();

  const [newPropertyDialogOpen, setNewPropertyDialogOpen] = React.useState(
    false
  );

  return (
    <PageLayout
      loading={isLoading}
      error={error}
      layoutType="admin"
      sticky={
        <List spacing={0} width="100%">
          <CardRow header borderBottom>
            <PropertySelector
              onSelect={(property) =>
                history.push(routes.admin.getPropertyDetail(property?.id || ""))
              }
            />
            <ButtonNew
              onButtonNewClick={() => setNewPropertyDialogOpen(true)}
            />
          </CardRow>
          <CardRow borderBottom>
            <CardHeader
              className={clsx(classes.match)}
              title={t("consumptionOverview")}
            />
            <Filters />
          </CardRow>
        </List>
      }
    >
      <div>{isLoading && <LoadingSkeleton />}</div>
      <Box padding={2}>
        <List>
          {data?.properties.map((property) => (
            <PropertyCard
              key={property?.id}
              property={property}
              onDetailClick={(property: any) =>
                history.push(routes.admin.getPropertyDetail(property?.id))
              }
            />
          ))}
        </List>
      </Box>
      <NewPropertyDialog
        open={newPropertyDialogOpen}
        onClose={() => setNewPropertyDialogOpen(false)}
      />
    </PageLayout>
  );
}

export default PropertiesPage;
