import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Navigation from "../../../components/Navigation";
import Row from "../../../components/Row";
import { useTranslation } from "react-i18next";
import { ADMIN_PAGE_BG_COLOR } from "../../../theme";
import routes from "../../../constants/routes";
import { useLocation } from "react-router";
import AvatarDropdownMenu from "../../../components/AvatarDropdownMenu";
import EmptyState from "../../../components/EmptyState";
import ErrorMessage from "../../../components/ErrorMessage";
import { GraphQLError } from "graphql";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

type Props = {
  children: JSX.Element | JSX.Element[];
  loading?: boolean;
  emptyState?: boolean;
  error?: GraphQLError | null | unknown;
};

type AdminPageMenuItem = {
  text: string;
  to: string;
  selected: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.default,
  },
  contentRoot: {},
  container: {},
}));

function AdminPageLayout({
  children,
  loading = false,
  emptyState = false,
  error = null,
}: Props) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const adminPageMenuItems: AdminPageMenuItem[] = [
    // {
    //   text: t("dashboard"),
    //   to: routes.admin.dashboard,
    //   selected: pathname.includes(routes.admin.dashboard),
    // },
    {
      text: t("loxoneMiniserver"),
      to: routes.admin.loxoneMiniserver,
      selected: pathname.includes(routes.admin.loxoneMiniserver),
    },
    {
      text: t("properties"),
      to: routes.admin.properties,
      selected: pathname.includes("property"),
    },
    {
      text: t("owner"),
      to: routes.admin.owner,
      selected: pathname.includes(routes.admin.owner),
    },
  ];

  const mobileMenu = (
    <Navigation.MobileNavigationMenu>
      {adminPageMenuItems.map((menuItem) => (
        <MenuItem
          component={Link}
          to={menuItem.to}
          selected={menuItem.selected}
          key={menuItem.text}
        >
          {menuItem.text}
        </MenuItem>
      ))}
    </Navigation.MobileNavigationMenu>
  );

  const menu = (
    <Row maxWidth>
      {adminPageMenuItems.map((menuItem) => {
        return (
          <Navigation.Button
            to={menuItem.to}
            selected={menuItem.selected}
            key={menuItem.text}
          >
            {menuItem.text}
          </Navigation.Button>
        );
      })}
    </Row>
  );

  const navigation = (
    <Navigation color={ADMIN_PAGE_BG_COLOR}>
      <Navigation.Logo />
      {isMobile ? mobileMenu : menu}
      <AvatarDropdownMenu avatar="A" />
    </Navigation>
  );

  return (
    <div className={classes.root}>
      {navigation}
      <div className={classes.contentRoot}>
        {children}
        {error && <ErrorMessage fullScreen />}
        {emptyState && !loading && !error && <EmptyState fullScreen />}
      </div>
    </div>
  );
}

export default AdminPageLayout;
