import React from "react";
import Row from "../../Row";
import { Container, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import getColor from "../../../utils/getColor";
import { NAV_BAR_HEIGHT, NAV_HEIGHT } from "../../../theme";
import clsx from "clsx";

interface PageHeaderProps {
  children: React.ReactNode;
  color?: string;
}

interface PrimaryRowProps {
  children: React.ReactNode;
}

interface SecondaryRowProps {
  children: React.ReactNode;
}

interface TitleProps {
  children: React.ReactNode;
}

interface SubtitleProps {
  children: React.ReactNode;
  color?: string;
}

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: (props) => ({
    width: "100%",
    marginTop: NAV_BAR_HEIGHT,
    display: "flex",
    alignItems: "center",
    paddingTop: 48,
    paddingBottom: 24,
    backgroundColor: props.color ?? theme.palette.secondary.main,
    "&:after": {
      content: '""',
      position: "absolute",
      zIndex: 0,
      left: 0,
      width: "100%",
      height: NAV_HEIGHT,
      display: "block",
      backgroundColor: props.color ?? theme.palette.secondary.main,
    },
  }),
  container: {
    zIndex: 1,
  },
  primaryRow: {
    flex: 1,
  },
  secondaryRow: {},
  title: {
    fontSize: 28,
    color: "white",
    fontWeight: 500,
  },
  subtitle: (props: any) => ({
    fontSize: 20,
    marginTop: 4,
    fontWeight: 300,
    color: getColor(props.color, theme),
  }),
  pageHeaderRow: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      "& div:first-child": {
        marginBottom: 16,
      },
    },
  },
}));

function PageHeader(props: PageHeaderProps) {
  const classes = useStyles(props);
  const { children } = props;
  return (
    <div className={clsx(classes.root)}>
      <Container className={classes.container}>
        <Row className={classes.pageHeaderRow}>{children}</Row>
      </Container>
    </div>
  );
}

function PrimaryRow(props: PrimaryRowProps) {
  const { children } = props;
  const classes = useStyles(props);

  return (
    <Row spacing={32} alignItems="center" className={classes.primaryRow}>
      {children}
    </Row>
  );
}

function SecondaryRow(props: SecondaryRowProps) {
  const { children } = props;
  const classes = useStyles(props);

  return <Row className={classes.secondaryRow}>{children}</Row>;
}

function Title(props: TitleProps) {
  const { children } = props;
  const classes = useStyles(props);

  return (
    <Typography variant={"h5"} className={classes.title}>
      {children}
    </Typography>
  );
}

function Subtitle(props: SubtitleProps) {
  const { children } = props;
  const classes = useStyles(props);

  return <Typography className={classes.subtitle}>{children}</Typography>;
}

PageHeader.PrimaryRow = PrimaryRow;
PageHeader.SecondaryRow = SecondaryRow;
PageHeader.Title = Title;
PageHeader.Subtitle = Subtitle;

export default PageHeader;
