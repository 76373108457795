import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Box, Grid } from "@material-ui/core";

interface Props {
  items?: number;
}

function LoadingSkeletonGrid({ items = 4 }: Props) {
  const skeletonItems = new Array(items);
  return (
    <>
      {skeletonItems.fill(0).map((_, index) => (
        <Box width="100%" paddingX={4} key={index}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Skeleton
                variant="rect"
                width="100%"
                height={520}
                style={{ marginBottom: 12, marginTop: 24 }}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                variant="rect"
                width="100%"
                height={520}
                style={{ marginBottom: 12, marginTop: 24 }}
              />
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
}

export default LoadingSkeletonGrid;
