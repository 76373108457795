import { gql } from "graphql-request";

export const TENANT_FIELDS = gql`
  fragment TenantFields on TenantWithDatesDto {
    firstName
    lastName
    id
    email
    dateIn
    dateOut
    apartments {
      id
    }
  }
`;
