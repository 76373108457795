import {
  ConsumptionPricingFieldsFragment,
  ConsumptionType,
} from "../api/types";

function getPricingValues(pricing: Array<ConsumptionPricingFieldsFragment>) {
  return {
    pricingElectricityL:
      pricing?.find((p) => p.pricingType === ConsumptionType.ElectricityL)
        ?.basePrice ?? 0,
    pricingElectricityH:
      pricing?.find((p) => p.pricingType === ConsumptionType.ElectricityH)
        ?.basePrice ?? 0,
    pricingElectricityPV:
      pricing?.find((p) => p.pricingType === ConsumptionType.ElectricityPv)
        ?.basePrice ?? 0,
    pricingElectricity:
      pricing?.find((p) => p.pricingType === ConsumptionType.Electricity)
        ?.basePrice ?? 0,
    pricingMaximumPower:
      pricing?.find((p) => p.pricingType === ConsumptionType.MaximumPower)
        ?.basePrice ?? 0,
    pricingWaterWarm:
      pricing?.find((p) => p.pricingType === ConsumptionType.WaterWarmVolume)
        ?.basePrice ?? 0,
    pricingWaterCold:
      pricing?.find((p) => p.pricingType === ConsumptionType.WaterColdVolume)
        ?.basePrice ?? 0,
    pricingHeating:
      pricing?.find((p) => p.pricingType === ConsumptionType.HeatingEnergy)
        ?.basePrice ?? 0,
    pricingCharging:
      pricing?.find((p) => p.pricingType === ConsumptionType.Charging)
        ?.basePrice ?? 0,
  };
}

export default getPricingValues;
