import { gql } from "graphql-request";
import { useMutation } from "react-query";
import QueryKeys from "../QueryKeys";
import { gqlClient } from "../../App";

function useDeletePropertyOwner() {
  return useMutation(QueryKeys.DELETE_PROPERTY_OWNER, async (input: String) => {
    return await gqlClient.request(
      gql`
        mutation DeletePropertyOwner($input: String!) {
          deletePropertyOwner(id: $input)
        }
      `,
      {
        input,
        operationName: QueryKeys.DELETE_PROPERTY_OWNER,
      }
    );
  });
}

export default useDeletePropertyOwner;
