import React, { useEffect, useState } from "react";
import PageLayout from "../../../layouts/PageLayout/PageLayout";
import SearchHeader from "../../../components/SearchHeader";
import { Box } from "@material-ui/core";
import SetInvoiceDialog from "../../../components/SetInvoiceDialog";
import useListApartments from "../../../api/hooks/useListApartments";
import { useParams } from "react-router-dom";
import ApartmentSelector from "../../../fragments/ApartmentSelector";
import routes from "../../../constants/routes";
import { useHistory } from "react-router";
import useDeleteInvoice from "../../../api/hooks/useDeleteInvoice";
import QueryKeys from "../../../api/QueryKeys";
import { queryClient } from "../../../App";
import InvoicesTable from "../../../components/InvoicesTable";

function InvoicesPage() {
  const history = useHistory();
  const { apartmentId, propertyId } = useParams<{
    apartmentId: string;
    propertyId: string;
  }>();
  const { data: apartmentData } = useListApartments(
    { id: apartmentId },
    Boolean(apartmentId)
  );
  const deleteInvoice = useDeleteInvoice();

  const [newInvoiceDialogOpen, setNewInvoiceDialogOpen] = useState<boolean>(
    false
  );

  const [invoiceIdToEdit, setInvoiceIdToEdit] = useState<string>("");

  useEffect(() => {
    if (!newInvoiceDialogOpen) {
      setInvoiceIdToEdit("");
    }
  }, [newInvoiceDialogOpen]);

  const handleDeleteClick = async (invoiceId: string) => {
    deleteInvoice.mutate(invoiceId, {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.LIST_APARTMENTS]);
      },
    });
  };

  const handleEditClick = (invoiceId?: string) => {
    if (invoiceId) {
      setInvoiceIdToEdit(invoiceId);
      setNewInvoiceDialogOpen(true);
    }
  };

  const apartment = apartmentData?.apartments?.[0];
  const invoices = apartment?.invoices || [];

  return (
    <PageLayout
      loading={false}
      error={false}
      layoutType="owner"
      displayBackButton
      contentMarginTop
      onNavigateBack={() =>
        history.push(routes.owner.getApartmentDetail(propertyId, apartmentId))
      }
      sticky={
        <SearchHeader
          onButtonNewClick={() => setNewInvoiceDialogOpen(true)}
          selector={
            <ApartmentSelector
              onSelect={(apartment) =>
                history.push(
                  routes.owner.getInvoices(propertyId, apartment?.id || "")
                )
              }
            />
          }
        />
      }
    >
      <Box>
        <InvoicesTable
          invoices={invoices}
          onDeleteInvoiceClick={(id) => handleDeleteClick(id)}
          onEditInvoiceClick={(id) => handleEditClick(id)}
        />
      </Box>
      <SetInvoiceDialog
        open={newInvoiceDialogOpen}
        apartmentId={apartmentId}
        onClose={() => setNewInvoiceDialogOpen(false)}
        apartmentTenants={apartment?.tenants || []}
        invoiceId={invoiceIdToEdit}
      />
    </PageLayout>
  );
}

export default InvoicesPage;
