import { roundValue } from "../roundValue";
import { ChartItemFieldsFragment } from "../../api/types";
import getLabel from "./getLabel";

export type DonutChartItem = {
  id: string;
  label: string;
  value: number;
  color: string;
};

export type DonutChartData = {
  data: Array<DonutChartItem>;
};

const colors = [
  "hsl(343, 70%, 50%)",
  "hsl(177, 70%, 50%)",
  "hsl(0, 70%, 50%)",
  "hsl(2, 70%, 50%)",
  "hsl(28, 70%, 50%)",
];

function getDonutChartData(
  items: Array<ChartItemFieldsFragment | null> = [],
  valueKey: "value" | "totalPrice" = "totalPrice"
): DonutChartData {
  if (!items || !items.length) {
    return {
      data: [],
    };
  }

  const data = items
    .filter((item) => Boolean(item) && item?.value !== 0)
    .map((item, i) => {
      const itemType = getLabel(item!.itemType);
      return {
        id: `${itemType}`,
        item: `${item?.item}`,
        label: getLabel(item!.itemType),
        // @ts-ignore
        value: roundValue(item[valueKey]),
        color: colors[(i % colors.length) - 1],
      };
    });

  return {
    data,
  };
}

export default getDonutChartData;
