import { TFunction } from "i18next";
import ErrorCodes from "../constants/ErrorCodes";

export default function getErrorMessage(
  errorCode: string | null | undefined,
  t: TFunction
) {
  switch (errorCode) {
    case ErrorCodes.NoConsumptionForTimespan:
      return t("errors.noConsumptionForTimespan");
    case ErrorCodes.NotFoundForTimespan:
      return t("errors.notFoundForTimespan");
    case ErrorCodes.UserWithEmailAlreadyExists:
      return t("errors.existingUser");
    case ErrorCodes.IncompleteData:
      return t("errors.notEnoughEntriesForInvoice");
    case ErrorCodes.IdMustBeUuid:
      return t("errors.idMustBeUuid");
    case ErrorCodes.DataGatewayIdMustBeUuid:
      return t("errors.dataGatewayIdMustBeUuid");
    case ErrorCodes.PropertyAlreadyAssigned:
      return t("errors.propertyAlreadyAssigned");
    case ErrorCodes.PropertyAlreadyExists:
      return t("errors.propertyAlreadyExists");
    case ErrorCodes.OwnerAddressUndefined:
      return t("errors.ownerAddressUndefined");
    case ErrorCodes.TenantAddressUndefined:
      return t("errors.tenantAddressUndefined");
    case ErrorCodes.PropertyApartmentAreaUndefined:
      return t("propertyApartmentAreaUndefinedError");
    case ErrorCodes.AreaAmortizationNotEmpty:
      return t("areaAmortizationNotEmpty");
    case ErrorCodes.ConsumptionAmortizationEmpty:
      return t("consumptionAmortizationEmpty");
    case ErrorCodes.Undefined:
      return "";
    default:
      return t("unexpectedError");
  }
}
