import React from "react";
import NewPropertyDialog from "../NewPropertyDialog";
import NewApartmentDialog from "../NewApartmentDialog";
import NewReadonlyPropertyDialog from "../NewReadonlyPropertyDialog";
import { Box, Menu, MenuItem, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ButtonNew from "../ButtonNew";
import { useKeycloak } from "@react-keycloak/web";
import hasRealmRole from "../../utils/hasRealmRole";
import Role from "../../auth/Role";

function ButtonNewProperty() {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const isOwner = hasRealmRole(keycloak, Role.Owner);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [newPropertyDialogOpen, setNewPropertyDialogOpen] = React.useState(
    false
  );
  const [newApartmentDialogOpen, setNewApartmentDialogOpen] = React.useState(
    false
  );
  const [
    newReadonlyPropertyDialogOpen,
    setNewReadonlyPropertyDialogOpen,
  ] = React.useState(false);

  function handleClose() {
    setAnchorEl(null);
  }

  const menu = (
    <Menu
      id="consumption-periods-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      getContentAnchorEl={null}
    >
      <MenuItem
        onClick={() => {
          setNewPropertyDialogOpen(true);
          handleClose();
        }}
      >
        <Typography>{t("newProperty")}</Typography>
      </MenuItem>
      {isOwner && (
        <MenuItem
          onClick={() => {
            setNewApartmentDialogOpen(true);
            handleClose();
          }}
        >
          <Typography>{t("newApartment")}</Typography>
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          setNewReadonlyPropertyDialogOpen(true);
          handleClose();
        }}
      >
        <Typography>{t("newReadonlyProperty")}</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <Box display="flex" flex={1}>
      <ButtonNew
        onButtonNewClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      />
      {menu}
      <NewPropertyDialog
        open={newPropertyDialogOpen}
        onClose={() => setNewPropertyDialogOpen(false)}
      />
      <NewApartmentDialog
        open={newApartmentDialogOpen}
        onClose={() => setNewApartmentDialogOpen(false)}
      />
      <NewReadonlyPropertyDialog
        open={newReadonlyPropertyDialogOpen}
        onClose={() => setNewReadonlyPropertyDialogOpen(false)}
      />
    </Box>
  );
}

export default ButtonNewProperty;
