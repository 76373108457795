import React, { CSSProperties } from "react";
import { CARD_SPACING, ROW_HEIGHT } from "../../../theme";
import { Theme, Typography } from "@material-ui/core";
import Row from "../../Row";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

interface CardProps {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  cypressId?: string;
}

interface HeaderProps {
  children: React.ReactNode;
}

interface HeaderTitleProps {
  children: React.ReactNode;
}

interface HeaderActionProps {
  children: React.ReactNode;
}

interface ContentProps {
  children: React.ReactNode;
  withSpacing?: boolean;
  height?: string;
  maxHeight?: string | number | boolean;
}

interface ActionsProps {
  children: React.ReactNode;
  withSpacing?: boolean;
}

interface RowProps {
  children: React.ReactNode;
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-evenly"
    | "space-around";
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: 10,
  },
  row: {
    height: ROW_HEIGHT,
    paddingLeft: CARD_SPACING,
    paddingRight: CARD_SPACING,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  dialogHeaderRow: {
    background: "white",
    height: ROW_HEIGHT,
    padding: CARD_SPACING,
    paddingTop: 40,
    paddingBottom: 40,
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: 1,
  },
  header: {
    top: 0,
    left: 0,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: ROW_HEIGHT,
    paddingLeft: CARD_SPACING,
    paddingRight: CARD_SPACING,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerTitle: {},
  content: {
    height: (props: any) => props?.height,
    overflowY: "auto",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogActions: {
    display: "flex",
    zIndex: 1,
    justifyContent: "flex-end",
    paddingTop: 16,
    paddingBottom: 20,
    background: "white",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  spacing: {
    padding: CARD_SPACING,
  },
  maxHeight: {
    maxHeight: (props: any) => props?.maxHeight || "calc(100vh - 250px)",
  },
  horizontalSpacing: {
    paddingLeft: CARD_SPACING,
    paddingRight: CARD_SPACING,
  },
}));

function Card({ children, className, style, cypressId }: CardProps) {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, className)}
      style={style}
      data-cy={cypressId}
    >
      {children}
    </div>
  );
}

function DialogHeader({ dialogTitle, onCloseClick }: any) {
  const classes = useStyles();

  return (
    <Row justifyContent="space-between" className={classes.dialogHeaderRow}>
      <Typography className={classes.headerTitle} variant="h4">
        {dialogTitle}
      </Typography>
      <IconButton
        onClick={onCloseClick}
        size="medium"
        data-cy="dialog-header-close-button"
      >
        <CloseIcon />
      </IconButton>
    </Row>
  );
}

function Header({ children }: HeaderProps) {
  const classes = useStyles();
  return <Row className={classes.header}>{children}</Row>;
}

function HeaderTitle({ children }: HeaderTitleProps) {
  const classes = useStyles();
  return (
    <Typography className={classes.headerTitle} variant="h5">
      {children}
    </Typography>
  );
}

function HeaderActions({ children }: HeaderActionProps) {
  return <Row>{children}</Row>;
}

function Content(props: ContentProps) {
  const classes = useStyles(props);
  const { children, withSpacing, maxHeight } = props;

  return (
    <div
      className={clsx(classes.content, {
        [classes.spacing]: withSpacing,
        [classes.maxHeight]: Boolean(maxHeight),
      })}
    >
      {children}
    </div>
  );
}

function Actions({ children, withSpacing }: ActionsProps) {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.actions, {
        [classes.horizontalSpacing]: withSpacing,
      })}
    >
      {children}
    </div>
  );
}

function DialogActions({ children, withSpacing }: ActionsProps) {
  const classes = useStyles();
  return (
    <Row
      className={clsx(classes.dialogActions, {
        [classes.horizontalSpacing]: withSpacing,
      })}
    >
      {children}
    </Row>
  );
}

function CardRow({ children, justifyContent }: RowProps) {
  const classes = useStyles();
  return (
    <Row className={classes.row} justifyContent={justifyContent}>
      {children}
    </Row>
  );
}

Card.Header = Header;
Card.HeaderTitle = HeaderTitle;
Card.HeaderActions = HeaderActions;
Card.Content = Content;
Card.Actions = Actions;
Card.Row = CardRow;
Card.HeaderActions = HeaderActions;
Card.DialogHeader = DialogHeader;
Card.DialogActions = DialogActions;

export default Card;
