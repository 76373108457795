import { gql } from "graphql-request";
import { useMutation } from "react-query";
import QueryKeys from "../QueryKeys";
import { gqlClient } from "../../App";

function useDeleteInvoice() {
  return useMutation(QueryKeys.DELETE_INVOICE, async (input: String) => {
    return await gqlClient.request(
      gql`
        mutation DeleteInvoice($input: String!) {
          deleteInvoice(id: $input)
        }
      `,
      {
        input,
        operationName: QueryKeys.DELETE_INVOICE,
      }
    );
  });
}

export default useDeleteInvoice;
