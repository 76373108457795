import { ConsumptionType } from "../../api/types";

// Color schemes: https://nivo.rocks/guides/colors/

const colorMap: Record<string, string> = {
  [ConsumptionType.ElectricityL]: "#d9ee8b",
  [ConsumptionType.ElectricityH]: "#a6d96a",
  [ConsumptionType.Electricity]: "#65bd63",
  [ConsumptionType.ElectricityPv]: "#FF850F",
  [ConsumptionType.MaximumPower]: "#427a40",
  [ConsumptionType.HeatingEnergy]: "#fee08b",
  [ConsumptionType.Charging]: "#9d9ac7",
  [ConsumptionType.HeatingFlow]: "#fcad61",
  [ConsumptionType.WaterColdVolume]: "#75add0",
  [ConsumptionType.WaterWarmVolume]: "#abd9e9",
  [ConsumptionType.WaterColdFlow]: "#313795",
  [ConsumptionType.WaterWarmFlow]: "#4575b4",
};

function getFillColor(consumptionType: string) {
  return colorMap[consumptionType] || "";
}

export default getFillColor;
