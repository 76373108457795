import React from "react";
import {
  Bar,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import getLabel from "../../../../utils/chart/getLabel";
import getFillColor from "../../../../utils/chart/getFillColor";
import getBarChartData from "../../../../utils/chart/getBarChartData";
import useFilterStore, {
  fullFilterState,
} from "../../../../store/useFilterStore";
import shallow from "zustand/shallow";
import { makeStyles } from "@material-ui/styles";
import Row from "../../../../components/Row";
import ConsumptionItemCard from "../../../../components/ConsumptionItemCard";
import getConsumptionCardsData from "../../../../utils/chart/getConsumptionCardsData";
import getErrorCode from "../../../../utils/getErrorCode";
import ErrorCodes from "../../../../constants/ErrorCodes";
import ErrorMessage from "../../../../components/ErrorMessage";
import EmptyState from "../../../../components/EmptyState";
import { useTranslation } from "react-i18next";
import { CHARTS_CONTAINER_HEIGHT } from "../../../../theme";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import getMaxBarSize from "../../../../utils/chart/getMaxBarSize";
import contextComponents from "../../../../utils/chart/context/contextComponents";
import getYAxisLabel from "../../../../utils/chart/context/getYAxisLabel";
import getDynamicAxisProps from "../../../../utils/chart/context/getDynamicAxisProps";

interface Props {
  consumptionData: any;
  error: any;
  loading?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    width: "100%",
    marginBottom: 24,
  },
  consumptions: {
    flex: 1,
  },
  consumptionCards: {
    marginBottom: 32,
    marginLeft: 8,
    flexWrap: "wrap",
  },
  errorMessage: {
    height: "100%",
  },
  emptyStateRoot: {
    height: CHARTS_CONTAINER_HEIGHT,
  },
}));

function PropertyConsumption({ consumptionData, error, loading }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { showCosts } = useFilterStore(fullFilterState, shallow);
  const errorCode = getErrorCode(error);

  const chartData = getBarChartData(
    consumptionData,
    showCosts ? "totalPrice" : "value"
  );

  const consumptionCardsData = getConsumptionCardsData(consumptionData);

  if (loading) {
    return (
      <div className={classes.emptyStateRoot}>
        <LoadingIndicator />
      </div>
    );
  }

  if (errorCode === ErrorCodes.IncompleteData) {
    return (
      <div className={classes.emptyStateRoot}>
        <ErrorMessage
          className={classes.errorMessage}
          title={t("errors.incompleteDataHeader")}
          message={t("errors.incompleteDataText")}
        />
      </div>
    );
  }

  if (!chartData?.keys?.length) {
    return (
      <div className={classes.emptyStateRoot}>
        <EmptyState />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Row className={classes.consumptionCards} alignItems="flex-start">
        {Object.keys(consumptionCardsData)?.map(
          (consumptionType: any, index: number) => (
            <ConsumptionItemCard
              consumptionType={consumptionType}
              value={consumptionCardsData[consumptionType].value}
              totalPrice={consumptionCardsData[consumptionType].totalPrice}
              key={index}
            />
          )
        )}
      </Row>
      <ResponsiveContainer
        width="100%"
        height={CHARTS_CONTAINER_HEIGHT}
        debounce={1}
      >
        <ComposedChart
          width={500}
          height={CHARTS_CONTAINER_HEIGHT}
          data={chartData.items}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <XAxis dataKey="name" {...getDynamicAxisProps(chartData)} />
          <YAxis label={getYAxisLabel()} />
          {contextComponents}
          {chartData.keys.map((key: any) => (
            <Bar
              key={key}
              dataKey={getLabel(key)}
              stackId="a"
              fill={getFillColor(key)}
              maxBarSize={getMaxBarSize(chartData?.keys?.length)}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default PropertyConsumption;
