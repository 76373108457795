enum ErrorCodes {
  Unknown = "UNKNOWN",
  Undefined = "UNDEFINED",
  NotFoundForTimespan = "NOT_FOUND_FOR_TIMESPAN",
  NoConsumptionForTimespan = "NO_CONSUMPTION_FOR_TIMESPAN",
  IncompleteData = "INCOMPLETE_DATA",
  UserWithEmailAlreadyExists = "USER_WITH_EMAIL_ALREADY_EXISTS",
  IdMustBeUuid = "ID_MUST_BE_UUID",
  DataGatewayIdMustBeUuid = "DATA_GATEWAY_ID_MUST_BE_UUID",
  PropertyAlreadyAssigned = "PROPERTY_ALREADY_ASSIGNED",
  PropertyAlreadyExists = "PROPERTY_ALREADY_EXISTS",
  OwnerAddressUndefined = "OWNER_ADDRESS_UNDEFINED",
  TenantAddressUndefined = "TENANT_ADDRESS_UNDEFINED",
  PropertyApartmentAreaUndefined = "PROPERTY_APARTMENT_AREA_UNDEFINED",
  AreaAmortizationNotEmpty = "AREA_AMORTIZATION_NOT_EMPTY",
  ConsumptionAmortizationEmpty = "CONSUMPTION_AMORTIZATION_EMPTY",
}

export default ErrorCodes;
