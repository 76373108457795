import React, { ChangeEvent } from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikValues } from "formik";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormCard from "../FormCard";
import { ListPropertiesQuery, PropertyDto } from "../../../api/types";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Autocomplete from "../../Autocomplete";

export enum OwnerPropertiesFormFields {
  ManagedProperty = "managedProperty",
}

export interface OwnerPropertiesFormValues extends FormikValues {
  managedProperty: PropertyDto[];
}

interface Props {
  values: OwnerPropertiesFormValues;
  onChange: (e: React.ChangeEvent<any>) => void;
  disabledFields?: Set<OwnerPropertiesFormFields>;
  onFieldChange: (fieldName: string, value: any) => void;
  propertiesList: ListPropertiesQuery["properties"];
}

const useStyles = makeStyles(() => ({
  grid: { maxWidth: 368 },
  autocompleteInput: {
    "& div": {
      height: "100%",
    },
  },
}));

function OwnerPropertiesForm({
  values,
  propertiesList,
  onFieldChange,
  disabledFields,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { managedProperty } = values;

  return (
    <FormCard
      header={t("managedProperty")}
      headerIcon={<HomeOutlinedIcon fontSize="default" />}
    >
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12}>
          <Autocomplete
            options={propertiesList}
            onChange={(e: ChangeEvent<any>, value: any) => {
              onFieldChange(OwnerPropertiesFormFields.ManagedProperty, value);
            }}
            value={managedProperty}
            id={OwnerPropertiesFormFields.ManagedProperty}
            fieldName={OwnerPropertiesFormFields.ManagedProperty}
            label={t("property")}
            multiple
            className={classes.autocompleteInput}
            disabled={disabledFields?.has(
              OwnerPropertiesFormFields.ManagedProperty
            )}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
}

export default OwnerPropertiesForm;
