import { gql } from "graphql-request";
import { useMutation } from "react-query";
import QueryKeys from "../QueryKeys";
import { gqlClient } from "../../App";

function useSetPropertyOwner() {
  return useMutation(QueryKeys.SET_PROPERTY_OWNER, async (input: any) => {
    return await gqlClient.request(
      gql`
        mutation setPropertyOwner($input: SetPropertyOwnerInput!) {
          setPropertyOwner(input: $input) {
            id
          }
        }
      `,
      {
        input,
        operationName: QueryKeys.SET_PROPERTY_OWNER,
      }
    );
  });
}

export default useSetPropertyOwner;
