import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "../../../layouts/PageLayout/PageLayout";
import CardHeader from "../../../components/Card/CardHeader";
import { Box, makeStyles } from "@material-ui/core";
import CardRow from "../../../components/Card/CardRow/CardRow";
import clsx from "clsx";
import Filters from "../../../fragments/Filters";
import PropertyCard from "./components/PropertyCard";
import useListProperties from "../../../api/hooks/useListProperties";
import List from "../../../components/List";
import Header from "../../../components/Header/Header";
import { useHistory } from "react-router";
import routes from "../../../constants/routes";

import LoadingSkeleton from "../../../components/LoadingSkeleton";

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    width: "100%",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.06)",
    overflow: "hidden",
  },
  header: {
    height: "100%",
  },
  match: {
    flex: 1,
  },
}));

function PropertiesPage() {
  const { t } = useTranslation();
  const { data, isLoading, error } = useListProperties();
  const classes = useStyles();
  const history = useHistory();

  return (
    <PageLayout
      loading={isLoading}
      error={error}
      layoutType="owner"
      sticky={
        <List spacing={0} width="100%">
          <Header />
          <CardRow borderBottom>
            <CardHeader
              className={clsx(classes.match)}
              title={t("consumptionOverview")}
            />
            <Filters />
          </CardRow>
        </List>
      }
    >
      <div>{isLoading && <LoadingSkeleton />}</div>
      <Box padding={2}>
        <List>
          {data?.properties.map((property) => (
            <PropertyCard
              key={property?.id}
              property={property}
              onDetailClick={(property) =>
                history.push(routes.owner.getPropertyDetail(property?.id || ""))
              }
            />
          ))}
        </List>
      </Box>
    </PageLayout>
  );
}

export default PropertiesPage;
