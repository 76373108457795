import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import useDownloadInvoice from "../../../hooks/useDownloadInvoice";

function InvoicePage() {
  const { invoiceId } = useParams<{
    invoiceId: string;
  }>();
  const { handleDownload } = useDownloadInvoice();

  useEffect(() => {
    if (invoiceId) {
      handleDownload(invoiceId);
    }
  }, [handleDownload, invoiceId]);

  return <div></div>;
}

export default InvoicePage;
