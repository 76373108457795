import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import Role from "../../auth/Role";
import AccessDeniedPage from "../../pages/401/AccessDeniedPage";

interface Props extends RouteProps {
  roles: Array<Role>;
}

function PrivateRoute({ children, roles = [], ...rest }: Props) {
  const { keycloak } = useKeycloak();
  const authenticated = keycloak.authenticated;

  const render = React.useCallback(
    ({ location }) => {
      const hasAccess = roles.some(
        (role) =>
          keycloak?.hasRealmRole(role) ||
          // @ts-ignore
          keycloak?.idTokenParsed?.requested_role === role
      );

      if (authenticated && !hasAccess) {
        return <AccessDeniedPage />;
      }

      // If user is authenticated, continue to the requested page
      if (authenticated) {
        return children;
      }

      // User is not authenticated, redirect to login page
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      );
    },
    [authenticated, children, keycloak, roles]
  );

  return <Route {...rest} render={render} />;
}

export default PrivateRoute;
