import moment from "moment";
import { ConsumptionPeriod } from "../../types";

export function getNextDate(
  input: moment.MomentInput,
  period: ConsumptionPeriod,
  method: "next" | "prev"
) {
  const date = moment(input);
  const modifier = method === "next" ? 1 : -1;

  switch (period) {
    case ConsumptionPeriod.Day:
      return date.add(modifier, "day").startOf("day");
    case ConsumptionPeriod.Week:
      return date.add(modifier, "week").startOf("isoWeek");
    case ConsumptionPeriod.Month:
      return date.add(modifier, "month").startOf("month");
    case ConsumptionPeriod.HalfYear:
      return date.add(6 * modifier, "month").startOf("month");
    case ConsumptionPeriod.Year:
      return date.startOf("year").add(modifier, "year");
  }
}

export function getViewEndDate(
    input: moment.MomentInput,
    period: ConsumptionPeriod,
) {
  const date = moment(input);

  switch (period) {
    case ConsumptionPeriod.Day:
      return date.endOf("day");
    case ConsumptionPeriod.Week:
      return date.endOf("isoWeek");
    case ConsumptionPeriod.Month:
      return date.endOf("month");
    case ConsumptionPeriod.HalfYear:
      return date.add(6, "month").subtract(1, "day").endOf("day");
    case ConsumptionPeriod.Year:
      return date.endOf("year");
  }
}

export function getCurrentDate(
  input: moment.MomentInput,
  period: ConsumptionPeriod
): moment.Moment {
  const date = moment(input);

  switch (period) {
    case ConsumptionPeriod.Day:
      return date.startOf("day");
    case ConsumptionPeriod.Week:
      return date.startOf("isoWeek");
    case ConsumptionPeriod.Month:
      return date.startOf("month");
    case ConsumptionPeriod.HalfYear:
      return date.startOf("month");
    case ConsumptionPeriod.Year:
      return date.startOf("year");
  }
}
