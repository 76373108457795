import React, { useState } from "react";
import { ConsumptionType } from "../../../api/types";
import {
  Checkbox,
  FormControl,
  Input,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {},
  formControl: {},
  input: {
    width: 200,
  },
}));

const typeOptions = [
  ConsumptionType.ElectricityL,
  ConsumptionType.ElectricityH,
  ConsumptionType.HeatingEnergy,
  ConsumptionType.WaterColdVolume,
  ConsumptionType.WaterWarmVolume,
  ConsumptionType.Charging,
];

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      minHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

interface Props {
  id: string;
  hidden?: boolean;
}

export default function ConsumptionSelector({ id, hidden }: Props) {
  const classes = useStyles();
  const labelId = `${id}-label`;
  const selectId = `${id}-select`;
  const { t } = useTranslation();
  const [types, setTypes] = useState<Array<ConsumptionType>>(typeOptions);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTypes(event.target.value as any);
  };

  const renderValue = (types: Array<ConsumptionType>) => {
    if (types.length === typeOptions.length) {
      return t("all");
    }
    return types.map(t).join(", ");
  };

  if (hidden) {
    return null;
  }

  return (
    <FormControl className={classes.formControl}>
      <Select
        labelId={labelId}
        id={selectId}
        multiple
        value={types}
        onChange={handleChange}
        input={<Input className={classes.input} color="primary" />}
        renderValue={renderValue as any}
        MenuProps={MenuProps}
      >
        {typeOptions.map((type) => (
          <MenuItem key={type} value={type}>
            <Checkbox checked={types.indexOf(type) > -1} />
            <ListItemText primary={t(type)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
