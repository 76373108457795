import { gqlClient } from "../App";
import { gql } from "graphql-request";
import QueryKeys from "../api/QueryKeys";
import { formatDate } from "../components/DatePicker/DatePicker";
import { Moment } from "moment";
import { ConsumptionPeriod } from "../types";
import { ConsumptionType } from "../api/types";

const getConsumptionAsCsv = async (
  from: Moment,
  to: Moment,
  period: ConsumptionPeriod,
  propertyId: string,
  counters: Array<ConsumptionType>,
  apartmentId?: string | null,
  fileName?: string,
  level?: ConsumptionPeriod
) => {
  const input = {
    from: from?.toISOString() || "",
    to: to?.toISOString() || "",
    propertyId: propertyId || "",
    apartmentId: apartmentId || undefined,
    counters,
    level: level || undefined,
  };

  const data = await gqlClient
    .request(
      gql`
        query GetConsumptionAsCsv($input: FetchConsumptionDataInput!) {
          getConsumptionAsCsv(consumptionInput: $input)
        }
      `,
      { input, operationName: QueryKeys.GET_CONSUMPTION_AS_CSV }
    )
    .catch((e) => {});

  if (data?.getConsumptionAsCsv) {
    let formattedDate = "";

    if (period) {
      formattedDate = formatDate(from, period)
        .replace(/[^\w\s]|_/g, " ")
        .replace(/\s+/g, " ");
    }

    const element = document.createElement("a");
    const file = new Blob([data?.getConsumptionAsCsv], {
      type: "csv/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${
      fileName || apartmentId || propertyId
    } ${formattedDate}.csv`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
};

export default getConsumptionAsCsv;
