import React from "react";
import ErrorMessage from "../../components/ErrorMessage";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";

function AccessDeniedPage() {
  const { t } = useTranslation("common");
  const { keycloak } = useKeycloak();

  return (
    <ErrorMessage
      title={t("accessDenied")}
      message={t("contactAdmin")}
      actionButtonText={t("logout")}
      onActionButtonClick={() => {
        keycloak.logout();
      }}
    />
  );
}

export default AccessDeniedPage;
