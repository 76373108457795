import React from "react";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import AddIcon from "@material-ui/icons/Add";
import { BORDER_COLOR, DIVIDER_COLOR, SUBHEADER_COLOR } from "../../theme";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import getDisplayName from "../../utils/getDisplayName";
import getInvoiceDisplayName from "../../utils/getInvoiceDisplayName";
import DownloadInvoiceButton from "../../components/DownloadInvoiceButton";

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    maxWidth: 375,
    minWidth: 280,
    alignSelf: "stretch",
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px",
    borderBottom: `2px solid ${DIVIDER_COLOR}`,
    "& h5": { flex: 1, marginLeft: 16 },
    "& svg": {
      color: SUBHEADER_COLOR,
    },
  },
  invoiceCard: {
    border: `1px solid ${BORDER_COLOR}`,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px",
    borderRadius: 8,
    "& div": { flex: 1, marginLeft: 16 },
    "& svg": {
      color: SUBHEADER_COLOR,
    },
  },
  cardContent: {
    "& > div:not(:last-child)": {
      marginBottom: 12,
    },
  },
  invoicePeriod: {
    fontWeight: 500,
  },
  tenant: {
    color: SUBHEADER_COLOR,
  },
}));

interface Props {
  invoices?: Array<any>;
  limit?: number;
  onAddNewInvoice?: () => void;
  onSeeAllInvoices: () => void;
}

function InvoicesCard(props: Props) {
  const { invoices, limit = 10, onAddNewInvoice, onSeeAllInvoices } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);

  return (
    <Card className={classes.card}>
      <div className={classes.cardHeader}>
        <FormatListNumberedIcon fontSize="small" />
        <Typography variant="h5">{t("invoices")}</Typography>
        {onAddNewInvoice && (
          <IconButton size="small" onClick={onAddNewInvoice}>
            <AddIcon fontSize="small" />
          </IconButton>
        )}
      </div>
      <CardContent className={classes.cardContent}>
        {invoices?.slice(0, limit)?.map((invoice) => (
          <div key={invoice?.id} className={classes.invoiceCard}>
            <PictureAsPdfIcon fontSize="small" />
            <div>
              <Typography className={classes.invoicePeriod}>
                {getInvoiceDisplayName(invoice, true)}
              </Typography>
              <Typography className={classes.tenant}>
                {getDisplayName(invoice?.tenant)}
              </Typography>
            </div>
            <DownloadInvoiceButton invoiceId={invoice?.id} />
          </div>
        ))}
        {invoices?.length ? (
          <Button color="secondary" size="small" onClick={onSeeAllInvoices}>
            {t("allInvoices")}
          </Button>
        ) : null}
      </CardContent>
    </Card>
  );
}

export default InvoicesCard;
