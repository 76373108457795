import { gql } from "graphql-request";
import { useQuery } from "react-query";
import QueryKeys from "../QueryKeys";
import { GetFiltersInput, ListApartmentsQuery } from "../types";
import { gqlClient } from "../../App";
import { TENANT_FIELDS } from "../fragments/tenantFields";
import ADDRESS_FIELDS from "../fragments/addressFields";
import CONSUMPTION_PRICING_FIELDS from "../fragments/consumptionPricingFields";
import INVOICE_NAME_FIELDS from "../fragments/invoiceNameFields";

function useListApartments(filters?: GetFiltersInput, enabled = true) {
  return useQuery<ListApartmentsQuery>(
    [QueryKeys.LIST_APARTMENTS, filters],
    async () => {
      return await gqlClient.request(
        gql`
          ${CONSUMPTION_PRICING_FIELDS}
          ${TENANT_FIELDS}
          ${ADDRESS_FIELDS}
          ${INVOICE_NAME_FIELDS}
          query ListApartments($filters: GetFiltersInput) {
            apartments(filters: $filters) {
              id
              name
              isEditable
              floor
              apartmentNumber
              areaSize
              isEditable
              tenants {
                ...TenantFields
              }
              property {
                id
                name
                address {
                  ...AddressFields
                }
                defaultConsumptionPricings {
                  ...ConsumptionPricingFields
                }
              }
              invoices {
                ...InvoiceNameFields
              }
              consumptionPricings {
                ...ConsumptionPricingFields
              }
            }
          }
        `,
        {
          filters,
          operationName: QueryKeys.LIST_APARTMENTS,
        }
      );
    },
    {
      enabled,
    }
  );
}

export default useListApartments;
