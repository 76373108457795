import React, { useState } from "react";
import IconMore from "@material-ui/icons/MoreVert";
import {
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { SUBHEADER_COLOR } from "../../theme";
import ExportConsumptionModal from "../../components/ExportConsumptionsModal";
import { ConsumptionPeriod } from "../../types";

const useStyles = makeStyles(() => ({
  menuItem: {
    "& > svg": {
      marginRight: 8,
      color: SUBHEADER_COLOR,
    },
  },
}));

interface Props {
  apartmentId?: string | null;
  propertyId: string;
  fileName?: string;
  className?: string;
  disabled?: boolean;
  level?: ConsumptionPeriod;
}

function ExportConsumptionAsCsv({
  apartmentId,
  propertyId,
  fileName,
  className,
  disabled = false,
  level,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openExportCsvModal, setOpenExportCsvModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <IconButton
        disabled={disabled}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <IconMore />
      </IconButton>
      <Menu
        id="consumption-periods-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            setOpenExportCsvModal(true);
            handleClose();
          }}
        >
          <DescriptionOutlinedIcon fontSize="small" />
          <Typography>{t("exportCsv")}</Typography>
        </MenuItem>
      </Menu>
      <ExportConsumptionModal
        open={openExportCsvModal}
        onClose={() => setOpenExportCsvModal(false)}
        apartmentId={apartmentId}
        propertyId={propertyId}
        fileName={fileName}
        level={level}
      />
    </div>
  );
}

export default ExportConsumptionAsCsv;
