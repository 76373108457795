import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import { NAV_HEIGHT } from "../../theme";

interface Props {
  title?: string;
  message?: string;
  children?: JSX.Element | JSX.Element[];
  actionButtonText?: string;
  onActionButtonClick?: () => void;
  className?: string;
  fullScreen?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  message: {
    maxWidth: 400,
    marginBottom: 16,
  },
  logoutButton: {
    minWidth: 160,
  },
  fullScreen: {
    height: `calc(100vh - ${NAV_HEIGHT}px)`,
  },
}));

function ErrorMessage({
  title: titleProp,
  message: messageProp,
  actionButtonText,
  onActionButtonClick,
  className,
  children,
  fullScreen,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  const title = titleProp || t("serviceUnavailableTitle");
  const message = messageProp || t("serviceUnavailableMessage");

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.fullScreen]: fullScreen,
      })}
    >
      <Typography gutterBottom variant="h4">
        {title}
      </Typography>
      <Typography
        align="center"
        color="textSecondary"
        className={classes.message}
      >
        {message}
      </Typography>
      {actionButtonText && (
        <Button
          variant="contained"
          color="secondary"
          className={classes.logoutButton}
          onClick={onActionButtonClick}
        >
          {actionButtonText}
        </Button>
      )}
      {children}
    </div>
  );
}

export default ErrorMessage;
