const QueryKeys = {
  GET_CONSUMPTION: "GET_CONSUMPTION",
  GET_CONSUMPTION_AS_CSV: "GET_CONSUMPTION_AS_CSV",
  LIST_PROPERTIES: "LIST_PROPERTIES",
  LIST_TENANTS: "LIST_TENANTS",
  LIST_PROPERTY_OWNERS: "LIST_PROPERTY_OWNERS",
  LIST_APARTMENTS: "LIST_APARTMENTS",
  LIST_DATA_GATEWAYS: "LIST_DATA_GATEWAYS",
  LIST_INVOICES: "LIST_INVOICES",
  SET_PROPERTY: "SET_PROPERTY",
  SET_PROPERTY_OWNER: "SET_PROPERTY_OWNER",
  SET_TENANT: "SET_TENANT",
  SET_APARTMENT: "SET_APARTMENT",
  SET_DATA_GATEWAY: "SET_DATA_GATEWAY",
  PAGE_DATA_GATEWAY: "PAGE_DATA_GATEWAY",
  SET_INVOICE: "SET_INVOICE",
  DELETE_INVOICE: "DELETE_INVOICE",
  DELETE_TENANT: "DELETE_TENANT",
  DELETE_APARTMENT: "DELETE_APARTMENT",
  DELETE_PROPERTY: "DELETE_PROPERTY",
  DELETE_PROPERTY_OWNER: "DELETE_PROPERTY_OWNER",
  TENANT_OVERVIEW: "TENANT_OVERVIEW",
  OWNER_DASHBOARD: "OWNER_DASHBOARD",
  PROPERTY_DETAIL: "PROPERTY_DETAIL",
  PROPERTY_OWNER_AUTOCOMPLETE: "PROPERTY_OWNER_AUTOCOMPLETE",
  DATA_GATEWAY_DETAIL: "DATA_GATEWAY_DETAIL",
  DELETE_DATA_GATEWAY: "DELETE_DATA_GATEWAY",
  APARTMENT_IDS_FOR_PROPERTIES: "APARTMENT_IDS_FOR_PROPERTIES",
  PROPERTY_SELECTOR: "PROPERTY_SELECTOR",
  APARTMENT_SELECTOR: "APARTMENT_SELECTOR",
  TENANT_SELECTOR: "TENANT_SELECTOR",
  OWNER_SELECTOR: "OWNER_SELECTOR",
  ADD_READONLY_PROPERTY: "ADD_READONLY_PROPERTY",
  TENANT_OVERVIEW_DATA: "TENANT_OVERVIEW_DATA",
};

export default QueryKeys;
