import React, { useEffect, useState } from "react";
import { CardHeader } from "../../components/Card";
import getDisplayName from "../../utils/getDisplayName";
import { HomeIconPrimary } from "../../assets";
import SearchableMenu from "../../components/SearchableMenu";
import {
  OwnerSelectorDataQuery,
  OwnerSelector_OwnerFragment,
} from "../../api/types";
import { makeStyles } from "@material-ui/core";
import { SELECTOR_WIDTH } from "../../theme";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { gql } from "graphql-request";
import { useQuery } from "react-query";
import QueryKeys from "../../api/QueryKeys";
import { gqlClient } from "../../App";

const useStyles = makeStyles(() => ({
  root: {
    width: SELECTOR_WIDTH,
  },
}));

export interface OwnerSelectorProps {
  onSelect?: (owner?: OwnerSelector_OwnerFragment | null) => void;
}

function useOwnerSelectorData() {
  return useQuery<OwnerSelectorDataQuery>(
    [QueryKeys.OWNER_SELECTOR],
    async () =>
      await gqlClient.request(gql`
        ${OwnerSelector.fragments.owners}
        query OwnerSelectorData {
          propertyOwners {
            ...OwnerSelector_Owner
          }
        }
      `)
  );
}

function OwnerSelector({ onSelect }: OwnerSelectorProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ownerId } = useParams<{ ownerId: string }>();
  const { data } = useOwnerSelectorData();

  const [
    selectedOwner,
    setSelectedOwner,
  ] = useState<OwnerSelector_OwnerFragment | null>(null);
  const [anchorEl, setAnchorEl] = useState<any>(undefined);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const query = searchQuery.toLowerCase();
  const filteredOwners = data?.propertyOwners?.filter(
    (owner) =>
      owner?.id?.toLowerCase().includes(query) ||
      owner?.firstName?.toLowerCase()?.includes(query) ||
      owner?.lastName?.toLowerCase()?.includes(query)
  );

  useEffect(() => {
    if (ownerId) {
      const selectedOwner = data?.propertyOwners?.find(
        (owner) => owner?.id === ownerId
      );
      setSelectedOwner(selectedOwner || null);
    }
  }, [ownerId, setSelectedOwner, data]);

  const handleSelect = (
    ownerId: string | null,
    owner?: OwnerSelector_OwnerFragment | null
  ) => {
    setSelectedOwner(owner || null);
    handleClose();

    if (onSelect) {
      onSelect(owner);
    }
  };

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <CardHeader
        title={getDisplayName(selectedOwner) || t("all")}
        label={t("tenant")}
        icon={HomeIconPrimary}
        onClick={handleClick}
      />
      <SearchableMenu<OwnerSelector_OwnerFragment>
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        items={filteredOwners || new Array<any>()}
        getId={(item) => item?.id}
        getName={(item) => getDisplayName(item)}
        onSelect={handleSelect}
        value={selectedOwner?.id}
        searchQuery={searchQuery}
        onChangeSearchQuery={(value) => setSearchQuery(value)}
      />
    </div>
  );
}

OwnerSelector.fragments = {
  owners: gql`
    fragment OwnerSelector_Owner on PropertyOwnerDto {
      id
      firstName
      lastName
    }
  `,
};

export default OwnerSelector;
