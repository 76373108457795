import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Menu, MenuItem, MenuProps, Typography } from "@material-ui/core";
import SearchInput from "../SearchInput";
import {
  DIVIDER_BORDER,
  SELECTOR_MAX_HEIGHT,
  SELECTOR_WIDTH,
} from "../../theme";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props<T extends object = any> {
  items?: Array<T>;
  anchorEl?: HTMLElement;
  open: boolean;
  value?: string | null;
  getId: (item: T) => string;
  getName: (item: T) => string;
  onSelect?: (id: string | null, item: T | null) => void;
  onClose?: () => void;
  className?: string;
  MenuProps?: MenuProps;
  maxHeight?: number;
  searchQuery?: string;
  onChangeSearchQuery?: (value: string) => void;
  removeAllItemsOption?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {},
  paper: {
    width: `calc(${SELECTOR_WIDTH}px + 24px)`,
    maxHeight: SELECTOR_MAX_HEIGHT,
    borderTopLeftRadius: "0px !important",
    borderTopRightRadius: "0px !important",
    overflow: "hidden",
  },
  menu: {
    marginTop: 12,
    padding: "8px !important",
    borderRadius: 0,
  },
  searchInput: {
    width: "calc(100% - 16px)",
    backgroundColor: "#F7F8F9",
    marginLeft: 8,
    marginBottom: 8,
  },
  listContainer: {
    width: "100%",
    height: `calc(${SELECTOR_MAX_HEIGHT}px - 48px)`,
    borderTop: DIVIDER_BORDER,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  itemName: {
    whiteSpace: "break-spaces",
  },
}));

function SearchableMenu<T extends object>(props: Props<T>) {
  const {
    items = [],
    anchorEl,
    value,
    open,
    getId,
    getName,
    onClose,
    onSelect,
    className,
    searchQuery,
    onChangeSearchQuery = () => {},
    MenuProps,
    removeAllItemsOption = false,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles(props);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      className={clsx(classes.menu, className)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      classes={{ paper: classes.paper }}
      {...MenuProps}
    >
      <SearchInput
        value={searchQuery}
        onChange={(e) => onChangeSearchQuery(e.target.value)}
        className={classes.searchInput}
      />
      <div className={classes.listContainer}>
        {!removeAllItemsOption && (
          <MenuItem
            onClick={() => {
              if (onSelect) {
                onSelect(null, null);
              }
            }}
            selected={!Boolean(value)}
          >
            <Typography>{t("all")}</Typography>
          </MenuItem>
        )}
        {items?.map((item) => (
          <MenuItem
            key={getId(item)}
            value={getId(item)}
            selected={getId(item) === value}
            onClick={() => {
              if (onSelect) {
                onSelect(getId(item), item);
              }
            }}
          >
            <Typography className={classes.itemName}>
              {getName(item)}
            </Typography>
          </MenuItem>
        ))}
      </div>
    </Menu>
  );
}

export default SearchableMenu;
