import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { FormikProvider, useFormik } from "formik";
import QueryKeys from "../../api/QueryKeys";
import ErrorAlert from "../ErrorAlert";
import Dialog from "../Dialog";
import ApartmentForm from "../Forms/ApartmentForm";
import useListProperties from "../../api/hooks/useListProperties";
import useSetApartment from "../../api/hooks/useSetApartment";
import useApartmentIdsForProperty from "../../api/hooks/useApartmentIdsForProperty";
import getPricingInput from "../../utils/getPricingInput";
import getApartmentPricingValues from "../../utils/getApartmentPricingValues";
import { forOwn } from "lodash";
import { makeStyles } from "@material-ui/styles";
import {
  ApartmentInfoFormFields,
  ApartmentInfoFormValues,
} from "../Forms/ApartmentInfoForm/ApartmentInfoForm";
import { useHistory } from "react-router-dom";
import routes from "../../constants/routes";
import { ApartmentPricesFormValues } from "../Forms/ApartmentPricesForm/ApartmentPricesForm";

interface Props {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  errorAlert: {
    marginBottom: 16,
  },
}));

type ValidationErrors = {
  invalidName?: boolean;
  invalidProperty?: boolean;
  invalidApartmentNumber?: boolean;
};

export function validateApartmentForm(values: ApartmentInfoFormValues) {
  const errors: ValidationErrors = {};

  if (!values.selectedApartment?.name) {
    errors.invalidName = true;
  }

  if (!values.property) {
    errors.invalidProperty = true;
  }

  if (!values.apartmentNumber) {
    errors.invalidApartmentNumber = true;
  }

  return errors;
}

function NewApartmentDialog({ open, onClose }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { data: propertiesList } = useListProperties();
  const [placeholders, setPlaceholders] = useState<{
    [key: string]: number | string;
  }>({});
  const history = useHistory();
  const setApartment = useSetApartment();

  const formik = useFormik<ApartmentInfoFormValues & ApartmentPricesFormValues>(
    {
      initialValues: {
        name: null,
        selectedApartment: null,
        floor: "",
        apartmentNumber: "",
        property: null,
        pricingHeating: "",
        pricingWaterWarm: "",
        pricingWaterCold: "",
        pricingElectricityH: "",
        pricingElectricityL: "",
        pricingElectricityPV: "",
        pricingElectricity: "",
        pricingMaximumPower: "",
        pricingCharging: "",
        areaSize: "",
      },
      onSubmit: () => {},
      validate: validateApartmentForm,
      validateOnMount: true,
      validateOnChange: true,
    }
  );

  const { data: apartmentsList } = useApartmentIdsForProperty(
    formik.values.property?.id || "",
    !!formik.values.property?.id
  );

  const apartmentIds: Array<string> = apartmentsList?.getApartmentIdsForProperty?.map(
    (id: string) => ({
      id,
      name: id,
    })
  );

  const handleSubmit = async () => {
    const values = formik?.values;
    const { areaSize, floor, property, apartmentNumber } = values;

    await setApartment.mutate(
      {
        id: undefined,
        name: values?.selectedApartment?.name || "",
        floor: floor ?? "",
        propertyId: property?.id ?? "",
        apartmentNumber: apartmentNumber?.toString() ?? "",
        consumptionPricings: getPricingInput(values),
        areaSize: areaSize ? Number(areaSize) : 0,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.LIST_APARTMENTS]);
          queryClient.invalidateQueries([QueryKeys.APARTMENT_SELECTOR]);
          queryClient.invalidateQueries([QueryKeys.PROPERTY_DETAIL]);
          queryClient.invalidateQueries([QueryKeys.GET_CONSUMPTION]);
          formik.resetForm();

          if (onClose) {
            onClose();
          }

          history.push(
            routes.owner.getPropertyDetail(values?.property?.id || "")
          );
        },
      }
    );
  };

  const handleCloseClick = () => {
    formik.resetForm();
    setApartment.reset();

    if (onClose) {
      onClose();
    }
  };

  const handleFieldChange = (fieldName: string, value: any) => {
    if (fieldName === ApartmentInfoFormFields.Property) {
      const defaultPricings = getApartmentPricingValues(
        value?.defaultConsumptionPricings
      );
      forOwn(defaultPricings, (value, key) => {
        formik.setFieldValue(key, "");
      });
      setPlaceholders(defaultPricings);
    }
    formik.setFieldValue(fieldName, value);
  };

  const disabledSubmitButton = !formik.isValid || setApartment.isLoading;

  return (
    <Dialog
      open={open}
      dialogHeader={t("newApartment")}
      onClose={handleCloseClick}
      maxWidth="lg"
      data-cy="new-tenant-dialog"
      onSubmit={handleSubmit}
      disabledSubmitButton={disabledSubmitButton}
      primaryButtonLoading={setApartment.isLoading}
      errorAlert={
        <ErrorAlert error={setApartment.error} className={classes.errorAlert} />
      }
    >
      <FormikProvider value={formik}>
        <ApartmentForm
          values={formik.values}
          onChange={formik.handleChange}
          onFieldChange={handleFieldChange}
          propertiesList={propertiesList?.properties || []}
          apartmentsList={apartmentIds || []}
          placeholders={placeholders}
        />
      </FormikProvider>
    </Dialog>
  );
}

export default NewApartmentDialog;
