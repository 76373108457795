import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import FormCard from "../FormCard";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { ApartmentDto, TenantFormApartmentsFragment } from "../../../api/types";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import ApartmentFieldArray from "./ApartmentFieldArray";

export type ApartmentFields = {
  apartment: ApartmentDto | null;
  dateIn: string | null | MaterialUiPickersDate;
  dateOut: string | null | MaterialUiPickersDate;
};

export interface TenantApartmentsFormValues extends FormikValues {
  apartments: Array<ApartmentFields>;
}

export enum TenantApartmentsFormFields {
  Apartment = "apartmentId",
  DateIn = "dateIn",
  DateOut = "dateOut",
  Apartments = "apartments",
}

export interface TenantApartmentsFormValues extends FormikValues {
  apartments: Array<ApartmentFields>;
}

interface Props {
  values: TenantApartmentsFormValues;
  apartmentsList: Array<TenantFormApartmentsFragment | null>;
  isEditable?: boolean;
}

const useStyles = makeStyles(() => ({
  grid: { maxWidth: 830 },
}));

function TenantApartmentsForm({ values, apartmentsList, isEditable }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { apartments } = values;

  return (
    <FormCard
      header={t("associatedResidentialUnits")}
      headerIcon={<HomeOutlinedIcon fontSize="default" />}
    >
      <div className={classes.grid}>
        <ApartmentFieldArray
          apartmentFields={apartments}
          allApartments={apartmentsList || []}
          isEditable={isEditable}
        />
      </div>
    </FormCard>
  );
}

export default TenantApartmentsForm;
