import { createMuiTheme } from "@material-ui/core";

export const ROW_HEIGHT = 64;
export const CHART_SECTION_HEIGHT = 400;
export const BAR_CHART_SECTION_HEIGHT_SMALL = 250;
export const BAR_CHART_SECTION_HEIGHT_SMALL_PX = `${BAR_CHART_SECTION_HEIGHT_SMALL}px`;
export const NAV_BAR_HEIGHT = 64;
export const HEADER_HEIGHT = 112;
export const NAV_HEIGHT = NAV_BAR_HEIGHT + HEADER_HEIGHT;
export const CARD_SPACING = 24;
export const OWNER_PAGE_BG_COLOR = "#291e3c";
export const ADMIN_PAGE_BG_COLOR = "#291e3c";
export const TENANT_PAGE_BG_COLOR = "#291e3c";
export const DIVIDER_COLOR = "#F1F2F5";
export const BORDER_COLOR = "#E7E7ED";
export const BORDER_COLOR_SECONDARY = "#CDCED9";
export const TEXTFIELD_BACKGROUND_COLOR = "#F7F8F9";
export const ELECTRICITY_CHART_COLORS = ["rgb(151, 227, 213)"];
export const WARNING_COLOR = "#B95000";
export const TEXT_PRIMARY = "rgba(0, 0, 0, 0.87)";
export const TEXT_SECONDARY = "rgba(0, 0, 0, 0.56)";
export const SUBHEADER_COLOR = "#6B6C7E";
export const BODY_TEXT_COLOR = "#272833";
export const BUTTON_PRIMARY = "#63C46A";
export const BORDER_RADIUS = 12;
export const DIVIDER_BORDER = `1px solid ${DIVIDER_COLOR}`;
export const CARD_ROW_BORDER = `2px solid ${DIVIDER_COLOR}`;
export const SELECTOR_WIDTH = 270;
export const SELECTOR_MAX_HEIGHT = "90vh";
export const CHARTS_CONTAINER_HEIGHT = 400;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#6fb859",
      light: "#a1eb88",
      dark: "#3e882c",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#6B6C7E",
      light: "#524567",
      dark: "#000017",
      contrastText: "#ffffff",
    },
    error: {
      main: "#C31212",
      light: WARNING_COLOR,
    },
    background: { default: "#F0F0F0", paper: "white" },
  },
  typography: {
    fontFamily: ["IBM Plex Sans", "Roboto", "Arial"].join(","),
  },
  overrides: {
    MuiMenuItem: {
      root: {
        background: "transparent",
        "&$selected, &$selected:hover, &$selected:focus": {
          backgroundColor: "#F0F5FF",
        },
        "&:hover": {
          backgroundColor: "#F0F5FF",
        },
      },
    },
    MuiButton: {
      root: {
        margin: "inherit",
        minWidth: 100,
      },
      label: {
        paddingLeft: 8,
        paddingRight: 8,
      },
      outlinedSecondary: {
        border: "1px solid #CDCED9",
        color: SUBHEADER_COLOR,
      },
      outlinedSizeSmall: {},
    },
    MuiTypography: {
      root: {
        margin: "inherit",
      },
      h4: {
        fontSize: 24,
        fontWeight: 500,
      },
      h5: {
        fontSize: 18,
        fontWeight: 400,
        color: BODY_TEXT_COLOR,
      },
      h6: {
        textTransform: "uppercase",
        fontSize: 14,
        color: "#A0A0A0",
      },
      body1: { fontSize: 14, color: BODY_TEXT_COLOR },
      caption: {
        display: "block",
        color: "rgba(0, 0, 0, 0.54)",
        marginBottom: 24,
      },
      overline: {
        textTransform: "uppercase",
        color: "#A0A0A0",
      },
    },
    MuiContainer: {
      root: {
        position: "relative",
      },
    },
    MuiCard: {
      root: {
        boxShadow: "0px 2px 4px rgba(39, 40, 51, 0.12)",
        borderRadius: 8,
      },
    },
    MuiTableRow: {
      root: {
        height: ROW_HEIGHT,
      },
    },
    MuiTableCell: {
      root: {
        paddingLeft: 24,
        borderBottom: `1px solid ${BORDER_COLOR} !important`,
      },
      head: {
        color: "#A0A0A0",
        textTransform: "uppercase",
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "transparent",
      },
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: WARNING_COLOR,
      },
    },
    MuiInputLabel: {
      asterisk: {
        color: WARNING_COLOR,
      },
    },
    MuiTabs: {
      indicator: {
        height: 4,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
      },
    },
    MuiInputBase: {
      root: {
        border: `1px solid ${BORDER_COLOR}`,
        padding: "2px 12px",
        height: 32,
        margin: 0,
        borderRadius: 4,
        backgroundColor: TEXTFIELD_BACKGROUND_COLOR,
        fontSize: 14,
        "&$multiline": {
          paddingTop: 8,
          paddingBottom: 8,
          height: "auto",
        },
      },
    },
    MuiInput: {
      underline: {
        borderBottom: "none",
      },
    },
    MuiChip: {
      root: {
        overflow: "hidden",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
