import { PhotovoltaicsCostDistributionType } from "../api/types";

export const propertyFormikInitialValues = {
  name: "",
  dataGatewayId: "",
  propertyId: "",
  areaSize: "",
  city: "",
  addressLine1: "",
  addressLine2: "",
  zip: "",
  pricingHeating: "",
  pricingWaterWarm: "",
  pricingWaterCold: "",
  pricingElectricityH: "",
  pricingElectricityL: "",
  pricingElectricityPV: "",
  pricingElectricity: "",
  pricingMaximumPower: "",
  pricingCharging: "",
  propertyOwner: null,
  additionalCosts: [],
  counterPricings: [],
  amortizedCosts: [],
  mainCounterName: "",
  costDistributionType: PhotovoltaicsCostDistributionType.Uniform,
};
