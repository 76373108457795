import React from "react";
import DownloadIcon from "@material-ui/icons/GetApp";
import { IconButton } from "@material-ui/core";
import useDownloadInvoice from "../../hooks/useDownloadInvoice";

interface Props {
  invoiceId: string;
}

function DownloadInvoiceButton({ invoiceId }: Props) {
  const { handleDownload } = useDownloadInvoice();

  const onDownloadClick = () => {
    handleDownload(invoiceId);
  };

  return (
    <IconButton size="small" onClick={onDownloadClick}>
      <DownloadIcon fontSize="small" />
    </IconButton>
  );
}

export default DownloadInvoiceButton;
