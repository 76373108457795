import {
  Input as MaterialInput,
  InputProps,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  BORDER_COLOR,
  SUBHEADER_COLOR,
  TEXTFIELD_BACKGROUND_COLOR,
} from "../../theme";
import Label from "../Label";
import Row from "../Row";

export interface ExtendedInputProps extends InputProps {
  label?: any;
  gutterBottom?: number;
  className?: string;
  [key: string]: any;
  hidden?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  required?: boolean;
  unit?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${BORDER_COLOR}`,
    padding: "2px 12px",
    height: 32,
    margin: 0,
    borderRadius: 4,
    backgroundColor: TEXTFIELD_BACKGROUND_COLOR,
    fontSize: 14,
    marginBottom: (props: ExtendedInputProps) => props.gutterBottom,
    "&$multiline": {
      paddingTop: 8,
      paddingBottom: 8,
      height: "auto",
    },
    marginRight: "8px !important",
  },
  disabled: {
    backgroundColor: theme.palette.grey["100"],
  },
  multiline: {},
  unit: {
    fontSize: 14,
    color: SUBHEADER_COLOR,
    fontWeight: 400,
  },
}));

function Input(props: ExtendedInputProps) {
  const {
    label,
    className,
    gutterBottom = 8,
    ref,
    InputProps,
    hidden,
    autoFocus,
    disabled,
    required,
    unit,
    ...rest
  } = props;
  const classes = useStyles({ gutterBottom });

  if (hidden) {
    return null;
  }

  return (
    <div className={className}>
      <Label value={label} required={required} />
      <Row>
        <MaterialInput
          ref={ref}
          classes={{
            root: classes.root,
            disabled: classes.disabled,
            multiline: classes.multiline,
          }}
          disableUnderline
          autoFocus={autoFocus}
          disabled={disabled}
          {...InputProps}
          {...rest}
        />
        {unit && <Typography className={classes.unit}>{unit}</Typography>}
      </Row>
    </div>
  );
}

export default Input;
