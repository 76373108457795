import moment, { Moment } from "moment";
import { ApartmentDto } from "../../api/types";

export function formatTenantApartments(
  apartments: Array<{
    apartment: ApartmentDto | null;
    dateIn: string | Moment | null;
    dateOut: string | Moment | null;
  }>
) {
  return apartments.map((apartment) => {
    return {
      apartmentId: apartment?.apartment?.id,
      dateIn: apartment.dateIn
        ? moment(apartment.dateIn).format("YYYY-MM-DD")
        : undefined,
      dateOut: apartment?.dateOut
        ? moment(apartment.dateOut).format("YYYY-MM-DD")
        : undefined,
    };
  });
}
