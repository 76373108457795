import React from "react";
import moment from "moment";
import { ConsumptionPeriod } from "../../types";
import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import { ArrowLeftIcon } from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";
import { ArrowRightIcon } from "@material-ui/pickers/_shared/icons/ArrowRightIcon";

interface Props {
  date: moment.Moment;
  period: ConsumptionPeriod;
  onChange?: (date: moment.Moment) => void;
}

const useStyles = makeStyles(() => ({
  date: {
    marginLeft: 12,
    marginRight: 12,
  },
}));

export function getNextDate(
  input: moment.MomentInput,
  period: ConsumptionPeriod,
  method: "next" | "prev"
) {
  const date = moment(input);
  const modifier = method === "next" ? 1 : -1;

  switch (period) {
    case ConsumptionPeriod.Day:
      return date.add(1 * modifier, "day").startOf("day");
    case ConsumptionPeriod.Week:
      return date.add(1 * modifier, "week").startOf("isoWeek");
    case ConsumptionPeriod.Month:
      return date.add(1 * modifier, "month").startOf("month");
    case ConsumptionPeriod.HalfYear:
      return date.add(6 * modifier, "month").startOf("month");
    case ConsumptionPeriod.Year:
      return date.add(1 * modifier, "year").startOf("year");
  }
}

export function formatDate(
  input: moment.MomentInput,
  period: ConsumptionPeriod
) {
  const date = moment(input);

  switch (period) {
    case ConsumptionPeriod.Day:
      return date.format("DD. MMMM, YYYY");
    case ConsumptionPeriod.Week:
      return `${date.format("[KW] WW")}`;
    case ConsumptionPeriod.Month:
      return date.format("MMM YYYY");
    case ConsumptionPeriod.HalfYear:
      return date.format("MMM YYYY");
    case ConsumptionPeriod.Year:
      return date.format("YYYY");
  }
}

export default function DatePicker({ date, onChange, period }: Props) {
  const classes = useStyles();
  const today = moment();

  const handleChange = (date: moment.Moment) => {
    if (onChange) {
      onChange(date);
    }
  };

  return (
    <Box flexDirection="row" display="flex" alignItems="center" flex={1}>
      <IconButton
        onClick={() => {
          handleChange(getNextDate(date, period, "prev"));
        }}
      >
        <ArrowLeftIcon />
      </IconButton>
      <Typography className={classes.date} align="center" variant="subtitle2">
        {formatDate(date, period)}
      </Typography>
      <IconButton
        disabled={getNextDate(date, period, "next").isAfter(today)}
        onClick={() => {
          handleChange(getNextDate(date, period, "next"));
        }}
      >
        <ArrowRightIcon />
      </IconButton>
    </Box>
  );
}
