import { uniq } from "lodash";
import React, { useEffect, useMemo } from "react";
import useConsumption from "../../../api/hooks/useConsumption";
import { ConsumptionType, FetchConsumptionDataInput } from "../../../api/types";

export type ConsumptionRenderProps = {
  loading: boolean;
  // TODO: Type this
  consumption: any;
  error: any;
};

interface Props {
  input: Partial<FetchConsumptionDataInput>;
  children: (options: ConsumptionRenderProps) => React.ReactNode;
  enabled?: boolean;
  types?: Array<ConsumptionType>;
  setOptions?: any;
  onRenderPropsChange?: (props: ConsumptionRenderProps) => void;
}

export default function ConsumptionLoader({
  input,
  children,
  enabled,
  types,
  setOptions = () => {},
  onRenderPropsChange,
}: Props): any {
  const { data, isLoading, error } = useConsumption(input, enabled);

  const typesFilter = useMemo(() => new Set(types), [types]);

  const options = useMemo(
    () => uniq(data?.getConsumption?.map((item) => item.itemType)),
    [data]
  );

  useEffect(() => {
    if (isLoading) {
      setOptions([]);
    } else if (options.length) {
      setOptions(options);
    } else {
      setOptions(undefined);
    }
  }, [options, setOptions, isLoading]);

  const renderProps = useMemo(
    () => ({
      loading: isLoading,
      consumption: data?.getConsumption.map((item) => {
        if (typesFilter.has(item.itemType)) {
          return {
            ...item,
          };
        } else {
          return {
            ...item,
            value: 0,
            totalPrice: 0,
          };
        }
      }),
      error,
    }),
    [isLoading, data, error, typesFilter]
  );

  if (onRenderPropsChange) {
    onRenderPropsChange(renderProps);
  }

  return children(renderProps);
}
