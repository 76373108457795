import _ from "lodash";
import { ChartItemFieldsFragment, ConsumptionType } from "../../api/types";
import getLabel from "./getLabel";
import { ConsumptionPeriod } from "../../types";
import { roundValue } from "../roundValue";

type Context = {
  name: string;
  unit: string;
};

type Values = {
  [key in ConsumptionType]?: number;
};

type ChartItem = Context & Values;

export interface BarChartData {
  items: Array<ChartItem>;
  keys: Array<string>;
}

// TODO: Add proper typing
function getBarChartData(
  items: Array<ChartItemFieldsFragment | null> = [],
  valueKey: "value" | "totalPrice" = "totalPrice",
  itemKey: "item" | "itemType" = "item",
  period?: ConsumptionPeriod
): BarChartData {
  if (!items || !items.length) {
    return {
      items: [],
      keys: [],
    };
  }
  const renderPrice = valueKey === "totalPrice";

  const dataMap: any = {};
  const keys: Set<string> = new Set();

  const createMap: any = () => {
    for (const item of items) {
      if (!item) {
        continue;
      }

      const key = item[itemKey];
      const currentItem = dataMap[key] || {};
      const itemType = getLabel(item.itemType);
      keys.add(item.itemType);
      const name = itemKey === "item" ? item.item : getLabel(item.itemType);
      const formattedName = name.length > 15 ? `${name.slice(0, 15)}...` : name;

      dataMap[key] = {
        ...currentItem,
        name: formattedName,
        unitLabel: {
          ...currentItem.unitLabel,
          [`${itemType}`]: renderPrice ? "CHF" : item.unit,
        },
        [`${itemType}`]: roundValue(item[valueKey]),
      };
    }
  };

  createMap();

  const data: Array<ChartItem> = _.flatMap(dataMap);

  return {
    items: data,
    keys: Array.from(keys),
  };
}

export default getBarChartData;
