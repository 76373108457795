import { gql } from "graphql-request";
import { useMutation } from "react-query";
import QueryKeys from "../QueryKeys";
import { gqlClient } from "../../App";

function useSetTenant() {
  return useMutation(QueryKeys.SET_TENANT, async (input: any) => {
    return await gqlClient.request(
      gql`
        mutation SetTenant($input: SetTenantInput!) {
          setTenant(input: $input) {
            id
          }
        }
      `,
      {
        input,
        operationName: QueryKeys.SET_TENANT,
      }
    );
  });
}

export default useSetTenant;
