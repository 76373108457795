import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Menu, Theme } from "@material-ui/core";
import Row from "../Row";
import clsx from "clsx";
import { HEADER_HEIGHT, NAV_BAR_HEIGHT } from "../../theme";
import { Link } from "react-router-dom";
import { logo } from "../../assets";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { makeStyles } from "@material-ui/styles";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";

interface NavigationProps {
  children: React.ReactNode;
  sticky?: React.ReactNode;
  color?: string;
  renderBackButton?: boolean;
  onBackClick?: () => void;
}

interface NavButtonProps {
  selected?: boolean;
  children?: React.ReactNode;
  to: string;
}

interface MobileNavigationProps {
  children?: React.ReactNode;
}

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: (props: NavigationProps) => ({
    position: "fixed",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    height: NAV_BAR_HEIGHT * 2,
    color: "white",
    zIndex: 100,
    backgroundColor: props.color ?? theme.palette.secondary.main,
  }),
  container: {
    zIndex: 1,
  },
  sticky: {
    zIndex: 1,
  },
  logoContainer: {
    height: NAV_BAR_HEIGHT,
  },
  logoImage: {
    width: 160,
    marginTop: 8,
    padding: 12,
  },
  navRow: {},
  navButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 36,
    fontSize: 18,
    color: "#ffffffbb",
    textTransform: "none",
    borderRadius: 0,
    textDecoration: "inherit",
    padding: "4px 16px",
    borderBottom: `2px solid transparent`,
    "&$selected": {
      opacity: 1,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      color: "white",
    },
    "&:hover": {
      color: "white",
    },
    whiteSpace: "nowrap",
  },
  avatarContainer: {
    marginLeft: 24,
    cursor: "pointer",
    "&:hover": {},
  },
  selected: {},
  scrolled: {
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.1)",
  },
  iconButton: {
    color: "white",
  },
  menu: {
    marginTop: 48,
  },
  backButton: {
    position: "absolute",
    left: -32,
    top: 16,
    height: 32,
    width: 32,
    borderRadius: 4,
    marginBottom: 8,
    backgroundColor: "rgba(255, 255, 255, 0.16)",
    "& svg": {
      color: "white",
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.16)",
      opacity: 0.8,
    },
  },
}));

function Navigation(props: NavigationProps) {
  const { children, sticky, onBackClick, renderBackButton } = props;
  const classes = useStyles(props);
  const history = useHistory();

  const [scrolled, setScrolled] = useState(false);

  useScrollPosition(({ currPos }) => {
    setScrolled(HEADER_HEIGHT + currPos.y < 0);
  });

  return (
    <div
      className={clsx(classes.root, "mui-fixed", {
        [classes.scrolled]: scrolled,
      })}
    >
      <Container className={classes.container}>
        <Row>{children}</Row>
      </Container>
      <Container className={classes.sticky}>
        {renderBackButton && (
          <IconButton
            className={classes.backButton}
            onClick={() => (onBackClick ? onBackClick() : history.goBack())}
          >
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        )}
        <Row>{sticky}</Row>
      </Container>
    </div>
  );
}

function MobileNavigationMenu(props: MobileNavigationProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles(props);
  const { children } = props;

  const handleMenuIconClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="menu"
        aria-controls="mobile-menu"
        aria-haspopup="true"
        onClick={handleMenuIconClick}
        className={classes.iconButton}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="navigation-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {children}
      </Menu>
    </div>
  );
}

function Logo() {
  const { t } = useTranslation();
  const classes = useStyles({});
  return (
    <Row className={classes.logoContainer}>
      <img className={classes.logoImage} src={logo} alt={t("appName")} />
    </Row>
  );
}

function NavButton({ children, selected, to }: NavButtonProps) {
  const classes = useStyles({});
  return (
    <Link
      to={to}
      className={clsx(classes.navButton, { [classes.selected]: selected })}
    >
      {children}
    </Link>
  );
}

Navigation.Button = NavButton;
Navigation.Logo = Logo;
Navigation.MobileNavigationMenu = MobileNavigationMenu;

export default Navigation;
