import { AmortizedCostDto, AmortizedCostInput } from "../../api/types";

const formatAmortizationCostsInput = (
  amortizationCosts: Array<AmortizedCostDto>
): Array<AmortizedCostInput> => {
  const amortizationCostsInput = [];

  for (let cost of amortizationCosts) {
    amortizationCostsInput.push({
      description: cost.description,
      counterType: cost.counterType,
      amount: Number(cost.amount),
      costDistributionType: cost.costDistributionType,
      from: cost.from,
      to: cost.to || cost.from,
    });
  }

  return amortizationCostsInput;
};

export default formatAmortizationCostsInput;
