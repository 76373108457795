import React from "react";
import { DefaultRawDatum, PieSvgProps, ResponsivePie } from "@nivo/pie";
import { DONUT_DEFAULT_FILL } from "./config/fill";
import { DonutChartData } from "../../../utils/chart/getDonutChartData";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import ErrorMessage from "../../ErrorMessage";
import { CircularProgress } from "@material-ui/core";
import getErrorCode from "../../../utils/getErrorCode";
import ErrorCodes from "../../../constants/ErrorCodes";

interface Props {
  data: DonutChartData;
  loading?: boolean;
  config?: Omit<PieSvgProps<DefaultRawDatum>, "data">;
  units?: Array<string>;
  error?: any;
}

const useStyles = makeStyles(() => ({
  root: {},
  errorMessage: {
    height: "100%",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
}));

function DonutChart({ data, config, loading, units, error }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const errorCode = getErrorCode(error);

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={32} color="secondary" />
      </div>
    );
  }

  if (errorCode === ErrorCodes.IncompleteData) {
    return (
      <ErrorMessage
        className={classes.errorMessage}
        title={t("errors.incompleteDataHeader")}
        message={t("errors.incompleteDataText")}
      />
    );
  }

  if (units?.length === 0) {
    return (
      <ErrorMessage
        className={classes.errorMessage}
        title={t("noCheckboxesSelectedTitle")}
        message={t("noCheckboxesSelectedMessage")}
      />
    );
  }

  if (!data?.data?.length) {
    return (
      <ErrorMessage
        className={classes.errorMessage}
        title={t("noResultsTitle")}
        message={t("noResultsMessage")}
      />
    );
  }

  return (
    <ResponsivePie
      data={data.data}
      margin={{ top: 20, right: 30, bottom: 20, left: 10 }}
      innerRadius={0.55}
      padAngle={2}
      cornerRadius={0}
      colors={{ scheme: "nivo" }}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      enableSliceLabels
      sliceLabelsSkipAngle={32}
      sliceLabelsTextColor="#000"
      fill={DONUT_DEFAULT_FILL}
      legends={[]}
      {...config}
    />
  );
}

export default React.memo(DonutChart);
