import Role from "../auth/Role";

const hasRealmRole = (keycloak: any, role: Role) => {
  return (
    keycloak?.hasRealmRole(role) ||
    // @ts-ignore
    keycloak?.idTokenParsed?.requested_role === role
  );
};

export default hasRealmRole;
