import React from "react";
import { CartesianGrid, Tooltip, XAxis } from "recharts";
import { TEXT_PRIMARY } from "../../../theme";
import propertyTooltipFormatter from "../formatter/propertyTooltipFormatter";

const contextComponents = (
  <>
    <CartesianGrid stroke="#c2c2c2" strokeDasharray="1 3" />
    <XAxis dataKey="name" scale="band" />
    <Tooltip
      itemStyle={{
        color: TEXT_PRIMARY,
      }}
      labelFormatter={() => ""}
      formatter={propertyTooltipFormatter}
    />
  </>
);

export default contextComponents;
