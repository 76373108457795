import { KeycloakTokenParsed } from "keycloak-js";
import { PropertyEvFormValues } from "./components/Forms/PhotovoltaicsConfigForm/PhotovoltaicsConfigForm";
import { PropertyInfoFormValues } from "./components/Forms/PropertyInfoForm/PropertyInfoForm";
import { PropertyPricesFormValues } from "./components/Forms/PropertyPricesForm/PropertyPricesForm";
import { PropertyOwnerFormValues } from "./components/Forms/PropertyOwnerForm/PropertyOwnerForm";
import { MainCounterFormValues } from "./components/Forms/MainCounterForm/MainCounterForm";

export enum ConsumptionPeriod {
  Day = "day",
  Week = "week",
  Month = "month",
  HalfYear = "halfyear",
  Year = "year",
}

export interface IdTokenParsed extends KeycloakTokenParsed {
  given_name?: string;
  family_name?: string;
  email?: string;
}

export type BarChartItem = {
  item: string;
  type: string;
  [key: string]: string | number;
};

export type BarChartData = {
  keys: Array<string>;
  data: Array<BarChartItem>;
  tooltipFormat?: (value: number) => string;
  axisLeft?: any;
};

export type LineChartValue = {
  x: string;
  y: number;
};

export type LineChartData = {
  id: string;
  data: Array<LineChartValue>;
};

export enum Unit {
  Energy = "kWh",
  Flow = "m3/h",
  Volume = "m3",
  Day = "Tag",
  Power = "kW",
  Month = "Monat",
}

export type ChartContainerState = {
  date: moment.Moment;
  period: ConsumptionPeriod;
};

export enum ValueKey {
  Value = "value",
  TotalPrice = "totalPrice",
}

export type PropertyFormValues = MainCounterFormValues & PropertyInfoFormValues &
  PropertyPricesFormValues &
  PropertyOwnerFormValues &
  PropertyEvFormValues;
