import React, { useState } from "react";
import { GraphQLError } from "graphql";
import MenuItem from "@material-ui/core/MenuItem";
import { Container, makeStyles, useTheme } from "@material-ui/core";
import { useLocation } from "react-router";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import Navigation from "../../components/Navigation";
import { Link } from "react-router-dom";
import Row from "../../components/Row";
import {
  DIVIDER_COLOR,
  NAV_BAR_HEIGHT,
  OWNER_PAGE_BG_COLOR,
} from "../../theme";
import AvatarDropdownMenu from "../../components/AvatarDropdownMenu";
import ErrorMessage from "../../components/ErrorMessage";
import EmptyState from "../../components/EmptyState";
import menuItems from "./menuItems";
import Header from "../../components/Header/Header";
import { useKeycloak } from "@react-keycloak/web";
import OwnerProfileDialog from "../../components/OwnerProfileDialog";

export interface Props {
  children: JSX.Element | JSX.Element[];
  sticky?: JSX.Element;
  headerPrimary?: JSX.Element | JSX.Element[];
  headerSecondary?: JSX.Element | JSX.Element[];
  footer?: JSX.Element | JSX.Element[];
  layoutType: LayoutType;
  loading?: boolean;
  emptyState?: boolean;
  error?: GraphQLError | null | unknown;
  displayBackButton?: boolean;
  onNavigateBack?: () => void;
  contentMarginTop?: boolean;
}

export interface MenuItemConfig {
  text: string;
  to: string;
  selected: string;
}

export type LayoutType = "admin" | "owner" | "tenant";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.default,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 2,
    marginTop: NAV_BAR_HEIGHT,
    pointerEvents: "auto",
    // minHeight: `calc(100vh - (${NAV_BAR_HEIGHT}px + 24px))`,
  },
  contentRoot: {
    width: "100%",
    height: "100%",
    backgroundColor: "#F7F8F9",
    borderRadius: (props: Props) => (props.footer ? 0 : "0px 0px 12px 12px"),
    overflow: "hidden",
    marginTop: (props: Props) => (props.contentMarginTop ? -NAV_BAR_HEIGHT : 0),
    // paddingBottom: NAV_BAR_HEIGHT,
    flex: 1,
  },
  headerPrimary: {
    width: "100%",
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 8,
    height: NAV_BAR_HEIGHT,
    borderRadius: "12px 12px 0px 0px",
    backgroundColor: "white",
    borderBottom: `1px solid ${DIVIDER_COLOR}`,
  },
  headerSecondary: {
    width: "100%",
    height: NAV_BAR_HEIGHT,
    backgroundColor: "white",
  },
  footer: {
    position: "sticky",
    bottom: 0,
  },
  navPlaceholder: {
    height: NAV_BAR_HEIGHT * 2,
  },
}));

function PageLayout(props: Props) {
  const {
    layoutType,
    emptyState,
    error,
    loading,
    footer,
    children,
    displayBackButton = false,
    onNavigateBack,
    sticky = <Header />,
  } = props;
  const classes = useStyles(props);
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const items = menuItems[layoutType];
  const { keycloak } = useKeycloak();
  const ownerId = keycloak?.subject;

  const [ownerProfileOpen, setOwnerProfileOpen] = useState<boolean>(false);

  const mobileMenu = items && (
    <Navigation.MobileNavigationMenu>
      {items?.map((item) => (
        <MenuItem
          component={Link}
          to={item.to}
          selected={pathname.includes(item.selected)}
          key={item.text}
        >
          {item.text}
        </MenuItem>
      ))}
    </Navigation.MobileNavigationMenu>
  );

  const menu = (
    <Row maxWidth justifyContent="center">
      {items?.map((menuItem) => {
        return (
          <Navigation.Button
            to={menuItem.to}
            selected={pathname.includes(menuItem.selected)}
            key={menuItem.text}
          >
            {menuItem.text}
          </Navigation.Button>
        );
      })}
    </Row>
  );

  const navigation = (
    <Navigation
      color={OWNER_PAGE_BG_COLOR}
      sticky={sticky}
      renderBackButton={displayBackButton}
      onBackClick={onNavigateBack}
    >
      <Navigation.Logo />
      {isMobile ? mobileMenu : menu}
      <AvatarDropdownMenu
        avatar="E"
        onProfileClick={() => setOwnerProfileOpen(true)}
      />
    </Navigation>
  );

  return (
    <div className={classes.root} data-cy="page-root">
      {navigation}
      <Container className={classes.container}>
        <div className={classes.navPlaceholder} />
        <div className={classes.contentRoot}>
          {children}
          {error && <ErrorMessage fullScreen />}
          {emptyState && !loading && !error && <EmptyState fullScreen />}
        </div>
        <div className={classes.footer}>
          <div>{footer}</div>
        </div>
      </Container>
      <OwnerProfileDialog
        open={ownerProfileOpen}
        onClose={() => setOwnerProfileOpen(false)}
        ownerId={ownerId}
      />
    </div>
  );
}

export default PageLayout;
