import React, { useEffect } from "react";
import { FormikProvider, useFormik } from "formik";
import { makeStyles } from "@material-ui/styles";
import useListPropertyOwners from "../../api/hooks/useListPropertyOwners";
import useSetPropertyOwner from "../../api/hooks/useSetPropertyOwner";
import { ns } from "../../utils/object/object.utils";
import {
  OwnerInfoFormFields,
  OwnerInfoFormValues,
} from "../Forms/OwnerInfoForm/OwnerInfoForm";
import {
  OwnerPropertiesFormFields,
  OwnerPropertiesFormValues,
} from "../Forms/OwnerPropertiesForm/OwnerPropertiesForm";
import { validateOwnerForm } from "../NewOwnerDialog/NewOwnerDialog";
import Dialog from "../Dialog";
import Box from "@material-ui/core/Box";
import OwnerForm from "../Forms/OwnerForm";
import ErrorAlert from "../ErrorAlert";
import { useTranslation } from "react-i18next";
import { OwnerPaymentDetailsFormValues } from "../Forms/OwnerPaymentDetailsForm/OwnerPaymentDetailsForm";
import QueryKeys from "../../api/QueryKeys";
import { queryClient } from "../../App";

const useStyles = makeStyles(() => ({
  errorAlert: {
    marginTop: 16,
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  ownerId?: string;
}

function OwnerProfileDialog({ ownerId, open, onClose }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const setPropertyOwner = useSetPropertyOwner();

  const { data } = useListPropertyOwners({ id: ownerId }, !!ownerId);
  const owner = ns(() => data?.propertyOwners[0], null);

  const formik = useFormik<
    OwnerInfoFormValues &
      OwnerPropertiesFormValues &
      OwnerPaymentDetailsFormValues
  >({
    initialValues: {
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      zip: "",
      city: "",
      email: "",
      bankAccountNumber: "",
      beneficiaryName: "",
      beneficiaryAddressLine1: "",
      beneficiaryAddressLine2: "",
      beneficiaryZip: "",
      beneficiaryCity: "",
      beneficiaryVAT: "",
      managedProperty: [],
    },
    onSubmit: () => {},
    validate: validateOwnerForm,
    validateOnChange: true,
  });

  const updateFormValues = () => {
    formik.setValues({
      firstName: owner?.firstName || "",
      lastName: owner?.lastName || "",
      addressLine1: owner?.address?.addressLineOne || "",
      addressLine2: owner?.address?.addressLineTwo || "",
      zip: owner?.address?.zip || "",
      city: owner?.address?.city || "",
      email: owner?.email || "",
      bankAccountNumber: owner?.bankAccountNumber || "",
      beneficiaryName: owner?.beneficiaryName || "",
      beneficiaryAddressLine1: owner?.beneficiaryAddress?.addressLineOne || "",
      beneficiaryAddressLine2: owner?.beneficiaryAddress?.addressLineTwo || "",
      beneficiaryZip: owner?.beneficiaryAddress?.zip || "",
      beneficiaryCity: owner?.beneficiaryAddress?.city || "",
      beneficiaryVAT: owner?.beneficiaryVAT || "",
      managedProperty: owner?.properties || null,
    });
  };

  useEffect(() => {
    if (owner) {
      updateFormValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner, open]);

  const handleClose = () => {
    setPropertyOwner.reset();
    formik.resetForm();
    onClose();
  };

  const handleSubmit = async () => {
    const {
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      zip,
      city,
      email,
      bankAccountNumber,
      managedProperty,
      beneficiaryName,
      beneficiaryAddressLine1,
      beneficiaryAddressLine2,
      beneficiaryZip,
      beneficiaryCity,
      beneficiaryVAT,
    } = formik.values;

    setPropertyOwner.mutate(
      {
        id: ownerId,
        firstName,
        lastName,
        address: {
          addressLineOne: addressLine1,
          addressLineTwo: addressLine2,
          city,
          zip,
        },
        email,
        bankAccountNumber,
        beneficiaryName,
        beneficiaryAddress: {
          addressLineOne: beneficiaryAddressLine1,
          addressLineTwo: beneficiaryAddressLine2,
          city: beneficiaryCity,
          zip: beneficiaryZip,
        },
        beneficiaryVAT,
        propertyIds: managedProperty?.map((property) => property?.id),
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([QueryKeys.LIST_PROPERTY_OWNERS]);
          handleClose();
        },
      }
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      onSubmit={handleSubmit}
      dialogHeader={t("myProfile")}
    >
      <FormikProvider value={formik}>
        <Box>
          <OwnerForm
            values={formik.values}
            onChange={formik.handleChange}
            onFieldChange={(fieldName, value) => {
              formik.setFieldValue(fieldName, value);
            }}
            propertiesList={[]}
            disabledOwnerInfoFields={new Set([OwnerInfoFormFields.Email])}
            disabledPropertiesFields={
              new Set([OwnerPropertiesFormFields.ManagedProperty])
            }
          />
          <ErrorAlert
            error={setPropertyOwner?.error}
            className={classes.errorAlert}
          />
        </Box>
      </FormikProvider>
    </Dialog>
  );
}

export default OwnerProfileDialog;
