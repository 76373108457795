import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { DIVIDER_COLOR, SUBHEADER_COLOR } from "../../theme";
import { AddressDto } from "../../api/types";
import Row from "../Row";

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    maxWidth: 375,
    alignSelf: "stretch",
  },
  header: {
    padding: "8px 16px",
    borderBottom: `2px solid ${DIVIDER_COLOR}`,
    "& svg": {
      color: SUBHEADER_COLOR,
    },
  },
  address: {
    "& > p": {
      fontSize: 16,
    },
  },
}));

interface Props {
  cardHeader?: string;
  address?: AddressDto;
}

function AddressCard({ cardHeader, address }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  if (!address) {
    return null;
  }

  return (
    <Card className={classes.card}>
      <Row className={classes.header}>
        <HomeOutlinedIcon />
        <Typography variant="h5">{cardHeader || t("address")}</Typography>
      </Row>
      <CardContent>
        <div className={classes.address}>
          <Typography>{address?.addressLineOne}</Typography>
          <Typography>{address?.addressLineTwo}</Typography>
          <Typography>{`${address?.zip}, ${address?.city}`}</Typography>
        </div>
      </CardContent>
    </Card>
  );
}

export default AddressCard;
