import moment from "moment";
import { InvoiceDto } from "../api/types";

function getInvoiceDisplayName(
  invoice?: Partial<InvoiceDto> | null,
  excludeName?: boolean
) {
  const parts = [];

  if (invoice?.fromDate && invoice?.toDate) {
    const from = moment(invoice?.fromDate).format("DD/MM/YYYY");
    const to = moment(invoice?.toDate).format("DD/MM/YYYY");
    parts.push(`${from} - ${to}`);
  }

  if (invoice?.tenant?.firstName && invoice?.tenant?.lastName && !excludeName) {
    const firstNameAbrv = invoice?.tenant?.firstName
      ?.substr(0, 1)
      ?.concat(". ");
    parts.push(`${firstNameAbrv ?? ""} ${invoice?.tenant?.lastName}`);
  }

  return parts.filter((p) => Boolean(p)).join(", ");
}

export default getInvoiceDisplayName;
