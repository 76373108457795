import { gql } from "graphql-request";
import { useMutation } from "react-query";
import QueryKeys from "../QueryKeys";
import { gqlClient } from "../../App";
import { SetInvoiceInput } from "../types";

function useSetInvoice() {
  return useMutation(QueryKeys.SET_INVOICE, async (input: SetInvoiceInput) => {
    return await gqlClient.request(
      gql`
        mutation SetInvoice($input: SetInvoiceInput!) {
          setInvoice(input: $input) {
            id
          }
        }
      `,
      {
        input,
        operationKey: QueryKeys.SET_INVOICE,
      }
    );
  });
}

export default useSetInvoice;
