import React, { ChangeEvent } from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikValues } from "formik";
import { Grid, TextFieldProps } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  ApartmentForm_PropertyFragment,
  ListPropertiesQuery,
} from "../../../api/types";
import FormCard from "../FormCard";
import Autocomplete from "../../Autocomplete";
import Input from "../../Input";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

export enum ApartmentInfoFormFields {
  Floor = "floor",
  ApartmentNumber = "apartmentNumber",
  Property = "property",
  SelectedApartment = "selectedApartment",
  AreaSize = "areaSize",
}

export interface ApartmentInfoFormValues extends FormikValues {
  selectedApartment: { name: string; id: string } | null;
  floor: string;
  apartmentNumber: string;
  property: ApartmentForm_PropertyFragment | null;
  areaSize: "";
}

interface Props {
  values: ApartmentInfoFormValues;
  onChange: (e: React.ChangeEvent<any>) => void;
  onFieldChange: (fieldName: string, value: any) => void;
  disabledFields?: Set<ApartmentInfoFormFields>;
  propertiesList: ListPropertiesQuery["properties"];
  apartmentsList: any;
  placeholders?: {
    [key: string]: number | string;
  };
  isEditable?: boolean;
}

const useStyles = makeStyles(() => ({
  grid: { maxWidth: 368 },
  floorInput: {
    width: 145,
  },
}));

function ApartmentInfoForm({
  onChange,
  values,
  propertiesList,
  onFieldChange,
  placeholders,
  apartmentsList,
  isEditable = true,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const getTextFieldProps = (
    fieldName: ApartmentInfoFormFields
  ): TextFieldProps => ({
    id: fieldName,
    name: fieldName,
    onChange,
    color: "secondary",
    disabled: !isEditable,
    placeholder: placeholders?.[fieldName]?.toString(),
    InputLabelProps: { shrink: true },
    fullWidth: true,
  });

  const {
    selectedApartment,
    floor,
    apartmentNumber,
    property,
    areaSize,
  } = values;

  return (
    <FormCard
      header={t("apartment")}
      headerIcon={<HomeOutlinedIcon fontSize="default" />}
    >
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12}>
          <Input
            required
            value={apartmentNumber}
            label={t("apartmentName")}
            data-cy="af-number-input"
            gutterBottom={0}
            InputProps={{
              ...getTextFieldProps(ApartmentInfoFormFields.ApartmentNumber),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id={ApartmentInfoFormFields.Property}
            value={property}
            fieldName={ApartmentInfoFormFields.Property}
            options={propertiesList}
            label={t("property")}
            required
            cypressId="af-property-input"
            onChange={(e: ChangeEvent<any>, value: any) => {
              onFieldChange(ApartmentInfoFormFields.Property, value);
              onFieldChange(ApartmentInfoFormFields.SelectedApartment, "");
            }}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id={ApartmentInfoFormFields.SelectedApartment}
            value={selectedApartment}
            fieldName={ApartmentInfoFormFields.SelectedApartment}
            options={apartmentsList}
            label={t("loxoneApartmentId")}
            required
            cypressId="af-name-input"
            onChange={(e: ChangeEvent<any>, value: any) => {
              onFieldChange(ApartmentInfoFormFields.SelectedApartment, value);
            }}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            value={floor}
            label={t("floor")}
            data-cy="af-floor-input"
            gutterBottom={0}
            InputProps={{
              ...getTextFieldProps(ApartmentInfoFormFields.Floor),
            }}
            className={classes.floorInput}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            value={areaSize}
            label={t("apartmentAreaSize")}
            data-cy="af-areaSize-input"
            gutterBottom={0}
            InputProps={{
              ...getTextFieldProps(ApartmentInfoFormFields.AreaSize),
            }}
            className={classes.floorInput}
            type="number"
            inputProps={{ min: 0 }}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
}

export default ApartmentInfoForm;
