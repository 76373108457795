import React from "react";
import {
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { useTranslation } from "react-i18next";
import getInvoiceDisplayName from "../../utils/getInvoiceDisplayName";
import { InvoiceDto } from "../../api/types";
import getDisplayName from "../../utils/getDisplayName";
import Row from "../Row";
import DownloadInvoiceButton from "../DownloadInvoiceButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { SUBHEADER_COLOR } from "../../theme";
import hasRealmRole from "../../utils/hasRealmRole";
import keycloak from "../../auth/keycloak";
import Role from "../../auth/Role";
import moment from "moment";

const useStyles = makeStyles(() => ({
  paper: { borderRadius: 0, boxShadow: "none" },
  tableHeadRow: {
    height: 48,
    "& > th": {
      paddingTop: 0,
      paddingBottom: 0,
      textTransform: "capitalize",
      color: SUBHEADER_COLOR,
    },
  },
  tableBodyRow: {
    height: 54,
    "& > td": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));

interface Props {
  invoices: Array<any>;
  onDeleteInvoiceClick: (invoiceId: string) => void;
  onEditInvoiceClick?: (invoiceId: string) => void;
}

function InvoicesTable({
  invoices,
  onDeleteInvoiceClick,
  onEditInvoiceClick,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const isTenant = hasRealmRole(keycloak, Role.Tenant);

  return (
    <TableContainer component={Paper} className={classes.paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell>{t("period")}</TableCell>
            <TableCell>{t("tenant")}</TableCell>
            <TableCell>{t("dateSent")}</TableCell>
            <TableCell>
              <div />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices?.map((invoice) => (
            <TableRow key={invoice?.id} className={classes.tableBodyRow}>
              <TableCell>
                <Row>
                  {getInvoiceDisplayName(invoice as InvoiceDto, true)}
                  {invoice.messagingStartTime ? (
                    <Tooltip
                      title={t("scheduledInvoiceDescription") || ""}
                      style={{ marginLeft: 10 }}
                    >
                      <SendIcon fontSize="small" color="secondary" />
                    </Tooltip>
                  ) : null}
                </Row>
              </TableCell>
              <TableCell>{getDisplayName(invoice?.tenant)}</TableCell>
              <TableCell>
                {invoice?.dateSent
                  ? moment(invoice.dateSent).format("YYYY-MM-DD")
                  : "-"}
              </TableCell>
              <TableCell align="right">
                <Row justifyContent="flex-end">
                  {invoice.messagingStartTime && onEditInvoiceClick ? (
                    <IconButton
                      size="small"
                      onClick={() => onEditInvoiceClick(invoice?.id)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <DownloadInvoiceButton invoiceId={invoice?.id} />
                  )}
                  {!isTenant && <Divider orientation="vertical" flexItem />}
                  {!isTenant && (
                    <IconButton
                      size="small"
                      onClick={() => onDeleteInvoiceClick(invoice?.id)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </Row>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default InvoicesTable;
