import { LayoutType, MenuItemConfig } from "./PageLayout";
import routes from "../../constants/routes";
import { t } from "../../i18n";

const menuItems: Record<LayoutType, Array<MenuItemConfig>> = {
  admin: [
    {
      text: t("loxoneMiniserver"),
      to: routes.admin.loxoneMiniserver,
      selected: routes.admin.loxoneMiniserver,
    },
    {
      text: t("properties"),
      to: routes.admin.properties,
      selected: routes.admin.properties,
    },
    {
      text: t("owner"),
      to: routes.admin.owner,
      selected: routes.admin.owner,
    },
  ],
  owner: [
    {
      text: t("property"),
      to: routes.owner.properties,
      selected: routes.owner.properties,
    },
    {
      text: t("tenant"),
      to: routes.owner.tenant,
      selected: routes.owner.tenant,
    },
  ],
  tenant: [],
};

export default menuItems;
