import React from "react";
import {
  IconButton,
  makeStyles,
  Paper,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PropertyOwnerDto, TenantDto } from "../../api/types";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PersonIcon from "@material-ui/icons/Person";
import getDisplayName from "../../utils/getDisplayName";
import { SUBHEADER_COLOR } from "../../theme";
import LockIcon from "@material-ui/icons/Lock";
import Row from "../Row";

const useStyles = makeStyles(() => ({
  root: {},
  paper: { borderRadius: 0, boxShadow: "none" },
  tableHeadRow: {
    height: 48,
    "& > th": {
      paddingTop: 0,
      paddingBottom: 0,
      textTransform: "capitalize",
      color: SUBHEADER_COLOR,
    },
    "& > th:not(:last-child)": {
      minWidth: 140,
    },
  },
  tableBodyRow: {
    height: 54,
    "& > td": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  personIcon: {
    marginRight: 12,
    verticalAlign: "middle",
  },
  lockIcon: {
    marginLeft: 12,
    verticalAlign: "middle",
    color: SUBHEADER_COLOR,
  },
  nameTableCell: {
    display: "flex",
    flexDirection: "row",
  },
}));

interface Props {
  rows: Array<TenantDto | PropertyOwnerDto>;
  onOpenDetailClick: (tenant: TenantDto | PropertyOwnerDto) => void;
  loading?: boolean;
}

function instanceOfTenantDto(row: any): row is TenantDto {
  return true;
}

function Table({ rows, onOpenDetailClick }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} className={classes.paper}>
      <MUITable aria-label="table">
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell>{t("name")}</TableCell>
            <TableCell>{t("email")}</TableCell>
            <TableCell>{`${t("addressLine")} 1`}</TableCell>
            <TableCell>{`${t("addressLine")} 2`}</TableCell>
            <TableCell>{t("zip")}</TableCell>
            <TableCell>{t("city")}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow key={row.id} className={classes.tableBodyRow}>
              <TableCell>
                <Row>
                  <PersonIcon
                    fontSize="small"
                    color="secondary"
                    className={classes.personIcon}
                  />
                  <Typography>{getDisplayName(row)}</Typography>
                  {instanceOfTenantDto(row) && row?.isEditable === false && (
                    <LockIcon
                      fontSize="small"
                      color="secondary"
                      className={classes.lockIcon}
                    />
                  )}
                </Row>
              </TableCell>
              <TableCell>{row?.email}</TableCell>
              <TableCell>{row?.address?.addressLineOne}</TableCell>
              <TableCell>{row?.address?.addressLineTwo}</TableCell>
              <TableCell>{row?.address?.zip}</TableCell>
              <TableCell>{row?.address?.city}</TableCell>
              <TableCell align="right">
                <IconButton size="small" onClick={() => onOpenDetailClick(row)}>
                  <ChevronRightIcon color="secondary" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
}

export default Table;
