import React, { useMemo } from "react";
import {
  ADMIN_PAGE_BG_COLOR,
  OWNER_PAGE_BG_COLOR,
  TENANT_PAGE_BG_COLOR,
} from "../../../theme";
import Header from "../../../components/_deprecated/PageHeader/PageHeader";
import { CircularProgress, Fab, IconButton } from "@material-ui/core";
import IconAdd from "@material-ui/icons/Add";
import SearchInput from "../../../components/SearchInput";
import moment from "moment";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../../constants/routes";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/styles";

interface Props {
  title: string;
  subtitle?: string;
  onAddClick?: (event?: any) => void;
  displayBackButton?: boolean;
  hideDate?: boolean;
  hideSearchInput?: boolean;
  loading?: boolean;
}

const useStyles = makeStyles(() => ({
  progress: {
    color: "white",
  },
}));

function HeaderLayout({
  displayBackButton = false,
  title,
  subtitle,
  onAddClick,
  hideDate = false,
  hideSearchInput = false,
  loading,
}: Props) {
  const { pathname } = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const backgroundColor = useMemo(() => {
    if (pathname.includes(routes.adminRoot)) {
      return ADMIN_PAGE_BG_COLOR;
    }
    if (pathname.includes(routes.tenantRoot)) {
      return TENANT_PAGE_BG_COLOR;
    }
    return OWNER_PAGE_BG_COLOR;
  }, [pathname]);

  return (
    <Header color={backgroundColor}>
      <Header.PrimaryRow>
        {displayBackButton && (
          <IconButton
            onClick={() => history.goBack()}
            size="small"
            color="primary"
          >
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
        )}
        <Header.Title>{title}</Header.Title>
        {!hideDate && !isMobile && (
          <Header.Subtitle>
            {subtitle ? "" : moment().format("dddd, DD.MM.YYYY")}
          </Header.Subtitle>
        )}
        {loading && <CircularProgress size={24} className={classes.progress} />}
      </Header.PrimaryRow>
      <Header.SecondaryRow>
        {onAddClick && (
          <Fab
            onClick={onAddClick}
            size="small"
            color="primary"
            aria-label="add"
            data-cy="header-add-button"
          >
            <IconAdd fontSize="small" />
          </Fab>
        )}
        {!hideSearchInput && <SearchInput />}
      </Header.SecondaryRow>
    </Header>
  );
}

export default HeaderLayout;
