import React, { useEffect, useState } from "react";
import { CardRow } from "../../../components/Card";
import { Box } from "@material-ui/core";
import PageLayout from "../../../layouts/PageLayout/PageLayout";
import routes from "../../../constants/routes";
import { FormikProvider, useFormik } from "formik";
import ErrorAlert from "../../../components/ErrorAlert";
import SearchHeader from "../../../components/SearchHeader";
import OwnerSelector from "../../../fragments/OwnerSelector";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { makeStyles } from "@material-ui/styles";
import NewOwnerDialog from "../../../components/NewOwnerDialog";
import useDeletePropertyOwner from "../../../api/hooks/useDeletePropertyOwner";
import useListPropertyOwners from "../../../api/hooks/useListPropertyOwners";
import useSetPropertyOwner from "../../../api/hooks/useSetPropertyOwner";
import useListProperties from "../../../api/hooks/useListProperties";
import { ns } from "../../../utils/object/object.utils";
import { OwnerInfoFormValues } from "../../../components/Forms/OwnerInfoForm/OwnerInfoForm";
import { OwnerPropertiesFormValues } from "../../../components/Forms/OwnerPropertiesForm/OwnerPropertiesForm";
import QueryKeys from "../../../api/QueryKeys";
import OwnerForm from "../../../components/Forms/OwnerForm";
import { validateOwnerForm } from "../../../components/NewOwnerDialog/NewOwnerDialog";
import Button from "../../../components/Button";
import { OwnerPaymentDetailsFormValues } from "../../../components/Forms/OwnerPaymentDetailsForm/OwnerPaymentDetailsForm";

const useStyles = makeStyles(() => ({
  errorAlert: {
    marginTop: 16,
  },
}));

function OwnerDetailPage() {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { ownerId } = useParams<{ ownerId: string }>();
  const setPropertyOwner = useSetPropertyOwner();
  const deletePropertyOwner = useDeletePropertyOwner();

  const { data } = useListPropertyOwners({ id: ownerId }, !!ownerId);
  const { data: propertiesList } = useListProperties();

  const [newOwnerDialogOpen, setNewOwnerDialogOpen] = useState<boolean>(false);

  const owner = ns(() => data?.propertyOwners[0], null);

  const formik = useFormik<
    OwnerInfoFormValues &
      OwnerPropertiesFormValues &
      OwnerPaymentDetailsFormValues
  >({
    initialValues: {
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      zip: "",
      city: "",
      email: "",
      bankAccountNumber: "",
      beneficiaryName: "",
      beneficiaryAddressLine1: "",
      beneficiaryAddressLine2: "",
      beneficiaryZip: "",
      beneficiaryCity: "",
      beneficiaryVAT: "",
      managedProperty: [],
    },
    onSubmit: () => {},
    validate: validateOwnerForm,
    validateOnChange: true,
  });

  const updateFormValues = () => {
    formik.setValues({
      firstName: owner?.firstName || "",
      lastName: owner?.lastName || "",
      addressLine1: owner?.address?.addressLineOne || "",
      addressLine2: owner?.address?.addressLineTwo || "",
      zip: owner?.address?.zip || "",
      city: owner?.address?.city || "",
      email: owner?.email || "",
      bankAccountNumber: owner?.bankAccountNumber || "",
      beneficiaryName: owner?.beneficiaryName || "",
      beneficiaryAddressLine1: owner?.beneficiaryAddress?.addressLineOne || "",
      beneficiaryAddressLine2: owner?.beneficiaryAddress?.addressLineTwo || "",
      beneficiaryZip: owner?.beneficiaryAddress?.zip || "",
      beneficiaryCity: owner?.beneficiaryAddress?.city || "",
      beneficiaryVAT: owner?.beneficiaryVAT || "",
      managedProperty: owner?.properties || null,
    });
  };

  useEffect(() => {
    if (owner) {
      updateFormValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner]);

  const handleSubmit = async () => {
    const {
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      zip,
      city,
      email,
      bankAccountNumber,
      managedProperty,
      beneficiaryName,
      beneficiaryAddressLine1,
      beneficiaryAddressLine2,
      beneficiaryCity,
      beneficiaryZip,
      beneficiaryVAT,
    } = formik.values;

    setPropertyOwner.mutate(
      {
        id: ownerId,
        firstName,
        lastName,
        address: {
          addressLineOne: addressLine1,
          addressLineTwo: addressLine2,
          city,
          zip,
        },
        email,
        bankAccountNumber,
        beneficiaryName,
        beneficiaryAddress: {
          addressLineOne: beneficiaryAddressLine1,
          addressLineTwo: beneficiaryAddressLine2,
          city: beneficiaryCity,
          zip: beneficiaryZip,
        },
        beneficiaryVAT,
        propertyIds: managedProperty?.map((property) => property?.id),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKeys.LIST_PROPERTY_OWNERS]);
          formik.resetForm();
          history.push(routes.admin.owner);
        },
      }
    );
  };

  const handleDelete = () => {
    deletePropertyOwner.mutate(ownerId, {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.LIST_PROPERTY_OWNERS]);
        formik.resetForm();
        history.push(routes.admin.owner);
      },
    });
  };

  const header = (
    <SearchHeader
      onButtonNewClick={() => setNewOwnerDialogOpen(true)}
      selector={
        <OwnerSelector
          onSelect={(owner) =>
            history.push(routes.admin.getOwnerDetail(owner?.id || ""))
          }
        />
      }
    />
  );

  const isLoading = setPropertyOwner.isLoading || deletePropertyOwner.isLoading;

  const footer = (
    <CardRow footer justifyContent="space-between">
      <Button
        loading={deletePropertyOwner.isLoading}
        variant="outlined"
        color="default"
        onClick={handleDelete}
        disabled={isLoading}
      >
        {t("delete")}
      </Button>
      <Button
        loading={setPropertyOwner.isLoading}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isLoading || !formik.isValid}
      >
        {t("save")}
      </Button>
    </CardRow>
  );

  return (
    <PageLayout
      displayBackButton
      onNavigateBack={() => history.push(routes.admin.owner)}
      loading={false}
      error={false}
      layoutType="admin"
      sticky={header}
      footer={footer}
      contentMarginTop
    >
      <FormikProvider value={formik}>
        <Box padding={3}>
          <OwnerForm
            values={formik.values}
            onChange={formik.handleChange}
            onFieldChange={(fieldName, value) => {
              formik.setFieldValue(fieldName, value);
            }}
            propertiesList={propertiesList?.properties || []}
          />
          <ErrorAlert
            error={deletePropertyOwner?.error || setPropertyOwner?.error}
            className={classes.errorAlert}
          />
        </Box>
      </FormikProvider>
      <NewOwnerDialog
        open={newOwnerDialogOpen}
        onClose={() => setNewOwnerDialogOpen(false)}
      />
    </PageLayout>
  );
}

export default OwnerDetailPage;
