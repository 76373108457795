import { gql } from "graphql-request";
import { useMutation } from "react-query";
import QueryKeys from "../QueryKeys";
import { gqlClient } from "../../App";
import { SetPropertyInput } from "../types";

function useSetProperty() {
  return useMutation(
    QueryKeys.SET_PROPERTY,
    async (input: SetPropertyInput) => {
      return await gqlClient.request(
        gql`
          mutation SetProperty($input: SetPropertyInput!) {
            setProperty(input: $input) {
              id
            }
          }
        `,
        {
          input,
          operationName: QueryKeys.SET_PROPERTY,
        }
      );
    }
  );
}

export default useSetProperty;
