import { gql } from "graphql-request";
import { useMutation } from "react-query";
import QueryKeys from "../QueryKeys";
import { gqlClient } from "../../App";

function useDeleteProperty() {
  return useMutation(QueryKeys.DELETE_PROPERTY, async (input: String) => {
    return await gqlClient.request(
      gql`
        mutation DeleteProperty($input: String!) {
          deleteProperty(id: $input)
        }
      `,
      {
        input,
        operationName: QueryKeys.DELETE_PROPERTY,
      }
    );
  });
}

export default useDeleteProperty;
