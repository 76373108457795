import React from "react";
import TenantPageLayout from "../../../../layouts/_deprecated/TenantPageLayout";
import HeaderLayout from "../../../../layouts/_deprecated/HeaderLayout/HeaderLayout";
import { useTranslation } from "react-i18next";

function ConsumptionPage() {
  const { t } = useTranslation();

  return (
    <TenantPageLayout>
      <HeaderLayout title={t("consumption")} />
    </TenantPageLayout>
  );
}

export default ConsumptionPage;
