import create from "zustand";
import { ConsumptionType } from "../api/types";
import { ConsumptionPeriod } from "../types";
import moment from "moment";
import immerMiddleware from "./middleware/immerMiddleware";
import { getNextDate } from "../utils/date/date.utils";

interface State {
  consumptionTypes: Set<ConsumptionType>;
  consumptionPeriod: ConsumptionPeriod;
  date: moment.Moment;
  showCosts: boolean;
  setDate: (moment: moment.MomentInput) => void;
  setConsumptionTypes: (types: Set<ConsumptionType>) => void;
  setConsumptionPeriod: (period: ConsumptionPeriod) => void;
  setShowCosts: (showCosts: boolean) => void;
  setPrevDate: () => void;
  setNextDate: () => void;
}

const consumptionTypesList = [
  ConsumptionType.WaterWarmVolume,
  ConsumptionType.WaterColdVolume,
  ConsumptionType.ElectricityL,
  ConsumptionType.ElectricityH,
  ConsumptionType.ElectricityPv,
  ConsumptionType.HeatingEnergy,
  ConsumptionType.Charging,
  ConsumptionType.MaximumPower,
];

const useFilterStore = create<State>(
  immerMiddleware((set, get) => ({
    consumptionTypes: new Set<ConsumptionType>(consumptionTypesList),

    consumptionPeriod: ConsumptionPeriod.Week,

    date: moment().startOf("isoWeek"),

    showCosts: true,

    setDate: (m: moment.MomentInput) =>
      set((state) => {
        state.date = moment(m);
      }),

    setConsumptionTypes: (types: Set<ConsumptionType>) =>
      set((state) => {
        state.consumptionTypes = types;
      }),

    setConsumptionPeriod: (period: ConsumptionPeriod) =>
      set((state) => {
        state.consumptionPeriod = period;
      }),

    setShowCosts: (showCosts: boolean) =>
      set((state) => {
        state.showCosts = showCosts;
      }),

    setPrevDate: () =>
      set((state) => {
        state.date = getNextDate(get().date, get().consumptionPeriod, "prev");
      }),

    setNextDate: () =>
      set((state) => {
        state.date = getNextDate(get().date, get().consumptionPeriod, "next");
      }),
  }))
);

export const fullFilterState = (state: State) => state;

export default useFilterStore;
