import React, { CSSProperties } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

interface Props {
  children: JSX.Element | JSX.Element[] | React.ReactNode;

  // Vertical space between children
  spacing?: number;

  // Wrapper element to wrap children
  wrapper?: React.ElementType;

  // Classes to be applied to a root container
  className?: string;

  // Flex align-items
  alignItems?: string;

  // Flex justify-content
  justifyContent?: string;

  style?: CSSProperties;

  onClick?: (e: any) => void;

  onDoubleClick?: () => void;

  width?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: (props: Props) => props.width || "unset",
    alignItems: (props: Props) => props.alignItems || "flex-start",
    justifyContent: (props: Props) => props.justifyContent || "flex-start",
    "& > *": {
      marginBottom: (props: Props) =>
        props.spacing === undefined ? 16 : props.spacing,
    },
    "& :last-child": {
      marginBottom: 0,
    },
  },
}));

function List(props: Props) {
  const { children, wrapper, className, style, onClick, onDoubleClick } = props;
  const classes = useStyles(props);
  const Wrapper = wrapper || "div";

  return (
    <Wrapper
      onDoubleClick={onDoubleClick}
      onClick={onClick}
      className={clsx(classes.root, className)}
      style={style}
    >
      {children}
    </Wrapper>
  );
}

export default List;
