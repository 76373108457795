import React from "react";
import { InputAdornment, InputBase, InputBaseProps } from "@material-ui/core";
import IconSearch from "@material-ui/icons/SearchOutlined";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { BORDER_COLOR, TEXTFIELD_BACKGROUND_COLOR } from "../../theme";

const useStyles = makeStyles(() => ({
  root: {
    border: "none",
    padding: 0,
  },
  inputRoot: {
    border: `1px solid ${BORDER_COLOR}`,
    borderRadius: 4,
    backgroundColor: TEXTFIELD_BACKGROUND_COLOR,
    padding: 8,
  },
  searchIcon: {
    margin: 0,
  },
}));

interface Props extends InputBaseProps {
  gutterBottom?: number;
}

function SearchInput({ gutterBottom = 0, ...rest }: Props) {
  const { t } = useTranslation("common");
  const classes = useStyles();

  return (
    <InputBase
      endAdornment={
        <InputAdornment
          component="div"
          position="end"
          className={classes.searchIcon}
        >
          <IconSearch color="action" component="svg" />
        </InputAdornment>
      }
      classes={{
        input: classes.root,
        root: classes.inputRoot,
      }}
      placeholder={t("search")}
      {...rest}
    />
  );
}

export default SearchInput;
