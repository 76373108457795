import { IdTokenParsed } from "../types";

export default function getUserCredentials(idTokenParsed?: IdTokenParsed) {
  if (!idTokenParsed) {
    return "";
  }

  const { given_name, family_name, email } = idTokenParsed;

  if (typeof given_name === "string") {
    return given_name.charAt(0);
  }

  if (typeof family_name === "string") {
    return family_name.charAt(0);
  }

  if (typeof email === "string") {
    return email.charAt(0);
  }

  return "";
}
