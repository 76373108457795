import React from "react";
import Icon from "../components/Icon";
import Home from "@material-ui/icons/HomeOutlined";
import ViewAgendaOutlinedIcon from "@material-ui/icons/ViewAgendaOutlined";
import { BUTTON_PRIMARY, TEXT_SECONDARY } from "../theme";

export const HomeIconPrimary = (
  <Icon
    width={32}
    height={32}
    icon={<Home htmlColor="white" />}
    backgroundColor={BUTTON_PRIMARY}
  />
);

export const HomeIconWhite = (
  <Icon
    width={32}
    height={32}
    icon={<Home htmlColor={TEXT_SECONDARY} />}
    backgroundColor="transparent"
  />
);

export const ApartmentIconPrimary = (
  <Icon
    width={32}
    height={32}
    icon={<ViewAgendaOutlinedIcon htmlColor="white" />}
    backgroundColor={BUTTON_PRIMARY}
  />
);

export const ApartmentIconWhite = (
  <Icon
    width={32}
    height={32}
    icon={<ViewAgendaOutlinedIcon htmlColor={TEXT_SECONDARY} />}
    backgroundColor="transparent"
  />
);
