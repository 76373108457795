import React, { ChangeEvent } from "react";
import { Autocomplete as MUIAutocomplete } from "@material-ui/lab";
import Chip from "@material-ui/core/Chip";
import Input from "../Input";
import { makeStyles } from "@material-ui/styles";

interface AutocompleteOption {
  id: string;
  name: string;
  object?: any;
}

interface Props {
  fieldName?: string;
  id?: string;
  options?: any;
  onChange?: (
    e: ChangeEvent<any>,
    value: AutocompleteOption[] | string
  ) => void;
  label?: string;
  value?: any;
  required?: boolean;
  optionLabel?: (option: any) => string;
  multiple?: boolean;
  cypressId?: string;
  disabled?: boolean;
  [key: string]: any;
  filterSelectedOptions?: boolean;
}

const useStyles = makeStyles(() => ({
  chip: {
    height: "auto",
    margin: "4px 4px 4px 0",
  },
}));

function Autocomplete({
  onChange,
  value,
  label,
  id,
  fieldName,
  className,
  options = [],
  required,
  optionLabel,
  multiple,
  cypressId,
  disabled,
  filterSelectedOptions = true,
}: Props) {
  const defaultProps = {
    options: options,
    getOptionLabel: optionLabel
      ? optionLabel
      : (option: any) => option?.name ?? "",
    getOptionSelected: (option: any, selectedOption: any) =>
      option.id === selectedOption?.id || option.name === selectedOption?.name,
  };
  const classes = useStyles();

  return (
    <MUIAutocomplete
      {...defaultProps}
      id={id}
      multiple={multiple}
      data-cy={cypressId}
      autoComplete
      includeInputInList
      onChange={onChange}
      value={value}
      filterSelectedOptions={filterSelectedOptions}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option?.name}
            {...getTagProps({ index })}
            className={classes.chip}
          />
        ))
      }
      disabled={disabled}
      renderInput={(params: any) => (
        <Input
          {...params}
          name={fieldName}
          label={label}
          margin="normal"
          color="secondary"
          className={className}
          required={required}
          gutterBottom={0}
        />
      )}
    />
  );
}

export default Autocomplete;
