import { gql } from "graphql-request/dist";

const INVOICE_NAME_FIELDS = gql`
  fragment InvoiceNameFields on InvoiceDto {
    id
    fromDate
    toDate
    date
    nr
    dateSent
    messagingStartTime
    intervalMonths
    tenant {
      firstName
      lastName
    }
  }
`;

export default INVOICE_NAME_FIELDS;
