import React, { useState } from "react";
import Section from "../../Section";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BarChart from "../BarChart";
import getBarChartData from "../../../utils/chart/_deprecated/getBarChartData";
import {
  ConsumptionType,
  PropertyConsumptionFragment,
} from "../../../api/types";
import { gql } from "graphql-request";
import { ConsumptionPeriod } from "../../../types";
import ChartContainer from "../ChartContainer";
import ConsumptionLoader, {
  ConsumptionRenderProps,
} from "../ConsumptionLoader/ConsumptionLoader";
import { RenderProps } from "../ChartContainer/ChartContainer";

interface Props {
  property?: PropertyConsumptionFragment | null;
  selectedPeriod?: ConsumptionPeriod;
  onPeriodChange?: (period: ConsumptionPeriod) => void;
  onRenderPropsChange?: (renderProps: RenderProps) => void;
  onConsumptionRenderPropsChange?: (props: ConsumptionRenderProps) => void;
}

function PropertyConsumption({
  property,
  onRenderPropsChange,
  onConsumptionRenderPropsChange,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [options, setOptions] = useState<Array<ConsumptionType> | undefined>();

  return (
    <Section borderBottom={isMobile}>
      <ChartContainer
        height="330px"
        storageId={property?.id}
        SectionProps={{ borderBottom: false }}
        options={options}
        onRenderPropsChange={onRenderPropsChange}
      >
        {({ from, to, valueKey, types, units }) => (
          <ConsumptionLoader
            input={{
              from: from.toISOString(),
              to: to.toISOString(),
              propertyId: property?.id,
            }}
            types={types}
            enabled={Boolean(property?.id)}
            setOptions={setOptions}
            onRenderPropsChange={onConsumptionRenderPropsChange}
          >
            {({ consumption, loading, error }) => (
              <BarChart
                loading={loading}
                config={{
                  ...getBarChartData(consumption, valueKey),
                }}
                units={units}
                error={error}
              />
            )}
          </ConsumptionLoader>
        )}
      </ChartContainer>
    </Section>
  );
}

PropertyConsumption.fragments = {
  property: gql`
    fragment PropertyConsumption on PropertyDto {
      id
    }
  `,
};

export default PropertyConsumption;
