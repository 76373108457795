import _ from "lodash";
import { ChartItemFieldsFragment } from "../../api/types";
import { BarChartData, BarChartItem, ConsumptionPeriod } from "../../types";
import moment from "moment";
import getLabel from "./getLabel";
import formatCurrency from "./formatCurrency";

function formatKey(timestamp: moment.MomentInput, period?: ConsumptionPeriod) {
  const date = moment(timestamp);

  switch (period) {
    case ConsumptionPeriod.Day:
      return date.format("HH:mm");
    case ConsumptionPeriod.Week:
      return date.format("ddd");
    case ConsumptionPeriod.Month:
      return date.format("DD.MM");
    case ConsumptionPeriod.HalfYear:
      return date.format("MMMM");
    case ConsumptionPeriod.Year:
      return date.format("MMMM");
  }

  return date.format("DD.MM HH:mm");
}

function getHistoricalBarChartData(
  items?: Array<ChartItemFieldsFragment | null>,
  period?: ConsumptionPeriod,
  valueKey?: "value" | "totalPrice",
  unit?: string
): BarChartData {
  if (!items || !items.length) {
    return {
      keys: [],
      data: [],
    };
  }
  const renderPrice = valueKey === "totalPrice";
  const dataMap: any = {};

  const createMap: any = () => {
    for (const item of items) {
      if (!item) {
        continue;
      }

      const key = formatKey(item?.timestamp, period);
      const currentItem = dataMap[key] || {};
      const itemType = getLabel(item.itemType);

      dataMap[key] = {
        ...currentItem,
        item: key,
        itemType,
        type: itemType,
        [`${itemType}`]:
          // @ts-ignore
          Math.round((item[valueKey || "totalPrice"] + Number.EPSILON) * 100) /
          100,
        [`${itemType}Unit`]: item.unit,
      };
    }
  };

  createMap();

  const data: Array<BarChartItem> = _.flatMap(dataMap);

  const keys = _.chain(items)
    .groupBy("itemType")
    .keys()
    .value()
    .map((k) => getLabel(k));

  return {
    keys,
    data,
    axisLeft: {
      format: renderPrice ? formatCurrency : (v: any) => `${v}`,
    },
  };
}

export default getHistoricalBarChartData;
