import React from "react";
import PageLayout from "../../../layouts/PageLayout/PageLayout";
import CardRow from "../../../components/Card/CardRow/CardRow";
import ApartmentSelector from "../../../fragments/ApartmentSelector";
import routes from "../../../constants/routes";
import Filters from "../../../fragments/Filters";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import useListApartments from "../../../api/hooks/useListApartments";
import { useTenantOverview } from "../OverviewPage/OverviewPage";
import { Tab, Tabs } from "@material-ui/core";
import Row from "../../../components/Row";
import TenantCard from "../../../components/TenantCard";
import AddressCard from "../../../components/AddressCard";
import InvoicesCard from "../../../fragments/InvoicesCard";
import { Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import TabPanel from "../../../components/TabPanel";
import ApartmentConsumptionHistory from "../../../components/ApartmentConsumptionHistory";
import List from "../../../components/List";

const useStyles = makeStyles<Theme, any>((theme) => ({
  apartmentInfoRoot: {
    width: "100%",
    [theme.breakpoints.down(1100)]: {
      flexDirection: "column",
    },
  },
  apartmentInfo: {
    width: "100%",
    display: "flex",
    flex: "none",
    flexDirection: "column",
    justifyContent: "flex-start",
    [theme.breakpoints.down(1100)]: {
      width: "100%",
      marginBottom: 24,
    },
  },
  tabs: {
    marginTop: 16,
  },
  consumptionHistory: {
    marginBottom: 50,
  },
  details: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& > *": {
      [theme.breakpoints.down(1100)]: {
        width: "100%",
      },
    },
    "& > div:not(:last-child)": {
      marginBottom: 16,
    },
  },
  label: {
    color: "#272833",
  },
  row: {
    [theme.breakpoints.down(740)]: {
      flexDirection: "column",
      "& > div:not(:last-child)": {
        marginBottom: 16,
      },
    },
  },
}));

function ApartmentDetailPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { apartmentId } = useParams<{
    apartmentId: string;
  }>();
  const history = useHistory();
  const { data, isLoading, error } = useTenantOverview();
  const {
    data: apartmentData,
    isLoading: isLoadingApartments,
    error: loadingApartmentsError,
  } = useListApartments({ id: apartmentId }, Boolean(apartmentId));

  const apartment = apartmentData?.apartments?.find(
    (apartment) => apartment?.id === apartmentId
  );

  const handleSelectApartment = (apartment: any) => {
    if (!apartment || !apartment?.id) {
      history.replace(routes.tenant.overview);
    } else {
      history.push(routes.tenant.getApartmentDetail(apartment?.id || ""));
    }
  };

  return (
    <PageLayout
      loading={isLoadingApartments || isLoading}
      error={loadingApartmentsError || error}
      layoutType="tenant"
      sticky={
        <List spacing={0} width="100%">
          <CardRow header borderBottom>
            <ApartmentSelector
              apartments={data?.apartments}
              onSelect={handleSelectApartment}
              removeAllItemsOption
            />
          </CardRow>
          <CardRow borderBottom>
            <Tabs value={0} className={classes.tabs} indicatorColor="primary">
              <Tab label={t("consumption")} className={classes.label} />
            </Tabs>
            {<Filters />}
          </CardRow>
        </List>
      }
    >
      <TabPanel value={0} index={0}>
        <div className={classes.apartmentInfo}>
          <div className={classes.consumptionHistory}>
            <ApartmentConsumptionHistory apartment={apartment} />
          </div>
          <Row alignItems="flex-start" className={classes.row}>
            <TenantCard tenants={apartment?.tenants} />
            <AddressCard address={apartment?.property?.address} />
            <InvoicesCard
              invoices={apartment?.invoices}
              limit={2}
              onSeeAllInvoices={() =>
                history.push(routes.tenant.getInvoices(apartment?.id || ""))
              }
            />
          </Row>
        </div>
      </TabPanel>
    </PageLayout>
  );
}

export default ApartmentDetailPage;
