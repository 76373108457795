import React from "react";
import PrivateRoute from "../PrivateRoute";
import Role from "../../auth/Role";
import routes from "../../constants/routes";
import { Route, Switch } from "react-router";
import AdminPropertiesPage from "../../pages/admin/PropertiesPage";
import LoginPage from "../../pages/auth/LoginPage";
import PageNotFound from "../../pages/404/PageNotFound";
import AdminOwnerPage from "../../pages/admin/OwnersPage";
import AdminPropertyDetailPage from "../../pages/admin/PropertyDetailPage";
import OwnerPropertiesPage from "../../pages/owner/PropertiesPage";
import OwnerPropertyDetailPage from "../../pages/owner/PropertyDetailPage";
import DataGatewayPage from "../../pages/admin/DataGatewayPage";
import AdminDashboardPage from "../../pages/admin/_deprecated/DashboardPage";
import TenantConsumptionPage from "../../pages/tenant/_deprecated/ConsumptionPage";
import TenantInvoicesPage from "../../pages/tenant/InvoicesPage";
import InvoicesPage from "../../pages/owner/InvoicesPage";
import { useKeycloak } from "@react-keycloak/web";
import TenantsPage from "../../pages/owner/TenantsPage";
import TenantDetailPage from "../../pages/owner/TenantDetailPage";
import TenantApartmentDetailPage from "../../pages/tenant/ApartmentDetailPage";
import ApartmentDetailPage from "../../pages/owner/ApartmentDetailPage";
import AdminOwnerDetailPage from "../../pages/admin/OwnerDetailPage";
import InvoicePage from "../../pages/invoice/InvoicePage";

function AppRouter() {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return null;
  }

  return (
    <Switch>
      {/* ADMIN ROUTES */}
      <PrivateRoute roles={[Role.Admin]} exact path={routes.admin.dashboard}>
        <AdminDashboardPage />
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Admin]}
        exact
        path={routes.admin.loxoneMiniserver}
      >
        <DataGatewayPage />
      </PrivateRoute>
      <PrivateRoute roles={[Role.Admin]} exact path={routes.admin.properties}>
        <AdminPropertiesPage />
      </PrivateRoute>
      <PrivateRoute roles={[Role.Admin]} path={routes.admin.propertyDetail}>
        <AdminPropertyDetailPage />
      </PrivateRoute>
      <PrivateRoute roles={[Role.Admin]} exact path={routes.admin.owner}>
        <AdminOwnerPage />
      </PrivateRoute>
      <PrivateRoute roles={[Role.Admin]} exact path={routes.admin.ownerDetail}>
        <AdminOwnerDetailPage />
      </PrivateRoute>

      {/* OWNER ROUTES */}
      <PrivateRoute roles={[Role.Owner]} exact path={routes.owner.properties}>
        <OwnerPropertiesPage />
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Owner]}
        exact
        path={routes.owner.propertyDetail}
      >
        <OwnerPropertyDetailPage />
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Owner]}
        exact
        path={routes.owner.apartmentDetail}
      >
        <ApartmentDetailPage />
      </PrivateRoute>
      <PrivateRoute roles={[Role.Owner]} exact path={routes.owner.tenant}>
        <TenantsPage />
      </PrivateRoute>
      <PrivateRoute roles={[Role.Owner]} exact path={routes.owner.tenantDetail}>
        <TenantDetailPage />
      </PrivateRoute>
      <PrivateRoute roles={[Role.Owner]} path={routes.owner.invoices}>
        <InvoicesPage />
      </PrivateRoute>

      {/* TENANT ROUTES */}
      {/*<PrivateRoute roles={[Role.Tenant]} exact path={routes.tenant.overview}>*/}
      {/*  <TenantOverviewPage />*/}
      {/*</PrivateRoute>*/}
      <PrivateRoute roles={[Role.Tenant]} path={routes.tenant.apartmentDetail}>
        <TenantApartmentDetailPage />
      </PrivateRoute>
      <PrivateRoute roles={[Role.Tenant]} path={routes.tenant.invoices}>
        <TenantInvoicesPage />
      </PrivateRoute>
      <PrivateRoute
        roles={[Role.Tenant]}
        exact
        path={routes.tenant.consumption}
      >
        <TenantConsumptionPage />
      </PrivateRoute>

      {/* OTHER ROUTES */}
      <PrivateRoute
        roles={[Role.Owner, Role.Tenant, Role.Admin, Role.Dev]}
        path={routes.invoice}
      >
        <InvoicePage />
      </PrivateRoute>
      <Route exact path={routes.login}>
        <LoginPage />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
}

export default AppRouter;
