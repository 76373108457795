import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import Dialog from "../Dialog";
import { Typography } from "@material-ui/core";
import ErrorAlert from "../ErrorAlert";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  primaryButtonLoading?: boolean;
  primaryButtonText?: string;
  confirmationText?: string;
  error: any;
}

const useStyles = makeStyles(() => ({
  errorAlert: {
    marginTop: 16,
  },
  textField: {
    marginBottom: 16,
  },
  infoText: {},
  content: { padding: "16px 8px" },
}));

function ConfirmationDialog({
  open,
  onClose,
  primaryButtonLoading,
  onSubmit,
  error,
  primaryButtonText,
  confirmationText,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      background="white"
      onClose={handleClose}
      maxWidth="sm"
      data-cy="confirmation-dialog"
      onSubmit={onSubmit}
      primaryButtonLoading={primaryButtonLoading}
      primaryButtonText={primaryButtonText || t("delete")}
    >
      <div className={classes.content}>
        <Typography variant="body2" className={classes.infoText}>
          {confirmationText || t("deleteConfirmationText")}
        </Typography>
        {error && (
          <ErrorAlert
            error={t("unexpectedError")}
            className={classes.errorAlert}
          />
        )}
      </div>
    </Dialog>
  );
}

export default ConfirmationDialog;
