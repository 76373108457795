import React, { CSSProperties } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

interface Props {
  children: JSX.Element | JSX.Element[] | React.ReactNode;

  // Horizontal space between children
  spacing?: number;

  // Wrapper element to wrap children
  wrapper?: React.ElementType;

  // Classes to be applied to a root container
  className?: string;

  // Flex align-items
  alignItems?: string;

  // Flex justify-content
  justifyContent?: string;

  style?: CSSProperties;

  onClick?: (e: any) => void;

  onDoubleClick?: () => void;

  maxWidth?: boolean;

  hidden?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: (props: Props) => props.alignItems || "center",
    justifyContent: (props: Props) => props.justifyContent || "flex-start",
    "& > *": {
      marginRight: (props: Props) => `${props.spacing ?? 16}px !important`,
    },
    "& :last-child": {
      marginRight: `0px !important`,
    },
  },
  maxWidth: {
    flex: 1,
  },
}));

function Row(props: Props) {
  const {
    children,
    wrapper,
    className,
    style,
    onClick,
    onDoubleClick,
    maxWidth,
    hidden,
  } = props;
  const classes = useStyles(props);
  const Wrapper = wrapper || "div";

  if (hidden) {
    return null;
  }

  return (
    <Wrapper
      onDoubleClick={onDoubleClick}
      onClick={onClick}
      className={clsx(
        classes.root,
        { [classes.maxWidth]: maxWidth },
        className
      )}
      style={style}
    >
      {children}
    </Wrapper>
  );
}

export default Row;
