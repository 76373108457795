import React from "react";
import { Skeleton } from "@material-ui/lab";
import { CHART_SECTION_HEIGHT } from "../../theme";
import { Box } from "@material-ui/core";

interface Props {
  items?: number;
}

function LoadingSkeleton({ items = 3 }: Props) {
  const skeletonItems = new Array(items);
  return (
    <>
      {skeletonItems.fill(0).map((_, index) => (
        <Box width="100%" paddingX={4} key={index}>
          <Skeleton
            variant="rect"
            width="100%"
            height={64}
            style={{ marginBottom: 12, marginTop: 24 }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={CHART_SECTION_HEIGHT}
            style={{ marginBottom: 64 }}
          />
        </Box>
      ))}
    </>
  );
}

export default LoadingSkeleton;
