export default function getMaxBarSize(numberOfItems: number) {
  if (numberOfItems < 5) {
    return 100;
  }

  if (numberOfItems < 10) {
    return 80;
  }

  return 60;
}
