import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikValues } from "formik";
import FormCard from "../FormCard";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { Grid, TextFieldProps, Typography } from "@material-ui/core";
import Input from "../../Input";
import { useTranslation } from "react-i18next";
import { ExtendedInputProps } from "../../Input/Input";
import { BORDER_COLOR_SECONDARY, SUBHEADER_COLOR } from "../../../theme";

const useStyles = makeStyles(() => ({
  grid: { maxWidth: 780 },
  subheader: {
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 5,
    color: SUBHEADER_COLOR,
    width: "100%",
    borderBottom: `1px solid ${BORDER_COLOR_SECONDARY}`,
  },
}));

export enum OwnerPaymentDetailsFormFields {
  BankAccountNumber = "bankAccountNumber",
  BeneficiaryName = "beneficiaryName",
  BeneficiaryAddressLine1 = "beneficiaryAddressLine1",
  BeneficiaryAddressLine2 = "beneficiaryAddressLine2",
  BeneficiaryZip = "beneficiaryZip",
  BeneficiaryCity = "beneficiaryCity",
  BeneficiaryVAT = "beneficiaryVAT",
}

export interface OwnerPaymentDetailsFormValues extends FormikValues {
  bankAccountNumber: string;
  beneficiaryName: string;
  beneficiaryAddressLine1: string;
  beneficiaryAddressLine2: string;
  beneficiaryZip: string;
  beneficiaryCity: string;
  beneficiaryVAT: string;
}

interface Props {
  values: OwnerPaymentDetailsFormValues;
  onChange: (e: React.ChangeEvent<any>) => void;
  isEditable?: boolean;
}

function OwnerPaymentDetailsForm({
  values,
  onChange,
  isEditable = true,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    bankAccountNumber,
    beneficiaryName,
    beneficiaryAddressLine1,
    beneficiaryAddressLine2,
    beneficiaryCity,
    beneficiaryZip,
    beneficiaryVAT,
  } = values;

  const getTextFieldProps = (
    fieldName: OwnerPaymentDetailsFormFields
  ): TextFieldProps & ExtendedInputProps => ({
    id: fieldName,
    name: fieldName,
    onChange,
    color: "secondary",
    InputLabelProps: { shrink: true },
    fullWidth: true,
    gutterBottom: 0,
    disabled: !isEditable,
  });

  return (
    <FormCard
      header={t("paymentDetails")}
      headerIcon={<CreditCardIcon fontSize="default" />}
    >
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12} sm={6}>
          <Input
            id={OwnerPaymentDetailsFormFields.BankAccountNumber}
            name={OwnerPaymentDetailsFormFields.BankAccountNumber}
            value={bankAccountNumber}
            onChange={onChange}
            label={t("iban")}
            data-cy="owner-form-bank-account-number"
            color="secondary"
            disabled={!isEditable}
            fullWidth
            gutterBottom={0}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            id={OwnerPaymentDetailsFormFields.BeneficiaryName}
            name={OwnerPaymentDetailsFormFields.BeneficiaryName}
            value={beneficiaryName}
            onChange={onChange}
            label={t("beneficiary")}
            data-cy="owner-form-beneficiary"
            color="secondary"
            disabled={!isEditable}
            fullWidth
            gutterBottom={0}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
              id={OwnerPaymentDetailsFormFields.BeneficiaryVAT}
              name={OwnerPaymentDetailsFormFields.BeneficiaryVAT}
              value={beneficiaryVAT}
              onChange={onChange}
              label={t("beneficiaryVAT")}
              data-cy="owner-form-beneficiaryVAT"
              color="secondary"
              disabled={!isEditable}
              fullWidth
              gutterBottom={0}
              InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subheader}>{t("address")}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            value={beneficiaryAddressLine1}
            label={`${t("addressLine")} 1`}
            data-cy="owner-form-beneficiary-address-line-1"
            InputProps={{
              ...getTextFieldProps(
                OwnerPaymentDetailsFormFields.BeneficiaryAddressLine1
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            value={beneficiaryAddressLine2}
            label={`${t("addressLine")} 2`}
            data-cy="owner-form-beneficiary-address-line-2"
            InputProps={{
              ...getTextFieldProps(
                OwnerPaymentDetailsFormFields.BeneficiaryAddressLine2
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input
            value={beneficiaryZip}
            label={t("zip")}
            data-cy="owner-form-beneficiary-zip"
            InputProps={{
              ...getTextFieldProps(
                OwnerPaymentDetailsFormFields.BeneficiaryZip
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={9} />
        <Grid item xs={12} sm={6}>
          <Input
            value={beneficiaryCity}
            label={t("city")}
            data-cy="owner-form-beneficiary-city"
            InputProps={{
              ...getTextFieldProps(
                OwnerPaymentDetailsFormFields.BeneficiaryCity
              ),
            }}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
}

export default OwnerPaymentDetailsForm;
