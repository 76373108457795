import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import App, { gqlClient } from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import moment from "moment";
import "moment/locale/de";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./auth/keycloak";

moment.locale("de");

function AppWrapper() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={(event: string) => {
        if (event === "onAuthRefreshSuccess") {
          gqlClient.setHeader("authorization", `Bearer ${keycloak.token}`);
        }
      }}
    >
      <Router>
        <App />
      </Router>
    </ReactKeycloakProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
