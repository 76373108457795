import { InvoiceItemType } from "../../api/types";
import { t } from "../../i18n";

const labelMap: Record<string, string> = {
  [InvoiceItemType.ElectricityL]: t("consumptionType.pricing.electricityL"),
  [InvoiceItemType.ElectricityH]: t("consumptionType.pricing.electricityH"),
  [InvoiceItemType.ElectricityPv]: t("consumptionType.pricing.electricityPV"),
  [InvoiceItemType.Electricity]: t("consumptionType.pricing.electricity"),
  [InvoiceItemType.MaximumPower]: t("consumptionType.pricing.maximumPower"),
  [InvoiceItemType.HeatingEnergy]: t("consumptionType.pricing.heatingEnergy"),
  [InvoiceItemType.WaterColdVolume]: t(
    "consumptionType.pricing.waterColdVolume"
  ),
  [InvoiceItemType.WaterWarmVolume]: t(
    "consumptionType.pricing.waterWarmVolume"
  ),
  [InvoiceItemType.Charging]: t("consumptionType.pricing.charging"),
  [InvoiceItemType.AdditionalCost]: t("consumptionType.pricing.additionalCost"),
};

function getPricingLabel(invoiceItemType: string) {
  return labelMap[invoiceItemType] || "";
}

export default getPricingLabel;
