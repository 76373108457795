import React, { useState } from "react";
import Row from "../../components/Row";
import {
  Button,
  ButtonGroup,
  ButtonProps,
  makeStyles,
  Typography,
} from "@material-ui/core";
import IconDropdown from "@material-ui/icons/ArrowDropDown";
import IconLeft from "@material-ui/icons/ChevronLeft";
import IconRight from "@material-ui/icons/ChevronRight";
import IconChart from "@material-ui/icons/InsertChartOutlined";
import useFilterStore, { fullFilterState } from "../../store/useFilterStore";
import shallow from "zustand/shallow";
import { formatDate } from "../../components/DatePicker/DatePicker";
import ConsumptionPeriodSelector from "../ConsumptionPeriodSelector";
import ConsumptionTypeSelector from "../ConsumptionTypeSelector";
import moment from "moment";
import { getCurrentDate } from "../../utils/date/date.utils";
import { DatePicker, DatePickerView } from "@material-ui/pickers";
import { ConsumptionPeriod } from "../../types";

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
  },
  caption: {
    margin: 0,
  },
  button: {
    flex: "none !important",
    textTransform: "none",
    margin: 0,
  },
  dateButton: {
    backgroundColor: "#e0e0e0",
    borderRadius: 0,
    borderRight: "1px solid #bdbdbd",
  },
}));

export default function Filters() {
  const classes = useStyles();

  const {
    showCosts,
    setShowCosts,
    consumptionPeriod,
    date,
    setDate,
    setNextDate,
    setPrevDate,
  } = useFilterStore(fullFilterState, shallow);

  const [openDatepicker, setOpenDatepicker] = useState<boolean>(false);

  const buttonProps: Partial<ButtonProps> = {
    disableElevation: true,
    variant: "contained",
    size: "small",
    className: classes.button,
    endIcon: <IconDropdown />,
  };

  const datePickerViews: Array<DatePickerView> = [];

  if (
    consumptionPeriod === ConsumptionPeriod.Day ||
    consumptionPeriod === ConsumptionPeriod.Week
  ) {
    datePickerViews.push("date");
  } else if (consumptionPeriod === ConsumptionPeriod.Month) {
    datePickerViews.push("month");
  } else if (consumptionPeriod === ConsumptionPeriod.Year) {
    datePickerViews.push("year");
  }

  return (
    <Row alignItems="center" justifyContent="flex-end" className={classes.root}>
      <Typography className={classes.caption} variant="caption">
        Filters
      </Typography>
      <ConsumptionTypeSelector buttonProps={buttonProps} />
      <ConsumptionPeriodSelector buttonProps={buttonProps} />
      <ButtonGroup
        size="small"
        variant="contained"
        disableElevation
        className={classes.button}
      >
        <Button
          color={showCosts ? "default" : "primary"}
          onClick={() => {
            setShowCosts(false);
          }}
          className={classes.button}
        >
          <IconChart fontSize="small" />
        </Button>
        <Button
          color={showCosts ? "primary" : "default"}
          onClick={() => {
            setShowCosts(true);
          }}
        >
          CHF
        </Button>
      </ButtonGroup>
      <ButtonGroup
        size="small"
        variant="contained"
        className={classes.button}
        disableElevation
      >
        <Button onClick={setPrevDate}>
          <IconLeft fontSize="small" />
        </Button>
        <DatePicker
          autoOk
          value={date}
          open={openDatepicker}
          onChange={(date) => {
            setDate(getCurrentDate(date, consumptionPeriod));
            setOpenDatepicker(false);
          }}
          onClose={() => setOpenDatepicker(false)}
          animateYearScrolling
          views={datePickerViews}
          TextFieldComponent={(params: any) => (
            <Button
              {...params}
              onClick={() => setOpenDatepicker(true)}
              className={classes.dateButton}
              variant="contained"
            >
              {formatDate(date, consumptionPeriod)}
            </Button>
          )}
        />
        <Button
          onClick={setNextDate}
          disabled={moment(date).isSameOrAfter(
            getCurrentDate(moment(), consumptionPeriod)
          )}
        >
          <IconRight fontSize="small" />
        </Button>
      </ButtonGroup>
    </Row>
  );
}
