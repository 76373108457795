import { BarChartData, ConsumptionPeriod } from "../../types";
import _ from "lodash";
import getHistoricalBarChartData from "./getHistoricalBarChartData";
import { ConsumptionFieldsFragment, ConsumptionType } from "../../api/types";

function mapConsumptionHistory(
  consumptionHistory: ConsumptionFieldsFragment | undefined,
  consumptionType: ConsumptionType
): Record<string, BarChartData> {
  return _(ConsumptionPeriod)
    .flatMap()
    .reduce((result, key) => {
      const data = consumptionHistory?.data?.[key]?.filter(
        (d) => d?.itemType === consumptionType
      );
      result[key] = getHistoricalBarChartData(data, key);
      return result;
    }, {} as Record<string, BarChartData>);
}

export default mapConsumptionHistory;
