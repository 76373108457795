import { gql } from "graphql-request";
import { useQuery } from "react-query";
import QueryKeys from "../QueryKeys";
import { gqlClient } from "../../App";

function useApartmentIdsForProperty(propertyId: String, enabled = true) {
  return useQuery(
    [QueryKeys.APARTMENT_IDS_FOR_PROPERTIES, propertyId],
    async () => {
      return await gqlClient.request(
        gql`
          query ApartmentIdsForProperty($propertyId: String!) {
            getApartmentIdsForProperty(propertyId: $propertyId)
          }
        `,
        {
          propertyId,
          operationName: QueryKeys.APARTMENT_IDS_FOR_PROPERTIES,
        }
      );
    },
    {
      enabled,
    }
  );
}

export default useApartmentIdsForProperty;
