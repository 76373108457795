import React, { Fragment } from "react";
import { FieldArray } from "formik";
import { Button, Grid, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import { PropertyPricesFormFields } from "./PropertyPricesForm";
import {
  ConsumptionPricingFieldsFragment,
  ConsumptionType,
} from "../../../api/types";
import Input from "../../Input";
import RemoveIcon from "@material-ui/icons/Remove";
import { BORDER_COLOR_SECONDARY, SUBHEADER_COLOR } from "../../../theme";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 700,
  },
  deleteButton: {
    border: `1px solid ${BORDER_COLOR_SECONDARY}`,
    borderRadius: 4,
    height: 32,
    width: 32,
    marginTop: 18,
    color: SUBHEADER_COLOR,
  },
  addButton: { color: SUBHEADER_COLOR, marginTop: 4 },
  input: {},
  priceInput: {
    "& > div": {
      display: "flex",
      alignItems: "baseline",
    },
  },
  row: {
    marginBottom: 16,
  },
  gridRow: {
    marginBottom: 12,
    maxWidth: 640,
  },
}));

interface Props {
  additionalCosts?: Array<ConsumptionPricingFieldsFragment>;
  isEditable?: boolean;
}

function AdditionalCostFields({ additionalCosts = [], isEditable }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FieldArray
      name={PropertyPricesFormFields.AdditionalCosts}
      validateOnChange={false}
    >
      {({ remove, push, replace }) => (
        <Fragment>
          {additionalCosts.map((pricing, index) => (
            <Grid
              key={index}
              container
              spacing={3}
              alignItems="flex-start"
              className={classes.gridRow}
            >
              <Grid item xs={12} sm={5}>
                <Input
                  value={pricing.label}
                  label={t("pricingLabel")}
                  color="secondary"
                  onChange={(e) =>
                    replace(index, { ...pricing, label: e.target.value })
                  }
                  className={classes.input}
                  fullWidth
                  gutterBottom={0}
                  disabled={!isEditable}
                />
              </Grid>
              <Grid item xs={10} sm={5}>
                <Input
                  label={t("pricePerMonth")}
                  value={pricing.basePrice}
                  color="secondary"
                  type="number"
                  inputProps={{ min: 0 }}
                  onChange={(e) =>
                    replace(index, { ...pricing, basePrice: e.target.value })
                  }
                  className={classes.priceInput}
                  unit="CHF"
                  fullWidth
                  gutterBottom={0}
                  disabled={!isEditable}
                />
              </Grid>
              <Grid item xs={1} sm={2}>
                <IconButton
                  onClick={() => remove(index)}
                  className={classes.deleteButton}
                  disableRipple
                  disabled={!isEditable}
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              onClick={() =>
                push({
                  basePrice: "",
                  label: "",
                  pricingType: ConsumptionType.AdditionalCost,
                })
              }
              size="small"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.addButton}
              disabled={!isEditable}
            >
              {t("priceConfiguration")}
            </Button>
          </Grid>
        </Fragment>
      )}
    </FieldArray>
  );
}

export default AdditionalCostFields;
