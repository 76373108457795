import React from "react";
import CardRow from "../Card/CardRow/CardRow";
import ButtonNew from "../ButtonNew";
import { makeStyles } from "@material-ui/styles";

interface Props {
  selector: React.ReactElement;
  onButtonNewClick: () => void;
  hideButtonNew?: boolean;
}

const useStyles = makeStyles(() => ({
  cardRow: {
    "& > div": {
      marginRight: "0px !important",
    },
    "& > button": {
      margin: 0,
    },
  },
}));

function SearchHeader({
  onButtonNewClick,
  selector,
  hideButtonNew = false,
}: Props) {
  const classes = useStyles();

  return (
    <CardRow
      header
      borderBottom
      justifyContent="space-between"
      className={classes.cardRow}
    >
      {selector}
      {!hideButtonNew && <ButtonNew onButtonNewClick={onButtonNewClick} />}
    </CardRow>
  );
}

export default SearchHeader;
