import { gql } from "graphql-request";
import React, { ChangeEvent, useEffect, useState } from "react";
import Autocomplete from "../../Autocomplete";
import { useQuery } from "react-query";
import QueryKeys from "../../../api/QueryKeys";
import { gqlClient } from "../../../App";
import {
  PropertyOwnerAutocompleteQuery,
  PropertyOwnerDto,
} from "../../../api/types";
import formatPropertyOwnerName from "../../../utils/format/formatPropertyOwnerName";
import { useTranslation } from "react-i18next";

interface Props {
  onChange?: (e: ChangeEvent<any>, value: any) => void;
  value?: Partial<PropertyOwnerDto | null>;
  disabled?: boolean;
  hidden?: boolean;
}

const PROPERTY_OWNER_AUTOCOMPLETE = gql`
  query PropertyOwnerAutocomplete {
    propertyOwners {
      id
      firstName
      lastName
    }
  }
`;

function usePropertyOwnerAutocomplete() {
  return useQuery<PropertyOwnerAutocompleteQuery>(
    QueryKeys.PROPERTY_OWNER_AUTOCOMPLETE,
    async () =>
      gqlClient.request(PROPERTY_OWNER_AUTOCOMPLETE, {
        operationName: QueryKeys.PROPERTY_OWNER_AUTOCOMPLETE,
      })
  );
}

function PropertyOwnerAutocomplete({ disabled, onChange, value }: Props) {
  const { t } = useTranslation();
  const { data, isLoading } = usePropertyOwnerAutocomplete();
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    const updated = data?.propertyOwners?.map((o) => ({
      ...o,
      name: formatPropertyOwnerName(o),
    }));
    setOptions(updated);
  }, [data]);

  return (
    <Autocomplete
      disabled={disabled || isLoading}
      options={options || []}
      value={value}
      onChange={onChange}
      required
      label={t("owner")}
    />
  );
}

export default PropertyOwnerAutocomplete;
