import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Row from "../../Row";
import List from "../../List";
import DropdownIcon from "@material-ui/icons/ExpandMore";
import LockIcon from "@material-ui/icons/Lock";
import { SUBHEADER_COLOR, TEXT_PRIMARY } from "../../../theme";
import clsx from "clsx";

interface Props {
  icon?: JSX.Element;
  title?: string;
  subtitle?: string;
  label?: string;
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
  className?: string;
  children?: JSX.Element | JSX.Element[];
  isEditable?: boolean;
  hideDropDownIcon?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    color: TEXT_PRIMARY,
    overflow: "hidden",
    cursor: (props: Props) => (props.onClick ? "pointer" : "inherit"),
  },
  icon: {
    width: 32,
    height: 32,
  },
  list: {
    height: "100%",
    flex: 1,
  },
  iconContainer: {},
  title: {
    fontWeight: 500,
    maxWidth: 180,
  },
  label: {},
  dropdownIcon: {},
  lockIcon: {
    color: SUBHEADER_COLOR,
  },
}));

export default function CardHeader(props: Props) {
  const {
    icon,
    title = "",
    subtitle = "",
    label = "",
    disabled,
    onClick,
    className,
    children,
    isEditable = true,
    hideDropDownIcon,
  } = props;
  const classes = useStyles(props);

  function handleClick(event: MouseEvent) {
    if (disabled) {
      return;
    }

    if (onClick) {
      onClick(event);
    }
  }

  return (
    <Row
      className={clsx(classes.root, className)}
      onClick={handleClick}
      alignItems="center"
    >
      {icon && <div className={classes.iconContainer}>{icon}</div>}
      <List justifyContent="center" className={classes.list} spacing={0}>
        {label && (
          <Typography
            className={classes.label}
            variant="caption"
            component="div"
          >
            {label}
          </Typography>
        )}
        {title && (
          <Row>
            <Typography
              variant={!label && !subtitle ? "h5" : "inherit"}
              className={classes.title}
              component="div"
              noWrap
            >
              {title}
            </Typography>
            {!isEditable && (
              <LockIcon
                fontSize="small"
                color="inherit"
                className={classes.lockIcon}
              />
            )}
          </Row>
        )}
        {subtitle && (
          <Typography
            className={classes.label}
            variant="caption"
            component="div"
          >
            {subtitle}
          </Typography>
        )}
      </List>
      {onClick && !hideDropDownIcon && (
        <DropdownIcon color="inherit" className={classes.dropdownIcon} />
      )}
      {children}
    </Row>
  );
}
