import React from "react";
import AdminPageLayout from "../../../../layouts/_deprecated/AdminPageLayout";
import HeaderLayout from "../../../../layouts/_deprecated/HeaderLayout/HeaderLayout";
import { useTranslation } from "react-i18next";

function DashboardPage() {
  const { t } = useTranslation();

  return (
    <AdminPageLayout>
      <HeaderLayout title={t("dashboard")} />
    </AdminPageLayout>
  );
}

export default DashboardPage;
