import React from "react";
import { makeStyles } from "@material-ui/styles";
import PageLayout from "../../../layouts/PageLayout/PageLayout";
import CardRow from "../../../components/Card/CardRow/CardRow";
import Filters from "../../../fragments/Filters";
import { useTranslation } from "react-i18next";
import Header from "../../../components/Header/Header";
import { useHistory, useParams } from "react-router-dom";
import usePropertyDetail from "../../../api/hooks/usePropertyDetail";
import useConsumption from "../../../api/hooks/useConsumption";
import useFilterStore, { fullFilterState } from "../../../store/useFilterStore";
import shallow from "zustand/shallow";
import { getCurrentDate, getNextDate } from "../../../utils/date/date.utils";
import { Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../../components/TabPanel";
import PropertyConsumption from "./components/PropetyConsumption";
import ApartmentsTable from "./components/ApartmentsTable";
import routes from "../../../constants/routes";
import { FormikProvider, useFormik } from "formik";
import PropertyForm from "../../../components/Forms/PropertyForm";
import ErrorAlert from "../../../components/ErrorAlert";
import useSetProperty from "../../../api/hooks/useSetProperty";
import useDeleteProperty from "../../../api/hooks/useDeleteProperty";
import { validatePropertyForm } from "../../../components/NewPropertyDialog/NewPropertyDialog";
import getPricingInput from "../../../utils/getPricingInput";
import { queryClient } from "../../../App";
import QueryKeys from "../../../api/QueryKeys";
import ExportConsumptionAsCsv from "../../../fragments/ExportConsumptionsAsCsv";
import Button from "../../../components/Button";
import formatCounterPricings from "../../../utils/format/formatCounterPricings";
import List from "../../../components/List";
import { TEXT_PRIMARY } from "../../../theme";
import formatAmortizationCostsInput from "../../../utils/format/formatAmortizationCostsInput";
import validateAmortizationCosts from "../../../utils/validate/validateAmortizationCosts";
import { propertyFormikInitialValues } from "../../../constants/propertyFormikInitialValues";
import { PropertyFormValues } from "../../../types";
import useSetPropertyValues from "../../../hooks/useSetPropertyValues";
import useApartmentIdsForProperty from "../../../api/hooks/useApartmentIdsForProperty";

const useStyles = makeStyles(() => ({
  root: {},
  tabs: {
    marginTop: 16,
    "& > *": {
      color: TEXT_PRIMARY,
    },
  },
  errorAlert: {
    marginTop: 16,
  },
  exportCsvButton: {
    position: "absolute",
    right: 40,
  },
}));

function PropertyDetailPage() {
  const { t } = useTranslation();
  const { propertyId } = useParams<{ propertyId: string }>();
  const classes = useStyles();
  const history = useHistory();
  const setProperty = useSetProperty();
  const deleteProperty = useDeleteProperty();
  const { date, consumptionPeriod, consumptionTypes } = useFilterStore(
    fullFilterState,
    shallow
  );

  const [tab, setTab] = React.useState(0);

  const { data: propertyData } = usePropertyDetail(
    propertyId,
    Boolean(propertyId)
  );

  const {
    data: consumptionData,
    isLoading: isLoadingConsumptionData,
    error,
  } = useConsumption(
    {
      from: getCurrentDate(date, consumptionPeriod).toISOString(),
      to: getNextDate(date, consumptionPeriod, "next").toISOString(),
      propertyId,
      counters: Array.from(consumptionTypes),
    },
    Boolean(propertyId)
  );

  const property = propertyData?.properties?.[0];
  const apartments = propertyData?.properties?.[0]?.apartments || [];
  const isEditable = property ? property.isEditable : true;

  const formik = useFormik<PropertyFormValues>({
    initialValues: propertyFormikInitialValues,
    onSubmit: () => {},
    validate: validatePropertyForm,
    validateOnChange: true,
  });

  useSetPropertyValues(property, formik.setValues, tab);

  // ApartmentsIds = counterIds
  const {
    data: { getApartmentIdsForProperty: counterIds = [] } = {
      getApartmentIdsForProperty: [],
    },
  } = useApartmentIdsForProperty(property?.id || "", !!property?.id);

  const handleSubmit = async () => {
    const {
      name,
      dataGatewayId,
      addressLine1,
      addressLine2,
      city,
      zip,
      propertyOwner,
      amortizedCosts,
      counterPricings,
      areaSize,
      mainCounterName,
      costDistributionType,
    } = formik.values;

    const counterPricingsList = formatCounterPricings(counterPricings);
    const amortizedCostsInput = formatAmortizationCostsInput(amortizedCosts);

    const photovoltaicsConfig = costDistributionType
      ? {
          costDistributionType,
        }
      : null;
    await setProperty.mutate(
      {
        id: property?.id,
        name,
        dataGatewayId,
        address: {
          addressLineOne: addressLine1,
          addressLineTwo: addressLine2,
          city,
          zip,
        },
        areaSize: areaSize ? Number(areaSize) : null,
        defaultConsumptionPricings: getPricingInput(formik.values),
        propertyOwnerId: propertyOwner?.id,
        counterPricings: [...counterPricingsList],
        amortizedCosts: [...amortizedCostsInput],
        mainCounterName,
        photovoltaicsConfig,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([QueryKeys.LIST_PROPERTIES]);
          await queryClient.invalidateQueries([QueryKeys.PROPERTY_SELECTOR]);
          await queryClient.refetchQueries([
            QueryKeys.PROPERTY_DETAIL,
            property?.id,
          ]);
          setTab(0);
          formik.resetForm();
        },
      }
    );
  };

  const handleDelete = () => {
    deleteProperty.mutate(property?.id || "", {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.LIST_PROPERTIES]);
        await queryClient.invalidateQueries([QueryKeys.PROPERTY_SELECTOR]);
        await queryClient.invalidateQueries([QueryKeys.PROPERTY_DETAIL]);
        formik.resetForm();
        history.push(routes.owner.properties);
      },
    });
  };

  const invalidAmortizationCosts = validateAmortizationCosts(
    formik.values.amortizationCosts
  );

  const isLoading = setProperty?.isLoading || deleteProperty?.isLoading;
  const disabledButton = isLoading || !isEditable || invalidAmortizationCosts;

  const footer = (
    <CardRow justifyContent="space-between" footer>
      <Button
        loading={deleteProperty.isLoading}
        variant="outlined"
        color="default"
        onClick={handleDelete}
        disabled={disabledButton}
      >
        {t("delete")}
      </Button>
      <Button
        loading={setProperty.isLoading}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={disabledButton || !formik.isValid}
      >
        {t("save")}
      </Button>
    </CardRow>
  );

  return (
    <PageLayout
      layoutType="owner"
      sticky={
        <List spacing={0} width="100%">
          <Header />
          <CardRow>
            <Tabs
              value={tab}
              onChange={(e, value) => {
                setTab(value);
              }}
              className={classes.tabs}
              indicatorColor="primary"
            >
              <Tab label={t("consumption")} />
              <Tab label={t("configure")} />
            </Tabs>
            {tab === 0 && <Filters />}
          </CardRow>
        </List>
      }
      footer={tab === 1 ? footer : undefined}
      displayBackButton
      onNavigateBack={() => history.push(routes.owner.properties)}
    >
      <TabPanel value={tab} index={0}>
        <ExportConsumptionAsCsv
          propertyId={propertyId}
          fileName={property?.name}
          className={classes.exportCsvButton}
          disabled={
            !consumptionData?.getConsumption || isLoadingConsumptionData
          }
        />
        <PropertyConsumption
          consumptionData={consumptionData?.getConsumption}
          error={error}
          loading={isLoadingConsumptionData}
        />
        <ApartmentsTable apartments={apartments} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <FormikProvider value={formik}>
          <PropertyForm
            values={formik.values}
            counterIds={counterIds}
            onChange={formik.handleChange}
            isEditable={isEditable}
            onFieldChange={(fieldName, value) => {
              formik.setFieldValue(fieldName, value);
            }}
          />
          <ErrorAlert
            error={setProperty?.error}
            className={classes.errorAlert}
          />
        </FormikProvider>
      </TabPanel>
    </PageLayout>
  );
}

export default PropertyDetailPage;
