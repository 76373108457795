import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { FormikProvider, useFormik } from "formik";
import QueryKeys from "../../api/QueryKeys";
import ErrorAlert from "../ErrorAlert";
import Dialog from "../Dialog";
import getPricingInput from "../../utils/getPricingInput";
import PropertyForm from "../Forms/PropertyForm";
import useSetProperty from "../../api/hooks/useSetProperty";
import { useKeycloak } from "@react-keycloak/web";
import Role from "../../auth/Role";
import { makeStyles } from "@material-ui/styles";
import formatCounterPricings from "../../utils/format/formatCounterPricings";
import formatAmortizationCostsInput from "../../utils/format/formatAmortizationCostsInput";
import validateAmortizationCosts from "../../utils/validate/validateAmortizationCosts";
import { propertyFormikInitialValues } from "../../constants/propertyFormikInitialValues";
import { PropertyFormValues } from "../../types";
import { PhotovoltaicsCostDistributionType } from "../../api/types";

interface Props {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  errorAlert: {
    marginBottom: 16,
  },
}));

type ValidationErrors = {
  invalidName?: boolean;
  invalidServerId?: boolean;
  invalidAddress?: boolean;
  invalidZip?: boolean;
  invalidCity?: boolean;
  missingMainCounterName?: boolean;
};

export function validatePropertyForm(values: PropertyFormValues) {
  const errors: ValidationErrors = {};

  if (!values.name) {
    errors.invalidName = true;
  }

  if (!values.dataGatewayId) {
    errors.invalidServerId = true;
  }

  if (!values.addressLine1) {
    errors.invalidAddress = true;
  }

  if (!values.zip) {
    errors.invalidZip = true;
  }

  if (!values.city) {
    errors.invalidCity = true;
  }

  if (
    values.costDistributionType ===
      PhotovoltaicsCostDistributionType.Consumption &&
    !values.mainCounterName
  ) {
    errors.missingMainCounterName = true;
  }

  return errors;
}

function NewPropertyDialog({ open, onClose }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const setProperty = useSetProperty();
  const queryClient = useQueryClient();
  const { keycloak } = useKeycloak();

  const formik = useFormik<PropertyFormValues>({
    initialValues: propertyFormikInitialValues,
    onSubmit: () => {},
    validate: validatePropertyForm,
    validateOnMount: true,
    validateOnChange: true,
  });

  const handleSubmit = async () => {
    const {
      name,
      dataGatewayId,
      addressLine1,
      addressLine2,
      city,
      zip,
      propertyOwner,
      counterPricings,
      amortizedCosts,
      areaSize,
    } = formik.values;

    const counterPricingsList = formatCounterPricings(counterPricings);
    const amortizedCostsInput = formatAmortizationCostsInput(amortizedCosts);

    await setProperty.mutate(
      {
        id: undefined,
        name,
        dataGatewayId,
        areaSize: areaSize ? Number(areaSize) : null,
        address: {
          addressLineOne: addressLine1,
          addressLineTwo: addressLine2,
          city,
          zip,
        },
        defaultConsumptionPricings: getPricingInput(formik.values),
        propertyOwnerId: propertyOwner?.id,
        counterPricings: [...counterPricingsList],
        amortizedCosts: [...amortizedCostsInput],
      },
      {
        onSuccess: async () => {
          if (onClose) {
            onClose();
          }
          await queryClient.invalidateQueries([QueryKeys.LIST_PROPERTIES]);
          await queryClient.invalidateQueries([QueryKeys.PROPERTY_SELECTOR]);
          formik.resetForm();
        },
      }
    );
  };

  const handleCloseClick = () => {
    formik.resetForm();
    setProperty.reset();

    if (onClose) {
      onClose();
    }
  };

  const { propertyOwner } = formik.values;
  const isAdmin = keycloak?.hasRealmRole(Role.Admin);

  const invalidAmortizationCosts = validateAmortizationCosts(
    formik.values.amortizationCosts
  );

  const disabledSubmitButton =
    setProperty.isLoading ||
    (isAdmin && !propertyOwner) ||
    !formik.isValid ||
    invalidAmortizationCosts;

  return (
    <Dialog
      open={open}
      dialogHeader={t("newProperty")}
      onClose={handleCloseClick}
      maxWidth="lg"
      data-cy="new-tenant-dialog"
      onSubmit={handleSubmit}
      disabledSubmitButton={disabledSubmitButton}
      primaryButtonLoading={setProperty.isLoading}
      errorAlert={
        <ErrorAlert error={setProperty?.error} className={classes.errorAlert} />
      }
    >
      <FormikProvider value={formik}>
        <PropertyForm
          values={formik.values}
          counterIds={[]}
          onChange={formik.handleChange}
          onFieldChange={(fieldName, value) => {
            formik.setFieldValue(fieldName, value);
          }}
        />
      </FormikProvider>
    </Dialog>
  );
}

export default NewPropertyDialog;
