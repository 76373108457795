import React, { MouseEvent, useState } from "react";
import {
  Avatar,
  Divider,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import routes from "../../constants/routes";
import MenuItem from "@material-ui/core/MenuItem";
import { useKeycloak } from "@react-keycloak/web";
import { queryClient } from "../../App";
import getUserData from "../../utils/getUserData";
import { IdTokenParsed } from "../../types";
import { Box } from "@material-ui/core";
import hasRealmRole from "../../utils/hasRealmRole";
import Role from "../../auth/Role";
import { BODY_TEXT_COLOR } from "../../theme";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import PersonIcon from "@material-ui/icons/Person";

interface Props {
  avatar: string;
  onProfileClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: 0,
    padding: 0,
    minWidth: "auto",
    "& > .MuiButton-label": {
      padding: 0,
    },
  },
  avatarDropdownMenu: {
    marginTop: 48,
    "& ul": {
      padding: 0,
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  menuButton: {
    width: "100%",
    height: 40,
    textAlign: "left",
  },
  listItemText: {
    "& > span": {
      color: BODY_TEXT_COLOR,
      textTransform: "capitalize",
      fontWeight: 400,
      fontSize: 14,
    },
  },
  listItemIcon: {
    minWidth: 20,
    marginRight: 18,
  },
  userInfo: {
    "& > h5": {
      marginBottom: 8,
    },
  },
}));

function AvatarDropdownMenu({ avatar, onProfileClick }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const { keycloak } = useKeycloak();
  const enableDevOptions = hasRealmRole(keycloak, Role.Dev);
  const isOwner = hasRealmRole(keycloak, Role.Owner);

  const handleClick = (event: MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (route: string) => {
    history.push(route);
    handleClose();
  };

  const userData = getUserData(keycloak.idTokenParsed as IdTokenParsed);

  const devOptions = enableDevOptions && [
    <MenuItem
      key="menu-item-admin"
      onClick={() => handleMenuItemClick(routes.admin.properties)}
    >
      {t("admin")}
    </MenuItem>,
    <MenuItem
      key="menu-item-owner"
      onClick={() => handleMenuItemClick(routes.owner.dashboard)}
    >
      {t("owner")}
    </MenuItem>,
    <MenuItem
      key="menu-item-tenant"
      onClick={() => handleMenuItemClick(routes.tenant.overview)}
    >
      {t("tenant")}
    </MenuItem>,
  ];

  const userInfo = (
    <Box width={260}>
      <Box padding="16px" className={classes.userInfo}>
        {userData?.displayName && (
          <Typography variant="h5">{userData.displayName}</Typography>
        )}
        {userData?.email && <Typography>{userData.email}</Typography>}
      </Box>
      <Divider />
    </Box>
  );

  const profileMenuItem = isOwner && onProfileClick && (
    <MenuItem
      onClick={() => {
        onProfileClick();
        handleClose();
      }}
      className={classes.menuButton}
    >
      <ListItemIcon className={classes.listItemIcon}>
        <PersonIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText className={classes.listItemText}>
        {t("myProfile")}
      </ListItemText>
    </MenuItem>
  );

  return (
    <div>
      <IconButton className={classes.button} onClick={handleClick} size="small">
        <Avatar className={classes.avatar}>
          {userData?.avatarLabel || avatar}
        </Avatar>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        className={classes.avatarDropdownMenu}
      >
        {userInfo}
        {devOptions}
        {profileMenuItem}
        <MenuItem
          onClick={() => {
            queryClient.clear();
            keycloak.logout();
            localStorage.clear();
          }}
          className={classes.menuButton}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <PowerSettingsNewIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText className={classes.listItemText}>
            {t("logout")}
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default AvatarDropdownMenu;
