import { gql } from "graphql-request";
import { useQuery } from "react-query";
import QueryKeys from "../QueryKeys";
import { GetInvoicesFiltersInput, InvoiceDto } from "../types";
import { gqlClient } from "../../App";
import INVOICE_NAME_FIELDS from "../fragments/invoiceNameFields";

function useListInvoices(filters?: GetInvoicesFiltersInput, enabled = true) {
  return useQuery(
    [QueryKeys.LIST_INVOICES, filters],
    async () => {
      return await gqlClient.request<{ invoices: InvoiceDto[] }>(
        gql`
          ${INVOICE_NAME_FIELDS}
          query Invoices($filters: GetInvoicesFiltersInput) {
            invoices(filters: $filters) {
              id
              date
              ...InvoiceNameFields
            }
          }
        `,
        {
          filters,
          operationName: QueryKeys.LIST_INVOICES,
        }
      );
    },
    {
      enabled,
    }
  );
}

export default useListInvoices;
