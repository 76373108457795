import { ConsumptionType } from "../../api/types";
import { Unit } from "../../types";

const unitMap: Record<string, string> = {
  [ConsumptionType.ElectricityL]: Unit.Energy,
  [ConsumptionType.ElectricityH]: Unit.Energy,
  [ConsumptionType.ElectricityPv]: Unit.Energy,
  [ConsumptionType.Electricity]: Unit.Energy,
  [ConsumptionType.MaximumPower]: Unit.Power,
  [ConsumptionType.Charging]: Unit.Energy,
  [ConsumptionType.HeatingEnergy]: Unit.Energy,
  [ConsumptionType.WaterColdVolume]: Unit.Volume,
  [ConsumptionType.WaterWarmVolume]: Unit.Volume,
  [ConsumptionType.WaterColdFlow]: Unit.Flow,
  [ConsumptionType.WaterWarmFlow]: Unit.Flow,
  [ConsumptionType.AdditionalCost]: Unit.Month,
};

function getUnit(consumptionType: string) {
  return unitMap[consumptionType] || "";
}

export default getUnit;
