import React from "react";
import {
  IconButton,
  makeStyles,
  Paper,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ApartmentDto } from "../../../../api/types";
import { useTranslation } from "react-i18next";
import { SUBHEADER_COLOR } from "../../../../theme";
import ViewAgendaOutlinedIcon from "@material-ui/icons/ViewAgendaOutlined";
import Row from "../../../../components/Row";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useHistory, useParams } from "react-router";
import routes from "../../../../constants/routes";
import LockIcon from "@material-ui/icons/Lock";

const useStyles = makeStyles(() => ({
  paper: { borderRadius: 6, boxShadow: "none" },
  tableHeadRow: {
    height: 48,
    "& > th": {
      paddingTop: 0,
      paddingBottom: 0,
      textTransform: "none",
      color: SUBHEADER_COLOR,
      "& svg": {
        color: SUBHEADER_COLOR,
      },
    },
  },
  tableBodyRow: {
    cursor: "pointer",
    height: 43,
    "& > td": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  lockIcon: {
    marginLeft: 8,
    verticalAlign: "middle",
    color: SUBHEADER_COLOR,
  },
}));

interface Props {
  apartments: Array<any>;
}

function ApartmentsTable({ apartments }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { propertyId } = useParams<{ propertyId: string }>();
  const history = useHistory();

  const cols = [
    {
      id: "apartmentName",
      label: t("apartment"),
      getValue: (apartment: ApartmentDto) => (
        <Row>
          <ViewAgendaOutlinedIcon fontSize="small" />
          <Typography>{apartment.apartmentNumber}</Typography>
          {apartment?.isEditable === false && (
            <LockIcon
              fontSize="small"
              color="inherit"
              className={classes.lockIcon}
            />
          )}
        </Row>
      ),
    },
    {
      id: "energy",
      label: "",
      getValue: () => "",
    },
    {
      id: "warmWater",
      label: "",
      getValue: () => "",
    },
    {
      id: " coldWater",
      label: "",
      getValue: () => "",
    },
    {
      id: "electricityH",
      label: "",
      getValue: () => "",
    },
    {
      id: "electricityL",
      label: "",
      getValue: () => "",
    },
    {
      id: "electricityPv",
      label: "",
      getValue: () => "",
    },
    {
      id: "openDetailButton",
      label: "",
      getValue: (apartment: ApartmentDto) => (
        <IconButton
          size="small"
          onClick={() =>
            history.push(
              routes.owner.getApartmentDetail(propertyId, apartment?.id)
            )
          }
        >
          <ChevronRightIcon />
        </IconButton>
      ),
    },
  ];

  if (!apartments?.length) {
    return null;
  }

  return (
    <TableContainer component={Paper} className={classes.paper}>
      <MUITable aria-label="table">
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            {cols.map((col) => (
              <TableCell className={classes.tableHeadRow} key={col.id}>
                {col.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {apartments?.map((apartment) => (
            <TableRow
              key={apartment.id}
              className={classes.tableBodyRow}
              onClick={() =>
                history.push(
                  routes.owner.getApartmentDetail(propertyId, apartment?.id)
                )
              }
            >
              {cols.map((col) => (
                <TableCell key={col.id} align="right">
                  {col.getValue ? col.getValue(apartment) : ""}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
}

export default ApartmentsTable;
