export const BASE_URL: string =
  process.env.REACT_APP_BASE_URL ?? "http://localhost:3001";

export const GQL_BASE_URL: string =
  process.env.REACT_APP_GQL_BASE_URL || `${BASE_URL}/graphql`;

export const DEV_BASE_URL: string =
  process.env.REACT_APP_DEV_BASE_URL ?? "http://localhost:3000";

export const AUTHORITY: string = process.env.REACT_APP_AUTHORITY ?? "";

export const KEYCLOAK_REALM: string =
  process.env.REACT_APP_KEYCLOAK_REALM ?? "";

export const CLIENT_ID: string = process.env.REACT_APP_CLIENT_ID ?? "";

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";

export const MONTH_DATE_FORMAT = "YYYY/MM";

export const DEV_ENV =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";
