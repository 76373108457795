import React from "react";
import { makeStyles } from "@material-ui/core";
import { horizontalDivider } from "../../assets";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  img: {
    height: "100%",
  },
}));

export default function HorizontalDivider() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.img} src={horizontalDivider} alt="" />
    </div>
  );
}
