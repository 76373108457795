import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SELECTOR_WIDTH } from "../../theme";
import { makeStyles } from "@material-ui/core";
import { CardHeader } from "../../components/Card";
import { gql } from "graphql-request";
import {
  ApartmentSelector_ApartmentFragment,
  ApartmentSelectorDataQuery,
} from "../../api/types";
import { useQuery } from "react-query";
import QueryKeys from "../../api/QueryKeys";
import { gqlClient } from "../../App";
import SearchableMenu from "../../components/SearchableMenu";
import { ApartmentIconPrimary } from "../../assets/icons";
import clsx from "clsx";
import { useHistory, useParams } from "react-router";
import useDebounce from "../../hooks/useDebounce";
import hasRealmRole from "../../utils/hasRealmRole";
import keycloak from "../../auth/keycloak";
import Role from "../../auth/Role";
import routes from "../../constants/routes";

const useStyles = makeStyles(() => ({
  root: {
    width: SELECTOR_WIDTH,
  },
  disabled: {
    opacity: 0.6,
    pointerEvents: "none",
  },
}));

export interface ApartmentSelectorProps<T extends object = any> {
  onSelect?: (apartment: T | null) => void;
  disabled?: boolean;
  apartments?: Array<T>;
  removeAllItemsOption?: boolean;
}

function useApartmentSelectorData() {
  return useQuery<ApartmentSelectorDataQuery>(
    [QueryKeys.APARTMENT_SELECTOR],
    async () =>
      await gqlClient.request(gql`
        ${ApartmentSelector.fragments.apartment}
        query ApartmentSelectorData {
          apartments {
            ...ApartmentSelector_Apartment
          }
        }
      `)
  );
}

function ApartmentSelector({
  onSelect,
  disabled,
  apartments,
  removeAllItemsOption,
}: ApartmentSelectorProps) {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { data } = useApartmentSelectorData();
  const { propertyId, apartmentId } = useParams<{
    propertyId: string;
    apartmentId: string;
  }>();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 300).toLowerCase();

  const propertyApartments = data?.apartments?.filter(
    // @ts-ignore
    (apartment) => apartment?.property?.id === propertyId
  );

  const filteredApartments = propertyApartments?.filter(
    (apartment) =>
      apartment?.id?.toLowerCase()?.includes(debouncedSearchQuery) ||
      apartment?.name?.toLowerCase()?.includes(debouncedSearchQuery) ||
      apartment?.apartmentNumber?.toLowerCase()?.includes(debouncedSearchQuery)
  );

  const [anchorEl, setAnchorEl] = useState<any>(undefined);
  const [
    selectedApartment,
    setSelectedApartment,
  ] = useState<ApartmentSelector_ApartmentFragment | null>(null);

  const isTenant = hasRealmRole(keycloak, Role.Tenant);

  useEffect(() => {
    const apartment = apartments?.length && apartments[0];
    if (isTenant && apartment) {
      setSelectedApartment(apartment);
      history.push(routes.tenant.getApartmentDetail(apartment?.id || ""));
    }
  }, [apartments, isTenant, history]);

  useEffect(() => {
    if (apartmentId) {
      const selectedApartment = data?.apartments?.find(
        (apartment) => apartment?.id === apartmentId
      );
      setSelectedApartment(selectedApartment || null);
    }
  }, [apartmentId, data, setSelectedApartment]);

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (
    apartmentId?: string | null,
    apartment?: ApartmentSelector_ApartmentFragment | null
  ) => {
    setSelectedApartment(apartment || null);
    handleClose();

    if (onSelect) {
      onSelect(apartment || null);
    }
  };

  return (
    <div className={clsx(classes.root, { [classes.disabled]: disabled })}>
      <CardHeader
        title={selectedApartment?.apartmentNumber || t("all")}
        label={t("apartment")}
        icon={ApartmentIconPrimary}
        onClick={handleClick}
      />
      <SearchableMenu<ApartmentSelector_ApartmentFragment>
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        items={apartments || filteredApartments || new Array<any>()}
        getId={(item) => item?.id}
        getName={(item) => item?.apartmentNumber || item?.name}
        onSelect={handleSelect}
        value={selectedApartment?.id}
        onChangeSearchQuery={(value) => setSearchQuery(value)}
        removeAllItemsOption={removeAllItemsOption}
      />
    </div>
  );
}

ApartmentSelector.fragments = {
  apartment: gql`
    fragment ApartmentSelector_Apartment on ApartmentDto {
      id
      name
      apartmentNumber
      property {
        id
      }
    }
  `,
};

export default ApartmentSelector;
