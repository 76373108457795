import { gql } from "graphql-request/dist";

export const CONSUMPTION_PRICING_FIELDS = gql`
  fragment ConsumptionPricingFields on ConsumptionPricingDto {
    basePrice
    pricingType
    label
  }
`;

export default CONSUMPTION_PRICING_FIELDS;
