import React, { Fragment } from "react";
import {
  ConsumptionType,
  CounterPricingDto,
  CounterType,
  InvoiceItemType,
} from "../../../api/types";
import { FieldArray } from "formik";
import { Button, Grid, IconButton, MenuItem, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";
import getLabel from "../../../utils/chart/getLabel";
import { PropertyPricesFormFields } from "./PropertyPricesForm";
import RemoveIcon from "@material-ui/icons/Remove";
import Input from "../../Input";
import Label from "../../Label/Label";
import { BORDER_COLOR_SECONDARY, SUBHEADER_COLOR } from "../../../theme";

const useStyles = makeStyles(() => ({
  deleteButton: {
    border: `1px solid ${BORDER_COLOR_SECONDARY}`,
    borderRadius: 4,
    height: 32,
    width: 32,
    marginTop: 18,
    color: SUBHEADER_COLOR,
  },
  addButton: { color: SUBHEADER_COLOR, marginTop: 4 },
  select: {
    width: "100%",
  },
  label: {
    fontSize: 12,
  },
  gridRow: {
    marginBottom: 12,
    maxWidth: 640,
  },
  priceInput: {
    "& > div": {
      display: "flex",
      alignItems: "baseline",
    },
  },
}));

const counterTypes: Array<{ id: CounterType; name: string }> = [
  {
    id: CounterType.Electricity,
    name: getLabel(ConsumptionType.Electricity),
  },
  {
    id: CounterType.Heating,
    name: getLabel(ConsumptionType.HeatingEnergy),
  },
  {
    id: CounterType.WaterCold,
    name: getLabel(InvoiceItemType.WaterColdVolume),
  },
  {
    id: CounterType.WaterWarm,
    name: getLabel(InvoiceItemType.WaterWarmVolume),
  },
  {
    id: CounterType.Charging,
    name: getLabel(InvoiceItemType.Charging),
  },
];

interface Props {
  counterPricings?: Array<CounterPricingDto>;
  isEditable?: boolean;
}

function CounterPricings({ counterPricings = [], isEditable = true }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <FieldArray
      name={PropertyPricesFormFields.CounterPricings}
      validateOnChange={false}
    >
      {({ remove, push, replace }) => (
        <Fragment>
          {counterPricings.map((pricing, index) => (
            <Grid
              key={index}
              container
              spacing={3}
              alignItems="flex-start"
              className={classes.gridRow}
            >
              <Grid item xs={12} sm={5}>
                <Label value={t("counterType")} />
                <Select
                  labelId="counter-type-label"
                  id="counter-type-select"
                  value={pricing.counterType}
                  className={classes.select}
                  disabled={!isEditable}
                  onChange={(e) =>
                    replace(index, {
                      ...pricing,
                      counterType: e.target.value,
                    })
                  }
                  color="secondary"
                  disableUnderline
                >
                  {counterTypes?.map((type, index) => (
                    <MenuItem value={type.id} key={index}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={10} sm={5}>
                <Input
                  label={t("pricePerMonth")}
                  value={pricing.price}
                  fullWidth
                  color="secondary"
                  type="number"
                  unit="CHF"
                  gutterBottom={0}
                  className={classes.priceInput}
                  disabled={!isEditable}
                  onChange={(e) =>
                    replace(index, {
                      ...pricing,
                      price: e.target.value,
                    })
                  }
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={1} sm={2}>
                <IconButton
                  onClick={() => remove(index)}
                  className={classes.deleteButton}
                  disableRipple
                  disabled={!isEditable}
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              onClick={() =>
                push({
                  counterType: "",
                  price: "",
                })
              }
              size="small"
              variant="outlined"
              color="secondary"
              startIcon={<AddIcon />}
              className={classes.addButton}
              disabled={!isEditable}
            >
              {t("priceConfiguration")}
            </Button>
          </Grid>
        </Fragment>
      )}
    </FieldArray>
  );
}

export default CounterPricings;
