import {
  ConsumptionPricingFieldsFragment,
  ConsumptionType,
} from "../api/types";

const getConsumptionPriceValue = (value: number | undefined) => {
  if (value && value > 0) {
    return value;
  }

  return "";
};

function getApartmentPricingValues(
  pricing: Array<ConsumptionPricingFieldsFragment>
) {
  return {
    pricingElectricityL: getConsumptionPriceValue(
      pricing?.find((p) => p.pricingType === ConsumptionType.ElectricityL)
        ?.basePrice
    ),
    pricingElectricityH: getConsumptionPriceValue(
      pricing?.find((p) => p.pricingType === ConsumptionType.ElectricityH)
        ?.basePrice
    ),
    pricingElectricityPV: getConsumptionPriceValue(
      pricing?.find((p) => p.pricingType === ConsumptionType.ElectricityPv)
        ?.basePrice
    ),
    pricingElectricity: getConsumptionPriceValue(
      pricing?.find((p) => p.pricingType === ConsumptionType.Electricity)
        ?.basePrice
    ),
    pricingMaximumPower: getConsumptionPriceValue(
      pricing?.find((p) => p.pricingType === ConsumptionType.MaximumPower)
        ?.basePrice
    ),
    pricingWaterWarm: getConsumptionPriceValue(
      pricing?.find((p) => p.pricingType === ConsumptionType.WaterWarmVolume)
        ?.basePrice
    ),
    pricingWaterCold: getConsumptionPriceValue(
      pricing?.find((p) => p.pricingType === ConsumptionType.WaterColdVolume)
        ?.basePrice
    ),
    pricingHeating: getConsumptionPriceValue(
      pricing?.find((p) => p.pricingType === ConsumptionType.HeatingEnergy)
        ?.basePrice
    ),
    pricingCharging: getConsumptionPriceValue(
      pricing?.find((p) => p.pricingType === ConsumptionType.Charging)
        ?.basePrice
    ),
  };
}

export default getApartmentPricingValues;
