import { useEffect } from "react";
import getPricingValues from "../utils/getPricingValues";
import {
  ConsumptionType,
  PropertyDetailFragment,
  PhotovoltaicsCostDistributionType,
} from "../api/types";
import formatPropertyOwnerName from "../utils/format/formatPropertyOwnerName";
import * as React from "react";
import { FormikErrors } from "formik/dist/types";
import { PropertyFormValues } from "../types";

export default function useSetPropertyValues(
  property: PropertyDetailFragment | undefined | null,
  setValuesFunc: (
    values: React.SetStateAction<PropertyFormValues>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<PropertyFormValues>> | Promise<void>,
  tab: number
) {
  useEffect(() => {
    if (property) {
      const pricing = property?.defaultConsumptionPricings;
      const pricingValues = getPricingValues(pricing);
      const additionalCosts = property?.defaultConsumptionPricings?.filter(
        (p) => p.pricingType === ConsumptionType.AdditionalCost
      );
      const counterPricings = property?.counterPricings;
      const amortizedCosts = property?.amortizedCosts ?? [];
      const owner: any = property?.owners?.[0] || null;
      const photovoltaicsConfig = property?.photovoltaicsConfig;

      setValuesFunc({
        name: property?.name,
        propertyId: property?.id,
        dataGatewayId: property?.dataGatewayId ?? "",
        city: property?.address?.city,
        addressLine1: property?.address?.addressLineOne,
        addressLine2: property?.address?.addressLineTwo,
        zip: property?.address?.zip,
        mainCounterName: property?.mainCounterName || "",
        propertyOwner: owner
          ? { ...owner, name: formatPropertyOwnerName(owner) }
          : null,
        additionalCosts: [...additionalCosts],
        counterPricings: [...counterPricings],
        amortizedCosts: [...amortizedCosts],
        areaSize: property?.areaSize?.toString() ?? "",
        ...pricingValues,
        costDistributionType:
          photovoltaicsConfig?.costDistributionType ||
          PhotovoltaicsCostDistributionType.Uniform,
      });
    }
  }, [property, setValuesFunc, tab]);
}
