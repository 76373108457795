import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  DateTime: any;
};

export type AddressDto = {
  __typename?: 'AddressDto';
  addressLineOne: Scalars['String'];
  addressLineTwo: Scalars['String'];
  city: Scalars['String'];
  zip: Scalars['String'];
};

export type AddressInput = {
  addressLineOne: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  zip: Scalars['String'];
};

export type AmortizedCostDto = {
  __typename?: 'AmortizedCostDto';
  description: Scalars['String'];
  counterType: CounterType;
  amount: Scalars['Float'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  costDistributionType: CostPortion;
};

export type AmortizedCostInput = {
  description: Scalars['String'];
  counterType: CounterType;
  amount: Scalars['Float'];
  from: Scalars['String'];
  to: Scalars['String'];
  costDistributionType: CostPortion;
};

export type ApartmentDto = {
  __typename?: 'ApartmentDto';
  id: Scalars['ID'];
  name: Scalars['String'];
  floor: Scalars['String'];
  apartmentNumber?: Maybe<Scalars['String']>;
  tenants: Array<Maybe<TenantWithDatesDto>>;
  property: PropertyDto;
  invoices: Array<InvoiceDto>;
  consumptionPricings: Array<ConsumptionPricingDto>;
  isEditable: Scalars['Boolean'];
  areaSize?: Maybe<Scalars['Float']>;
};

export type ApartmentWithDatesDto = {
  __typename?: 'ApartmentWithDatesDto';
  id: Scalars['ID'];
  name: Scalars['String'];
  floor: Scalars['String'];
  apartmentNumber?: Maybe<Scalars['String']>;
  tenants: Array<Maybe<TenantDto>>;
  property: PropertyDto;
  dateIn?: Maybe<Scalars['DateTime']>;
  dateOut?: Maybe<Scalars['DateTime']>;
  isEditable: Scalars['Boolean'];
};

export type ConsumptionDataDto = {
  __typename?: 'ConsumptionDataDto';
  day: Array<ConsumptionItemDto>;
  week: Array<ConsumptionItemDto>;
  month: Array<ConsumptionItemDto>;
  halfyear: Array<ConsumptionItemDto>;
  year: Array<ConsumptionItemDto>;
};

export type ConsumptionDto = {
  __typename?: 'ConsumptionDto';
  data: ConsumptionDataDto;
};

export type ConsumptionItemDto = {
  __typename?: 'ConsumptionItemDto';
  timestamp: Scalars['DateTime'];
  item: Scalars['String'];
  value: Scalars['Float'];
  unit: Scalars['String'];
  itemType: ConsumptionType;
  totalPrice: Scalars['Float'];
};

export type ConsumptionPricingDto = {
  __typename?: 'ConsumptionPricingDto';
  pricingType: ConsumptionType;
  basePrice: Scalars['Float'];
  label?: Maybe<Scalars['String']>;
};

export type ConsumptionPricingInput = {
  pricingType: ConsumptionType;
  basePrice: Scalars['Float'];
  label?: Maybe<Scalars['String']>;
};

export enum ConsumptionType {
  AdditionalCost = 'AdditionalCost',
  ElectricityL = 'Electricity_L',
  ElectricityH = 'Electricity_H',
  ElectricityPv = 'Electricity_PV',
  Electricity = 'Electricity',
  Charging = 'Charging',
  Power = 'Power',
  MaximumPower = 'MaximumPower',
  HeatingEnergy = 'Heating_Energy',
  HeatingFlow = 'Heating_Flow',
  WaterWarmVolume = 'Water_Warm_Volume',
  WaterWarmFlow = 'Water_Warm_Flow',
  WaterColdVolume = 'Water_Cold_Volume',
  WaterColdFlow = 'Water_Cold_Flow',
  CpuInfo = 'CPU_Info',
  MemoryUsage = 'Memory_Usage',
  MemoryMax = 'Memory_Max'
}

export enum CostPortion {
  Area = 'AREA',
  Consumption = 'CONSUMPTION',
  Uniform = 'UNIFORM'
}

export type CounterPricingDto = {
  __typename?: 'CounterPricingDto';
  counterType: CounterType;
  price: Scalars['Float'];
};

export type CounterPricingInput = {
  counterType: CounterType;
  price: Scalars['Float'];
};

export enum CounterType {
  Electricity = 'Electricity',
  Charging = 'Charging',
  Heating = 'Heating',
  WaterWarm = 'Water_Warm',
  WaterCold = 'Water_Cold',
  ElectricityPv = 'Electricity_PV',
  MaximumPower = 'MaximumPower'
}

export type DataGatewayDto = {
  __typename?: 'DataGatewayDto';
  id: Scalars['ID'];
  property: PropertyDto;
  systemUsage: ConsumptionDto;
};


export type FetchConsumptionDataInput = {
  from: Scalars['String'];
  to: Scalars['String'];
  propertyId: Scalars['ID'];
  apartmentId?: Maybe<Scalars['ID']>;
  counters?: Maybe<Array<ConsumptionType>>;
  level?: Maybe<Level>;
};

export type GetFiltersInput = {
  id?: Maybe<Scalars['ID']>;
};

export type GetInvoicesFiltersInput = {
  id?: Maybe<Scalars['ID']>;
  apartmentId?: Maybe<Scalars['ID']>;
  tenantId?: Maybe<Scalars['ID']>;
};

export type InvoiceDto = {
  __typename?: 'InvoiceDto';
  id: Scalars['ID'];
  date: Scalars['DateTime'];
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  items: Array<InvoiceItemDto>;
  vat: Scalars['Float'];
  total: Scalars['Float'];
  nr: Scalars['Int'];
  tenant: TenantDto;
  apartment: ApartmentDto;
  dateSent?: Maybe<Scalars['DateTime']>;
  messagingStartTime?: Maybe<Scalars['DateTime']>;
  intervalMonths?: Maybe<Scalars['Int']>;
};

export type InvoiceItemDto = {
  __typename?: 'InvoiceItemDto';
  itemType: InvoiceItemType;
  itemTotal: Scalars['Float'];
  unitAmount: Scalars['Float'];
  unitPrice: Scalars['Float'];
  label: Scalars['String'];
};

export enum InvoiceItemType {
  AdditionalCost = 'AdditionalCost',
  ElectricityL = 'Electricity_L',
  ElectricityH = 'Electricity_H',
  ElectricityPv = 'Electricity_PV',
  Electricity = 'Electricity',
  MaximumPower = 'MaximumPower',
  Charging = 'Charging',
  WaterWarmVolume = 'Water_Warm_Volume',
  WaterColdVolume = 'Water_Cold_Volume',
  HeatingEnergy = 'Heating_Energy',
  Counter = 'Counter'
}

export enum Level {
  Quarterhour = 'quarterhour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Halfyear = 'halfyear',
  Year = 'year'
}

export type Mutation = {
  __typename?: 'Mutation';
  setProperty?: Maybe<PropertyDto>;
  addReadonlyProperty: Scalars['ID'];
  deleteProperty?: Maybe<Scalars['ID']>;
  setPropertyOwner?: Maybe<PropertyOwnerDto>;
  deletePropertyOwner?: Maybe<Scalars['ID']>;
  setApartment?: Maybe<ApartmentDto>;
  deleteApartment?: Maybe<Scalars['ID']>;
  setTenant?: Maybe<TenantDto>;
  deleteTenant?: Maybe<Scalars['ID']>;
  setInvoice?: Maybe<InvoiceDto>;
  deleteInvoice?: Maybe<Scalars['ID']>;
  setDataGateway?: Maybe<DataGatewayDto>;
  deleteDataGateway?: Maybe<Scalars['ID']>;
};


export type MutationSetPropertyArgs = {
  input: SetPropertyInput;
};


export type MutationAddReadonlyPropertyArgs = {
  gatewayId: Scalars['String'];
};


export type MutationDeletePropertyArgs = {
  id: Scalars['String'];
};


export type MutationSetPropertyOwnerArgs = {
  input: SetPropertyOwnerInput;
};


export type MutationDeletePropertyOwnerArgs = {
  id: Scalars['String'];
};


export type MutationSetApartmentArgs = {
  input: SetApartmentInput;
};


export type MutationDeleteApartmentArgs = {
  id: Scalars['String'];
};


export type MutationSetTenantArgs = {
  input: SetTenantInput;
};


export type MutationDeleteTenantArgs = {
  id: Scalars['String'];
};


export type MutationSetInvoiceArgs = {
  input: SetInvoiceInput;
};


export type MutationDeleteInvoiceArgs = {
  id: Scalars['String'];
};


export type MutationSetDataGatewayArgs = {
  input: SetDataGatewayInput;
};


export type MutationDeleteDataGatewayArgs = {
  id: Scalars['String'];
};

export type PhotovoltaicsConfigDto = {
  __typename?: 'PhotovoltaicsConfigDto';
  costDistributionType?: Maybe<PhotovoltaicsCostDistributionType>;
};

export type PhotovoltaicsConfigInput = {
  costDistributionType: PhotovoltaicsCostDistributionType;
};

export enum PhotovoltaicsCostDistributionType {
  Consumption = 'CONSUMPTION',
  Uniform = 'UNIFORM',
  None = 'NONE'
}

export type PropertyDto = {
  __typename?: 'PropertyDto';
  id: Scalars['ID'];
  name: Scalars['String'];
  dataGatewayId?: Maybe<Scalars['ID']>;
  address: AddressDto;
  apartments: Array<Maybe<ApartmentDto>>;
  dataGateways: Array<Maybe<DataGatewayDto>>;
  mainOwner?: Maybe<PropertyOwnerDto>;
  owners?: Maybe<Array<PropertyOwnerDto>>;
  defaultConsumptionPricings: Array<ConsumptionPricingDto>;
  counterPricings: Array<CounterPricingDto>;
  amortizedCosts?: Maybe<Array<AmortizedCostDto>>;
  photovoltaicsConfig?: Maybe<PhotovoltaicsConfigDto>;
  mainCounterName?: Maybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  areaSize?: Maybe<Scalars['Float']>;
};

export type PropertyOwnerDto = UserDto & {
  __typename?: 'PropertyOwnerDto';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  address?: Maybe<AddressDto>;
  role: Role;
  properties: Array<Maybe<PropertyDto>>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  beneficiaryName?: Maybe<Scalars['String']>;
  beneficiaryAddress?: Maybe<AddressDto>;
  beneficiaryVAT?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getApartmentIdsForProperty: Array<Scalars['String']>;
  properties: Array<Maybe<PropertyDto>>;
  propertyOwners: Array<Maybe<PropertyOwnerDto>>;
  apartments: Array<Maybe<ApartmentDto>>;
  tenants: Array<Maybe<TenantDto>>;
  invoices: Array<Maybe<InvoiceDto>>;
  dataGateways: Array<Maybe<DataGatewayDto>>;
  getConsumption: Array<ConsumptionItemDto>;
  getConsumptionAsCsv: Scalars['String'];
};


export type QueryGetApartmentIdsForPropertyArgs = {
  propertyId: Scalars['String'];
};


export type QueryPropertiesArgs = {
  filters?: Maybe<GetFiltersInput>;
};


export type QueryPropertyOwnersArgs = {
  filters?: Maybe<GetFiltersInput>;
};


export type QueryApartmentsArgs = {
  filters?: Maybe<GetFiltersInput>;
};


export type QueryTenantsArgs = {
  filters?: Maybe<GetFiltersInput>;
};


export type QueryInvoicesArgs = {
  filters?: Maybe<GetInvoicesFiltersInput>;
};


export type QueryDataGatewaysArgs = {
  filters?: Maybe<GetFiltersInput>;
};


export type QueryGetConsumptionArgs = {
  consumptionInput: FetchConsumptionDataInput;
};


export type QueryGetConsumptionAsCsvArgs = {
  consumptionInput: FetchConsumptionDataInput;
};

export enum Role {
  Admin = 'admin',
  Owner = 'owner',
  Tenant = 'tenant',
  Anonymous = 'anonymous'
}

export type SetApartmentInput = {
  id?: Maybe<Scalars['ID']>;
  propertyId: Scalars['String'];
  name: Scalars['String'];
  floor?: Maybe<Scalars['String']>;
  apartmentNumber?: Maybe<Scalars['String']>;
  consumptionPricings: Array<Maybe<ConsumptionPricingInput>>;
  areaSize?: Maybe<Scalars['Float']>;
};

export type SetDataGatewayInput = {
  id: Scalars['ID'];
  propertyId: Scalars['ID'];
};

export type SetInvoiceInput = {
  startMonth: Scalars['String'];
  endMonth: Scalars['String'];
  includeVat: Scalars['Boolean'];
  tenantId: Scalars['ID'];
  apartmentId: Scalars['ID'];
  includedConsumptionTypes?: Maybe<Array<ConsumptionType>>;
  dateSent?: Maybe<Scalars['String']>;
  messagingStartTime?: Maybe<Scalars['String']>;
  intervalMonths?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
};

export type SetPropertyInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  dataGatewayId?: Maybe<Scalars['ID']>;
  address: AddressInput;
  defaultConsumptionPricings: Array<Maybe<ConsumptionPricingInput>>;
  counterPricings: Array<Maybe<CounterPricingInput>>;
  amortizedCosts: Array<Maybe<AmortizedCostInput>>;
  photovoltaicsConfig?: Maybe<PhotovoltaicsConfigInput>;
  mainCounterName?: Maybe<Scalars['String']>;
  propertyOwnerId?: Maybe<Scalars['ID']>;
  areaSize?: Maybe<Scalars['Float']>;
};

export type SetPropertyOwnerInput = {
  id?: Maybe<Scalars['ID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  address: AddressInput;
  propertyIds: Array<Maybe<Scalars['ID']>>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  beneficiaryName?: Maybe<Scalars['String']>;
  beneficiaryAddress?: Maybe<AddressInput>;
  beneficiaryVAT?: Maybe<Scalars['String']>;
};

export type SetTenantInput = {
  id?: Maybe<Scalars['ID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  address: AddressInput;
  apartments: Array<Maybe<TenantApartmentInput>>;
};

export type TenantApartmentInput = {
  apartmentId: Scalars['ID'];
  dateIn: Scalars['String'];
  dateOut?: Maybe<Scalars['String']>;
};

export type TenantDto = UserDto & {
  __typename?: 'TenantDto';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  address?: Maybe<AddressDto>;
  role: Role;
  apartments: Array<Maybe<ApartmentWithDatesDto>>;
  isEditable: Scalars['Boolean'];
  owners: Array<PropertyOwnerDto>;
};

export type TenantWithDatesDto = UserDto & {
  __typename?: 'TenantWithDatesDto';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  address?: Maybe<AddressDto>;
  role: Role;
  apartments: Array<Maybe<ApartmentDto>>;
  dateIn?: Maybe<Scalars['DateTime']>;
  dateOut?: Maybe<Scalars['DateTime']>;
  isEditable: Scalars['Boolean'];
};

export type UserDto = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  address?: Maybe<AddressDto>;
  role: Role;
};

export type AddressFieldsFragment = (
  { __typename?: 'AddressDto' }
  & Pick<AddressDto, 'addressLineOne' | 'addressLineTwo' | 'city' | 'zip'>
);

export type ChartItemFieldsFragment = (
  { __typename?: 'ConsumptionItemDto' }
  & Pick<ConsumptionItemDto, 'timestamp' | 'item' | 'itemType' | 'unit' | 'value' | 'totalPrice'>
);

export type ConsumptionFieldsFragment = (
  { __typename?: 'ConsumptionDto' }
  & { data: (
    { __typename?: 'ConsumptionDataDto' }
    & { day: Array<(
      { __typename?: 'ConsumptionItemDto' }
      & ChartItemFieldsFragment
    )>, week: Array<(
      { __typename?: 'ConsumptionItemDto' }
      & ChartItemFieldsFragment
    )>, month: Array<(
      { __typename?: 'ConsumptionItemDto' }
      & ChartItemFieldsFragment
    )>, halfyear: Array<(
      { __typename?: 'ConsumptionItemDto' }
      & ChartItemFieldsFragment
    )>, year: Array<(
      { __typename?: 'ConsumptionItemDto' }
      & ChartItemFieldsFragment
    )> }
  ) }
);

export type ConsumptionPricingFieldsFragment = (
  { __typename?: 'ConsumptionPricingDto' }
  & Pick<ConsumptionPricingDto, 'basePrice' | 'pricingType' | 'label'>
);

export type InvoiceNameFieldsFragment = (
  { __typename?: 'InvoiceDto' }
  & Pick<InvoiceDto, 'id' | 'fromDate' | 'toDate' | 'date' | 'nr' | 'dateSent' | 'messagingStartTime' | 'intervalMonths'>
  & { tenant: (
    { __typename?: 'TenantDto' }
    & Pick<TenantDto, 'firstName' | 'lastName'>
  ) }
);

export type TenantFieldsFragment = (
  { __typename?: 'TenantWithDatesDto' }
  & Pick<TenantWithDatesDto, 'firstName' | 'lastName' | 'id' | 'email' | 'dateIn' | 'dateOut'>
  & { apartments: Array<Maybe<(
    { __typename?: 'ApartmentDto' }
    & Pick<ApartmentDto, 'id'>
  )>> }
);

export type ApartmentIdsForPropertyQueryVariables = Exact<{
  propertyId: Scalars['String'];
}>;


export type ApartmentIdsForPropertyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getApartmentIdsForProperty'>
);

export type GetConsumptionQueryVariables = Exact<{
  input: FetchConsumptionDataInput;
}>;


export type GetConsumptionQuery = (
  { __typename?: 'Query' }
  & { getConsumption: Array<(
    { __typename?: 'ConsumptionItemDto' }
    & ChartItemFieldsFragment
  )> }
);

export type DeleteApartmentMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type DeleteApartmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteApartment'>
);

export type DeleteDataGatewayMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDataGatewayMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDataGateway'>
);

export type DeleteInvoiceMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type DeleteInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteInvoice'>
);

export type DeletePropertyMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type DeletePropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProperty'>
);

export type DeletePropertyOwnerMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type DeletePropertyOwnerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePropertyOwner'>
);

export type DeleteTenantMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type DeleteTenantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTenant'>
);

export type ListApartmentsQueryVariables = Exact<{
  filters?: Maybe<GetFiltersInput>;
}>;


export type ListApartmentsQuery = (
  { __typename?: 'Query' }
  & { apartments: Array<Maybe<(
    { __typename?: 'ApartmentDto' }
    & Pick<ApartmentDto, 'id' | 'name' | 'isEditable' | 'floor' | 'apartmentNumber' | 'areaSize'>
    & { tenants: Array<Maybe<(
      { __typename?: 'TenantWithDatesDto' }
      & TenantFieldsFragment
    )>>, property: (
      { __typename?: 'PropertyDto' }
      & Pick<PropertyDto, 'id' | 'name'>
      & { address: (
        { __typename?: 'AddressDto' }
        & AddressFieldsFragment
      ), defaultConsumptionPricings: Array<(
        { __typename?: 'ConsumptionPricingDto' }
        & ConsumptionPricingFieldsFragment
      )> }
    ), invoices: Array<(
      { __typename?: 'InvoiceDto' }
      & InvoiceNameFieldsFragment
    )>, consumptionPricings: Array<(
      { __typename?: 'ConsumptionPricingDto' }
      & ConsumptionPricingFieldsFragment
    )> }
  )>> }
);

export type InvoicesQueryVariables = Exact<{
  filters?: Maybe<GetInvoicesFiltersInput>;
}>;


export type InvoicesQuery = (
  { __typename?: 'Query' }
  & { invoices: Array<Maybe<(
    { __typename?: 'InvoiceDto' }
    & Pick<InvoiceDto, 'id' | 'date'>
    & InvoiceNameFieldsFragment
  )>> }
);

export type ListPropertiesQueryVariables = Exact<{
  filters?: Maybe<GetFiltersInput>;
}>;


export type ListPropertiesQuery = (
  { __typename?: 'Query' }
  & { properties: Array<Maybe<(
    { __typename?: 'PropertyDto' }
    & Pick<PropertyDto, 'id' | 'name' | 'isEditable' | 'dataGatewayId' | 'areaSize'>
    & { address: (
      { __typename?: 'AddressDto' }
      & AddressFieldsFragment
    ), owners?: Maybe<Array<(
      { __typename?: 'PropertyOwnerDto' }
      & Pick<PropertyOwnerDto, 'id' | 'firstName' | 'lastName' | 'email'>
      & { address?: Maybe<(
        { __typename?: 'AddressDto' }
        & Pick<AddressDto, 'addressLineOne' | 'addressLineTwo' | 'city' | 'zip'>
      )>, properties: Array<Maybe<(
        { __typename?: 'PropertyDto' }
        & Pick<PropertyDto, 'id' | 'name'>
      )>> }
    )>>, apartments: Array<Maybe<(
      { __typename?: 'ApartmentDto' }
      & Pick<ApartmentDto, 'id' | 'name'>
    )>>, defaultConsumptionPricings: Array<(
      { __typename?: 'ConsumptionPricingDto' }
      & ConsumptionPricingFieldsFragment
    )>, counterPricings: Array<(
      { __typename?: 'CounterPricingDto' }
      & Pick<CounterPricingDto, 'counterType' | 'price'>
    )> }
  )>> }
);

export type ListPropertyOwnersQueryVariables = Exact<{
  filters?: Maybe<GetFiltersInput>;
}>;


export type ListPropertyOwnersQuery = (
  { __typename?: 'Query' }
  & { propertyOwners: Array<Maybe<(
    { __typename?: 'PropertyOwnerDto' }
    & Pick<PropertyOwnerDto, 'id' | 'firstName' | 'lastName' | 'email' | 'bankAccountNumber' | 'beneficiaryName' | 'beneficiaryVAT'>
    & { beneficiaryAddress?: Maybe<(
      { __typename?: 'AddressDto' }
      & Pick<AddressDto, 'addressLineOne' | 'addressLineTwo' | 'city' | 'zip'>
    )>, address?: Maybe<(
      { __typename?: 'AddressDto' }
      & Pick<AddressDto, 'addressLineOne' | 'addressLineTwo' | 'city' | 'zip'>
    )>, properties: Array<Maybe<(
      { __typename?: 'PropertyDto' }
      & Pick<PropertyDto, 'id' | 'name'>
    )>> }
  )>> }
);

export type ListTenantsQueryVariables = Exact<{
  filters?: Maybe<GetFiltersInput>;
}>;


export type ListTenantsQuery = (
  { __typename?: 'Query' }
  & { tenants: Array<Maybe<(
    { __typename?: 'TenantDto' }
    & Pick<TenantDto, 'id' | 'isEditable' | 'firstName' | 'lastName' | 'email'>
    & { address?: Maybe<(
      { __typename?: 'AddressDto' }
      & Pick<AddressDto, 'addressLineOne' | 'addressLineTwo' | 'city' | 'zip'>
    )>, apartments: Array<Maybe<(
      { __typename?: 'ApartmentWithDatesDto' }
      & Pick<ApartmentWithDatesDto, 'id' | 'name' | 'dateIn' | 'dateOut' | 'apartmentNumber'>
    )>> }
  )>> }
);

export type PropertyDetailFragment = (
  { __typename?: 'PropertyDto' }
  & Pick<PropertyDto, 'id' | 'name' | 'dataGatewayId' | 'isEditable' | 'areaSize' | 'mainCounterName'>
  & { owners?: Maybe<Array<(
    { __typename?: 'PropertyOwnerDto' }
    & OwnerDetailsFragment
  )>>, address: (
    { __typename?: 'AddressDto' }
    & PropertyAddressFragment
  ), defaultConsumptionPricings: Array<(
    { __typename?: 'ConsumptionPricingDto' }
    & ConsumptionPricingFieldsFragment
  )>, counterPricings: Array<(
    { __typename?: 'CounterPricingDto' }
    & Pick<CounterPricingDto, 'price' | 'counterType'>
  )>, apartments: Array<Maybe<(
    { __typename?: 'ApartmentDto' }
    & Pick<ApartmentDto, 'id' | 'name' | 'isEditable' | 'apartmentNumber'>
  )>>, amortizedCosts?: Maybe<Array<(
    { __typename?: 'AmortizedCostDto' }
    & Pick<AmortizedCostDto, 'description' | 'counterType' | 'amount' | 'from' | 'to' | 'costDistributionType'>
  )>>, photovoltaicsConfig?: Maybe<(
    { __typename?: 'PhotovoltaicsConfigDto' }
    & Pick<PhotovoltaicsConfigDto, 'costDistributionType'>
  )> }
  & PropertyConsumptionFragment
);

export type PropertyDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PropertyDetailQuery = (
  { __typename?: 'Query' }
  & { properties: Array<Maybe<(
    { __typename?: 'PropertyDto' }
    & PropertyDetailFragment
  )>> }
);

export type SetApartmentMutationVariables = Exact<{
  input: SetApartmentInput;
}>;


export type SetApartmentMutation = (
  { __typename?: 'Mutation' }
  & { setApartment?: Maybe<(
    { __typename?: 'ApartmentDto' }
    & Pick<ApartmentDto, 'id'>
  )> }
);

export type SetDataGatewayMutationVariables = Exact<{
  input: SetDataGatewayInput;
}>;


export type SetDataGatewayMutation = (
  { __typename?: 'Mutation' }
  & { setDataGateway?: Maybe<(
    { __typename?: 'DataGatewayDto' }
    & Pick<DataGatewayDto, 'id'>
  )> }
);

export type SetInvoiceMutationVariables = Exact<{
  input: SetInvoiceInput;
}>;


export type SetInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { setInvoice?: Maybe<(
    { __typename?: 'InvoiceDto' }
    & Pick<InvoiceDto, 'id'>
  )> }
);

export type SetPropertyMutationVariables = Exact<{
  input: SetPropertyInput;
}>;


export type SetPropertyMutation = (
  { __typename?: 'Mutation' }
  & { setProperty?: Maybe<(
    { __typename?: 'PropertyDto' }
    & Pick<PropertyDto, 'id'>
  )> }
);

export type SetPropertyOwnerMutationVariables = Exact<{
  input: SetPropertyOwnerInput;
}>;


export type SetPropertyOwnerMutation = (
  { __typename?: 'Mutation' }
  & { setPropertyOwner?: Maybe<(
    { __typename?: 'PropertyOwnerDto' }
    & Pick<PropertyOwnerDto, 'id'>
  )> }
);

export type SetTenantMutationVariables = Exact<{
  input: SetTenantInput;
}>;


export type SetTenantMutation = (
  { __typename?: 'Mutation' }
  & { setTenant?: Maybe<(
    { __typename?: 'TenantDto' }
    & Pick<TenantDto, 'id'>
  )> }
);

export type ApartmentConsumption_ApartmentFragment = (
  { __typename?: 'ApartmentDto' }
  & Pick<ApartmentDto, 'id'>
  & { property: (
    { __typename?: 'PropertyDto' }
    & Pick<PropertyDto, 'id'>
  ) }
);

export type PropertyOwnerAutocompleteQueryVariables = Exact<{ [key: string]: never; }>;


export type PropertyOwnerAutocompleteQuery = (
  { __typename?: 'Query' }
  & { propertyOwners: Array<Maybe<(
    { __typename?: 'PropertyOwnerDto' }
    & Pick<PropertyOwnerDto, 'id' | 'firstName' | 'lastName'>
  )>> }
);

export type AddReadonlyPropertyMutationVariables = Exact<{
  gatewayId: Scalars['String'];
}>;


export type AddReadonlyPropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addReadonlyProperty'>
);

export type ApartmentForm_PropertyFragment = (
  { __typename?: 'PropertyDto' }
  & Pick<PropertyDto, 'id' | 'name'>
  & { defaultConsumptionPricings: Array<(
    { __typename?: 'ConsumptionPricingDto' }
    & ConsumptionPricingFieldsFragment
  )> }
);

export type TenantFormApartmentsFragment = (
  { __typename?: 'ApartmentDto' }
  & Pick<ApartmentDto, 'id' | 'name'>
);

export type OwnerDetailsFragment = (
  { __typename?: 'PropertyOwnerDto' }
  & Pick<PropertyOwnerDto, 'id' | 'firstName' | 'lastName' | 'email'>
  & { address?: Maybe<(
    { __typename?: 'AddressDto' }
    & AddressFieldsFragment
  )> }
);

export type PropertyAddressFragment = (
  { __typename?: 'AddressDto' }
  & Pick<AddressDto, 'addressLineOne' | 'addressLineTwo' | 'city' | 'zip'>
);

export type PropertyConsumptionFragment = (
  { __typename?: 'PropertyDto' }
  & Pick<PropertyDto, 'id'>
);

export type ApartmentSelectorDataQueryVariables = Exact<{ [key: string]: never; }>;


export type ApartmentSelectorDataQuery = (
  { __typename?: 'Query' }
  & { apartments: Array<Maybe<(
    { __typename?: 'ApartmentDto' }
    & ApartmentSelector_ApartmentFragment
  )>> }
);

export type ApartmentSelector_ApartmentFragment = (
  { __typename?: 'ApartmentDto' }
  & Pick<ApartmentDto, 'id' | 'name' | 'apartmentNumber'>
  & { property: (
    { __typename?: 'PropertyDto' }
    & Pick<PropertyDto, 'id'>
  ) }
);

export type OwnerSelectorDataQueryVariables = Exact<{ [key: string]: never; }>;


export type OwnerSelectorDataQuery = (
  { __typename?: 'Query' }
  & { propertyOwners: Array<Maybe<(
    { __typename?: 'PropertyOwnerDto' }
    & OwnerSelector_OwnerFragment
  )>> }
);

export type OwnerSelector_OwnerFragment = (
  { __typename?: 'PropertyOwnerDto' }
  & Pick<PropertyOwnerDto, 'id' | 'firstName' | 'lastName'>
);

export type PropertySelectorDataQueryVariables = Exact<{ [key: string]: never; }>;


export type PropertySelectorDataQuery = (
  { __typename?: 'Query' }
  & { properties: Array<Maybe<(
    { __typename?: 'PropertyDto' }
    & PropertySelector_PropertyFragment
  )>> }
);

export type PropertySelector_PropertyFragment = (
  { __typename?: 'PropertyDto' }
  & Pick<PropertyDto, 'id' | 'name'>
);

export type TenantSelectorDataQueryVariables = Exact<{ [key: string]: never; }>;


export type TenantSelectorDataQuery = (
  { __typename?: 'Query' }
  & { tenants: Array<Maybe<(
    { __typename?: 'TenantDto' }
    & TenantSelector_TenantFragment
  )>> }
);

export type TenantSelector_TenantFragment = (
  { __typename?: 'TenantDto' }
  & Pick<TenantDto, 'id' | 'firstName' | 'lastName'>
);

export type GetDataGatewayPageDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDataGatewayPageDataQuery = (
  { __typename?: 'Query' }
  & { dataGateways: Array<Maybe<(
    { __typename?: 'DataGatewayDto' }
    & Pick<DataGatewayDto, 'id'>
    & { property: (
      { __typename?: 'PropertyDto' }
      & Pick<PropertyDto, 'name'>
    ), systemUsage: (
      { __typename?: 'ConsumptionDto' }
      & { data: (
        { __typename?: 'ConsumptionDataDto' }
        & { week: Array<(
          { __typename?: 'ConsumptionItemDto' }
          & Pick<ConsumptionItemDto, 'item' | 'itemType' | 'timestamp' | 'totalPrice' | 'unit' | 'value'>
        )> }
      ) }
    ) }
  )>> }
);

export type DataGatewayDetailFragment = (
  { __typename?: 'DataGatewayDto' }
  & Pick<DataGatewayDto, 'id'>
  & { property: (
    { __typename?: 'PropertyDto' }
    & Pick<PropertyDto, 'id' | 'name'>
  ) }
);

export type DataGatewayDetailQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type DataGatewayDetailQuery = (
  { __typename?: 'Query' }
  & { dataGateways: Array<Maybe<(
    { __typename?: 'DataGatewayDto' }
    & DataGatewayDetailFragment
  )>> }
);

export type OwnerDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type OwnerDashboardQuery = (
  { __typename?: 'Query' }
  & { properties: Array<Maybe<(
    { __typename?: 'PropertyDto' }
    & Pick<PropertyDto, 'id' | 'name'>
    & { apartments: Array<Maybe<(
      { __typename?: 'ApartmentDto' }
      & Pick<ApartmentDto, 'id' | 'name' | 'floor'>
      & { invoices: Array<(
        { __typename?: 'InvoiceDto' }
        & InvoiceNameFieldsFragment
      )> }
    )>> }
  )>> }
);

export type TenantOverviewDataQueryVariables = Exact<{ [key: string]: never; }>;


export type TenantOverviewDataQuery = (
  { __typename?: 'Query' }
  & { apartments: Array<Maybe<(
    { __typename?: 'ApartmentDto' }
    & Pick<ApartmentDto, 'id' | 'name' | 'apartmentNumber'>
    & { tenants: Array<Maybe<(
      { __typename?: 'TenantWithDatesDto' }
      & Pick<TenantWithDatesDto, 'firstName' | 'lastName'>
    )>>, property: (
      { __typename?: 'PropertyDto' }
      & Pick<PropertyDto, 'id'>
      & { address: (
        { __typename?: 'AddressDto' }
        & Pick<AddressDto, 'addressLineOne' | 'addressLineTwo' | 'zip' | 'city'>
      ) }
    ), invoices: Array<(
      { __typename?: 'InvoiceDto' }
      & InvoiceNameFieldsFragment
    )> }
  )>> }
);

export type TenantOverviewQueryVariables = Exact<{ [key: string]: never; }>;


export type TenantOverviewQuery = (
  { __typename?: 'Query' }
  & { apartments: Array<Maybe<(
    { __typename?: 'ApartmentDto' }
    & Pick<ApartmentDto, 'id' | 'name'>
    & { property: (
      { __typename?: 'PropertyDto' }
      & Pick<PropertyDto, 'id'>
    ), invoices: Array<(
      { __typename?: 'InvoiceDto' }
      & InvoiceNameFieldsFragment
    )> }
  )>> }
);

export type GetConsumptionAsCsvQueryVariables = Exact<{
  input: FetchConsumptionDataInput;
}>;


export type GetConsumptionAsCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getConsumptionAsCsv'>
);

export const ChartItemFieldsFragmentDoc = gql`
    fragment ChartItemFields on ConsumptionItemDto {
  timestamp
  item
  itemType
  unit
  value
  totalPrice
}
    `;
export const ConsumptionFieldsFragmentDoc = gql`
    fragment ConsumptionFields on ConsumptionDto {
  data {
    day {
      ...ChartItemFields
    }
    week {
      ...ChartItemFields
    }
    month {
      ...ChartItemFields
    }
    halfyear {
      ...ChartItemFields
    }
    year {
      ...ChartItemFields
    }
  }
}
    ${ChartItemFieldsFragmentDoc}`;
export const InvoiceNameFieldsFragmentDoc = gql`
    fragment InvoiceNameFields on InvoiceDto {
  id
  fromDate
  toDate
  date
  nr
  dateSent
  messagingStartTime
  intervalMonths
  tenant {
    firstName
    lastName
  }
}
    `;
export const TenantFieldsFragmentDoc = gql`
    fragment TenantFields on TenantWithDatesDto {
  firstName
  lastName
  id
  email
  dateIn
  dateOut
  apartments {
    id
  }
}
    `;
export const PropertyConsumptionFragmentDoc = gql`
    fragment PropertyConsumption on PropertyDto {
  id
}
    `;
export const AddressFieldsFragmentDoc = gql`
    fragment AddressFields on AddressDto {
  addressLineOne
  addressLineTwo
  city
  zip
}
    `;
export const OwnerDetailsFragmentDoc = gql`
    fragment OwnerDetails on PropertyOwnerDto {
  id
  firstName
  lastName
  email
  address {
    ...AddressFields
  }
}
    ${AddressFieldsFragmentDoc}`;
export const PropertyAddressFragmentDoc = gql`
    fragment PropertyAddress on AddressDto {
  addressLineOne
  addressLineTwo
  city
  zip
}
    `;
export const ConsumptionPricingFieldsFragmentDoc = gql`
    fragment ConsumptionPricingFields on ConsumptionPricingDto {
  basePrice
  pricingType
  label
}
    `;
export const PropertyDetailFragmentDoc = gql`
    fragment PropertyDetail on PropertyDto {
  ...PropertyConsumption
  id
  name
  dataGatewayId
  isEditable
  areaSize
  mainCounterName
  owners {
    ...OwnerDetails
  }
  address {
    ...PropertyAddress
  }
  defaultConsumptionPricings {
    ...ConsumptionPricingFields
  }
  counterPricings {
    price
    counterType
  }
  apartments {
    id
    name
    isEditable
    apartmentNumber
  }
  amortizedCosts {
    description
    counterType
    amount
    from
    to
    costDistributionType
  }
  photovoltaicsConfig {
    costDistributionType
  }
}
    ${PropertyConsumptionFragmentDoc}
${OwnerDetailsFragmentDoc}
${PropertyAddressFragmentDoc}
${ConsumptionPricingFieldsFragmentDoc}`;
export const ApartmentConsumption_ApartmentFragmentDoc = gql`
    fragment ApartmentConsumption_Apartment on ApartmentDto {
  id
  property {
    id
  }
}
    `;
export const ApartmentForm_PropertyFragmentDoc = gql`
    fragment ApartmentForm_Property on PropertyDto {
  id
  name
  defaultConsumptionPricings {
    ...ConsumptionPricingFields
  }
}
    ${ConsumptionPricingFieldsFragmentDoc}`;
export const TenantFormApartmentsFragmentDoc = gql`
    fragment TenantFormApartments on ApartmentDto {
  id
  name
}
    `;
export const ApartmentSelector_ApartmentFragmentDoc = gql`
    fragment ApartmentSelector_Apartment on ApartmentDto {
  id
  name
  apartmentNumber
  property {
    id
  }
}
    `;
export const OwnerSelector_OwnerFragmentDoc = gql`
    fragment OwnerSelector_Owner on PropertyOwnerDto {
  id
  firstName
  lastName
}
    `;
export const PropertySelector_PropertyFragmentDoc = gql`
    fragment PropertySelector_Property on PropertyDto {
  id
  name
}
    `;
export const TenantSelector_TenantFragmentDoc = gql`
    fragment TenantSelector_Tenant on TenantDto {
  id
  firstName
  lastName
}
    `;
export const DataGatewayDetailFragmentDoc = gql`
    fragment DataGatewayDetail on DataGatewayDto {
  id
  property {
    id
    name
  }
}
    `;
export const ApartmentIdsForPropertyDocument = gql`
    query ApartmentIdsForProperty($propertyId: String!) {
  getApartmentIdsForProperty(propertyId: $propertyId)
}
    `;
export const GetConsumptionDocument = gql`
    query GetConsumption($input: FetchConsumptionDataInput!) {
  getConsumption(consumptionInput: $input) {
    ...ChartItemFields
  }
}
    ${ChartItemFieldsFragmentDoc}`;
export const DeleteApartmentDocument = gql`
    mutation DeleteApartment($input: String!) {
  deleteApartment(id: $input)
}
    `;
export const DeleteDataGatewayDocument = gql`
    mutation DeleteDataGateway($id: String!) {
  deleteDataGateway(id: $id)
}
    `;
export const DeleteInvoiceDocument = gql`
    mutation DeleteInvoice($input: String!) {
  deleteInvoice(id: $input)
}
    `;
export const DeletePropertyDocument = gql`
    mutation DeleteProperty($input: String!) {
  deleteProperty(id: $input)
}
    `;
export const DeletePropertyOwnerDocument = gql`
    mutation DeletePropertyOwner($input: String!) {
  deletePropertyOwner(id: $input)
}
    `;
export const DeleteTenantDocument = gql`
    mutation DeleteTenant($input: String!) {
  deleteTenant(id: $input)
}
    `;
export const ListApartmentsDocument = gql`
    query ListApartments($filters: GetFiltersInput) {
  apartments(filters: $filters) {
    id
    name
    isEditable
    floor
    apartmentNumber
    areaSize
    isEditable
    tenants {
      ...TenantFields
    }
    property {
      id
      name
      address {
        ...AddressFields
      }
      defaultConsumptionPricings {
        ...ConsumptionPricingFields
      }
    }
    invoices {
      ...InvoiceNameFields
    }
    consumptionPricings {
      ...ConsumptionPricingFields
    }
  }
}
    ${TenantFieldsFragmentDoc}
${AddressFieldsFragmentDoc}
${ConsumptionPricingFieldsFragmentDoc}
${InvoiceNameFieldsFragmentDoc}`;
export const InvoicesDocument = gql`
    query Invoices($filters: GetInvoicesFiltersInput) {
  invoices(filters: $filters) {
    id
    date
    ...InvoiceNameFields
  }
}
    ${InvoiceNameFieldsFragmentDoc}`;
export const ListPropertiesDocument = gql`
    query ListProperties($filters: GetFiltersInput) {
  properties(filters: $filters) {
    id
    name
    isEditable
    dataGatewayId
    isEditable
    areaSize
    address {
      ...AddressFields
    }
    owners {
      id
      firstName
      lastName
      email
      address {
        addressLineOne
        addressLineTwo
        city
        zip
      }
      properties {
        id
        name
      }
    }
    apartments {
      id
      name
    }
    defaultConsumptionPricings {
      ...ConsumptionPricingFields
    }
    counterPricings {
      counterType
      price
    }
  }
}
    ${AddressFieldsFragmentDoc}
${ConsumptionPricingFieldsFragmentDoc}`;
export const ListPropertyOwnersDocument = gql`
    query ListPropertyOwners($filters: GetFiltersInput) {
  propertyOwners(filters: $filters) {
    id
    firstName
    lastName
    email
    bankAccountNumber
    beneficiaryName
    beneficiaryVAT
    beneficiaryAddress {
      addressLineOne
      addressLineTwo
      city
      zip
    }
    address {
      addressLineOne
      addressLineTwo
      city
      zip
    }
    properties {
      id
      name
    }
  }
}
    `;
export const ListTenantsDocument = gql`
    query ListTenants($filters: GetFiltersInput) {
  tenants(filters: $filters) {
    id
    isEditable
    firstName
    lastName
    email
    isEditable
    address {
      addressLineOne
      addressLineTwo
      city
      zip
    }
    apartments {
      id
      name
      dateIn
      dateOut
      apartmentNumber
    }
  }
}
    `;
export const PropertyDetailDocument = gql`
    query PropertyDetail($id: ID!) {
  properties(filters: {id: $id}) {
    ...PropertyDetail
  }
}
    ${PropertyDetailFragmentDoc}`;
export const SetApartmentDocument = gql`
    mutation SetApartment($input: SetApartmentInput!) {
  setApartment(input: $input) {
    id
  }
}
    `;
export const SetDataGatewayDocument = gql`
    mutation SetDataGateway($input: SetDataGatewayInput!) {
  setDataGateway(input: $input) {
    id
  }
}
    `;
export const SetInvoiceDocument = gql`
    mutation SetInvoice($input: SetInvoiceInput!) {
  setInvoice(input: $input) {
    id
  }
}
    `;
export const SetPropertyDocument = gql`
    mutation SetProperty($input: SetPropertyInput!) {
  setProperty(input: $input) {
    id
  }
}
    `;
export const SetPropertyOwnerDocument = gql`
    mutation setPropertyOwner($input: SetPropertyOwnerInput!) {
  setPropertyOwner(input: $input) {
    id
  }
}
    `;
export const SetTenantDocument = gql`
    mutation SetTenant($input: SetTenantInput!) {
  setTenant(input: $input) {
    id
  }
}
    `;
export const PropertyOwnerAutocompleteDocument = gql`
    query PropertyOwnerAutocomplete {
  propertyOwners {
    id
    firstName
    lastName
  }
}
    `;
export const AddReadonlyPropertyDocument = gql`
    mutation AddReadonlyProperty($gatewayId: String!) {
  addReadonlyProperty(gatewayId: $gatewayId)
}
    `;
export const ApartmentSelectorDataDocument = gql`
    query ApartmentSelectorData {
  apartments {
    ...ApartmentSelector_Apartment
  }
}
    ${ApartmentSelector_ApartmentFragmentDoc}`;
export const OwnerSelectorDataDocument = gql`
    query OwnerSelectorData {
  propertyOwners {
    ...OwnerSelector_Owner
  }
}
    ${OwnerSelector_OwnerFragmentDoc}`;
export const PropertySelectorDataDocument = gql`
    query PropertySelectorData {
  properties {
    ...PropertySelector_Property
  }
}
    ${PropertySelector_PropertyFragmentDoc}`;
export const TenantSelectorDataDocument = gql`
    query TenantSelectorData {
  tenants {
    ...TenantSelector_Tenant
  }
}
    ${TenantSelector_TenantFragmentDoc}`;
export const GetDataGatewayPageDataDocument = gql`
    query GetDataGatewayPageData {
  dataGateways {
    id
    property {
      name
    }
    property {
      name
    }
    systemUsage {
      data {
        week {
          item
          itemType
          timestamp
          totalPrice
          unit
          value
        }
      }
    }
  }
}
    `;
export const DataGatewayDetailDocument = gql`
    query DataGatewayDetail($id: ID) {
  dataGateways(filters: {id: $id}) {
    ...DataGatewayDetail
  }
}
    ${DataGatewayDetailFragmentDoc}`;
export const OwnerDashboardDocument = gql`
    query OwnerDashboard {
  properties {
    id
    name
    apartments {
      id
      name
      floor
      invoices {
        ...InvoiceNameFields
      }
    }
  }
}
    ${InvoiceNameFieldsFragmentDoc}`;
export const TenantOverviewDataDocument = gql`
    query TenantOverviewData {
  apartments {
    id
    name
    apartmentNumber
    tenants {
      firstName
      lastName
    }
    property {
      id
      address {
        addressLineOne
        addressLineTwo
        zip
        city
      }
    }
    invoices {
      ...InvoiceNameFields
    }
  }
}
    ${InvoiceNameFieldsFragmentDoc}`;
export const TenantOverviewDocument = gql`
    query TenantOverview {
  apartments {
    id
    name
    property {
      id
    }
    invoices {
      ...InvoiceNameFields
    }
  }
}
    ${InvoiceNameFieldsFragmentDoc}`;
export const GetConsumptionAsCsvDocument = gql`
    query GetConsumptionAsCsv($input: FetchConsumptionDataInput!) {
  getConsumptionAsCsv(consumptionInput: $input)
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    ApartmentIdsForProperty(variables: ApartmentIdsForPropertyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ApartmentIdsForPropertyQuery> {
      return withWrapper(() => client.request<ApartmentIdsForPropertyQuery>(ApartmentIdsForPropertyDocument, variables, requestHeaders));
    },
    GetConsumption(variables: GetConsumptionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetConsumptionQuery> {
      return withWrapper(() => client.request<GetConsumptionQuery>(GetConsumptionDocument, variables, requestHeaders));
    },
    DeleteApartment(variables: DeleteApartmentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteApartmentMutation> {
      return withWrapper(() => client.request<DeleteApartmentMutation>(DeleteApartmentDocument, variables, requestHeaders));
    },
    DeleteDataGateway(variables: DeleteDataGatewayMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDataGatewayMutation> {
      return withWrapper(() => client.request<DeleteDataGatewayMutation>(DeleteDataGatewayDocument, variables, requestHeaders));
    },
    DeleteInvoice(variables: DeleteInvoiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteInvoiceMutation> {
      return withWrapper(() => client.request<DeleteInvoiceMutation>(DeleteInvoiceDocument, variables, requestHeaders));
    },
    DeleteProperty(variables: DeletePropertyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeletePropertyMutation> {
      return withWrapper(() => client.request<DeletePropertyMutation>(DeletePropertyDocument, variables, requestHeaders));
    },
    DeletePropertyOwner(variables: DeletePropertyOwnerMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeletePropertyOwnerMutation> {
      return withWrapper(() => client.request<DeletePropertyOwnerMutation>(DeletePropertyOwnerDocument, variables, requestHeaders));
    },
    DeleteTenant(variables: DeleteTenantMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteTenantMutation> {
      return withWrapper(() => client.request<DeleteTenantMutation>(DeleteTenantDocument, variables, requestHeaders));
    },
    ListApartments(variables?: ListApartmentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ListApartmentsQuery> {
      return withWrapper(() => client.request<ListApartmentsQuery>(ListApartmentsDocument, variables, requestHeaders));
    },
    Invoices(variables?: InvoicesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InvoicesQuery> {
      return withWrapper(() => client.request<InvoicesQuery>(InvoicesDocument, variables, requestHeaders));
    },
    ListProperties(variables?: ListPropertiesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ListPropertiesQuery> {
      return withWrapper(() => client.request<ListPropertiesQuery>(ListPropertiesDocument, variables, requestHeaders));
    },
    ListPropertyOwners(variables?: ListPropertyOwnersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ListPropertyOwnersQuery> {
      return withWrapper(() => client.request<ListPropertyOwnersQuery>(ListPropertyOwnersDocument, variables, requestHeaders));
    },
    ListTenants(variables?: ListTenantsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ListTenantsQuery> {
      return withWrapper(() => client.request<ListTenantsQuery>(ListTenantsDocument, variables, requestHeaders));
    },
    PropertyDetail(variables: PropertyDetailQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PropertyDetailQuery> {
      return withWrapper(() => client.request<PropertyDetailQuery>(PropertyDetailDocument, variables, requestHeaders));
    },
    SetApartment(variables: SetApartmentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SetApartmentMutation> {
      return withWrapper(() => client.request<SetApartmentMutation>(SetApartmentDocument, variables, requestHeaders));
    },
    SetDataGateway(variables: SetDataGatewayMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SetDataGatewayMutation> {
      return withWrapper(() => client.request<SetDataGatewayMutation>(SetDataGatewayDocument, variables, requestHeaders));
    },
    SetInvoice(variables: SetInvoiceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SetInvoiceMutation> {
      return withWrapper(() => client.request<SetInvoiceMutation>(SetInvoiceDocument, variables, requestHeaders));
    },
    SetProperty(variables: SetPropertyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SetPropertyMutation> {
      return withWrapper(() => client.request<SetPropertyMutation>(SetPropertyDocument, variables, requestHeaders));
    },
    setPropertyOwner(variables: SetPropertyOwnerMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SetPropertyOwnerMutation> {
      return withWrapper(() => client.request<SetPropertyOwnerMutation>(SetPropertyOwnerDocument, variables, requestHeaders));
    },
    SetTenant(variables: SetTenantMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SetTenantMutation> {
      return withWrapper(() => client.request<SetTenantMutation>(SetTenantDocument, variables, requestHeaders));
    },
    PropertyOwnerAutocomplete(variables?: PropertyOwnerAutocompleteQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PropertyOwnerAutocompleteQuery> {
      return withWrapper(() => client.request<PropertyOwnerAutocompleteQuery>(PropertyOwnerAutocompleteDocument, variables, requestHeaders));
    },
    AddReadonlyProperty(variables: AddReadonlyPropertyMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AddReadonlyPropertyMutation> {
      return withWrapper(() => client.request<AddReadonlyPropertyMutation>(AddReadonlyPropertyDocument, variables, requestHeaders));
    },
    ApartmentSelectorData(variables?: ApartmentSelectorDataQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ApartmentSelectorDataQuery> {
      return withWrapper(() => client.request<ApartmentSelectorDataQuery>(ApartmentSelectorDataDocument, variables, requestHeaders));
    },
    OwnerSelectorData(variables?: OwnerSelectorDataQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OwnerSelectorDataQuery> {
      return withWrapper(() => client.request<OwnerSelectorDataQuery>(OwnerSelectorDataDocument, variables, requestHeaders));
    },
    PropertySelectorData(variables?: PropertySelectorDataQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PropertySelectorDataQuery> {
      return withWrapper(() => client.request<PropertySelectorDataQuery>(PropertySelectorDataDocument, variables, requestHeaders));
    },
    TenantSelectorData(variables?: TenantSelectorDataQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TenantSelectorDataQuery> {
      return withWrapper(() => client.request<TenantSelectorDataQuery>(TenantSelectorDataDocument, variables, requestHeaders));
    },
    GetDataGatewayPageData(variables?: GetDataGatewayPageDataQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDataGatewayPageDataQuery> {
      return withWrapper(() => client.request<GetDataGatewayPageDataQuery>(GetDataGatewayPageDataDocument, variables, requestHeaders));
    },
    DataGatewayDetail(variables?: DataGatewayDetailQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DataGatewayDetailQuery> {
      return withWrapper(() => client.request<DataGatewayDetailQuery>(DataGatewayDetailDocument, variables, requestHeaders));
    },
    OwnerDashboard(variables?: OwnerDashboardQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OwnerDashboardQuery> {
      return withWrapper(() => client.request<OwnerDashboardQuery>(OwnerDashboardDocument, variables, requestHeaders));
    },
    TenantOverviewData(variables?: TenantOverviewDataQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TenantOverviewDataQuery> {
      return withWrapper(() => client.request<TenantOverviewDataQuery>(TenantOverviewDataDocument, variables, requestHeaders));
    },
    TenantOverview(variables?: TenantOverviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TenantOverviewQuery> {
      return withWrapper(() => client.request<TenantOverviewQuery>(TenantOverviewDocument, variables, requestHeaders));
    },
    GetConsumptionAsCsv(variables: GetConsumptionAsCsvQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetConsumptionAsCsvQuery> {
      return withWrapper(() => client.request<GetConsumptionAsCsvQuery>(GetConsumptionAsCsvDocument, variables, requestHeaders));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;