import React, { useEffect, useState } from "react";
import { AttachMoneyOutlined, SpeedOutlined } from "@material-ui/icons";
import { uniq } from "lodash";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/styles";
import { Switch } from "@material-ui/core";
import Section from "../../Section";
import ConsumptionPeriodTabs from "../ConsumptionPeriodTabs";
import Row from "../../Row";
import {
  ChartContainerState,
  ConsumptionPeriod,
  ValueKey,
} from "../../../types";
import { ROW_HEIGHT } from "../../../theme";
import { SectionProps } from "../../Section/Section";
import ConsumptionSelector from "../ConsumptionSelector";
import ConsumptionChechboxes from "../ConsumptionCheckboxes/ConsumptionChechboxes";
import DatePicker, { getNextDate } from "../../DatePicker/DatePicker";
import { ConsumptionType } from "../../../api/types";
import getUnit from "../../../utils/chart/getUnit";
import { getCurrentDate } from "../../../utils/date/date.utils";

export type RenderProps = {
  period: ConsumptionPeriod;
  from: moment.Moment;
  to: moment.Moment;
  valueKey: ValueKey;
  types: Array<ConsumptionType>;
  units: Array<string>;
};

interface Props {
  title?: string;
  children: (options: RenderProps) => React.ReactNode;
  height?: string;
  storageId?: string;
  SectionProps?: Omit<SectionProps, "children">;
  condensed?: boolean;
  options?: Array<ConsumptionType>;
  onRenderPropsChange?: (renderProps: RenderProps) => void;
}

const useStyles = makeStyles(() => ({
  root: {},
  header: {
    height: ROW_HEIGHT,
  },
  title: {},
  date: {
    marginLeft: 12,
    marginRight: 12,
  },
  switchRow: {
    paddingRight: 32,
  },
}));

const typeOptions = [
  ConsumptionType.Electricity,
  ConsumptionType.ElectricityL,
  ConsumptionType.ElectricityH,
  ConsumptionType.HeatingEnergy,
  ConsumptionType.WaterColdVolume,
  ConsumptionType.WaterWarmVolume,
  ConsumptionType.Charging,
];

const stateCache: Record<string, ChartContainerState> = {
  initialState: {
    date: getCurrentDate(moment(), ConsumptionPeriod.Week),
    period: ConsumptionPeriod.Week,
  },
};

function ChartContainer({
  children,
  height,
  SectionProps,
  storageId,
  condensed,
  options = typeOptions,
  onRenderPropsChange,
}: Props) {
  const classes = useStyles();
  const cacheKey = storageId || "initialState";
  const cachedState = stateCache[cacheKey];
  const [period, setPeriod] = useState<ConsumptionPeriod>(
    cachedState?.period || ConsumptionPeriod.Week
  );
  const [date, setDate] = useState<moment.Moment>(
    cachedState?.date || getCurrentDate(moment(), period)
  );
  const [valueKey, setValueKey] = useState(ValueKey.TotalPrice);
  const [types, setTypes] = useState<Record<ConsumptionType, boolean>>(
    options.reduce((res, type) => {
      res[type] = true;
      return res;
    }, {} as any)
  );
  // @ts-ignore
  const selectedTypes = Object.keys(types).filter((key) => Boolean(types[key]));
  const selectedUnits = uniq(selectedTypes.map(getUnit));
  const renderPrices = valueKey === ValueKey.TotalPrice;

  useEffect(() => {
    setDate(() => getCurrentDate(moment(), period));
  }, [period]);

  useEffect(() => {}, [types, valueKey]);

  stateCache[cacheKey] = {
    date,
    period,
  };

  if (onRenderPropsChange) {
    onRenderPropsChange({
      period,
      from: date,
      to: getNextDate(date, period, "next"),
      valueKey,
      types: selectedTypes as any,
      units: selectedUnits,
    });
  }

  return (
    <div>
      <Row className={classes.header}>
        <DatePicker
          date={date}
          period={period}
          onChange={(update) => {
            setDate(update);
          }}
        />
        <ConsumptionSelector id={storageId || uuidv4()} hidden />
        <Row className={classes.switchRow}>
          <SpeedOutlined />
          <Switch
            checked={renderPrices}
            onChange={(e) => {
              const checked = e.target.checked; // Price selected
              setValueKey(checked ? ValueKey.TotalPrice : ValueKey.Value);
            }}
          />
          <AttachMoneyOutlined />
        </Row>
        <ConsumptionPeriodTabs
          value={period}
          onChange={setPeriod}
          condensed={condensed}
        />
      </Row>
      <Section height={height || "400px"} borderBottom {...SectionProps}>
        {children({
          period,
          from: getCurrentDate(date, period),
          to: getNextDate(date, period, "next"),
          valueKey,
          types: selectedTypes as any,
          units: selectedUnits,
        })}
      </Section>
      <ConsumptionChechboxes
        options={options}
        types={types}
        onChange={setTypes}
      />
    </div>
  );
}

export default ChartContainer;
