import React from "react";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { GraphQLError } from "graphql";
import getErrorMessage from "../../utils/getErrorMessage";
import getErrorCode from "../../utils/getErrorCode";

interface Props {
  className?: string;

  variant?: "standard" | "filled" | "outlined";

  error?: GraphQLError | null | unknown;

  errorMessage?: string;
}

const useStyles = makeStyles(() => ({
  root: {},
  alert: {},
}));

function ErrorAlert({
  error,
  className,
  variant = "standard",
  errorMessage = "",
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation("common");

  if (!error && !errorMessage) {
    return null;
  }

  const errorCode = getErrorCode(error);

  return (
    <Alert
      variant={variant}
      className={clsx(classes.alert, className)}
      severity="error"
    >
      {errorMessage || getErrorMessage(errorCode, t)}
    </Alert>
  );
}

export default ErrorAlert;
