import React from "react";
import { ListPropertiesQuery } from "../../../api/types";
import { makeStyles } from "@material-ui/styles";
import OwnerInfoForm, {
  OwnerInfoFormFields,
  OwnerInfoFormValues,
} from "../OwnerInfoForm/OwnerInfoForm";
import OwnerPropertiesForm, {
  OwnerPropertiesFormFields,
  OwnerPropertiesFormValues,
} from "../OwnerPropertiesForm/OwnerPropertiesForm";
import OwnerPaymentDetailsForm from "../OwnerPaymentDetailsForm";
import { OwnerPaymentDetailsFormValues } from "../OwnerPaymentDetailsForm/OwnerPaymentDetailsForm";

const useStyles = makeStyles(() => ({
  root: {
    "& > div:not(:last-child)": {
      marginBottom: 16,
    },
  },
}));

interface Props {
  values: OwnerInfoFormValues &
    OwnerPropertiesFormValues &
    OwnerPaymentDetailsFormValues;
  onChange: (e: React.ChangeEvent<any>) => void;
  onFieldChange: (fieldName: string, value: any) => void;
  propertiesList: ListPropertiesQuery["properties"];
  disabledOwnerInfoFields?: Set<OwnerInfoFormFields>;
  disabledPropertiesFields?: Set<OwnerPropertiesFormFields>;
}

function OwnerForm({
  values,
  onChange,
  onFieldChange,
  propertiesList,
  disabledOwnerInfoFields,
  disabledPropertiesFields,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <OwnerInfoForm
        values={values}
        onChange={onChange}
        disabledFields={disabledOwnerInfoFields}
      />
      <OwnerPaymentDetailsForm values={values} onChange={onChange} />
      <OwnerPropertiesForm
        values={values}
        onChange={onChange}
        onFieldChange={onFieldChange}
        propertiesList={propertiesList}
        disabledFields={disabledPropertiesFields}
      />
    </div>
  );
}

export default OwnerForm;
