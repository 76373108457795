import React from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
} from "@material-ui/core";
import { ConsumptionType } from "../../../api/types";
import { useTranslation } from "react-i18next";

interface Props {
  onChange?: (types: Record<ConsumptionType, boolean>) => void;
  hidden?: boolean;
  options?: Array<ConsumptionType>;
  types?: Record<ConsumptionType, boolean>;
}

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 16,
    marginRight: 16,
    minHeight: 42,
    alignItems: "center",
  },
  checkbox: {
    marginRight: 8,
  },
}));

const typeOptions = [
  ConsumptionType.ElectricityL,
  ConsumptionType.ElectricityH,
  ConsumptionType.HeatingEnergy,
  ConsumptionType.WaterColdVolume,
  ConsumptionType.WaterWarmVolume,
  ConsumptionType.Charging,
];

export default function ConsumptionCheckboxes({
  hidden,
  onChange,
  options = typeOptions,
  types = {} as any,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      const update = { ...types, [event.target.name]: event.target.checked };
      onChange(update);
    }
  };

  if (hidden) {
    return null;
  }

  return (
    <FormGroup className={classes.root} row>
      {options.map((type) => (
        <FormControlLabel
          key={type}
          control={
            <Checkbox
              checked={types[type]}
              onChange={handleChange}
              name={type}
              className={classes.checkbox}
            />
          }
          label={t(type)}
        />
      ))}
    </FormGroup>
  );
}
