import React, { useEffect, useState } from "react";
import Dialog from "../Dialog";
import { useTranslation } from "react-i18next";
import ErrorAlert from "../ErrorAlert";
import moment, { Moment } from "moment";
import { makeStyles } from "@material-ui/styles";
import useFilterStore, { fullFilterState } from "../../store/useFilterStore";
import shallow from "zustand/shallow";
import { BORDER_COLOR_SECONDARY, SUBHEADER_COLOR } from "../../theme";
import { Grid, Typography } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import Input from "../Input";
import getConsumptionAsCsv from "../../utils/getConsumptionAsCsv";
import { getNextDate } from "../DatePicker/DatePicker";
import { ConsumptionPeriod } from "../../types";
import { getCurrentDate } from "../../utils/date/date.utils";

interface Props {
  open: boolean;
  onClose: () => void;
  apartmentId?: string | null;
  propertyId: string;
  fileName?: string;
  level?: ConsumptionPeriod;
}

const useStyles = makeStyles(() => ({
  content: { paddingBottom: 20 },
  errorAlert: {
    marginTop: 16,
  },
  subheader: {
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 5,
    color: SUBHEADER_COLOR,
    width: "100%",
    borderBottom: `1px solid ${BORDER_COLOR_SECONDARY}`,
  },
}));

function ExportConsumptionModal({
  open,
  onClose,
  apartmentId,
  propertyId,
  fileName,
  level,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { consumptionPeriod, date, consumptionTypes } = useFilterStore(
    fullFilterState,
    shallow
  );

  const [startDate, setStartDate] = useState<Moment>(moment());
  const [endDate, setEndDate] = useState<Moment>(moment());

  useEffect(() => {
    const getDates = () => {
      const startDate = getCurrentDate(date, consumptionPeriod);
      const endDate = getNextDate(date, consumptionPeriod, "next");

      setStartDate(startDate);
      setEndDate(endDate);
    };

    getDates();
  }, [consumptionPeriod, date, open]);

  const handleSubmit = async () => {
    await getConsumptionAsCsv(
      startDate,
      endDate,
      consumptionPeriod,
      propertyId,
      Array.from(consumptionTypes),
      apartmentId || null,
      fileName,
      level
    );
    onClose();
  };

  const invalidDates = moment(startDate).isAfter(moment(endDate));

  return (
    <Dialog
      open={open}
      dialogHeader={t("exportCsv")}
      onClose={onClose}
      maxWidth="sm"
      data-cy="new-tenant-dialog"
      onSubmit={handleSubmit}
      primaryButtonText={t("export")}
      background="white"
    >
      <div className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className={classes.subheader}>{t("period")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              format="DD/MM/yyyy"
              variant="dialog"
              label={t("from")}
              value={startDate}
              onChange={(date) => setStartDate(moment(date))}
              TextFieldComponent={(params: any) => (
                <Input {...params} fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              minDate={startDate}
              format="DD/MM/yyyy"
              variant="dialog"
              label={t("to")}
              value={endDate}
              onChange={(date) => setEndDate(moment(date))}
              TextFieldComponent={(params: any) => (
                <Input {...params} fullWidth />
              )}
            />
          </Grid>
        </Grid>
      </div>
      <ErrorAlert
        error={false}
        errorMessage={invalidDates ? t("errors.invalidDates") : ""}
        className={classes.errorAlert}
      />
    </Dialog>
  );
}

export default ExportConsumptionModal;
