import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

interface Props {
  className?: string;
  src?: string;
  icon?: JSX.Element;
  width?: number | string;
  height?: number | string;
  backgroundColor?: string;
  borderRadius?: string;
}

const useStyles = makeStyles(() => ({
  root: (props: Props) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: props?.width || 32,
    height: props?.height || 32,
    backgroundColor: props?.backgroundColor || "transparent",
    borderRadius: props?.borderRadius || 8,
  }),
  img: {
    flex: 1,
  },
}));

export default function Icon(props: Props) {
  const { className, src, icon } = props;
  const classes = useStyles(props);

  return (
    <div className={clsx(classes.root, className)}>
      {src && <img src={src} alt="" />}
      {icon}
    </div>
  );
}
