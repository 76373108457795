import React from "react";
import { makeStyles } from "@material-ui/core";
import { BORDER_RADIUS, CARD_ROW_BORDER, NAV_BAR_HEIGHT } from "../../../theme";
import Row from "../../Row";
import clsx from "clsx";

interface Props {
  className?: string;
  children?: JSX.Element | Array<JSX.Element | boolean>;
  header?: boolean;
  footer?: boolean;
  borderBottom?: boolean;
  justifyContent?: string;
}

const useStyles = makeStyles(() => ({
  root: (props: Props) => ({
    width: "100%",
    height: NAV_BAR_HEIGHT + 1,
    backgroundColor: "white",
    paddingLeft: BORDER_RADIUS,
    paddingRight: BORDER_RADIUS,
    boxShadow: props.footer ? "0px 0px 12px 0px rgba(0,0,0,0.1)" : "none",
    borderRadius: `
    ${props.header ? BORDER_RADIUS : 0}px 
    ${props.header ? BORDER_RADIUS : 0}px
    ${props.footer ? BORDER_RADIUS : 0}px
    ${props.footer ? BORDER_RADIUS : 0}px
    `,
    borderBottom: props.borderBottom ? CARD_ROW_BORDER : "none",
  }),
}));

export default function CardRow(props: Props) {
  const { children, className, justifyContent } = props;
  const classes = useStyles(props);
  return (
    <Row
      className={clsx(classes.root, className)}
      justifyContent={justifyContent}
    >
      {children}
    </Row>
  );
}
