import React, { useState } from "react";
import PageLayout from "../../../layouts/PageLayout/PageLayout";
import CardRow from "../../../components/Card/CardRow/CardRow";
import ButtonNew from "../../../components/ButtonNew";
import DataGatewayDialog from "./components/DataGatewayDialog";
import { useQuery } from "react-query";
import {
  ConsumptionType,
  GetDataGatewayPageDataQuery,
} from "../../../api/types";
import QueryKeys from "../../../api/QueryKeys";
import { gqlClient } from "../../../App";
import { gql } from "graphql-request";
import {
  Box,
  Button,
  Grid,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import Card from "../../../components/_deprecated/Card";
import Section from "../../../components/Section";
import BarChart from "../../../components/_deprecated/BarChart/BarChart";
import mapConsumptionHistory from "../../../utils/chart/mapConsumptionHistory";
import { ConsumptionPeriod } from "../../../types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { CardHeader } from "../../../components/Card";
import DvrIcon from "@material-ui/icons/Dvr";
import IconRight from "@material-ui/icons/ChevronRight";
import { SUBHEADER_COLOR } from "../../../theme";
import LoadingSkeletonGrid from "../../../components/LoadingSkeletonGrid";

const useStyles = makeStyles<Theme, any>((theme) => ({
  cardRoot: {
    margin: 0,
  },
  content: {
    paddingTop: 12,
  },
  cardHeader: {
    color: SUBHEADER_COLOR,
    "& > div:nth-child(2)": {
      "& > div": {
        "& > div": {
          maxWidth: 350,
          [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
          },
        },
      },
    },
  },
  header: {
    marginTop: 12,
    marginBottom: 12,
    textAlign: "center",
    fontSize: 12,
  },
}));

const usePageData = () =>
  useQuery<GetDataGatewayPageDataQuery>(
    [QueryKeys.PAGE_DATA_GATEWAY],
    async () => {
      return await gqlClient.request(
        gql`
          query GetDataGatewayPageData {
            dataGateways {
              id
              property {
                name
              }
              property {
                name
              }
              systemUsage {
                data {
                  week {
                    item
                    itemType
                    timestamp
                    totalPrice
                    unit
                    value
                  }
                }
              }
            }
          }
        `,
        {
          operationName: QueryKeys.PAGE_DATA_GATEWAY,
        }
      );
    }
  );

function DataGatewayPage() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const [
    newMiniserverDialogOpen,
    setNewMiniserverDialogOpen,
  ] = useState<boolean>(false);
  const [gatewayId, setGatewayId] = useState<string>();
  const { data, isLoading } = usePageData();

  const grid = (
    <Grid container spacing={2}>
      {data?.dataGateways
        .filter((g) => g !== null)
        .map((g, index) => (
          <Grid key={index} item xs={12} md={6}>
            <Card className={classes.cardRoot}>
              <CardRow borderBottom>
                <CardHeader
                  className={classes.cardHeader}
                  title={g?.property?.name || ""}
                  icon={<DvrIcon fontSize="small" />}
                  hideDropDownIcon
                  onClick={() => {
                    setNewMiniserverDialogOpen(true);
                    setGatewayId(g?.id);
                  }}
                />
                <Button
                  size="small"
                  variant="outlined"
                  endIcon={<IconRight />}
                  onClick={() => {
                    setNewMiniserverDialogOpen(true);
                    setGatewayId(g?.id);
                  }}
                >
                  Detail
                </Button>
              </CardRow>
              <Card.Content>
                <Section>
                  <Section.Content>
                    <Typography className={classes.header} variant="h6">
                      {t("CPU")}
                    </Typography>
                    <Box height={150}>
                      <BarChart
                        // @ts-ignore
                        config={{
                          ...mapConsumptionHistory(
                            // @ts-ignore
                            g?.systemUsage,
                            ConsumptionType.CpuInfo
                          )[ConsumptionPeriod.Week],
                          legends: [],
                          colors: ["#50D2A0"],
                          margin: { top: 12, left: 45, right: 0, bottom: 24 },
                          labelFormat: (v) => `${v} %`,
                          maxValue: 100,
                          axisLeft: {
                            tickValues: [0, 25, 50, 75, 100],
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            format: (v) => `${v} %`,
                          },
                        }}
                      />
                    </Box>
                  </Section.Content>
                  <Section.Content>
                    <Typography className={classes.header} variant="h6">
                      {t("HEAP")}
                    </Typography>
                    <Box height={150}>
                      <BarChart
                        // @ts-ignore
                        config={{
                          ...mapConsumptionHistory(
                            // @ts-ignore
                            g?.systemUsage,
                            ConsumptionType.MemoryUsage
                          )[ConsumptionPeriod.Week],
                          legends: [],
                          colors: ["#50D2A0"],
                          margin: { top: 12, left: 45, right: 0, bottom: 24 },
                          labelFormat: (v) => `${v} %`,
                          maxValue: 100,
                          axisLeft: {
                            tickValues: [0, 25, 50, 75, 100],
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            format: (v) => `${v} %`,
                          },
                        }}
                      />
                    </Box>
                  </Section.Content>
                </Section>
              </Card.Content>
            </Card>
          </Grid>
        ))}
    </Grid>
  );

  const header = (
    <CardRow header borderBottom justifyContent="space-between">
      <CardHeader title={t("serverPerformance")} />
      <ButtonNew onButtonNewClick={() => setNewMiniserverDialogOpen(true)} />
    </CardRow>
  );

  return (
    <PageLayout
      loading={false}
      error={undefined}
      layoutType="admin"
      sticky={header}
      contentMarginTop
    >
      <div>{isLoading && <LoadingSkeletonGrid />}</div>
      <Box padding={2}>{grid}</Box>
      <DataGatewayDialog
        onClose={() => {
          setNewMiniserverDialogOpen(false);
          setGatewayId("");
        }}
        open={newMiniserverDialogOpen}
        id={gatewayId}
      />
    </PageLayout>
  );
}

export default DataGatewayPage;
